import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export type IPartnerWithdrawalStatus = "success" | "failed" | "canceled" | "processing"

export interface IPartnerWithdrawal {
    id: string
    value: number
    status: IPartnerWithdrawalStatus
    pix: string
    createdAt: string
    finishedAt: string
}

export const partnerWithdrawals: IGenericModel<IPartnerWithdrawal> = {
    ...generic(endpoints.PARTNER_WITHDRAWALS),
}
