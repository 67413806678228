import { format } from "date-fns"
import { InputType } from "./constants"
import { formatDateBarTime } from "./helper"

export function formatCustomInputValue(inputType: InputType, value: any): string {
  switch (inputType) {
    case InputType.Date:
      // return value ? new Date(value)?.toLocaleString?.() : ""
      return value ? formatDateBarTime(value) : ""
    case InputType.Boolean:
      if (![null, undefined, ""].includes(value)) {
        return value == "1" ? "Sim" : "Não"
      }
      return ""
    case InputType.MultiSelect:
      try {
        if (typeof value == "string") {
          const parsed = JSON.parse(value)
          return parsed?.join?.(", ")
        }
        return value?.join?.(", ") || value
      } catch (e) {
        console.log(e)
        return value
      }
    // return typeof value == "boolean" ? ("Sim" : "Não") : value
    default:
      return value
  }
}

// export function formatDefaultInputValue(property: string, value: any): { label: string; formattedValue: string } {
//     switch (property) {
//         case 'subject':
//             return {
//                 label: "Assunto",
//                 formattedValue: value
//             }
//         case 'status':
//             return {
//                 label: "Status",
//                 formattedValue: (Ticket.StatusLabel as any)[value]
//             }
//         case 'priority':
//             return {
//                 label: "Prioridade",
//                 formattedValue: (Ticket.PriorityLabel as any)[value]
//             }
//         case 'type':
//             return {
//                 label: "Tipo",
//                 formattedValue: (Ticket.TypeLabel as any)[value]
//             }
//         case 'contact':
//             return {
//                 label: "Contato",
//                 formattedValue: getFullName(value as IContact)
//             }
//         case 'organization':
//             return {
//                 label: "Organização",
//                 formattedValue: (value as IOrganization)?.name
//             }
//         case 'createdAt':
//             return {
//                 label: "Criado em",
//                 formattedValue: new Date(value)?.toLocaleDateString?.()
//             }
//         case 'updatedAt':
//             return {
//                 label: "Atualizado em",
//                 formattedValue: new Date(value)?.toLocaleDateString?.()
//             }
//         case 'nextSlaBreach':
//             return {
//                 label: "Próxima violação de SLA",
//                 formattedValue: (value ? new Date(value)?.toLocaleString?.() : "")
//             }
//         case 'groupId':
//             return {
//                 label: "Grupo",
//                 formattedValue: store.getState().support.groups.all.find((group) => group._id == value)?.name || ""
//             }
//         case 'group':
//             return {
//                 label: "Grupo",
//                 formattedValue: value?.name || ""
//             }
//         case 'channelId':
//             return {
//                 label: "Canal",
//                 formattedValue: store.getState().support.channels.all.find((channel) => channel._id == value)?.name || ""
//             }
//         case 'assigneeId':
//             return {
//                 label: "Atribuído para",
//                 formattedValue: getFullName(store.getState().support.agents.all.find((agent) => agent._id == value)?.contact)
//             }

//         case 'assignee':
//             return {
//                 label: "Atribuído",
//                 formattedValue: getFullName(value)
//             }
//         case 'follower':
//             return {
//                 label: "Seguidor",
//                 formattedValue: getFullName(value)
//             }
//         case 'tag':
//             return {
//                 label: "Etiqueta",
//                 formattedValue: value?.name
//             }

//         default:
//             // Se for custom field
//             if (value.type) {
//                 return {
//                     label: property,
//                     formattedValue: formatCustomInputValue(value.type, value.value)
//                 }
//             }
//             return {
//                 label: property,
//                 formattedValue: value
//             }
//     }
// }
