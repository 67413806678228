import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum CommissionStatus {
    PENDING = "pending",
    CANCELED = "canceled",
    PAID = "paid",
}

export interface ICommissionTransaction {
    id: string
    _id: string
    createdAt: string
    updatedAt: string
    workspaceId: string

    value: number
    status: CommissionStatus
    paidAt: string
    receiptUrl: string
    monthReference: string

    invoices: {
        id: string
        workspace: {
            id: string
            avatar: string
            name: string
        }
        commissionPercentage: number
        commissionValue: number
        externalTransactionId: string
        payLink: boolean
        subscription: {
            id: string
            numbers: number
            instagramUnits: number
            users: number
            total: number
        }
        amount: number
        paidAt: string
    }[]
}

export const partnerCommissions: IGenericModel<ICommissionTransaction> = {
    ...generic(endpoints.PARTNER_COMMISSIONS),
}
