import ChannelsSelect from "components-old/ui/ChannelsSelect"
import { CHANNEL_TYPES } from "lib/constants"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { Form } from "antd"
import SelectReference from "pages/settings/workflows/actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import InputVariableFormItem from "components-old/forms/InputVariable/InputVariableFormItem"

interface FindConversationPropsAction extends BaseEditActionChildrenProps {}

const FindConversationAction = (props: FindConversationPropsAction) => {
  const fieldName = [...props.actionFieldName, "conversation"]

  return (
    <div>
      <Form.Item label="Contato" name={[...fieldName, "contactReference"]}>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.contact} />
      </Form.Item>
      <InputVariableFormItem field={WorkflowDefs.Fields.conversation.record.id} name={[...fieldName, "id"]} />
      <ChannelsSelect label="Canal*" placeholder="Selecione um canal" name={[...fieldName, "channelId"]} filter={(c) => c.channelId == CHANNEL_TYPES.WHATSAPP} />
    </div>
  )
}

export default FindConversationAction
