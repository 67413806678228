import React, { useState, useEffect, useRef, memo, useCallback, CSSProperties } from "react"
import { Dropdown, Form, Input, Menu, Row, Spin } from "antd"

import styles from "./style.module.css"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"
import { areEqual, FixedSizeList as List } from "react-window"
import cx from "classnames"

interface ISearchDropItem {
  searchValue: string
  key?: string
  node: React.ReactNode
}

interface ISearchDropProps {
  children: React.ReactNode
  options: ISearchDropItem[]
  title: string | React.ReactNode
  defaultSearchValue?: string
  loading?: boolean
  persistent?: boolean
  isAsync?: boolean
  onChange?: (value: string) => void
  width?: number
  virtual?: boolean
  visible?: boolean
  setVisible?: (visible: boolean) => void
  pure?: boolean
  overlayClassname?: string
  menuStyle?: CSSProperties
  overlayStyle?: CSSProperties
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined
}
export const SearchHeaderComponent = (props: any) => (
  <div style={{ padding: "8px 2px 0" }} className={styles.search_drop_header}>
    {props.children}
  </div>
)
export const SearchScrollerComponent = (props: any) => {
  return (
    <Spin spinning={!!props.loading}>
      <div
        style={{
          maxHeight: 405,
          // overflowY: "auto",
          // overflowX: "hidden",
          // padding: "0 8px 8px",
          paddingLeft: 8,
          minHeight: props.loading || props.isAsync ? 80 : 0,
          ...(props.virtual === false ? { overflowY: "auto", paddingRight: 8 } : {}),
        }}
      >
        {props.children}
      </div>
    </Spin>
  )
}

interface ISearchDropInput {
  onChange: (value: string) => void
  searchValue: string
  defaultSearchValue?: string
  visible?: boolean
  title?: string | React.ReactNode
}

export const SearchDropInput = memo((props: ISearchDropInput) => {
  const searchRef = useRef<any>()
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)

  useEffect(() => {
    if (!isMobile) {
      // searchRef?.current?.focus?.()
      setTimeout(() => {
        searchRef?.current?.focus()
        props.onChange(props.defaultSearchValue || "")
      }, 50)
    }
  }, [searchRef, props.visible, isMobile, props.title])

  return (
    <div>
      <Row>
        <Form.Item noStyle>
          <Input
            value={props.searchValue}
            onChange={(e) => props.onChange(e.target?.value)}
            ref={searchRef}
            // style={{
            //     margin: 8,
            //     width: "100%",
            //     borderRadius: 4,
            //     height: 32,
            //     minHeight: 32,
            //     backgroundColor: "var(--search-bg)"
            // }}
            placeholder="Pesquisar"
            // autoFocus
            size="small"
            className={styles.search_input}
          />
        </Form.Item>
      </Row>
    </div>
  )
})

function SearchDrop(props: ISearchDropProps) {
  const [visible, setVisible] = useState(false)
  const [searchValue, setSearchValue] = useState<string>(props.defaultSearchValue || "")
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const [key, setKey] = useState(1)

  const dropProps = {
    ...(props.persistent ? { visible: props.setVisible ? props.visible : visible } : {}),
  }

  const items = props.options.filter((opt) => {
    return opt.searchValue?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase())
  })

  const Row = ({ index, style, ...rest }) => {
    return (
      <div style={{ ...style, width: "calc(100% - 8px)" }} key={index}>
        {items[index]?.node}
      </div>
    )
  }

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        if (visible || props.visible) {
          setKey((prevKey) => prevKey + 1)
          setVisible(false)
          props.setVisible?.(false)
        }
      }
    },
    [props, visible]
  )

  // On ESC key press, close dropdown
  useEffect(() => {
    if (visible) {
      window.removeEventListener("keydown", handleEsc)
      window.addEventListener("keydown", handleEsc)
    }
    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [handleEsc, visible])

  return (
    <Dropdown
      {...dropProps}
      key={key}
      trigger={["click"]}
      onVisibleChange={(visible) => {
        setVisible(visible)
        props.setVisible?.(visible)
      }}
      // className={styles.search_drop}
      destroyPopupOnHide
      getPopupContainer={
        props.getPopupContainer
          ? (triggerNode) => {
              return triggerNode
            }
          : undefined
      }
      overlayClassName={cx(
        styles.search_drop_overlay,
        {
          [styles.pure]: props.pure,
        },
        props.overlayClassname
      )}
      overlayStyle={props.overlayStyle}
      overlay={
        <Menu
          style={{
            width: isMobile ? window.innerWidth - 40 : props.width || 250,
            overflow: "hidden",
            ...(props.width ? { minWidth: props.width } : {}),
            padding: 0,
            marginLeft: isMobile ? 20 : 0,
            ...props.menuStyle,
          }}
          key="menu"
          className="min-w-[300px]"
        >
          <SearchHeaderComponent key="header">
            <div
              style={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: 5,
              }}
            >
              {props.title}
            </div>
            <SearchDropInput
              onChange={(value) => {
                setSearchValue(value)
                props.onChange?.(value)
              }}
              visible={visible}
              searchValue={searchValue}
              defaultSearchValue={props.defaultSearchValue}
              title={props.title}
            />
          </SearchHeaderComponent>
          {props.pure ? (
            items?.map((opt) => {
              return opt.node
            })
          ) : (
            <SearchScrollerComponent loading={props.loading} isAsync={props.isAsync} key="scroller" virtual={props.virtual}>
              {props.virtual != false ? (
                <List
                  itemCount={items?.length}
                  height={Math.min(items?.length * 36 + 8, 405)}
                  itemSize={36}
                  width={"100%"}
                  itemData={items}
                  style={{
                    paddingBottom: 8,
                    minHeight: 44,
                  }}
                  // itemKey={(index, data) => {
                  //     return data[index]?.key!
                  // }}
                >
                  {Row}
                </List>
              ) : (
                <div style={{ paddingBottom: 8 }}>
                  {items?.map((opt) => {
                    return <span key={opt.key}>{opt.node}</span>
                  })}
                </div>
              )}
            </SearchScrollerComponent>
          )}
        </Menu>
      }
    >
      {props.children}
    </Dropdown>
  )
}

export default memo(SearchDrop)
