import { Button, Dropdown, Form, Input, Menu, Tooltip } from "antd"
import MenuFragment from "components-old/MenuFragment"
import { LabelHelp } from "components-old/forms/LabelHelp"
import React from "react"
import { modalState } from "atoms/app.atom"
import { useSetRecoilState } from "recoil"
import JsonPathModal from "../JsonPathModal"
import { VariableEnum } from "pages/settings/workflows/workflow.enums"
import { InputField, WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import CustomFieldInput from "components-old/forms/CustomFieldInput"
import { InputType, InputTypeFromPrimitive } from "lib/constants"
import { CursorMagicSelection01Icon } from "components-old/icons"

interface FallbackDropProps {
  onChange: any
  onChangeJsonPath: any
  children: React.ReactNode
  fallback?: string
  jsonPath?: string
  setVisible?: any
  type?: VariableEnum
  reference?: string
  field?: InputField
}

const parseDefaultValue = (value: any, field) => {
  let parsedValue = value
  if (field.type === InputType.MultiSelect) {
    parsedValue = value?.split?.(",")
  }
  if (field.type === InputType.Boolean) {
    parsedValue = value === "1"
    return {
      defaultChecked: parsedValue,
      checked: undefined,
    }
  }

  return {
    defaultValue: parsedValue,
  }
}

function FallbackDrop(props: FallbackDropProps) {
  const setModal = useSetRecoilState(modalState)
  const variable = props.type && WorkflowDefs.VariableRecord[props.type]
  const [visible, setVisible] = React.useState(false)

  const field: InputField = props.field || {
    label: variable?.label || "",
    icon: variable?.icon!,
    type: InputTypeFromPrimitive[variable?.primitiveType || "string"],
    name: variable?.customFieldKey || "",
    description: variable?.description,
    isCustomField: !!variable?.customFieldKey,
    options: [],
  }

  return (
    <Dropdown
      destroyPopupOnHide
      visible={visible}
      overlay={
        <Menu
          className="p-2.5"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          }}
        >
          <MenuFragment>
            <div className="px-2">
              <Form layout="vertical">
                {variable?.primitiveType === "json" && (
                  <div className="relative">
                    <Form.Item
                      label={
                        <LabelHelp
                          help={
                            <div className="space-y-2">
                              <p>JSONPath é uma linguagem de consulta para JSON.</p>
                              <p>Use o JSONPath para acessar valores em um objeto JSON.</p>
                              <p>
                                Exemplo: <span className="text-amber-400">$.customFields.idade.value</span>
                              </p>
                              <a className="!underline !text-white" href="https://jsonpath.com/" target="_blank" rel="noreferrer">
                                Saiba mais
                              </a>
                            </div>
                          }
                        >
                          JSONPath
                        </LabelHelp>
                      }
                      className="mb-3"
                    >
                      <Input
                        placeholder="$.path.to.value"
                        // value={props.currentMentionSelected?.id}
                        // className="h-8 min-h-[32px] text-sm pl-1.5"
                        className="min-w-[250px] w-full"
                        defaultValue={props.jsonPath}
                        onChange={(e) => {
                          props.onChangeJsonPath(e.target.value)
                          // updateMentionFallbacksDebounced(e.target.value)
                        }}
                      />
                    </Form.Item>
                    <div className="absolute top-[36px] right-1.5">
                      <Tooltip title="Selecionar caminho">
                        <Button
                          type="text"
                          icon={<CursorMagicSelection01Icon className="text-primary [&>*]:!stroke-2" />}
                          // icon={<i className="fas fa-project-diagram text-primary"></i>}
                          size="small"
                          className="!rounded p-0"
                          htmlType="button"
                          onClick={() => {
                            setVisible(false)
                            props.setVisible?.(false)
                            setModal(
                              <JsonPathModal
                                onBack={() => {
                                  setModal(null)
                                }}
                                onSubmit={(jsonPath) => {
                                  props.onChangeJsonPath(jsonPath)
                                }}
                                type={props.type!}
                                jsonPath={props.jsonPath}
                              />
                            )
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/* <TagColor className="absolute top-[40px] right-2" palette={"red"} animated>
                      <i
                        className="fas fa-exclamation-triangle"
                        style={{
                          color: colors.red[600],
                        }}
                      ></i>
                      Caminho inválido
                    </TagColor> */}
                  </div>
                )}
                <Form.Item
                  label={<LabelHelp help="O valor do fallback é usado como reserva quando a variável não tem valor.">Fallback</LabelHelp>}
                  className="mb-2"
                >
                  <CustomFieldInput
                    htmlInputProps={parseDefaultValue(props.fallback, field)}
                    field={field}
                    onChange={(e) => {
                      props.onChange(e?.target?.value || e)
                      // updateMentionFallbacksDebounced(e.target.value)
                    }}
                  />
                  {/* <Input
                    placeholder=""
                    // value={props.currentMentionSelected?.id}
                    className="h-8 min-h-[32px] text-sm pl-1.5"
                    defaultValue={props.fallback}
                    onChange={(e) => {
                      props.onChange(e.target.value)
                      // updateMentionFallbacksDebounced(e.target.value)
                    }}
                  /> */}
                </Form.Item>
              </Form>
            </div>
          </MenuFragment>
        </Menu>
      }
      onVisibleChange={(visible) => {
        setVisible(visible)
        props.setVisible?.(visible)
      }}
      trigger={["click"]}
      placement="bottomLeft"
    >
      {props.children}
    </Dropdown>
  )
}

export default FallbackDrop
