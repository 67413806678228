import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

type ITransactionStatus = "paid" | "pending_payment" | "unpaid" | "canceled" | "ended" | "trialing" | "waiting_payment"

export interface ITransaction {
    id: string
    createdAt: string
    expiresAt: string
    amount: number
    boleto_url: string
    boleto_barcode: string
    card_brand: string
    card_holder_name: string
    card_last_digits: string
    status: ITransactionStatus
}

export const transactions: IGenericModel<ITransaction> = {
    ...generic(endpoints.PAYMENT_TRANSACTIONS)
}