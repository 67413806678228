import { Button } from "antd"
import { TestTube01Icon } from "components-old/icons"
import Empty from "components-old/shared/table/Empty"
import AnalyticsJSONViewer from "pages/settings/workflows/analytics/AnalyticsJSONViewer"
import React, { Suspense } from "react"

interface MakeRequestResponseProps {
  loading: boolean
  response: any
  testRequest: () => void
}

const LottieAnimation = React.lazy(() => import("../../../../../crm/campaigns/LottieRocket"))

function MakeRequestResponse(props: MakeRequestResponseProps) {
  if (props.loading) {
    return (
      <div className="flex-center flex-col gap-2 h-full">
        <Suspense fallback={<div className="h-52 w-52"></div>}>
          <LottieAnimation />
        </Suspense>
        <p className="text-secondary-300">Enviando requisição...</p>
      </div>
    )
  }

  if (props.response) {
    return (
      <div className="flex-center flex-col gap-2 h-full">
        <p className="text-xl font-bold -mt-4">Resposta obtida</p>
        {typeof props.response === "object" ? (
          <AnalyticsJSONViewer
            json={props.response}
            id="response"
            className="min-w-[400px] h-52 border-2 bg-transparent border-dashed border-divider-100 overflow-auto max-w-xl"
          />
        ) : (
          props.response
        )}
        <Button icon={<TestTube01Icon />} onClick={props.testRequest} className="mt-2 !text-primary rounded-xl" type="text">
          Testar Requisição
        </Button>
      </div>
    )
  }

  return (
    <div className="flex-center flex-col gap-2 h-full">
      <Empty className="h-52 w-52" />
      <p className="text-xl font-bold -mt-4">Sem resposta</p>
      <p>Teste sua requisição para obter uma resposta</p>
      <Button icon={<TestTube01Icon />} onClick={props.testRequest} className="mt-2 !text-primary rounded-xl" type="text">
        Testar Requisição
      </Button>
    </div>
  )
}

export default MakeRequestResponse
