import { getUrlBase } from "lib/helper"
import { IEnv } from "lib/request"

const envs: IEnv[] = [
  {
    url: "https://staging-api.kinbox.com.br/api",
    socketUrl: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    socketUrlB: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    v2SocketUrl: "https://api4-staging.kinbox.com.br/workspace",
    v1Url: "https://staging-api-v1.kinbox.com.br/api",
    v2Url: "https://api4-staging.kinbox.com.br",
    widgetUrl: "https://widget.kinbox.com.br",
    key: "staging",
  },
  {
    url: "https://kinbox-api-v0-bk-g6pbb.ondigitalocean.app/api",
    socketUrl: "https://notificator.kinbox.com.br",
    socketUrlB: "https://kinbox-api-v0-schedulers-fqnf8.ondigitalocean.app",
    v2SocketUrl: "https://notificator-v2.kinbox.com.br/workspace",
    key: "production",
    v1Url: "https://kinbox-api-sr7d7.ondigitalocean.app/api",
    v2Url: "https://api4.kinbox.com.br",
    widgetUrl: "https://widget.kinbox.com.br",
  },
  {
    url: "https://staging-api.kinbox.com.br/api",
    socketUrl: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    socketUrlB: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    v2SocketUrl: "http://localhost:3000/workspace",
    v1Url: "https://staging-api-v1.kinbox.com.br/api",
    v2Url: "http://localhost:3000",
    widgetUrl: "http://localhost:3005",
    key: "dev",
  },
  {
    url: "http://localhost:3000/api",
    socketUrl: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    socketUrlB: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    v2SocketUrl: "https://api4-staging.kinbox.com.br/workspace",
    key: "localhost",
    v1Url: "https://staging-api-v1.kinbox.com.br/api",
    v2Url: "https://api4-staging.kinbox.com.br",
    widgetUrl: "http://localhost:3005",
  },
  {
    url: "http://localhost:3000/api",
    socketUrl: "https://notificator.kinbox.com.br",
    socketUrlB: "https://kinbox-api-v0-schedulers-fqnf8.ondigitalocean.app",
    v2SocketUrl: "https://notificator-v2.kinbox.com.br/workspace",
    key: "localhost-prod",
    v1Url: "https://kinbox-api-sr7d7.ondigitalocean.app/api",
    v2Url: "https://api4.kinbox.com.br",
    widgetUrl: "https://widget.kinbox.com.br",
  },
  { url: "", socketUrl: "", socketUrlB: "", key: "custom", v1Url: "", v2Url: "", widgetUrl: "", v2SocketUrl: "" },
  {
    url: "mock",
    socketUrl: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    socketUrlB: "https://kinbox-api-v0-staging-notificat-9nl4h.ondigitalocean.app",
    v2SocketUrl: "https://api4-staging.kinbox.com.br/workspace",
    key: "mock",
    v1Url: "https://staging-api-v1.kinbox.com.br/api",
    v2Url: "https://api4-staging.kinbox.com.br",
    widgetUrl: "http://localhost:3005",
  },
]

export default envs

export const envKeys = {
  production: {
    facebook_app_id: "406703890178760",
    ml_client_id: "523359125402684",
    ml_redirect_url: `${getUrlBase()}/settings/channels/new/mercado-livre/oauth`,
  },
  staging: {
    facebook_app_id: "940205773499170",
    ml_client_id: "523359125402684",
    ml_redirect_url: "https://kinbox-staging.netlify.app/settings/channels/new/mercado-livre/oauth",
  },
}
