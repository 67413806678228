import React, { useEffect, useState } from "react"
import { Alert, Form } from "antd"
import { getResourcesToExportPaginated } from "lib/export.helper"
import { endpoints } from "config/endpoints.config"
import toast from "lib/toast"
import request from "lib/request"
import { IConversation } from "models/conversations"
import ExecutinActionModal from "components-old/ExecutingActionModal"
import FormModal from "components-old/forms/FormModal"
import { IChannel } from "models/channels"
import ChannelsSelect from "components-old/ui/ChannelsSelect"
import { CHANNEL_TYPES } from "lib/constants"
import { LabelHelp } from "components-old/forms/LabelHelp"

interface IForwardZapModalProps {
  channel: IChannel
  onBack?: () => void
}

let cancelAction = false

function ForwardZapModal(props: IForwardZapModalProps) {
  const [form] = Form.useForm()
  const [executingActions, setExecutingActions] = useState<{ executingLabel: string }>()
  const [progress, setProgress] = useState(0)
  const [actionProgressCounts, setActionProgressCounts] = useState([0, 0])
  // const { all: channels } = useStoreState((state) => state.channels)

  useEffect(() => {
    cancelAction = false
  }, [])

  const exec = async (dto: { executingLabel: string; endpoint: string; params: any; action: (elem: any) => Promise<void> }) => {
    setExecutingActions({ executingLabel: dto.executingLabel })
    const result = await getResourcesToExportPaginated<IConversation>(dto.endpoint, {
      params: dto.params,
      notToast: true,
    })

    const conversations = result.result
    setActionProgressCounts([conversations.length, 0])

    for (let index = 0; index < conversations.length; index++) {
      if (cancelAction) {
        break
      }
      const element = conversations[index]
      setProgress(((index + 1) / conversations.length) * 100)
      setActionProgressCounts([conversations.length, index + 1])
      await dto.action(element)
    }
  }

  async function onSubmit(model: any) {
    await exec({
      executingLabel: "Migrando conversas",
      endpoint: endpoints.INBOX,
      params: {
        filters: JSON.stringify({ channels: [{ id: props.channel?.id }] }),
      },
      action: async (element) => {
        await request.post(`${endpoints.TRANSFER_SINGLE_CONVERSATION_CHANNEL}/${element?.id}`, {
          transferToId: model.channelId,
          transferFromId: props.channel?.id,
        })
      },
    })

    if (cancelAction) {
      toast.warning("Ação abortada durante execução")
      return props.onBack?.()
    }

    return props.onBack?.()
  }

  if (executingActions) {
    return (
      <ExecutinActionModal
        executingLabel={executingActions.executingLabel}
        doneCount={actionProgressCounts[1]}
        totalCount={actionProgressCounts[0]}
        progress={progress}
        onCancel={() => {
          cancelAction = true
        }}
      />
    )
  }

  return (
    <FormModal
      form={form}
      onSubmit={onSubmit}
      type="modal"
      titleOverwrite="Migrar conversas"
      hideSuccessMessage
      //   successMessage="Membro excluído."
      //   formProps={{
      //     initialValues: {
      //       ...props.message,
      //     },
      //   }}
      okText="Migrar"
      saved={false}
    >
      <p>
        Migra todas as conversas existentes atualmente do canal <strong className="text-primary">{props.channel?.description}</strong> para outro canal de
        WhatsApp que você tenha.
      </p>
      <Alert
        className="mt-4"
        type="warning"
        description="É um procedimento que deve ser efetuado com cautela pois vai parecer que a conversa sempre foi do canal de destino. Mas pode ser útil para quando um canal é bloqueado por exemplo, caso se queira preservar o histórico de conversas."
      ></Alert>
      <ChannelsSelect
        name="channelId"
        label="Migrar para:"
        filter={(c) => c.channelId == CHANNEL_TYPES.WHATSAPP && c.id !== props.channel?.id}
        required
        placeholder="Selecione o canal que irá receber as conversas"
        allowClear={false}
      />
    </FormModal>
  )
}

export default ForwardZapModal
