import React from "react"
import { Col, Row, Select } from "antd"
import { SelectProps } from "antd/lib/select"
import { useStore, useStoreState } from "hooks"
import { getAsset, getMembersToAssign } from "lib/helper"
import Avatar from "../Avatar"
import { session } from "store"
import CustomSelect from "components-old/forms/CustomSelect"
import { twMerge } from "tailwind-merge"

interface IAgentsSelectProps extends SelectProps<any> {
  checkDisabled?: Function
  unassigned?: boolean
  beforeOption?: React.ReactNode
}

function AgentsSelect({ checkDisabled, beforeOption, unassigned, ...props }: IAgentsSelectProps) {
  const operatorStore = useStoreState((state) => state.members)
  const { all: groups } = useStoreState((state) => state.groups)
  const { user } = useStore(session)

  return (
    <CustomSelect allowClear showSearch {...props}>
      {beforeOption}
      {unassigned && (
        <Select.Option value={0} key={0} data-search="Não atribuído">
          <div className="flex gap-2 flex-nowrap truncate h-full items-center">
            <i className="far fa-user-slash w-5" />
            <span className="truncate">Não atribuído</span>
          </div>
        </Select.Option>
      )}
      {getMembersToAssign(user.id, operatorStore.all, groups).map((operator: any) => (
        <Select.Option value={operator.id} key={operator.id} disabled={checkDisabled?.(operator)} data-search={operator.name}>
          <div className="flex gap-2 flex-nowrap truncate h-full items-center">
            <Avatar name={operator.name} index={operator.id} fontSize={10} size={20} imgSrc={getAsset(operator.avatar!)} />
            <span className={twMerge("truncate", !operator?.isActive && "line-through")}>
              {operator.name}
              {user.id == operator.id ? " (Você)" : ""}
            </span>
          </div>
        </Select.Option>
      ))}
    </CustomSelect>
  )
}

export default AgentsSelect
