import { FilterState } from "components-old/forms/FiltersInput"
import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export enum GoalType {
  DEAL_VALUE = "deal-value",
  DEAL_PRODUCT = "product-count",
}

export interface Goal extends GenericResource {
  name: string
  description: string
  from: string
  to: string
  targetValue: number
  selectBy: string
  operatorIds?: string[]
  productIds?: string[]
  type: GoalType
  groupIds?: string[]
  authorId: number
  conditions?: FilterState
  milestones: {
    name: string
    targetValue: number
    type: "global" | "operator" | "group" | "all_operators"
    operatorId?: string
    groupId?: string
  }[]
}

export interface GoalReport {
  totals: {
    totalDeals: number
    avgTicket: number
    totalValue: number
    avgLifetime: number // dias
  }[]
  valueByOperator: { _id: string; totalValue: number; totalCount: number }[]
  valueByGroup: { _id: string; totalValue: number; totalCount: number }[]
  period: {
    totalValue: number
    totalDeals: number
    day: string // YYYY-MM-DD
  }[]
  periodByOperator: {
    totalValue: number
    totalDeals: number
    operatorId: number
    day: string // YYYY-MM-DD
  }[]
  data?: {
    value: number
    group: string
  }[]
}

export const goals: IGenericModel<Goal> = {
  ...generic(endpoints.GOALS),
}
