import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IAgentMessage {
    id?: string
    createdAt?: string
    channelId: string
    tempId?: string
    type?: "audio" | "image" | "video" | "file"
    text: string
    Operator: {
        id: string
        avatar?: string
        email: string
        name: string
    }
}

export const agentMessages: IGenericModel<IAgentMessage> = {
    ...generic(endpoints.AGENT_MESSAGES)
}