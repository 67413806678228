import React, { useMemo } from "react"
import { useStoreState } from "hooks"
import { FolderShared01Icon, Tag01Icon, UserIdVerificationIcon } from "components-old/icons"
import AgentAvatar from "components-old/AgentAvatar"
import ColorEmojiIcon from "components-old/forms/ColorEmojiIcon"
import { TagItem } from "components-old/chat/TagInput"
import { convertColorToTagColor } from "pages/settings/tags/EditTag"
import { WorkflowNodeData } from "../../workflow.defs"
import BaseExpanderFields from "../../actions/BaseExpanderFields"
import { CategoryEnum } from "../../workflow.enums"

interface ConversationNodeCoreProps {
  fields?: {
    icon: React.ElementType
    value: React.ReactNode
    label?: string
  }[]
  data: WorkflowNodeData
}

export interface NodeField {
  icon: React.ElementType
  value: React.ReactNode
  label?: string
}

function ConversationNodeCore(props: ConversationNodeCoreProps) {
  const { all: members } = useStoreState((state) => state.members)
  const { all: groups } = useStoreState((state) => state.groups)
  const { all: tags } = useStoreState((state) => state.tags)

  const { fields } = useMemo(() => {
    // Member
    const member = members.find((m) => +m.id == props.data?.action?.conversation?.operatorId)

    // Group
    const group = groups.find((g) => +g.id == props.data?.action?.conversation?.groupId)

    let fields: NodeField[] = []
    const conversation = props.data?.action?.conversation
    if (conversation?.operatorId) {
      fields.push({
        icon: UserIdVerificationIcon,
        value: (
          <div className="flex items-center gap-2 truncate shadow dark:bg-base-200 px-2 rounded-full">
            <AgentAvatar agent={member!} size={16} />
            <span className="truncate">{member?.name}</span>
          </div>
        ),
        label: "Agente atribuído",
      })
    }
    if (conversation?.groupId) {
      fields.push({
        icon: FolderShared01Icon,
        value: (
          <div className="flex items-center gap-2 truncate shadow dark:bg-base-200 px-2 rounded-full">
            <ColorEmojiIcon icon={group?.icon} size={16} />
            <span className="truncate">{group?.name}</span>
          </div>
        ),
        label: "Grupo",
      })
    }
    if (conversation?.tagIds) {
      fields.push({
        icon: Tag01Icon,
        value: (
          <div className="flex items-center gap-2">
            {conversation?.tagIds?.map((tagId: number) => {
              const tag = tags.find((g) => +g.id == tagId)
              if (!tag) return null
              return (
                <TagItem key={tagId} colors={convertColorToTagColor(tag?.color!)}>
                  {tag?.name}
                </TagItem>
              )
            })}
          </div>
        ),
        label: "Tags",
      })
    }

    return {
      fields: [...(props.fields || []), ...fields],
    }
  }, [groups, members, props.data?.action?.conversation, props.fields, tags])

  return <BaseExpanderFields fields={fields} category={CategoryEnum.CONVERSATION} />
}

export default ConversationNodeCore
