import { Form, Menu } from "antd"
import { AddListButton } from "components-old/shared/table/DnDFormList/AddListButton"
import SearchDrop from "components-old/ui/SearchDrop"
import { InputTypeIconNew } from "lib/constants"
import React from "react"
import { InputField } from "../workflow.defs"
import { useThrottledCallback } from "use-debounce"
import BaseUpdateActionItem from "./BaseUpdateActionItem"
import _ from "lodash"

// export interface BaseUpdateActionOption {
//   label: string
//   icon: React.ElementType
//   type: InputType
//   name: string
// }

interface BaseUpdateActionProps {
  form: any
  fieldName: string | string[]
  fields: {
    record: Record<string, InputField>
    array: InputField[]
  }
  extraFields?: InputField[]
}

function BaseUpdateAction(props: BaseUpdateActionProps) {
  const options = useThrottledCallback((formInstance) => {
    let options: any = []

    const array = [...(props.extraFields || []), ...(props.fields.array || [])]
    for (let index = 0; index < array.length; index++) {
      const opt = array[index]
      if (opt.selectable === false) continue
      let name = opt.isCustomField ? [...props.fieldName, "customFields", opt.name] : [...props.fieldName, opt.name]

      const alreadyExists = formInstance?.getFieldValue(name) != undefined
      const Icon = opt.icon || InputTypeIconNew?.[opt.type]
      options.push({
        searchValue: opt.label,
        key: opt.name,
        node: (
          <Menu.Item
            key={opt.name}
            eventKey={opt.name}
            icon={
              <i>
                <Icon className="text-primary" />
              </i>
            }
            disabled={alreadyExists}
            onClick={(e) => {
              formInstance.setFields([
                {
                  name,
                  value: "",
                },
              ])
            }}
          >
            {opt.label}
          </Menu.Item>
        ),
      })
    }

    return options
  }, 300)

  return (
    <Form.Item shouldUpdate noStyle>
      {(formInstance) => {
        const propertiesMap = formInstance?.getFieldValue(props.fieldName) || {}
        const propertiesMapKeys = Object.keys(propertiesMap)
        const customFieldsKeys = propertiesMap.customFields ? Object.keys(propertiesMap.customFields) : []
        const combined = _.uniq([...propertiesMapKeys, ...customFieldsKeys])

        return (
          <Form.Item>
            {props.extraFields
              ?.filter((x) => combined.includes(x.name))
              .map?.((x) => {
                return (
                  <BaseUpdateActionItem
                    key={x.name}
                    fieldName={props.fieldName}
                    propertiesMap={propertiesMap}
                    fieldKey={x.name}
                    formInstance={formInstance}
                    name={x.name}
                    optionRecord={x}
                  />
                )
              })}
            {combined
              ?.filter((key) => key !== "id")
              .map((key, i) => {
                const optionRecord = props.fields.record[key]
                const name = optionRecord?.isCustomField ? [...props.fieldName, "customFields", key, "value"] : [...props.fieldName, key]
                if (!optionRecord) return null

                return (
                  <BaseUpdateActionItem
                    key={key}
                    fieldName={props.fieldName}
                    propertiesMap={propertiesMap}
                    fieldKey={key}
                    formInstance={formInstance}
                    name={name}
                    optionRecord={optionRecord}
                  />
                )
              })}
            <SearchDrop
              title="Campos"
              options={options(formInstance) || []}
              overlayStyle={{
                minWidth: 300,
              }}
            >
              {/* className={!propertiesMapKeys.length && "w-full"} */}
              <AddListButton>Campo</AddListButton>
            </SearchDrop>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

export default BaseUpdateAction
