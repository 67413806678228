import { ColumnDef, SortingState, Updater } from "@tanstack/react-table"
import { isMobileAtom } from "atoms/app.atom"
import { GenericResource } from "models/generic"
import React from "react"
import { useRecoilState } from "recoil"
import { VirtualInfiniteTableDesktop } from "./VirtualInfiniteTableDesktop"
import { VirtualInfiniteTableMobile } from "./VirtualInfiniteTableMobile"

export interface VirtualInfiniteTableProps<T extends GenericResource> {
  columns: ColumnDef<T>[]
  data: T[]
  fetchNextPage: () => void
  isFetching: boolean
  isFetchingNextPage?: boolean
  isLoading: boolean
  totalCount: number
  windowMode?: boolean
  transparentHeader?: boolean
  onClickRow?: (row: T) => void
  className?: string
  local?: boolean
  isFiltering?: boolean
  onSaveNewPositions?: (newPositions: T[]) => void
  children?: React.ReactNode
  overscan?: number

  // If paginated
  sorting?: SortingState
  onSortChange?: (sortState: Updater<SortingState>) => void
}

function VirtualInfiniteTable<T extends GenericResource>(props: VirtualInfiniteTableProps<T>) {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)

  return isMobile || !props.local ? <VirtualInfiniteTableMobile {...props} /> : <VirtualInfiniteTableDesktop {...props} />
}

export default VirtualInfiniteTable
