import { Form, Switch } from "antd"
import { workflowElementsAtom } from "atoms/workflow.atom"
import { TagItem } from "components-old/chat/TagInput"
import { useStoreState } from "hooks"
import { Workflow } from "models/workflows"
import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

interface ActiveWorkflowButtonProps {
  getFieldValue: any
}

function ActiveWorkflowButton(props: ActiveWorkflowButtonProps) {
  const { all } = useStoreState((state) => state.workflows)
  const { id } = useParams<any>()

  const itemFromStore: Workflow | undefined = useMemo(() => {
    const item = all.find((x) => x.id == id)
    return item
  }, [all, id])

  const [elements, setElements] = useRecoilState(workflowElementsAtom)
  const hasTriggers = useMemo(() => {
    return Object.values(elements?.[0]?.data?.action?.triggers || {}).length > 0
  }, [elements?.[0]?.data?.action?.triggers])

  return (
    <div className="flex items-center gap-2 mr-2">
      <Form.Item name="active" noStyle valuePropName="checked">
        <Switch
        // onChange={(checked) => {
        //   if (checked && !verifyPaths(props.elements)) {
        //     setFieldsValue({
        //       isActive: false,
        //     })
        //     const failedNode: any = props.elements.find((el) => el.id == nodeFailedId)
        //     transform(
        //       {
        //         x: failedNode?.position?.x * -1 + window.innerWidth / 2 - 120,
        //         y: failedNode?.position?.y * -1 + window.innerHeight / 2,
        //         zoom: 1,
        //       },
        //       700
        //     )
        //     setSelectedElementsFlow([failedNode])

        //     toast.error('Necessário que todos os caminhos acabem no nó "Fim do Bot" para ativar esse Bot.')
        //   }
        // }}
        />
      </Form.Item>
      {hasTriggers &&
        (itemFromStore?.active ? (
          <div className="flex items-center gap-1 rounded-full bg-emerald-100 dark:bg-emerald-600 px-1 py-0.5 text-emerald-900 dark:text-emerald-50 text-xs border border-emerald-200 dark:border-emerald-600 border-solid">
            <div className="flex items-center gap-1 px-1">
              <div className="live-pulser">
                <div className="h-[9px] w-[9px] rounded-full bg-emerald-600 dark:bg-emerald-50 font-medium"></div>
              </div>
              Live
            </div>
          </div>
        ) : (
          <TagItem>Inativo</TagItem>
        ))}
    </div>
  )
}

export default ActiveWorkflowButton
