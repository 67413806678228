import React, { useState } from "react"
import { Button, Form } from "antd"
import { WorkflowDefs } from "../../workflow.defs"
import { WorkflowTriggerItem } from "../workflow.triggers.defs"
import AddTriggerBtn from "./AddTriggerBtn"
import { BaseEditActionChildrenProps } from "../BaseEditAction"
import CollapseCards from "pages/crm/deals/ViewDeal/CollapseCards"
import { Delete03Icon } from "components-old/icons"
import { ActionEnum } from "../../workflow.enums"

interface EditTriggerProps extends BaseEditActionChildrenProps {}

function EditTrigger(props: EditTriggerProps) {
  const fieldName = [...props.actionFieldName, "triggers"]
  const [activeKeys, setActiveKeys] = useState<any>(Object.keys(props.form?.getFieldValue(fieldName) || {}))

  return (
    <div>
      <Form.Item shouldUpdate noStyle>
        {(formInstance) => {
          const triggersObj = formInstance.getFieldValue(fieldName)
          const triggers = Object.keys(triggersObj || {}).map((triggerKey) => {
            return {
              def: WorkflowDefs.trigger.TriggerRecord[triggerKey as ActionEnum] as WorkflowTriggerItem,
            }
          })

          return (
            <CollapseCards
              activeKey={activeKeys}
              onChange={(keys) => {
                setActiveKeys(keys)
              }}
              destroyInactivePanel={false}
              bordered
              panels={triggers.map((trigger) => {
                return {
                  key: trigger.def.type,
                  forceRender: true,
                  title: (
                    <div className="h-12 relative flex items-center gap-4 rounded-lg justify-start text-left w-full">
                      <trigger.def.icon className="text-green-600 !w-6 !h-6" />
                      <div className="leading-5 -mt-1">
                        <div className="text-content-200 text-xs font-normal">
                          {WorkflowDefs.trigger.CategoryRecord[trigger.def?.category].label}
                        </div>
                        <div className="font-medium text-md">{trigger.def.name}</div>
                      </div>
                      {/* <div className="absolute right-3 top-3">
                  <Tooltip title="Remover">
                    <Button
                      icon={<Delete03Icon />}
                      type="text"
                      className="rounded-xl hover:bg-base-400"
                      onClick={() => {
                        // Modo secundário de atualizar elements, outra opção é usar updateElementAction
                        let tempTriggersObj = { ...triggersObj }
                        delete tempTriggersObj[triggerKey]
                        formInstance.setFields([
                          {
                            name: [...props.actionFieldName, "triggers"],
                            value: tempTriggersObj,
                          },
                        ])
                        props.syncElements()
                      }}
                    />
                  </Tooltip>
                </div> */}
                    </div>
                  ),
                  content: (
                    <div className="px-3 pt-2">
                      <trigger.def.edit
                        formInstance={formInstance}
                        actionFieldName={[...fieldName, trigger.def.type]}
                        syncElements={props.syncElements}
                      />
                      <Button
                        icon={<Delete03Icon className="!w-4 !h-4" />}
                        // type="text"
                        size="small"
                        className="rounded-lg hover:bg-base-400 !text-red-500 text-xs !bg-red-50 hover:!bg-red-100 focus:!bg-red-100 dark:!bg-transparent dark:hover:!bg-base-200 dark:focus:!bg-base-200  my-2"
                        onClick={() => {
                          // Modo secundário de atualizar elements, outra opção é usar updateElementAction
                          let tempTriggersObj = { ...triggersObj }
                          delete tempTriggersObj[trigger.def.type]
                          formInstance.setFields([
                            {
                              name: fieldName,
                              value: tempTriggersObj,
                            },
                          ])
                          props.syncElements()
                        }}
                      >
                        Excluir
                      </Button>
                    </div>
                  ),
                }
              })}
            />
          )
          // return Object.keys(triggersObj || {}).map((triggerKey) => {
          //   const triggerDef = WorkflowDefs.trigger.byType[triggerKey as WorkflowActionType] as WorkflowTriggerItem
          //   const Icon = triggerDef.icon
          //   const EditComponent = triggerDef.edit

          //   return (
          //     <a
          //       key={triggerDef.type}
          //       className="h-16 relative px-5 border border-solid border-divider-100 flex items-center gap-4 rounded-lg justify-start text-left w-full hover:bg-base-300 hover:border-base-300 hover:shadow-none mb-3"
          //       onClick={() =>
          //         setModal(
          //           <EditComponent
          //             onBack={() => {
          //               setModal(null)
          //             }}
          //             initialValues={triggersObj[triggerKey]}
          //             onSubmit={(model: any) => {
          //               formInstance.setFields([
          //                 {
          //                   name: [...props.actionFieldName, "triggers", triggerKey],
          //                   value: model,
          //                 },
          //               ])
          //               props.syncElements()
          //               setModal(null)
          //             }}
          //           />
          //         )
          //       }
          //       type="text"
          //     >
          //       <Icon className="text-primary !w-6 !h-6" />
          //       <div className="leading-5 -mt-1">
          //         <div className="text-content-200 text-xs">{WorkflowDefs.trigger.byCategoryType[triggerDef?.category].label}</div>
          //         <div className="font-medium text-md">{triggerDef.name}</div>
          //       </div>
          //       <div className="absolute right-3 top-3">
          //         <Tooltip title="Remover">
          //           <Button
          //             icon={<Delete03Icon />}
          //             type="text"
          //             className="rounded-xl hover:bg-base-400"
          //             onClick={() => {
          //               // Modo secundário de atualizar elements, outra opção é usar updateElementAction
          //               let tempTriggersObj = { ...triggersObj }
          //               delete tempTriggersObj[triggerKey]
          //               formInstance.setFields([
          //                 {
          //                   name: [...props.actionFieldName, "triggers"],
          //                   value: tempTriggersObj,
          //                 },
          //               ])
          //               props.syncElements()
          //             }}
          //           />
          //         </Tooltip>
          //       </div>
          //     </a>
          //   )
          // })
        }}
      </Form.Item>
      <AddTriggerBtn id={props.editNode?.id!} />
    </div>
  )
}
// return <Form.Item name={[...props.actionFieldName, triggerKey]} label="Texto"></Form.Item>

export default EditTrigger
