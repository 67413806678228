import { Tooltip } from "antd"
import React, { CSSProperties } from "react"

export function LabelHelp(props: {
  label?: string
  children?: any
  help: string | React.ReactNode
  iconStyle?: CSSProperties
  tooltipProps?: any
  className?: string
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: 6,
        alignItems: "center",
      }}
      className={props.className}
    >
      {props.label || props.children}
      {props.help && (
        <Tooltip title={props.help} {...props.tooltipProps}>
          <i className="far fa-question-circle" style={{ color: "var(--gray-40)", ...props.iconStyle }} />
        </Tooltip>
      )}
    </div>
  )
}
