import React, { useMemo } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Button, Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStore, useStoreState } from "hooks"
// import EmailSvg from "../svgs/EmailSvg"
import { IChannel } from "models/channels"
import smsImg from "assets/images/channels/sms.png"
import { useSetRecoilState } from "recoil"
import { modalState } from "atoms/app.atom"
import BuySmsModal from "./BuySmsModal"
import SmsTransactions from "./BuySmsModal/SmsTransactions"
import { session } from "store"
import { getHost } from "lib/helper"

interface IEditSmsProps extends RouteComponentProps<{ id: string }> {
    onChange: Function
}

function EditSms(props: IEditSmsProps) {
    const [form] = Form.useForm()
    const setModal = useSetRecoilState(modalState)
    const { all: channels } = useStoreState((state) => state.channels)
    const { id } = useParams<any>()
    const { workspace } = useStore(session)
    const { subdomain } = getHost()
    const isMola = subdomain == "mola"

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    return (
        <SettingsPageEdit<IChannel>
            {...props}
            pageComponent="modal"
            routeName="channels"
            title="Canal de SMS"
            onSubmitSuccess={() => {
                props.onChange()
            }}
            width={500}
            titleOverwrite={
                <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                        <Row wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        width: 24,
                                        height: 24,
                                        display: "flex",
                                        marginRight: 10,
                                    }}
                                >
                                    <img src={smsImg} style={{ width: "100%", height: "100%" }} alt="sms" />
                                </div>
                            </Col>
                            <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                                Canal de SMS
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            storePath={(store) => store.channelsSms}
            form={form}
            hideFooterWhenSaved
            okProps={{
                style: {
                    display: "none",
                },
            }}
        >
            <div style={{ color: "var(--content-secondary)" }}>
                {/* <p>Adicionando canal de SMS você pode comprar créditos para realizar disparos. Cada mensagem de SMS consome 1 crédito.</p> */}
                <Form.Item label="Créditos disponíveis">
                    <Input value={workspace.sms_credits || 0} disabled />
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        gap: 8,
                    }}
                >
                    {!isMola && (
                        <Button
                            onClick={() => {
                                setModal(<BuySmsModal />)
                            }}
                            type="primary"
                        >
                            Comprar créditos
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            setModal(<SmsTransactions onClose={() => setModal(null)} />)
                        }}
                    >
                        Ver histórico
                    </Button>
                </div>
            </div>
            <br />
        </SettingsPageEdit>
    )
}

export default EditSms
