import { flexRender } from "@tanstack/react-table"
import React, { memo } from "react"
import { twMerge } from "tailwind-merge"

interface VirtualInfiniteTableRowProps {
  virtualRow: any
  row: any
  onClickRow: any
  getCommonPinningStyles: any
}

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

function VirtualInfiniteTableRow(props: VirtualInfiniteTableRowProps) {
  const { virtualRow, row, getCommonPinningStyles } = props

  return (
    <tr
      data-index={virtualRow.index} //needed for dynamic row height measurement
      // ref={(node) => props.rowVirtualizer.measureElement(node)} //measure dynamic row height
      style={{
        display: "flex",
        position: "absolute",
        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
        width: "100%",
        borderTop: "1px solid var(--divider)",
        ...(isSafari ? { maxWidth: "calc(100% - 8px" } : {}),
      }}
      className={twMerge(
        "first:!border-transparent group",
        props.onClickRow &&
          "peer cursor-pointer hover:!border-transparent hover:bg-base-300 hover:rounded-xl focus-within:bg-base-300 focus-within:rounded-xl focus-within:!border-transparent"
      )}
      onClick={props.onClickRow ? () => props.onClickRow?.(row.original) : undefined}
    >
      {row.getVisibleCells().map((cell) => {
        const isPin = cell.column.getIsPinned()
        return (
          <td
            key={cell.id}
            style={{
              display: "flex",
              width: cell.column.getSize(),
              ...(isPin && getCommonPinningStyles?.(cell.column)),
            }}
            className={twMerge("h-14 flex items-center px-2.5 truncate", isPin && "flex-1 group-hover:bg-base-300 rounded-xl")}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

export default memo(VirtualInfiniteTableRow)
