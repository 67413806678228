import { useEffect } from "react"
import { useStoreState, useStoreActions } from "hooks"
import { State } from "easy-peasy"
import { IStoreModel } from "models"

type IUseGenericStore = <Result>(mapState: (state: State<IStoreModel>) => Result, options?: { getLocal?: string }) => Result

export const useGenericStore: IUseGenericStore = (statePath, options) => {
  const state = useStoreState(statePath)
  const actions = useStoreActions(statePath as any)

  useEffect(() => {
    ;(actions as any)?.getAll({ config: options })
  }, [])

  return state
}
