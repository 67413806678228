import { Checkbox, Form, Select } from "antd"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import SelectReference from "../../../actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import CustomSelect from "components-old/forms/CustomSelect"
import Icon from "components-old/common/Icon"
import AgentsSelect from "components-old/ui/AgentsSelect"
import RoundRobinMembersList from "./RoundRobinMembersList"
import CollapseCards from "pages/crm/deals/ViewDeal/CollapseCards"
import { LabelHelp } from "components-old/forms/LabelHelp"

interface AssignConversationActionProps extends BaseEditActionChildrenProps {}

export enum AssignType {
  ROUND_ROBIN = "round-robin",
  QUEUE = "queue",
  DIRECT = "direct",
  LAST_OPERATOR = "last-operator",
}

const AssignConversationAction = (props: AssignConversationActionProps) => {
  const fieldName = [...props.actionFieldName, "conversation", "assign"]

  return (
    <div>
      <Form.Item label="Conversa" name={[...props.actionFieldName, "conversation", "conversationReference"]} required>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.conversation} excludeId={props.editNode.id} />
      </Form.Item>
      <Form.Item label="Tipo" name={[...fieldName, "type"]} required>
        <CustomSelect>
          <Select.Option value={AssignType.ROUND_ROBIN} data-search={"Cíclico"}>
            <div className="flex items-center gap-2">
              <i className="fas fa-draw-circle text-primary"></i>
              Cíclico
            </div>
          </Select.Option>
          <Select.Option value={AssignType.QUEUE} data-search={"Cíclico com fila"}>
            <div className="flex items-center gap-2">
              <i className="fas fa-layer-group text-primary"></i>
              Cíclico com fila
            </div>
          </Select.Option>
          <Select.Option value={AssignType.DIRECT} data-search={"Agente"}>
            <div className="flex items-center gap-2">
              <i className="fas fa-user-headset text-primary"></i>
              Agente
            </div>
          </Select.Option>
          <Select.Option value={AssignType.LAST_OPERATOR} data-search={"Último agente que atendeu a conversa"}>
            <div className="flex items-center gap-2">
              <i className="fas fa-history text-primary"></i>
              Último agente que atendeu a conversa
            </div>
          </Select.Option>
        </CustomSelect>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {(formInstance) => {
          const type = formInstance.getFieldValue([...fieldName, "type"])
          return (
            <>
              {type === AssignType.DIRECT && (
                <Form.Item label="Agente" name={[...fieldName, "operatorId"]} required>
                  <AgentsSelect />
                </Form.Item>
              )}
              {type === AssignType.QUEUE && (
                <div className="text-xs p-3 bg-base-200 border-2 border-solid border-divider-100 my-3 rounded-lg">
                  No modo fila, caso não tenha nenhum agente elegível o sistema vai aguardar até que um agente fique elegível e então
                  atribuir a ele. As conversas são selecionadas da fila por ordem de chegada (FIFO).
                </div>
              )}
              {[AssignType.QUEUE, AssignType.ROUND_ROBIN].includes(type) && (
                <CollapseCards
                  defaultActiveKey={["Agentes participantes"]}
                  bordered
                  destroyInactivePanel={false}
                  panels={[
                    {
                      key: "Agentes participantes",
                      title: (
                        <LabelHelp
                          className="text-sm"
                          help="Agentes que participarão do ciclo de atribuição. Você pode escolher os agentes individualmente ou os agentes pertencentes a grupos."
                        >
                          Agentes participantes
                        </LabelHelp>
                      ),
                      content: <RoundRobinMembersList fieldName={fieldName} formInstance={formInstance as any} />,
                    },
                  ]}
                />
              )}
            </>
          )
        }}
      </Form.Item>

      <Form.Item label="Condições de elegibilidade">
        <Form.Item name={[...fieldName, "mustBeAvailable"]} valuePropName="checked" className="mb-1">
          <Checkbox>Agente deve estar disponível</Checkbox>
        </Form.Item>
        <Form.Item name={[...fieldName, "mustBeOnline"]} valuePropName="checked" className="mb-0">
          <Checkbox>Agente deve estar online</Checkbox>
        </Form.Item>
      </Form.Item>
    </div>
  )
}

export default AssignConversationAction
