import { Select, SelectProps } from "antd"
import { quickViewWorkflowNodeIdAtom, workflowVariablesAtom } from "atoms/workflow.atom"
import CustomSelect from "components-old/forms/CustomSelect"
import React, { useMemo } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { colors } from "lib/colors"
import { saveQuickViewPreviousPosition } from "../WorkflowCanva"
import { VariableCategory } from "../workflow.defs"

interface SelectReferenceProps extends SelectProps<any> {
  category: VariableCategory
  excludeId?: string
}

function SelectReference(props: SelectReferenceProps) {
  const setQuickViewNodeId = useSetRecoilState(quickViewWorkflowNodeIdAtom)
  const [variablesContext] = useRecoilState(workflowVariablesAtom)
  const { category, excludeId, ...rest } = props

  const contextItems = useMemo(() => {
    // const action = WorkflowDefs.ActionRecord

    return variablesContext.filter((v) => v.nodeType == props.category.type && v.nodeId !== props.excludeId)
  }, [props.category.type, props.excludeId, variablesContext])

  return (
    <CustomSelect
      {...rest}
      onDropdownVisibleChange={(open) => {
        if (open) {
          saveQuickViewPreviousPosition()
        }
      }}
    >
      {contextItems?.map((v) => {
        const label = props.category.label + " #" + v.nodeId
        const Icon = props.category.icon

        return (
          <Select.Option
            key={v.nodeId}
            value={v.nodeId}
            data-search={label}
            onMouseEnter={() => {
              if (v.nodeId) {
                setQuickViewNodeId(v.nodeId)
              }
            }}
            onMouseLeave={() => {
              if (v.nodeId) {
                setQuickViewNodeId(undefined)
              }
            }}
          >
            <div className="flex items-center gap-2">
              <Icon
                style={{
                  color: colors[props.category.palette]?.[600],
                }}
              />
              {label}
            </div>
          </Select.Option>
        )
      })}
    </CustomSelect>
  )
}

export default SelectReference
