import React, { useMemo } from "react"
import { CheckmarkCircle02Icon, ContactIcon, SquareSingleFireIcon, UserSearch01Icon } from "components-old/icons"
import { useRecoilState } from "recoil"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import BaseExpanderFields from "../../../actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import { variableAtom } from "atoms/app.atom"

interface CreateDealNodeProps extends BaseNodeProps {}

function CreateDealNode(props: CreateDealNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const deal = props.data?.action?.deal
    if (deal?.contactReference) {
      fields.push({
        icon: UserSearch01Icon,
        value: (
          <span>
            Contato <span className="text-emerald-600">#{deal?.contactReference}</span>
          </span>
        ),
        label: "Contato",
      })
    }
    if (deal?.conversationReference) {
      fields.push({
        icon: WorkflowDefs.CategoryRecord.conversation.icon,
        value: (
          <span>
            Conversa <span className="text-emerald-600">#{deal?.conversationReference}</span>
          </span>
        ),
        label: "Conversa",
      })
    }
    if (deal?.name) {
      fields.push({
        icon: SquareSingleFireIcon,
        value: transformStringToJSX(deal.name ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Nome",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.deal])

  return (
    <BaseNode {...props} title="Criar negócio">
      <BaseExpanderFields fields={fields} category={CategoryEnum.DEAL} />
    </BaseNode>
  )
}

export default CreateDealNode
