import { Button, Col, Form, Row, Tooltip } from "antd"
import React from "react"
import cx from "classnames"
import styles from "../style.module.css"
import { useRecoilState } from "recoil"
import { Elements, removeElements, useStoreActions } from "react-flow-renderer"
import { workflowSelectedElementsAtom } from "atoms/workflow.atom"
import { generateNewNodeId } from "../workflow.helper"
import { ActionEnum } from "../workflow.enums"

interface IBottomMidPanelProps {
  setElements: any
}

function BottomMidPanel(props: IBottomMidPanelProps) {
  const [selectedElements, setSelectedElements] = useRecoilState(workflowSelectedElementsAtom)
  const setSelectedElementsFlow = useStoreActions((actions) => actions.setSelectedElements)

  if (selectedElements?.length < 2) return null

  const onlyNodes = selectedElements.filter((e) => e.type != "removable_edge")

  return (
    <div className={styles.board_bottom_mid}>
      <div className={cx(styles.panel, styles.fade_in, styles.panel_dark)}>
        <Row align="middle" style={{ height: "100%" }} gutter={6}>
          <Col>
            <span
              style={{
                marginRight: 6,
              }}
            >
              <strong>{selectedElements?.length}</strong>
              {` `}
              selecionados
            </span>
          </Col>
          <Form.Item
            shouldUpdate={() => {
              return false
            }}
            noStyle
          >
            {(form) => {
              return (
                <Col>
                  <Tooltip title="Clonar selecionados">
                    <Button
                      type="text"
                      icon={
                        <i
                          className="fal fa-copy"
                          style={{
                            marginTop: -2,
                            opacity: !onlyNodes.length ? 0.2 : 1,
                          }}
                        />
                      }
                      disabled={!onlyNodes.length}
                      onClick={() => {
                        let acc = form?.getFieldValue("lastNodeId")
                        const newNodes = onlyNodes.map((el: any) => ({
                          id: generateNewNodeId({
                            getFieldValue: () => {
                              return acc
                            },
                            setFieldsValue: (val: any) => {
                              acc = val.lastNodeId
                            },
                          } as any),
                          type: el.type,
                          data: { ...el.data },
                          position: {
                            x: el.position.x + 200,
                            y: el.position.y + 200,
                          },
                        }))
                        const newNodesSerialzed = JSON.parse(JSON.stringify(newNodes))
                        form?.setFieldsValue({ lastNodeId: acc })
                        props.setElements((els: Elements) => [...els, ...newNodesSerialzed])
                        setSelectedElementsFlow(newNodesSerialzed?.map((el: any) => ({ id: el.id, type: el.type })))
                      }}
                    />
                  </Tooltip>
                </Col>
              )
            }}
          </Form.Item>
          <Col>
            <Tooltip title="Excluir selecionados">
              <Button
                type="text"
                icon={<i className="fal fa-trash-alt" style={{ marginTop: -2 }} />}
                onClick={() => {
                  props.setElements((els: Elements) =>
                    removeElements(
                      selectedElements?.filter((el) => el.type !== ActionEnum.TRIGGER),
                      els
                    )
                  )
                  setSelectedElements([])
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BottomMidPanel
