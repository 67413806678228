import { useStore, useStoreState } from "hooks"
import { Privacy } from "lib/constants"
import React, { useCallback, useMemo } from "react"
import { session } from "store"

export interface ItemWithPrivacy {
  id: any
  authorId?: string
  privacy?: {
    type?: Privacy
    groupIds?: string[]
  }
}

const usePrivacy = <T>(props?: { items?: ItemWithPrivacy[] }) => {
  const { all: groups } = useStoreState((state) => state.groups)
  const { user, workspace } = useStore(session)

  const filterPrivatedItems = useCallback(
    (items?: ItemWithPrivacy[]) => {
      const allowedItems = (items || []).filter((item) => {
        if (item?.privacy?.type === Privacy.PUBLIC || workspace.isOwner || !item.privacy?.type) {
          return true
        }

        if (item.privacy?.type === Privacy.PRIVATE && (item.authorId == user.id || !item.authorId)) {
          return true
        }

        if (item.privacy?.type === Privacy.GROUP) {
          if (!item.privacy?.groupIds) {
            return false
          }

          const myGroups = groups.filter((group) => group.members.includes(user.id))?.map((group) => group.id)

          return item.privacy?.groupIds.some((groupId) => {
            return myGroups.includes(groupId)
          })
        }

        return false
      })

      return allowedItems as unknown as T[]
    },
    [workspace.isOwner, user.id, groups]
  )

  // just run the effect on pathname and/or search change
  const allowedItems = useMemo(() => {
    return filterPrivatedItems(props?.items)
  }, [filterPrivatedItems, props?.items])

  // renders nothing, since nothing is needed
  return { allowedItems, filterPrivatedItems }
}

export default usePrivacy
