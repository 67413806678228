import React from "react"
import { Button, Col, Row } from "antd"
import styles from "./style.module.scss"
import { IColumnProps } from "."

interface IListPageHeaderProps<T> {
    columns: IColumnProps<T>[]
}

function ListPageHeader(props: IListPageHeaderProps<any>) {
    return (
        <Row
            justify="space-between"
            align="middle"
            // gutter={12}
            style={{ width: "100%" }}
            wrap={false}
            className={styles.list_header}
        >
            {props.columns.map((column, index) => (
                <Col
                    key={(column.key as any) || column.dataIndex!}
                    span={column.span || props.columns.length}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "visible",
                    }}
                    // className={styles.column}
                >
                    {column?.title as any}
                    {/* {column?.render?.(
                            props.item?.[(column as any)?.dataIndex!],
                            props.item,
                            index
                        ) || props.item?.[(column as any)?.dataIndex!]} */}
                </Col>
            ))}
        </Row>
    )
}

export default ListPageHeader
