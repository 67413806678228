import { Button, Form, Input } from "antd"
import Tooltip from "components-old/Tooltip"
import InputVariable from "components-old/forms/InputVariable"
import { Delete03Icon } from "components-old/icons"
import { AddListButton } from "components-old/shared/table/DnDFormList/AddListButton"
import React from "react"
import { twMerge } from "tailwind-merge"

interface MakeRequestHeadersProps {}

function MakeRequestHeaders(props: MakeRequestHeadersProps) {
  return (
    <Form.List name="headers">
      {(fields, { add, remove, move }) => {
        return (
          <div className="">
            {fields?.map((field, i) => (
              <div key={field.key} className="flex gap-2 mb-2 max-w-2xl">
                <Form.Item name={[field.name, "key"]} label={i === 0 ? "Chave" : ""} className="flex-1 mb-0">
                  <InputVariable floatingTrigger />
                </Form.Item>
                <Form.Item label={i === 0 ? "Valor" : ""} name={[field.name, "value"]} className="flex-1 mb-0">
                  <InputVariable floatingTrigger />
                </Form.Item>
                <div className={twMerge(i === 0 ? "mt-9" : "mt-2")}>
                  <Tooltip title="Remover" mouseEnterDelay={0}>
                    <Button
                      icon={
                        <i>
                          <Delete03Icon className="w-5 h-5" />
                        </i>
                      }
                      size="small"
                      shape="circle"
                      style={{
                        marginLeft: 0,
                        // padding: "0",
                      }}
                      type="text"
                      onClick={() => {
                        remove(field.name)
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ))}

            <AddListButton
              onClick={() => {
                add()
              }}
            >
              Header
            </AddListButton>
          </div>
        )
      }}
    </Form.List>
  )
}

export default MakeRequestHeaders
