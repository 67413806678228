import { themeAtom } from "atoms/app.atom"
import { ColorPalette, ColorSwatch, getDarkTagColors, getLightTagColors } from "lib/colors"
import React, { memo } from "react"
import { useRecoilState } from "recoil"
import { twMerge } from "tailwind-merge"

interface IconTagProps {
  icon: React.ElementType
  className?: string
  iconClassName?: string
  size?: "sm" | "md" | "lg"
  palette: ColorPalette
  customColors?: {
    bg?: string
    text?: string
    border?: string
  }
  lightTone?: {
    bg?: keyof ColorSwatch
    text?: keyof ColorSwatch
    border?: keyof ColorSwatch
  }
  darkTone?: {
    bg?: keyof ColorSwatch
    text?: keyof ColorSwatch
    border?: keyof ColorSwatch
  }
  style?: React.CSSProperties
}

function IconTag(props: IconTagProps) {
  const [theme] = useRecoilState(themeAtom)

  const colors =
    theme === "dark"
      ? getDarkTagColors(props.palette, { tone: props.darkTone })
      : getLightTagColors(props.palette, { tone: props.lightTone })

  return (
    <i
      className={twMerge(
        "rounded-xl flex-center shrink-0",
        (props.size === "md" || !props.size) && "!w-7 !h-7 p-[5px]",
        props.size === "sm" && "!w-5 !h-5 p-0.5",
        props.size === "lg" && "!w-7 !h-7 p-1",
        props.className
      )}
      style={{
        backgroundColor: props.customColors?.bg || colors.bg,
        color: props.customColors?.text || colors.text,
        borderColor: props.customColors?.border || colors.border,
        ...props.style,
      }}
    >
      <props.icon className={twMerge("w-full h-full", props.iconClassName)} />
    </i>
  )
}

export default memo(IconTag)
