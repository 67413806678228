import { FilterState } from "components-old/forms/FiltersInput"
import { endpoints } from "config/endpoints.config"
import { Privacy } from "lib/constants"
import { IGenericModel, generic } from "models/generic"

export enum ViewType {
  Session = "session",
  Presence = "presence",
  Deal = "deal",
}

export interface IView {
  id: string
  _id: string
  filterState: FilterState
  privacy: {
    type: Privacy
    groupIds?: string[]
  }
  createdAt: string
  workspaceId: string
  authorId: string
  type: ViewType
}

export const views: IGenericModel<IView> = {
  ...generic(endpoints.VIEW),
}
