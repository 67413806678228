import { endpoints } from "config/endpoints.config"
// import { action, thunk } from "easy-peasy"
// import request from "lib/request"
import { IGenericModel, generic } from "models/generic"

export interface IAdminLog {
    id: string
    description: string
    operatorId: string
    action: string
    actionMethod: "DELETE" | "POST" | "PUT" | "PATCH" | "GET" | "UPDATE" | "OTHER"
    createdAt: string
    workspaceId: string
    resource: string
    requestCode: number
    ip: string
    route: string
    elapsedTimeMs: number
    data: any
}

export interface IAdminLogTypes {
    actions: {
        value: string
        label: string
    }[]
    resources: {
        value: string
        label: string
    }[]
}

export const adminLogs: IGenericModel<IAdminLog> = {
    ...generic(endpoints.ADMIN_LOGS),
}
