import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface ICoupom {
    id: string
    code: string
    charges: number
    annualDiscount: number
    monthlyDiscount: number
    remaining: number
    isActive: boolean
    createdAt: string
    expiresAt: string
}

export const coupons: IGenericModel<ICoupom> = {
    ...generic(endpoints.ADMIN_COUPONS),
}
