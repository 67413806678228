import React from "react"
import FormModal from "components-old/forms/FormModal"
import { Button, Form, Input, InputNumber, Select } from "antd"
import { useGenericStore, useStoreState } from "hooks"
import { Deal } from "models/deals"
import CustomSelect from "components-old/forms/CustomSelect"
import Icon from "components-old/common/Icon"
import { twMerge } from "tailwind-merge"
import { formatterCurrencyNewWithoutDollarSign, parseDecimal } from "lib/masks"

interface IChooseProductsModalProps {
  onSubmit?: any
  onBack: any
  deal?: Deal
  name?: string | string[]
}

const getTotal = ({
  price,
  quantity,
  discount,
  discountType,
}: {
  price: number
  quantity: number
  discount: number
  discountType: "percentage" | "value"
}) => {
  if (discountType === "percentage") {
    return price * quantity - (price * quantity * discount) / 100
  } else {
    return price * quantity - discount
  }
}

export const calculateProductsTotal = (products) => {
  if (!products) {
    return
  }
  return products.reduce((acc, product) => {
    return acc + (getTotal(product) || 0)
  }, 0)
}

function ChooseProductsModal(props: IChooseProductsModalProps) {
  const [form] = Form.useForm()

  return (
    <FormModal
      form={form}
      onSubmit={props.onSubmit}
      onBack={props.onBack}
      type="modal"
      titleOverwrite={"Adicionar produtos a negociação"}
      formProps={{
        initialValues: { products: props.deal?.products },
        // onValuesChange(changedValues, values) {
        //   if (changedValues.pipelineId) {
        //     const pipeline = pipelines?.find((pipeline) => pipeline._id === changedValues.pipelineId)
        //     form.setFieldsValue({ stageId: pipeline?.stages?.[0].id })
        //   }
        // },
      }}
      width={800}
      saved={false}
      hideSuccessMessage
      isEditing
      okText="Salvar"
      noPrompt
    >
      <DealProductsCore form={form} name={props.name} />
      <div
        className="my-6"
        style={{
          borderTop: "2px dashed var(--divider)",
        }}
      ></div>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          return (
            <div className="flex justify-end my-4 text-md">
              <div className="bg-hover-overlay rounded p-4 flex-center gap-3">
                <strong>Total</strong>
                {calculateProductsTotal(getFieldsValue().products)?.toLocaleString?.("pt-BR") || 0}
              </div>
            </div>
          )
        }}
      </Form.Item>
    </FormModal>
  )
}

interface DealProductsCoreProps {
  form: any
  name?: string | string[]
}

export function DealProductsCore(props: DealProductsCoreProps) {
  const { all: products } = useGenericStore((state) => state.products)

  const listClass = "flex gap-2 items-center flex-nowrap"
  const itemClass = "w-[90px] min-w-[90px] text-end truncate"
  const itemClassSmall = "w-[80px] min-w-[80px] text-end truncate"

  return (
    <>
      <div className={twMerge(listClass, "mb-3 text-[13px]")}>
        <strong className="flex-1 w-full">Produtos</strong>
        <strong className={itemClass}>Preço</strong>
        <strong className={itemClass}>Quantidade</strong>
        <strong className={"w-40 min-w-[160px] text-end"}>Desconto</strong>
        <strong className={itemClass}>Total</strong>
        <strong className="w-6 min-w-[24px]"></strong>
      </div>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFields, setFieldsValue, getFieldsValue }) => {
          const name = props.name || "products"
          return (
            <Form.List name={name}>
              {(fields, { add, remove, move }) => {
                return (
                  <div className="">
                    {fields?.map((field, i) => (
                      <div key={field.key} className={twMerge(listClass, "mb-1.5")}>
                        {/* PRODUCT */}
                        <div className="flex-1 w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "id"]}
                            fieldKey={(field as any).fieldKey}
                            rules={[
                              {
                                required: true,
                                message: "Digite a opção.",
                              },
                            ]}
                            noStyle
                            className="flex-1 w-full"
                          >
                            <CustomSelect
                              placeholder="Selecione um produto"
                              showSearch
                              filterOption={(input, option: any) => {
                                return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                              className="flex-1 w-full"
                              onChange={(value) => {
                                const product = products?.find((product) => product._id === value)
                                props.form.setFields([
                                  {
                                    name,
                                    value: [
                                      ...(getFieldValue(name)?.map((p, index) => {
                                        if (index === i) {
                                          return {
                                            ...p,
                                            price: product?.price || p?.price || 0,
                                          }
                                        }
                                        return p
                                      }) || []),
                                    ],
                                  },
                                ])
                                // props.form.setFieldsValue({
                                //   products: [
                                //     ...getFieldValue(name)?.map((p, index) => {
                                //       if (index === i) {
                                //         return {
                                //           ...p,
                                //           price: product?.price || p?.price || 0,
                                //         }
                                //       }
                                //       return p
                                //     }) || [],
                                //   ],
                                // })
                              }}
                            >
                              {products?.map((product) => (
                                <Select.Option key={product._id} value={product._id}>
                                  {product.name}
                                </Select.Option>
                              ))}
                            </CustomSelect>
                          </Form.Item>
                        </div>
                        {/* PRICE */}
                        <div className={itemClass}>
                          <Form.Item {...field} name={[field.name, "price"]} fieldKey={(field as any).fieldKey} noStyle className="flex-1">
                            <InputNumber
                              controls={false}
                              className="w-full input-end"
                              parser={parseDecimal as any}
                              formatter={formatterCurrencyNewWithoutDollarSign}
                              step={0.01}
                              min={0}
                            />
                            {/* <InputNumber className="text-end" /> */}
                          </Form.Item>
                        </div>
                        {/* QUANTITY */}
                        <div className={itemClass}>
                          <Form.Item
                            {...field}
                            name={[field.name, "quantity"]}
                            fieldKey={(field as any).fieldKey}
                            noStyle
                            className="flex-1"
                          >
                            <InputNumber className="w-full" min={1} step={1} />
                          </Form.Item>
                        </div>
                        {/* DISCOUNT */}
                        <div className={"w-40 min-w-[160px]"}>
                          <div className="flex items-center">
                            <div className={itemClassSmall}>
                              <Form.Item
                                {...field}
                                name={[field.name, "discountType"]}
                                fieldKey={(field as any).fieldKey}
                                noStyle
                                className="flex-1"
                              >
                                <CustomSelect className="flex-1 w-full rounded-r-none">
                                  <Select.Option value={"percentage"}>%</Select.Option>
                                  <Select.Option value={"value"}>BRL</Select.Option>
                                </CustomSelect>
                              </Form.Item>
                            </div>
                            <div className={itemClassSmall}>
                              <Form.Item
                                {...field}
                                name={[field.name, "discount"]}
                                fieldKey={(field as any).fieldKey}
                                noStyle
                                className="flex-1 w-full min-w-[80px]"
                              >
                                <InputNumber
                                  controls={false}
                                  className="w-full input-end border-l-0 rounded-l-none"
                                  parser={parseDecimal as any}
                                  formatter={formatterCurrencyNewWithoutDollarSign}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        {/* TOTAL */}
                        <div className={itemClass}>
                          <Form.Item noStyle className="flex-1">
                            {/* {({ getFieldsValue }) => { */}
                            {/* return ( */}
                            <Input
                              className="w-full text-end"
                              disabled
                              value={getTotal({
                                price: getFieldValue(name)?.[i]?.price || 0,
                                quantity: getFieldValue(name)?.[i]?.quantity || 0,
                                discount: getFieldValue(name)?.[i]?.discount || 0,
                                discountType: getFieldValue(name)?.[i]?.discountType || "value",
                              })?.toLocaleString?.("pt-BR")}
                            />
                            {/* ) */}
                            {/* }} */}
                          </Form.Item>
                        </div>
                        <div className="w-6 min-w-[24px]">
                          <Button
                            icon={<i className="far fa-trash-alt" />}
                            size="small"
                            shape="circle"
                            style={{
                              marginLeft: 0,
                              padding: "0",
                            }}
                            type="text"
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({
                          quantity: 1,
                          discountType: "percentage",
                          discount: 0,
                        })
                      }}
                      // block
                      style={{
                        textAlign: "left",
                        height: 45,
                        borderWidth: 2,
                      }}
                      className="border-divider-100 !hover:border-primary rounded-lg mt-2"
                    >
                      <Icon name="fas fa-plus-circle text-primary" style={{ marginRight: 5 }} />
                      <span style={{ color: "var(--content-primary)" }}>Produto</span>
                    </Button>
                  </div>
                )
              }}
            </Form.List>
          )
        }}
      </Form.Item>
    </>
  )
}

export default ChooseProductsModal
