import React from "react"

type TruncateProps = {
    children: React.ReactNode
    width?: number
    maxWidth?: number
    title?: string
    style?: React.CSSProperties
}

function Truncate({ children, width, maxWidth, title, style }: TruncateProps) {
    return (
        <span className="truncate" style={{ width, maxWidth, display: "inline-block", ...style }} title={title}>
            {children}
        </span>
    )
}

export default Truncate
