import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { useRecoilState } from "recoil"
import { entityFieldsAtom } from "atoms/workflow.atom"
import InputVariableFormItem from "components-old/forms/InputVariable/InputVariableFormItem"

interface CreateContactActionProps extends BaseEditActionChildrenProps {}

const CreateContactAction = (props: CreateContactActionProps) => {
  const fieldName = [...props.actionFieldName, "contact"]
  const [dynamicFields] = useRecoilState(entityFieldsAtom)

  return (
    <div>
      {dynamicFields?.contact?.array.map((field) => {
        let name = [...fieldName, field.name]
        if (field.isCustomField) {
          name = [...fieldName, "customFields", field.name]
        }
        return <InputVariableFormItem key={field.name} field={field} name={name} />
      })}
    </div>
  )
}

export default CreateContactAction
