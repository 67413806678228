import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"
import { stores } from "store"
import request from "lib/request"
import toast from "lib/toast"

function PaymentStore(store) {
    const endpoint = config.endpoints.PAYMENT
    // const [order, setOrder] = useState()

    /*
     * Create Order
     */
    async function subscribe(payload, isUpgradingPlan) {
        stores.general.addLoader("payment")
        let response
        response = await req(isUpgradingPlan ? endpoint + "/change" : endpoint, {
            method: "post",
            data: payload,
        })
        stores.general.removeLoader("payment")
        const errors = response.error || response.errors
        if (!errors) {
            stores.session.patchWorkspace({
                subscription: response,
                workspaceId: stores.session.workspace.workspaceId,
            })
        }
        return response
    }

    /*
     * Create Order
     */
    async function cancelSubscription() {
        stores.general.addLoader("payment")
        let response
        try {
            response = await req(endpoint + "/" + stores.session.workspace.subscription.id, {
                method: "delete",
            })
        } catch (e) {
            response = {
                error: e,
            }
            stores.general.removeLoader("payment")
        }
        stores.general.removeLoader("payment")
        const errors = response.error || response.errors
        if (!errors) {
            stores.session.patchWorkspace({
                subscription: response,
                workspaceId: stores.session.workspace.workspaceId,
            })
        }
        return response
    }

    /*
     * Verify Coupon
     */
    async function verifyCoupon(couponCodeValue, onSuccess, onError) {
        stores.general.addLoader("coupon")

        // const response = await req(endpoint, config)
        try {
            const response = await request.get(
                config.endpoints.VERIFY_COUPON + "?code=" + couponCodeValue,

                config
            )
            toast.success("Desconto aplicado.")
            onSuccess(response.data)
            console.log(response.data)
            return response.data
        } catch (error) {
            console.log(error)
            onError()
            return error
        } finally {
            stores.general.removeLoader("coupon")
        }
    }

    return {
        ...store,
        subscribe,
        verifyCoupon,
        cancelSubscription,
    }
}

export default fromBase(config.endpoints.PAYMENT, PaymentStore)
