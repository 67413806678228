import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface DealContact {
  id: string
  name: string
  avatar: string
  phone: string
  email: string
  isPrivate?: boolean
  createdAt?: string
}

export enum DealStatus {
  WON = "won",
  LOST = "lost",
  OPEN = "open",
}

export const DealStatusLabel = {
  [DealStatus.WON]: "Ganho",
  [DealStatus.LOST]: "Perdido",
  [DealStatus.OPEN]: "Aberto",
}

export interface DealConversation {
  id: string
  platformId?: number
  lastMessageAt: string
  showPending: boolean
  pendingAt: string
}

export interface Deal extends GenericResource {
  name: string
  value: number
  lostReason?: string[]
  lostReasonObs?: string
  // wonDate: Date
  lastStageChanged?: Date
  wonLostDate?: Date
  contact: DealContact
  conversation?: DealConversation
  operatorId: number | null
  groupId: number | null
  pipelineId: string
  stageId: string
  authorId: number
  origin: string
  campaign: string
  position: number
  focusedCounts: number
  dueDate: string
  tagIds: number[]
  status: DealStatus
  comments: number
  authorType: "user" | "bot" | "automation"
  timeInStage?: Record<string, number> //mili seconds
  products: {
    id: string
    name: string
    price: number
    quantity: number
    discount: number
    discountType: "percentage" | "value"
    total: number
  }[]
  customFields?: Record<
    string,
    {
      value: any
    }
  >
  tasks?: Record<
    string,
    {
      done: boolean
      cadenceId: string
      required: boolean
    }
  >
}

export const deals: IGenericModel<Deal> = {
  ...generic(endpoints.DEALS),
}
