import React from "react"

export default function ModalBody(props) {
    const { children, noPadding } = props
    if (!children) return null
    return (
        <div className="modal-body" style={noPadding ? { padding: 0 } : {}}>
            {children}
        </div>
    )
}
