import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, RouteComponentProps, Prompt, useLocation, useHistory } from "react-router-dom"
import { IStorePath, useFetchOneFromParamId } from "hooks/useFetchOneFromParamId"
import { useStoreActions, useStoreState } from "hooks"
import { Form, Drawer, Button, Spin, Modal } from "antd"
import { removeLastPath } from "lib/helper"
import { FormInstance, FormProps } from "antd/lib/form"
import queryString from "query-string"
import toast from "lib/toast"
import EditPage from "../EditPage"
import { IMetaConfigInner } from "models/generic"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"
import { IExtraMore } from "components-old/CardBox"

type ISubmitBeforeConfig = {
  setSaving?: Function
  onClose?: Function
}

// export interface ISettingsPageProps<T> extends RouteComponentProps<{ id: string }> {
export interface ISettingsPageProps<T> {
  children: React.ReactNode
  storePath: IStorePath
  onSubmitSuccess?: (response: T, onClose: Function) => void
  onSubmitBefore?: (model: T, onSubmit: Function, config?: ISubmitBeforeConfig) => void
  routeName: string
  title?: string
  titleOverwrite?: string | boolean | React.ReactNode
  okText?: string | boolean
  successMessage?: string | boolean
  contentClassname?: string
  defaultValues?: Partial<T>
  form: FormInstance
  formProps?: FormProps
  pageComponent: "drawer" | "modal" | "page"
  width?: number
  onBack?: Function
  hideFooterWhenSaved?: boolean
  item?: T
  onReset?: Function
  saved?: boolean
  stickyFooter?: boolean
  noPrompt?: boolean
  setSaved?: Function
  actions?: React.ReactNode
  topActions?: React.ReactNode
  onChangeItem?: (item: T) => void
  drawerStyle?: React.CSSProperties
  okProps?: any
  itemParser?: (item: T) => any
  notFetchOne?: boolean
  hideFooter?: boolean
  metaConfigs?: IMetaConfigInner
  forceModelOnly?: boolean // Na hora de salvar envia apenas o model e nao o { ... item, ... model }
  extraMore?: IExtraMore[]
  after?: React.ReactNode
  footerStyle?: React.CSSProperties
  modalClassName?: string
}

function SettingsPageEdit<T extends Object>(props: ISettingsPageProps<T>) {
  const [_saved, _setSaved] = useState(true)
  const [saving, setSaving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [drawerOpened, setDrawerOpened] = useState(true)
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const pageComponent = isMobile ? "drawer" : props.pageComponent

  const location = useLocation()
  const history = useHistory()
  let { id } = useParams<any>()
  let { save } = useStoreActions(props.storePath)
  //@ts-ignore
  const { loading } = useStoreState(props.storePath as any)
  const item: T = useFetchOneFromParamId(props.storePath, {
    existingItem: props.item,
    notFetchOne: props.notFetchOne,
  })

  const isEditing = id != "new"
  const isFetching = (id && isEditing && !item) || loading.one

  const saved = useMemo(() => props.saved ?? _saved, [_saved, props.saved])
  const setSaved = useMemo(() => props.setSaved ?? _setSaved, [props.setSaved])

  useEffect(() => {
    const query = queryString.parse(location.search)

    if (query.cloneFromId) {
      setSaved(false)
    }
    setVisible(true)
  }, [])

  useEffect(() => {
    props.onChangeItem?.(item)
    if (item) {
      props.form.resetFields()
      props.onReset?.()
    }
  }, [item])

  const onClose = (forcedBack?: boolean) => {
    if (pageComponent == "page") {
      goBack()
    }
    if (drawerOpened || pageComponent == "modal") {
      if (!forcedBack && !saved) {
        goBack()
      } else {
        setVisible(false)
      }
    }
  }

  const goBack = useCallback(() => {
    props.onBack ? props.onBack() : history.push(removeLastPath(location.pathname))
  }, [history, props, location?.pathname])

  const onSubmit = async (model: any) => {
    setSaving(true)
    try {
      const payload = props.forceModelOnly ? model : { ...item, ...model }
      return save({ ...payload, metaConfigs: props.metaConfigs })
        .then((response: T) => {
          toast.success(props.successMessage || ((isEditing ? `${props.title} salvo.` : `${props.title} criado.`) as any))
          setSaving(false)
          setSaved(true)
          if (!props.onSubmitSuccess) {
            onClose(true)
          } else {
            props.onSubmitSuccess?.(response, onClose)
          }
        })
        .catch((e: any) => {
          setSaving(false)
        })
    } catch (e) {
      setSaving(false)
    }
  }

  const content = (
    <>
      <Prompt when={!saved && !props.noPrompt && !isMobile} message="Você tem mudanças não salvas, tem certeza que deseja fechar?" />
      <Spin spinning={isFetching}>
        <div className={props.contentClassname}>
          <Form
            initialValues={props.itemParser?.(props.item || item || props.defaultValues) || props.item || item || props.defaultValues}
            layout="vertical"
            onFinish={(model) => {
              props.onSubmitBefore
                ? props.onSubmitBefore(model as T, onSubmit, {
                    setSaving,
                    onClose,
                  })
                : onSubmit(model)
            }}
            form={props.form}
            hideRequiredMark
            {...props.formProps}
            onValuesChange={(changedValues, allValues) => {
              setSaved(false)
              props.formProps?.onValuesChange?.(changedValues, allValues)
            }}
          >
            {props.children}
          </Form>
        </div>
      </Spin>
    </>
  )

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: pageComponent == "drawer" ? "row-reverse" : "row",
        justifyContent: "space-between",
        padding: pageComponent == "modal" ? "2px 8px" : pageComponent == "drawer" ? "2px 12px" : "2px 0",
      }}
    >
      <div>{props.actions}</div>
      <Button type="primary" htmlType="submit" onClick={props.form.submit} loading={saving} {...props.okProps}>
        {props.okText || "Salvar"}
      </Button>
    </div>
  )

  if (pageComponent == "drawer") {
    return (
      <Drawer
        title={props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}
        width={isMobile ? Math.min(props.width || 430, window.innerWidth) : props.width || 430}
        onClose={() => onClose()}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        afterVisibleChange={() => {
          if (!drawerOpened) {
            setDrawerOpened(true)
          } else {
            !visible && goBack()
          }
        }}
        extra={props.topActions}
        footer={!props.hideFooter && (props.stickyFooter || isMobile) ? footer : undefined}
        maskClosable
        closeIcon={<i className="fal fa-times" style={{ fontSize: 21 }} />}
        style={props.drawerStyle}
      >
        {content}
        {!(props.stickyFooter || isMobile) && !props.hideFooter && (
          <div style={{ marginLeft: -12, marginTop: 35 }}>{(!props.hideFooterWhenSaved || !saved) && footer}</div>
        )}
      </Drawer>
    )
  }

  if (pageComponent == "modal") {
    return (
      <Modal
        title={
          <div className="flex items-center justify-between gap-2">
            <span>{props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}</span>
            {props.topActions}
          </div>
        }
        afterClose={() => goBack()}
        onCancel={() => onClose()}
        visible={visible}
        maskClosable
        footer={footer}
        closeIcon={<i className="far fa-times modal-close-icon" style={{ fontSize: 22 }} />}
        width={isMobile ? Math.min(props.width || 430, window.innerWidth) : props.width || 430}
        className={props.modalClassName}
        // maskTransitionName=""
        transitionName=""
      >
        {content}
      </Modal>
    )
  }

  if (pageComponent == "page") {
    return (
      <EditPage
        title={props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}
        footer={footer}
        onBack={goBack}
        topActions={props.topActions}
        extraMore={props.extraMore}
        width={props.width}
        after={props.after}
        footerStyle={props.footerStyle}
      >
        {content}
      </EditPage>
    )
  }

  return <div>{content}</div>
}

export default SettingsPageEdit
