function AppLoader() {
  return (
    <div id="initial-loading-wrapper" style={{ opacity: 1, zIndex: 999 }}>
      <div className="initial-loading">
        <svg width="64" height="55" viewBox="0 0 64 55" fill="none" xmlns="http://www.w3.org/2000/svg" className="initial-logo">
          <path
            d="M0.530286 3.64572L7.60275 52.2689C7.8308 53.8368 9.17965 55 10.7697 55H52.4697C54.0426 55 55.3822 53.8611 55.6298 52.3136L63.4095 3.69037C63.7194 1.75368 62.2177 0 60.2494 0H3.6972C1.74722 0 0.250603 1.7229 0.530286 3.64572ZM26.5214 36.6667C25.5319 36.6667 24.691 35.943 24.5437 34.9644L22.7459 23.0224C22.5638 21.8128 23.5005 20.7246 24.7236 20.7246H39.2764C40.4995 20.7246 41.4362 21.8128 41.2541 23.0224L39.4563 34.9644C39.309 35.943 38.4681 36.6667 37.4786 36.6667H26.5214Z"
            fill="#DFE5EF"
          />
        </svg>
        <div className="progress-bar" aria-hidden="true" role="progressbar">
          <div className="first-indicator" id="console-splash-progress-021280" style={{ transform: "scaleX(0.8)" }}>
            <span className="first-indicator-inner"></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppLoader
