import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { NodeField } from "../ConversationNodeCore"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import BaseExpanderFields from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { Handle, Position } from "react-flow-renderer"
import styles from "../../../actions/style.module.css"
import cx from "classnames"

interface AssignConversationNodeProps extends BaseNodeProps {}

function AssignConversationNode(props: AssignConversationNodeProps) {
  const { fields } = useMemo(() => {
    let fields: NodeField[] = []
    const conversation = props.data?.action?.conversation

    if (conversation?.conversationReference) {
      fields.push({
        icon: WorkflowDefs.CategoryRecord.conversation.icon,
        value: conversation?.conversationReference ? (
          <span>
            Conversa <span className="text-sky-600">#{conversation?.conversationReference}</span>
          </span>
        ) : (
          ""
        ),
        label: "Conversa",
      })
    }

    return {
      fields,
    }
  }, [props.data?.action?.conversation])

  const isQueue = props.data?.action?.conversation?.assign?.type == "queue"

  return (
    <BaseNode
      {...props}
      title="Atribuir para agente"
      handlesAfter={
        !isQueue ? (
          <>
            <div className="flex justify-end items-center h-6 mb-2 -mt-4">
              <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">
                Nenhum agente elegível
              </span>
              <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
                <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
              </Handle>
            </div>
          </>
        ) : undefined
      }
    >
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONVERSATION} />
    </BaseNode>
  )
}

export default AssignConversationNode
