import React, { useState, useMemo } from "react"
import Draggable from "react-draggable"
import useLocalStorage from "react-use/lib/useLocalStorage"
import cx from "classnames"
import { Drawer, Form, Input } from "antd"
import { twMerge } from "tailwind-merge"
import RadioboxField from "components-old/forms/RadioboxField"
import "./style.css"

const options = [
  {
    key: "staging",
    iconProps: { iconName: "far fa-vial", color: "text-red-400" },
    text: "Staging",
  },
  {
    key: "production",
    iconProps: { iconName: "far fa-server", color: "text-blue-500" },
    text: "Production",
  },
  {
    key: "localhost",
    iconProps: { iconName: "far fa-hammer-war", color: "text-red-500" },
    text: "Localhost Staging",
  },
  {
    key: "localhost-prod",
    iconProps: { iconName: "far fa-axe-battle", color: "text-blue-500" },
    text: "Localhost Prod",
  },
  {
    key: "dev",
    iconProps: { iconName: "far fa-gamepad", color: "text-emerald-500" },
    text: "Dev",
  },
  //   {
  //     key: "mock",
  //     iconProps: { iconName: "FileCode" },
  //     text: "Mock",
  //   },
  //   {
  //     key: "mock-error",
  //     iconProps: { iconName: "FileBug" },
  //     text: "Mock Error",
  //   },
  {
    key: "custom",
    iconProps: { iconName: "far fa-keyboard", color: "text-yellow-500" },
    text: "Custom",
  },
]

interface RequestInspectorProps {
  className?: string
  left?: boolean
  envs: any[]
  onChange?: (env: any) => void
}

function RequestInspector(props: RequestInspectorProps) {
  const { className, left, envs, onChange } = props
  const [form] = Form.useForm()
  const [environment, setEnvironment] = useLocalStorage("nanui-env", envs[0])
  
  const [isOpen, setIsOpen] = useState(false)
  const [pos, setPos] = useLocalStorage("nanui-inspector-pos", {
    x: 0,
    y: 0,
  })

  const columnProps = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
  }

  function _onChange(e, option) {
    const env = envs.find((x) => x.key === option.key) || {}
    const newEnv = {
      ...option,
      url: env?.url || "",
      socketUrl: env.socketUrl || "",
      socketUrlB: env.socketUrlB || "",
      v2SocketUrl: env.v2SocketUrl || "",
      v1Url: env.v1Url || "",
      v2Url: env.v2Url || "",
      widgetUrl: env.widgetUrl || "",
    }
    setEnvironment(newEnv)

    if (onChange) onChange(newEnv)
  }

  const selectedEnvOption = useMemo(() => options.find((x) => x.key === environment.key), [environment])

  return (
    <>
      <Draggable axis="y" defaultPosition={pos} onStop={(e, p) => setPos({ x: 0, y: p.y })}>
        <button
          className={cx("request-inspector bg-white min-w-[80px] h-8 text-sm font-normal text-center select-none no-underline text-[rgb(50,49,48)] cursor-pointer px-4 py-0 rounded-sm", className, {
            left,
            right: !left,
          })}
          onClick={() => setIsOpen(true)}
          onTouchStart={() => setIsOpen(true)}
        >
          <i className={twMerge(selectedEnvOption?.iconProps?.iconName, selectedEnvOption?.iconProps?.color)} />
          {environment.key === "custom" ? environment.url : selectedEnvOption?.text}
        </button>
      </Draggable>
      <Drawer
        title="Selecione o ambiente"
        width={450}
        onClose={() => setIsOpen(false)}
        visible={isOpen}
        placement="left"
        maskClosable
        closeIcon={<i className="fal fa-times" style={{ fontSize: 22 }} />}
        bodyStyle={{
          padding: 30,
        }}
        style={{
          zIndex: 9999,
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            env: environment.key,
            domain: environment.url,
            socketDomain: environment.socketUrl,
            socketDomainB: environment.socketUrlB,
            v2SocketDomain: environment.v2SocketUrl,
            v1Domain: environment.v1Url,
            v2Domain: environment.v2Url,
            widgetDomain: environment.widgetUrl,
          }}
          onValuesChange={(changeValues, allValues) => {
            const values = { ...allValues, ...changeValues }
            if (changeValues.env) {
              const option = options.find((x) => x.key === values.env)
              const env = envs.find((x) => x.key === values.env)
              _onChange(null, option)
              form.setFieldsValue({
                domain: env.url,
                socketDomain: env.socketUrl,
                socketDomainB: env.socketUrlB,
                v2SocketDomain: env.v2SocketUrl,
                v1Domain: env.v1Url,
                v2Domain: env.v2Url,
                widgetDomain: env.widgetUrl,
              })
            } else {
              setEnvironment({
                key: "custom",
                url: values.domain,
                socketUrl: values.socketDomain,
                socketUrlB: values.socketDomainB,
                v2SocketUrl: values.v2SocketDomain,
                v1Url: values.v1Domain,
                v2Url: values.v2Domain,
                widgetUrl: values.widgetDomain,
              })
              form.setFieldsValue({
                env: "custom",
              })
            }
          }}
        >
          <RadioboxField
            // label={}
            name="env"
            dashed
            className="grid-cols-3"
            items={options?.map((opt) => ({
              label: opt.text,
              value: opt.key,
              icon: <i className={twMerge(opt.iconProps?.iconName, opt.iconProps?.color)} />,
              //   tip: "Envia 1 mensagem a cada 60 segundos. Lote de 30 mensagens. Pausa de 30 minutos a cada lote enviado.",
            }))}
          />
          <Form.Item
            label="Domínio"
            name={"domain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio V1"
            name={"v1Domain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio V2"
            name={"v2Domain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio do Socket"
            name={"socketDomain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio do Socket B"
            name={"socketDomainB"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio do Socket V2"
            name={"v2SocketDomain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Domínio Widget"
            name={"widgetDomain"}
            style={{
              flexDirection: "column",
            }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Drawer>
      {/* <Panel
        isOpen={isOpen}
        // isBlocking={false}
        onDismiss={() => setIsOpen(false)}
        type={left ? PanelType.customNear : PanelType.smallFixedRight}
        headerText="Select a Environment"
        closeButtonAriaLabel="Close"
        customWidth={340}
        isLightDismiss
        // onRenderFooterContent={footer}
      >
        <Stack {...columnProps}>
          <ChoiceGroup
            label="Environments"
            selectedKey={environment.key}
            onChange={_onChange}
            options={options}
          />
          <TextField
            label="Domain"
            value={environment.url}
            onChange={(ev, url) =>
              setEnvironment({
                key: "custom",
                url,
              })
            }
          />
        </Stack>
      </Panel> */}
    </>
  )
}

export default RequestInspector
