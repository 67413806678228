import React, { useMemo } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { getChannelType } from "lib/helper"
import { useStoreActions, useStoreState } from "hooks"
import EditWhatsApp from "./whatsapp/EditWhatsApp"
import EditFacebook from "./facebook/EditFacebook"
import AddChannel from "./AddChannel"
import EditEmail from "./email/EditEmail"
import EditCustom from "./custom/EditCustom"
import EditTelegram from "./telegram/EditTelegram"
import EditInstagram from "./instagram/EditInstagram"
import EditMercadoLivre from "./mercadolivre/EditMercadoLivre"
import EditWhatsappCloud from "./whatsappCloud/EditWhatsappCloud"
import { WhatsappVersion } from "lib/constants"
import EditSms from "./sms/EditSms"

interface IEditChannelProps extends RouteComponentProps<{ id: string }> {}

function EditChannel(props: IEditChannelProps) {
    const { all: channels } = useStoreState((state) => state.channels)
    const { fetchAll } = useStoreActions((actions) => actions.channels)
    const { id } = useParams<any>()

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    if (id == "new") {
        return <AddChannel {...props} />
    }

    function onChange() {
        fetchAll({ config: { reset: true } })
        props.history.push("/settings/channels")
    }

    switch (getChannelType(channel?.channelId as any)?.path) {
        case "whatsapp":
            return channel?.version == WhatsappVersion.BAILEYS ? (
                <EditWhatsApp {...props} onChange={onChange} />
            ) : (
                <EditWhatsappCloud {...props} onChange={onChange} />
            )
        case "instagram":
            return <EditInstagram {...props} onChange={onChange} />
        case "telegram":
            return <EditTelegram {...props} onChange={onChange} />
        case "facebook":
            return <EditFacebook {...props} onChange={onChange} />
        case "email":
            return <EditEmail {...props} onChange={onChange} />
        case "sms":
            return <EditSms {...props} onChange={onChange} />
        case "custom":
            return <EditCustom {...props} onChange={onChange} />
        case "mercado-livre":
            return <EditMercadoLivre {...props} onChange={onChange} />
        default:
            return null
    }
}

export default EditChannel
