import React, { memo, useState } from "react"
import cx from "classnames"
import styles from "./style.module.css"
import stylesParent from "../style.module.css"

import { Button, Form, Tooltip } from "antd"
import { IBotActionType } from "models/bots"
import { useRecoilState, useSetRecoilState } from "recoil"
import { isMobileAtom, themeAtom } from "atoms/app.atom"
import { Elements, Handle, Position, removeElements, useStoreActions } from "react-flow-renderer"
import {
  editWorkflowNodeIdAtom,
  quickViewWorkflowNodeIdAtom,
  workflowActiveRunNodeAtom,
  workflowConnectingAtom,
  workflowElementsAtom,
  workflowRunBotOnStepAtom,
  workflowSelectedElementsAtom,
} from "atoms/workflow.atom"
import { WorkflowActionsItem, WorkflowDefs, WorkflowNodeData } from "../workflow.defs"
import { draggingNodeId } from "../WorkflowCanva"
import { twMerge } from "tailwind-merge"
import { colors } from "lib/colors"
import { workflowShouldAnimateNode } from "../EditWorkflow"
import { enableWorkflowAnimation, generateNewNodeId } from "../workflow.helper"

export interface BaseNodeProps {
  className?: string
  // icon?: string
  title?: string
  children?: React.ReactNode
  handles: React.ReactNode
  handlesAfter: React.ReactNode
  data: WorkflowNodeData
  id: string
  isConnectable: boolean
  isDragging: boolean
  type: IBotActionType
  xPos: number
  yPos: number
  selected: boolean
  notTarget: boolean
  customHeader?: React.ReactNode
  hideActions?: boolean
  defaultHandleLabel?: string
}

function BaseNode(props: BaseNodeProps) {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const [currentActionId, setCurrentActionId] = useRecoilState(workflowActiveRunNodeAtom)
  const setRunBotOnStep = useSetRecoilState(workflowRunBotOnStepAtom)
  const [selectedElements, setSelectedElements] = useRecoilState(workflowSelectedElementsAtom)
  const [connecting, setConnecting] = useRecoilState(workflowConnectingAtom)
  const setEditNodeId = useSetRecoilState(editWorkflowNodeIdAtom)
  const setElements = useSetRecoilState(workflowElementsAtom)
  const setSelectedElementsFlow = useStoreActions((actions) => actions.setSelectedElements)
  const [quickViewNodeId, setQuickViewNodeId] = useRecoilState(quickViewWorkflowNodeIdAtom)
  const [theme] = useRecoilState(themeAtom)
  const actionProps = WorkflowDefs.ActionRecord[props.type] as WorkflowActionsItem
  const category = WorkflowDefs.CategoryRecord[actionProps?.variableCategory]
  const quickViewing = quickViewNodeId === props.id
  const Icon = actionProps?.icon

  return (
    <div
      key={props.id}
      className={cx(
        styles.node,
        "g_node",
        "group cursor-pointer bg-white bg-opacity-[0.85] dark:bg-[rgb(44_46_48_/_var(--tw-bg-opacity))] shadow-[0_6px_12px_rgb(8_35_48_/_4%),0px_16px_24px_rgb(8_35_48_/_6%)] w-60 min-h-[100px] relative rounded-[20px]",
        (workflowShouldAnimateNode === true || workflowShouldAnimateNode === props.id) &&
          "animate-jump animate-duration-200 animate-ease-out",
        (actionProps.isConnectable === false || connecting === props.id) && styles.not_connectable,
        // (actionProps.isConnectable === false || connecting === props.id) && "pointer-events-none bg-opacity-40",
        quickViewing && "outline outline-4 outline-pink-600",
        props.className,
        {
          g_active: currentActionId == props.id,
        }
      )}
      onClick={(e) => {
        if (draggingNodeId) {
          if (e.ctrlKey) {
          } else {
            setEditNodeId(props.id)
          }
        }
      }}
    >
      <div className={cx((actionProps.isConnectable === false || connecting === props.id) && styles.not_connectable_content)}>
        {/* ACTIONS */}
        {!props.hideActions && props.selected && selectedElements?.length < 2 && !isMobile && (
          <div
            className={cx(
              // "absolute top-[-70px] w-[155px] mx-auto my-0 inset-x-0 animate-fade-up animate-duration-150",
              "absolute top-[-70px] w-[105px] mx-auto my-0 inset-x-0 animate-fade-up animate-duration-150",
              stylesParent.panel,
              stylesParent.fade_in
            )}
          >
            <div className="flex items-center gap-2 h-full">
              {/* <Tooltip title="Editar">
              <Button
                type="text"
                icon={<i className="far fa-edit" />}
                onClick={() => {
                  setEditNodeId(props.id)
                }}
              />
            </Tooltip> */}
              {/* <Tooltip title="Simular">
              <Button
                type="text"
                icon={<i className="far fa-play" />}
                onClick={() => {
                  setEditNodeId("")
                  setCurrentActionId(props.id)
                  setRunBotOnStep(true)
                }}
                disabled
              />
            </Tooltip> */}
              <Form.Item
                shouldUpdate={() => {
                  return false
                }}
                noStyle
              >
                {(form) => {
                  return (
                    <Tooltip title="Clonar">
                      <Button
                        type="text"
                        icon={<i className="far fa-copy" />}
                        onClick={() => {
                          const id = generateNewNodeId(form as any)
                          enableWorkflowAnimation(id)

                          setElements((els: Elements) => [
                            ...els,
                            {
                              id,
                              data: { ...props.data },
                              type: props.type,
                              position: {
                                x: props.xPos + 280,
                                y: props.yPos,
                              },
                            },
                          ])

                          setSelectedElementsFlow([{ id: id, type: props.type }])

                          setTimeout(() => {
                            setEditNodeId("")
                          }, 50)
                        }}
                      />
                    </Tooltip>
                  )
                }}
              </Form.Item>
              <Tooltip title="Excluir">
                <Button
                  type="text"
                  icon={<i className="far fa-trash-alt" style={{ marginTop: -2 }} />}
                  onClick={() => {
                    setEditNodeId("")
                    setElements((els: Elements) => removeElements([{ id: props.id } as any], [...els]))
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )}
        {props.customHeader || (
          <div className={cx("w-full flex items-center justify-center text-base h-10 px-1.5 pt-1.5")}>
            <div
              className="flex justify-start items-center w-full gap-2 text-white rounded-t-2xl rounded-b-xl"
              style={{
                backgroundColor: actionProps.colors?.bg || colors[category?.palette! || "slate"][theme === "dark" ? 800 : 700],
                color: actionProps.colors?.text || "white",
              }}
            >
              <div className="flex items-center gap-2 w-full pr-2">
                <div
                  className="w-9 h-9 rounded-xl flex-center bg-[rgba(0,0,0,0.1)]"
                  // style={{
                  //   backgroundColor: actionProps?.color,
                  // }}
                >
                  <Icon className={actionProps.colors?.text || "text-white"} />
                </div>
                {props.title}
                <span
                  className={twMerge(
                    "opacity-0 group-hover:opacity-60 transition-opacity text-3xs font-medium -mb-1",
                    quickViewing && "opacity-60"
                  )}
                >
                  #{props.id}
                </span>
              </div>
            </div>
          </div>
          // <div className={cx("w-full flex items-center justify-center text-base h-12 px-4 pt-1")}>
          //   <div
          //     className="flex justify-start items-center w-full gap-2 text-white rounded-xl"
          //     style={{
          //       backgroundColor: actionProps?.color,
          //     }}
          //   >
          //     <div
          //       className="w-9 h-9 rounded-xl flex-center"
          //       // style={{
          //       //   backgroundColor: actionProps?.color,
          //       // }}
          //     >
          //       <Icon className="text-white" />
          //     </div>
          //     {props.title}
          //   </div>
          // </div>
          // <div
          //   className={cx("text-[white] bg-[#0078d4] w-full flex items-center justify-center text-base h-[45px] p-5 rounded-xl")}
          //   style={{ backgroundColor: actionProps?.backgroundColor }}
          // >
          //   <div className="flex justify-start items-center w-full">
          //     {/* <i className={props.icon} style={{ marginRight: 10 }} /> */}
          //     <Icon />
          //     {props.title}
          //   </div>
          // </div>
        )}
        <div className="flex items-center justify-center p-[15px] pb-0 break-words h-full">{props.children}</div>
        {!props.notTarget && <Handle type="target" position={Position.Left} className={styles.node_handle_wrapper}></Handle>}
        {props.handles}
      </div>
      <div className="flex flex-col gap-2 items-end justify-center w-full">
        <div className="flex justify-end items-center w-full h-10">
          {props.defaultHandleLabel && (
            <span className="text-3xs -mr-2 text-content-200 bg-slate-100 px-2 rounded-full">{props.defaultHandleLabel || "Seguinte"}</span>
          )}
          <Handle type="source" position={Position.Right} id="default" className={styles.node_handle_source_wrapper}>
            <div
              className={cx(styles.node_handle)}
              style={{
                backgroundColor: "var(--edge)",
              }}
            />
          </Handle>
        </div>
        {props.handlesAfter}
      </div>
    </div>
  )
}

export default memo(BaseNode)
