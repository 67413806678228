import { endpoints } from "config/endpoints.config"
import { channelsTypes } from "lib/constants"
import { IGenericModel, generic } from "models/generic"

enum EMessageAPI {
    SENT_TO_MODULE = 0,
    SENT_TO_API = 2,
    AWAITING_RESEND_TO_MODULE = 4,
    DELIVERED = 6,
    ERROR = 8,
}

export interface IMessageAPI {
    id?: string
    createdAt: string
    message: string
    attempts?: number
    isDeleted?: boolean
    isSent?: boolean
    isError?: boolean
    workspacePlatformId: string
    isErrorAtSending: boolean
    isMedia: boolean
    status: EMessageAPI
    WorkspacePlatform: {
        id: string
        description: string
        platformId: string
    }
    executedAt: string
    to: string
    isExecuted: boolean
    sentAt: string
    callOptions?: any
    errorDescription?: string
}

export const messagesAPI: IGenericModel<IMessageAPI> = {
    ...generic(endpoints.MESSAGES_API),
}
