import { Menu } from "antd"
import { activeConversationSelector } from "atoms/conversation.atom"
import { endpoints } from "config/endpoints.config"
import { useStoreState } from "hooks"
import request from "lib/request"
import { ITag } from "models/tags"
import React, { memo } from "react"
import { useRecoilState } from "recoil"
import SearchDrop from "../SearchDrop"
import MenuItemInnerSelectable from "../SearchDrop/MenuItemInnerSelectable"
import { verifyPermission } from "lib/helper"
import toast from "lib/toast"
import { TagItem } from "components-old/chat/TagInput"
import { convertColorToTagColor } from "pages/settings/tags/EditTag"
import usePrivacy from "hooks/usePrivacy"

interface ITagDropProps {
  children: React.ReactNode
  selectedIds?: number[]
  onSubmit?: (model) => void
}

function TagDrop(props: ITagDropProps) {
  const { all: tags } = useStoreState((state) => state.tags)
  const [activeConversation, setActiveConversation] = useRecoilState(activeConversationSelector)

  const { allowedItems: allowedTags } = usePrivacy<ITag>({
    items: tags,
  })

  function toggleTag(tag: ITag) {
    if (props.onSubmit) {
      props.onSubmit(tag)
    } else {
      if (activeConversation?.tags.includes(tag.id)) {
        if (verifyPermission("chat_remove_tags", { restrictive: true })) {
          toast.error("Não tem permissão para remover tags.")
          return
        }
        setActiveConversation((x) => ({
          ...x!,
          tags: x?.tags?.filter((tagId) => tag.id != tagId)!,
        }))
        request.delete(endpoints.SAVE_TAG, {
          id: activeConversation?.id,
          callId: activeConversation?.callId,
          labelId: tag.id,
        })
      } else {
        setActiveConversation((x) => ({
          ...x!,
          tags: [...x?.tags!, tag.id],
        }))
        request.patch(endpoints.SAVE_TAG, {
          id: activeConversation?.id,
          callId: activeConversation?.callId,
          labelId: tag.id,
        })
      }
    }
  }

  return (
    <SearchDrop
      persistent
      title="Categorizar"
      options={allowedTags
        .sort((a, b) => {
          if (a?.id! > b?.id!) return 1
          if (a?.id! < b?.id!) return -1
          return 0
        })
        .map((tag) => ({
          searchValue: tag.name,
          node: (
            <Menu.Item
              key={tag.id}
              eventKey={tag.id}
              onClick={(e) => {
                e.domEvent.stopPropagation()
                toggleTag(tag)
              }}
              style={{
                width: "100%",
              }}
              className="global_full_width_menu_item"
            >
              <MenuItemInnerSelectable selected={props.selectedIds?.includes(Number(tag.id))} title={tag?.name}>
                <TagItem colors={convertColorToTagColor(tag.color)}>{tag.name}</TagItem>
              </MenuItemInnerSelectable>
            </Menu.Item>
          ),
        }))}
    >
      {props.children}
    </SearchDrop>
  )
}

export default memo(TagDrop)
