import toast from "lib/toast"
import { IPaginatedResponse } from "models/generic"
import request from "./request"
import * as XLSX from "xlsx"

// ███████╗██╗  ██╗██████╗  ██████╗ ██████╗ ████████╗
// ██╔════╝╚██╗██╔╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
// █████╗   ╚███╔╝ ██████╔╝██║   ██║██████╔╝   ██║
// ██╔══╝   ██╔██╗ ██╔═══╝ ██║   ██║██╔══██╗   ██║
// ███████╗██╔╝ ██╗██║     ╚██████╔╝██║  ██║   ██║
// ╚══════╝╚═╝  ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝
//
// export const exportData = (
//   data: any,
//   options: {
//     xls?: boolean;
//     filename?: string;
//     title?: string;
//   }
// ) => {
//   if (options?.xls) {
//     js2excel.json2excel({
//       data,
//       name: options?.filename,
//     });
//   } else {
//     const csvExporter = new ExportToCsv({
//       fieldSeparator: ",",
//       quoteStrings: '"',
//       decimalSeparator: ".",
//       showLabels: true,
//       useKeysAsHeaders: true,
//       filename: options?.filename || "export " + new Date().toLocaleString(),
//       title: options?.title || "Export " + new Date().toLocaleString(),
//     });
//     csvExporter.generateCsv(data);
//   }
// };

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝
//
export async function getResourcesToExportPaginated<T>(
  endpoint: string,
  options?: {
    count?: number
    params?: any
    limit?: number
    toastLabel?: string
    notToast?: boolean
  }
): Promise<{ hideToast: Function; result: T[]; count?: number }> {
  let acc: T[] = []
  let count = options?.count
  const limit = options?.limit || 500
  let hideToast
  if (!options?.notToast) {
    hideToast = toast.loading(`${options?.toastLabel || "Exportando"}... 0%`, 0, {
      key: "exporting",
    })
  }

  try {
    for (let i = 0; i < Math.ceil((count || limit) / limit); i++) {
      const response = await request.get<IPaginatedResponse<T>>(endpoint, {
        params: {
          ...(options?.params || {}),
          offset: acc.length,
          limit,
        },
      })

      if (!options?.count) {
        count = response.data.totalCount
      }

      acc = [...acc, ...response.data.data]

      const percentage = Math.floor((acc?.length / (count || 1)) * 100)
      if (!options?.notToast) {
        toast.loading(`${options?.toastLabel || "Exportando"}... ${percentage}%`, 0, {
          key: "exporting",
        })
      }
      const timeout = () =>
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(0)
          }, 1000)
        )
      await timeout()
    }
  } catch (error) {
    console.log(error)
    hideToast()
    toast.error("Ocorreu um erro ao exportar os dados")
  }

  return {
    result: acc,
    hideToast: options?.notToast ? () => {} : hideToast,
    count,
  }
}

// export const jsonToCSV = (arr: any[], config?: { hideHeader?: boolean }) => {
//     try {
//         const parser = new Parser(config?.hideHeader ? { header: false } : {})
//         const csv = parser.parse(arr)

//         return csv
//     } catch (err) {
//         console.error(err)
//         return ""
//     }
// }

// export function jsonToCSV(jsonData: any[], config?: { hideHeader?: boolean }): Promise<string> {
//     return new Promise((resolve, reject) => {
//         try {
//             // Substituir quebras de linha em todos os campos string do JSON
//             const sanitizedJsonData = jsonData.map((item) => {
//                 const sanitizedItem = { ...item }
//                 for (const key in sanitizedItem) {
//                     if (typeof sanitizedItem[key] === "string") {
//                         sanitizedItem[key] = sanitizedItem[key].replace(/\n/g, " ") // substitui '\n' por espaço
//                     }
//                 }
//                 return sanitizedItem
//             })

//             const parser = new Parser(config?.hideHeader ? { header: false, quote: '', escapedQuote } : {}) // Desabilita aspas
//             const csv = parser.parse(sanitizedJsonData)
//             resolve(csv)
//         } catch (error) {
//             reject(error)
//         }
//     })
// }

function sanitizeJsonData(jsonData: any[]) {
  return jsonData.map((item) => {
    const sanitizedItem = { ...item }
    for (const key in sanitizedItem) {
      if (typeof sanitizedItem[key] === "string") {
        // Substitui '\n' por espaço e envolve campos com vírgulas em aspas
        sanitizedItem[key] = sanitizedItem[key].replace(/\n/g, " ")
        // if (sanitizedItem[key].includes(",")) {
        //     sanitizedItem[key] = `"${sanitizedItem[key]}"`
        // }
      }
    }
    return sanitizedItem
  })
}

export function jsonToSheetOrCSV(arr: any[], config?: { hideHeader?: boolean; xlsx?: boolean; sheetName?: string; sanitize?: boolean }) {
  try {
    const data = config?.sanitize ? sanitizeJsonData(arr) : arr
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1")

    if (config?.xlsx) {
      return { workbook }
    } else {
      // Converter a planilha para CSV
      const csvContent = XLSX.utils.sheet_to_csv(worksheet, { forceQuotes: true, rawNumbers: true })

      // Adicionar BOM para corrigir os acentos
      const bom = "\uFEFF"
      const csvWithBom = bom + csvContent

      return { workbook, csv: csvWithBom }
    }
  } catch (e) {
    console.error("Erro ao exportar para csv", e)
  }

  return {}
}

// export function jsonToCSV(jsonData: any[], config?: { hideHeader?: boolean }): Promise<string> {
//   return new Promise((resolve, reject) => {
//     try {
//       // Substituir quebras de linha em todos os campos string do JSON
//       const sanitizedJsonData = jsonData.map((item) => {
//         const sanitizedItem = { ...item }
//         for (const key in sanitizedItem) {
//           if (typeof sanitizedItem[key] === "string") {
//             // Substitui '\n' por espaço e envolve campos com vírgulas em aspas
//             sanitizedItem[key] = sanitizedItem[key].replace(/\n/g, " ")
//             // if (sanitizedItem[key].includes(",")) {
//             //     sanitizedItem[key] = `"${sanitizedItem[key]}"`
//             // }
//           }
//         }
//         return sanitizedItem
//       })

//       const parser = new Parser(
//         config?.hideHeader
//           ? {
//               header: false,
//               //   quote: "",
//               //   formatters: {
//               //       string: stringFormatter({ quote: "" }), // This works
//               //   },
//             }
//           : {}
//       )
//       const csv = parser.parse(sanitizedJsonData)
//       resolve(csv)
//     } catch (error) {
//       reject(error)
//     }
//   })
// }
