import { Button, Form, Input, Select, Tabs } from "antd"
import CustomSelect from "components-old/forms/CustomSelect"
import FormModal from "components-old/forms/FormModal"
import { LabelHelp } from "components-old/forms/LabelHelp"
import { BaseEditActionChildrenProps } from "pages/settings/workflows/actions/BaseEditAction"
import MakeRequestHeaders from "./MakeRequestHeaders"
import MakeRequestResponse from "./MakeRequestResponse"
import toast from "lib/toast"
import { useEffect, useMemo, useRef, useState } from "react"
import InputVariable from "components-old/forms/InputVariable"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import IconTag from "components-old/shared/IconTag"
import { InputType } from "lib/constants"
import cx from "classnames"
import AnalyticsJSONViewer from "pages/settings/workflows/analytics/AnalyticsJSONViewer"
import { useDebouncedCallback } from "use-debounce"
import { parseVariableString } from "components-old/forms/InputVariable/variables.helper"
import { useRecoilState } from "recoil"
import TagColor from "components-old/shared/TagColor"
import { Alert01Icon } from "components-old/icons"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import { useLocation, useParams } from "react-router-dom"
import Tooltip from "components-old/Tooltip"
import { useStore } from "hooks"
import { session } from "store"
import { PromptMessage } from "pages/inbox/AIAssist/assist-prompts-gpt"
import { AssistTextTransformResponse } from "pages/inbox/AIAssistDrop"
import { assistantStreamTextAtom, variableAtom } from "atoms/app.atom"

interface MakeRequestModalProps extends BaseEditActionChildrenProps {
  onClose: () => void
}

const stringifyBody = (body: any) => {
  if (!body) return ""

  try {
    return JSON.stringify(body, null, 2)
  } catch (e) {
    console.error(e)
    return ""
  }
}

export const baseJSONPrompt = `
Ajeite e formate o json que irei te passar. Por exemplo, caso esteja faltando aspas coloque para mim. Exemplo:

input={
	"nome": "Fulano",
  "idade": 32,
	"sobrenome": string1,
}

output={
  "nome": "Fulano",
  "idade": 32,
	"sobrenome": "string1"
}
`

const analyzeJSONPrompt = `${baseJSONPrompt}

Dado o contexto acima, Ajeite e formate o json abaixo:

input=##TEXT##

output=`

const MakeRequestModal = (props: MakeRequestModalProps) => {
  const [form] = Form.useForm()
  const [activeKey, setActiveKey] = useState(props.editNode.data?.action?.request?.method === "GET" ? "headers" : "body")
  const [jsonToVerify, setJsonToVerify] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<any>()
  const [dynamicVariablesDef] = useRecoilState(variableAtom)
  const { id } = useParams<any>()
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [response, setResponse] = useState()
  const [loadingAssist, setLoadingAssist] = useState(false)
  const [bodyKey, setBodyKey] = useState(1)
  const [streamText, setStreamText] = useRecoilState(assistantStreamTextAtom)
  const { workspace, user } = useStore(session)
  const location = useLocation()

  const hasCredits = useMemo(() => {
    // return false/
    return workspace?.wallet?.balance > 0.1
  }, [workspace?.wallet?.balance])

  const promptReplacerStream = (
    instruction: PromptMessage | string,
    text: string,
    opts: {
      promptType?: string
      placeholders?: string
    }
  ) => {
    let promptMessages
    if (instruction instanceof Array) {
      promptMessages = instruction
    } else {
      promptMessages = [
        {
          role: "user",
          content: (instruction as string).replace("##TEXT##", text),
        },
      ]
    }

    setStreamText("")

    setLoadingAssist(true)
    return request
      .post<AssistTextTransformResponse>(endpoints.COMPLETE_TEXT_STREAM, {
        promptMessages,
        originalText: text,
        promptType: opts?.promptType,
        workspaceName: workspace.workspace,
        operatorName: user.name,
        origin: location?.pathname,
      })
      .then((res) => {
        if (res.data.text) {
          try {
            const t = res.data.text

            const obj = JSON.parse(t.replace("```json", "").replace("```\n", "").replace("```", "").replace(/\\n/g, "\n"))

            const value = JSON.stringify(obj, null, 2).replace(/\n/g, "\n\n")

            form.setFields([
              {
                name: "body",
                value,
              },
            ])
            setTimeout(() => {
              setBodyKey((prev) => prev + 1)
              verifyJsonDebounce(value)
              toast.success("Corrigido e formatado")
            }, 500)
          } catch (e) {
            console.error(e)
          }
        } else if (res.data?.error) {
          toast.error(res.data.error, 5, { key: "openai-error" })
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoadingAssist(false)
      })
  }

  const testRequest = () => {
    setActiveKey("response")
    setResponse(undefined)
    setLoadingResponse(true)
    request
      .post(`${endpoints.WORKFLOW}/${id}/test-request`, {
        method: form.getFieldValue("method"),
        url: form.getFieldValue("url"),
        headers: form.getFieldValue("headers").reduce((acc: any, header: any) => {
          acc[header.key] = header.value
          return acc
        }, {}),
        body: jsonToVerify ? jsonToVerify : form.getFieldValue("body"),
      })
      .then((res) => {
        setResponse(res.data)
      })
      .finally(() => {
        setLoadingResponse(false)
      })
  }

  const verifyJsonDebounce = useDebouncedCallback((body) => {
    try {
      const jsonString = parseVariableString(body?.replace(/&nbsp;/g, "")?.replace(/\u00a0/g, ""), {
        variablesRecord: dynamicVariablesDef.variablesRecord,
      })

      const json = JSON.parse(jsonString)

      setErrorMessage("")
      setJsonToVerify(json)
    } catch (e: any) {
      setJsonToVerify(false)
      setErrorMessage(e?.message)
      console.error(e)
    }
  }, 500)

  useEffect(() => {
    setTimeout(() => {
      verifyJsonDebounce(props.editNode?.data?.action?.request?.body || form.getFieldValue("body"))
    }, 500)
  }, [])

  const itemRef = useRef<any>({
    ...props.editNode?.data?.action?.request,
    // body: stringifyBody(props.editNode?.data?.action?.request?.body),
    headers: Object.entries(props.editNode?.data?.action?.request?.headers || {}).map(([key, value]) => ({
      key,
      value,
    })),
  })

  const onSubmit = (model: any) => {
    let body
    // try {
    //   if (typeof model.body === "string" && !!model.body?.trim?.() && model.method !== "GET" && model.method !== "DELETE") {
    //     console.log(model.body, model.body?.replace(/&nbsp;/g, ""))
    //     body = JSON.parse(model.body?.replace(/&nbsp;/g, "")?.replace(/\u00a0/g, ""))
    //   }
    // } catch (e) {
    //   console.error(e)
    //   toast.error("Body inválido, verifique se o JSON está formatado corretamente.")
    //   return
    // }

    props.form.setFields([
      {
        name: [...props.actionFieldName, "request"],
        value: {
          url: model.url,
          body: model.body,
          method: model.method,
          headers: model.headers?.reduce?.((acc: any, header: any) => {
            acc[header.key] = header.value
            return acc
          }, {}),
          responseExample: model.response,
        },
      },
    ])

    props.syncElements()
    props.onClose()
  }

  return (
    <FormModal
      form={form}
      onSubmit={onSubmit}
      type="modal"
      titleOverwrite={
        <div className="flex items-center gap-3">
          <IconTag
            icon={WorkflowDefs.ActionRecord.make_request.icon}
            palette="zinc"
            size="lg"
            lightTone={{
              bg: 700,
              text: 50,
            }}
          />
          {/* Requisição<span className="text-content-300 text-sm font-medium">#{props.editNode.id}</span> */}
          Requisição #{props.editNode.id}
          <span className="text-content-300 text-sm font-medium"></span>
        </div>
      }
      hideSuccessMessage
      // modalClassname="[&_.ant-modal-header]:bg-gray-700 [&_.ant-modal-close-x]:bg-gray-600 [&_.ant-modal-close-x>i]:text-white"
      modalClassname="[&_.ant-modal-header]:shadow [&_.ant-modal-footer]:bg-transparent [&_.ant-modal-footer]:py-4 [&_.ant-modal-footer_.ant-btn-primary]:px-6"
      item={itemRef.current}
      formProps={{
        initialValues: {
          method: "POST",
          body: `{

  "nome_ambiente": "{workspace:name|||}",

  "numero_aleatorio": {utils:random|||}

}`,
          ...itemRef.current,
        },
        onValuesChange(changedValues, values) {
          if (changedValues.method === "GET" || changedValues.method === "DELETE") {
            if (activeKey === "body") {
              setActiveKey("headers")
            }
            setJsonToVerify(undefined)
            setTimeout(() => {
              form.setFields([
                {
                  name: "body",
                  value: null,
                },
              ])
            }, 100)
          } else if (activeKey === "body" && changedValues.body) {
            verifyJsonDebounce(changedValues.body)
          }
        },
      }}
      onBack={props.onClose}
      width={1060}
      saved={false}
      bodyStyle={{
        padding: "0",
      }}
      okText="Salvar"
    >
      <div className="flex gap-4 items-center w-full px-7 pt-4">
        <Form.Item label="Método" name="method" required className="w-28">
          <CustomSelect>
            <Select.Option value="POST">
              <span className="text-amber-600 font-medium">POST</span>
            </Select.Option>
            <Select.Option value="GET">
              <span className="text-green-600 font-medium">GET</span>
            </Select.Option>
            <Select.Option value="PUT">
              <span className="text-blue-600 font-medium">PUT</span>
            </Select.Option>
            <Select.Option value="PATCH">
              <span className="text-purple-600 font-medium">PATCH</span>
            </Select.Option>
            <Select.Option value="DELETE">
              <span className="text-red-600 font-medium">DELETE</span>
            </Select.Option>
          </CustomSelect>
        </Form.Item>
        <Form.Item
          label={<LabelHelp help="Apenas links https são permitidos">Endereço URL</LabelHelp>}
          name="url"
          required
          className="flex-1"
        >
          <InputVariable floatingTrigger className="w-full flex-1" placeholder="Coloque a URL (e.g. https://kinbox.com.br)" />
        </Form.Item>
        <Form.Item label=" " shouldUpdate>
          {(formInstance) => {
            return (
              <Button
                icon={<i className="fas fa-play" />}
                onClick={testRequest}
                disabled={!jsonToVerify && ["POST", "PUT", "PATCH"].includes(formInstance.getFieldValue("method"))}
              >
                Testar
              </Button>
            )
          }}
        </Form.Item>
      </div>
      <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.method !== currentValues.method} noStyle>
        {(formInstance) => {
          const showBody = formInstance.getFieldValue("method") !== "GET" && formInstance.getFieldValue("method") !== "DELETE"
          return (
            <Tabs
              activeKey={activeKey}
              onTabClick={(key) => {
                setActiveKey(key)
              }}
              className="h-[440px] [&_.ant-tabs-nav]:pl-7 [&_.ant-tabs-nav]:shadow"
              style={{
                borderBottom: "1px solid var(--divider)",
              }}
            >
              <Tabs.TabPane
                tab="Headers"
                key="headers"
                className="pl-7 pr-4 py-4 h-full"
                // style={{
                //   boxShadow: "inset -1px 3px 10px 0 rgba(0, 0, 0, 0.1)",
                // }}
              >
                <MakeRequestHeaders />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Body"
                key="body"
                disabled={formInstance.getFieldValue("method") === "GET" || formInstance.getFieldValue("method") === "DELETE"}
              >
                {showBody && (
                  <div
                    className="flex h-full w-full dark:bg-[var(--input-background)]"
                    // style={{
                    //   boxShadow: "inset -1px 3px 10px 0 rgba(0, 0, 0, 0.1)",
                    // }}
                  >
                    <div className="flex-1 py-3 pl-3 pr-0 shrink-0 basis-1/2 max-w-[50%] [&_.variable-trigger]:right-2 relative">
                      <div className="absolute right-12 top-1.5 z-10">
                        <Tooltip title="Corrigir e formatar com IA">
                          <Button
                            type="text"
                            icon={<i className="fal fa-hand-sparkles !text-lg" />}
                            className="w-8 h-8"
                            loading={loadingAssist}
                            onClick={() => {
                              if (!hasCredits) {
                                toast.error("Você não tem créditos suficientes para usar essa funcionalidade.")
                              }
                              promptReplacerStream(analyzeJSONPrompt, form.getFieldValue("body"), {
                                promptType: "Formatar e corrigir",
                              })
                            }}
                          />
                        </Tooltip>
                      </div>
                      <Form.Item name="body" label={<div className="ml-4 text-content-300 text-2sm">Corpo da requisição</div>}>
                        <InputVariable
                          field={{
                            type: InputType.JSON,
                            label: "Corpo da requisição",
                            name: "body",
                          }}
                          key={bodyKey}
                          className={cx(
                            "border-none min-h-[352px] h-[352px] [&_.tiptap]:!min-h-[343px]"
                            // "[&_.tiptap]:px-0",
                            // "[&_.tiptap-wrapper]:overflow-auto"
                          )}
                          placeholder="Coloque o corpo da requisição em formato JSON"
                          showLineNumbers
                        />
                        {/* <JsonInput
                      id="request-body"
                      className="[&>.ace\_gutter]:!bg-white [&>.ace\_gutter]:!shadow"
                      placeholder="Digite o corpo da requisição em formato JSON"
                    /> */}
                      </Form.Item>
                    </div>
                    <div
                      className="flex-1 bg-base-200 py-3 px-7 shrink-0 basis-1/2 max-w-[50%] overflow-auto pretty-scroll pretty-scroll-hoverable"
                      style={{
                        borderLeft: "1px solid var(--divider)",
                        boxShadow: "inset -1px 3px 10px 0 rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {/* <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.body !== currentValues.body} noStyle>
                        {(formInstance) => {}}
                      </Form.Item> */}
                      <Form.Item label="Pré-visualização (Exemplo)">
                        {jsonToVerify ? (
                          <AnalyticsJSONViewer
                            json={jsonToVerify}
                            id="input"
                            className="p-0 bg-transparent overflow-visible [&_pre]:overflow-visible -mt-1"
                          />
                        ) : (
                          jsonToVerify === false && (
                            <div className="flex flex-col gap-2">
                              <span className="inline-flex">
                                <TagColor
                                  palette="red"
                                  lightTone={{
                                    bg: 500,
                                    text: 50,
                                  }}
                                  className="flex items-center gap-2 text-white rounded-2xl"
                                >
                                  <Alert01Icon className="text-white w-5 h-5" />
                                  JSON Inválido
                                </TagColor>
                              </span>
                              {errorMessage && <span className="text-red-500 text-sm">{errorMessage}</span>}
                            </div>
                          )
                        )}
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Resposta" key="response">
                <MakeRequestResponse loading={loadingResponse} response={response} testRequest={testRequest} />
              </Tabs.TabPane>
            </Tabs>
          )
        }}
      </Form.Item>
    </FormModal>
  )
}

export default MakeRequestModal
