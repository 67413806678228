import React, { useMemo } from "react"
import { Drawer } from "antd"
import { WorkflowLog } from "models/workflows"
import { twMerge } from "tailwind-merge"
import CollapseCards from "pages/crm/deals/ViewDeal/CollapseCards"
import { LabelHelp } from "components-old/forms/LabelHelp"
import AnalyticsJSONViewer from "./AnalyticsJSONViewer"
import { ExecutionLogItem } from "./AnalyticsExecutionModal"
import { colors } from "lib/colors"
import IconTag from "components-old/shared/IconTag"
import { ActionEnum } from "../workflow.enums"
import { CancelCircleIcon } from "components-old/icons"

interface AnalyticsExecutionActionModalProps {
  onCancel: () => void
  executionLog: WorkflowLog | null
  executionActionLogItem: ExecutionLogItem | null
}

function AnalyticsExecutionActionModal(props: AnalyticsExecutionActionModalProps) {
  const memos = useMemo(() => {
    let _input = {}
    let output = {}
    let errorOutput

    if (props.executionActionLogItem) {
      const { input, ...rest } = props.executionActionLogItem.actionLog?.context || {}
      _input = input || {}
      output = rest || {}

      if (props.executionLog?.error?.onActionId === props.executionActionLogItem.actionId) {
        const { onActionId, ...restError } = props.executionLog.error
        errorOutput = restError
      }
    }

    if (props.executionActionLogItem?.actionId === ActionEnum.TRIGGER) {
      _input = props.executionLog?.trigger.data
      //  {

      //   body: props.executionLog?.trigger.data.body || {},
      //   query: props.executionLog?.trigger.data.query || {},
      //   origin: props.executionLog?.trigger.data.origin || "",
      //   url:
      // }
    }

    return {
      input: _input,
      output,
      showOutput: props.executionActionLogItem?.actionId !== ActionEnum.TRIGGER && !errorOutput,
      showInput: true,
      errorOutput,
    }
  }, [props.executionActionLogItem, props.executionLog?.error, props.executionLog?.trigger?.data])

  return (
    <Drawer
      title={
        <div
          className="flex items-center gap-2 text-md font-bold"
          style={{
            color:
              props.executionActionLogItem?.actionRecord?.colors?.text ||
              colors[props.executionActionLogItem?.category?.palette || 0]?.[600],
          }}
        >
          {props.executionActionLogItem?.Icon && (
            <>
              <IconTag
                size="lg"
                icon={props.executionActionLogItem?.Icon}
                palette={props.executionActionLogItem?.category.palette!}
                lightTone={{
                  bg: 200,
                  text: 700,
                }}
                customColors={{
                  bg: props.executionActionLogItem?.actionRecord?.colors?.bg,
                  text: props.executionActionLogItem?.actionRecord?.colors?.text
                }}
              />
              {props.executionActionLogItem?.label} #{props.executionActionLogItem?.actionId}
            </>
          )}
        </div>
      }
      headerStyle={{
        paddingRight: 0,
      }}
      className="g_trigger_modal no-mask"
      visible={!!props.executionActionLogItem}
      footer={null}
      // closable={false}
      closeIcon={<i className={twMerge("far fa-times")} />}
      width={700}
      bodyStyle={{
        minHeight: 400,
      }}
      maskClosable
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      // onCancel={props.onCancel}
      onClose={props.onCancel}
      zIndex={31}
    >
      {props.executionActionLogItem && (
        <CollapseCards
          defaultActiveKey={["input", "output", "errorOutput"]}
          bordered
          destroyInactivePanel={false}
          panels={[
            {
              key: "input",
              title: (
                <LabelHelp className="text-sm flex items-center gap-3" help="Dados enviados para a ação.">
                  {/* <Download03Icon className="w-5 h-5 mr-1 text-amber-600" /> */}
                  Entrada
                </LabelHelp>
              ),
              content: <AnalyticsJSONViewer json={memos?.input} id="input" />,
            },
            ...(memos.showOutput
              ? [
                  {
                    key: "output",
                    title: (
                      <LabelHelp className="text-sm flex items-center gap-3" help="Os dados recebidos ao executar a ação.">
                        {/* <Upload03Icon className="w-5 h-5 mr-1 text-teal-600" /> */}
                        Saída
                      </LabelHelp>
                    ),
                    content: <AnalyticsJSONViewer json={memos?.output} id="output" />,
                  },
                ]
              : []),
            ...(memos.errorOutput
              ? [
                  {
                    key: "errorOutput",
                    title: (
                      <LabelHelp className="text-sm flex items-center gap-3 text-red-500" help="Os dados recebidos ao executar a ação.">
                        <CancelCircleIcon className="w-5 h-5 mr-1 text-red-500" />
                        Erro
                      </LabelHelp>
                    ),
                    content: <AnalyticsJSONViewer json={memos?.errorOutput} id="errorOutput" />,
                  },
                ]
              : []),
          ]}
        />
      )}
    </Drawer>
  )
}

export default AnalyticsExecutionActionModal
