import React from "react"
import { GenericResource } from "models/generic"
import { UseInfiniteQueryGenericProps } from "hooks/useInfiniteQueryGeneric"
import { Button, Dropdown, Menu, Skeleton } from "antd"
import SearchEmpty from "./SearchEmpty"
import Empty from "./Empty"
import "./table.scss"
import MenuLink from "components-old/menu/MenuLink"
import SubMenu from "antd/lib/menu/SubMenu"
import { exportTableData } from "./table.helper"
import { Csv02Icon, Download02Icon, Xls02Icon } from "components-old/icons"
import { DnDInfiniteTable } from "./dnd/DnDInfiniteTable"
import VirtualInfiniteTable, { VirtualInfiniteTableProps } from "./virtual/VirtualInfiniteTable"
import CardLayout from "../page/CardLayout"
import Separator from "components/navigation/Separator"

export interface TableExtraMore {
  id: string
  title?: string
  iconName?: string
  to?: string
  onClick?: () => void
  custom?: React.ReactNode
  disabled?: boolean
  separator?: boolean
}

export interface TablePageProps<T extends GenericResource> {
  title?: string
  initialData?: UseInfiniteQueryGenericProps<T>["initialData"]
  onSuccess?: UseInfiniteQueryGenericProps<T>["onSuccess"]
  top?: React.ReactNode
  getExportableData?: (data: T[]) => Promise<{ exportableData: Record<string, string>[]; filename: string }>
  extra?: TableExtraMore[]
  dnd?: boolean
  cardLayoutClassName?: string
  children?: React.ReactNode
  before?: React.ReactNode
}

export function TablePage<T extends GenericResource>(
  props: TablePageProps<T> & {
    tableProps: VirtualInfiniteTableProps<T>
  }
) {
  const hasExtra = !!props.extra?.length || !!props.getExportableData
  return (
    <CardLayout
      // className="max-w-3xl w-full mx-5 mt-5 mb-20 pb-4 mobile:shadow-none mobile:max-w-full mobile:p-0 mobile:m-0"
      // headerClassName="mobile:hidden"
      title={props.title}
      extra={
        hasExtra ? (
          <Dropdown
            key={"drop"}
            placement="bottomRight"
            overlay={
              <Menu>
                {/* <Menu expandIcon={}> */}
                {props.extra?.map((e) => {
                  if (e.custom) {
                    return e.custom
                  }
                  if (e.separator) {
                    return <Separator key={e.id || "separator-menu"} />
                  }
                  if (e.to) {
                    return (
                      <Menu.Item key={e.id} disabled={e.disabled}>
                        <MenuLink iconName={e.iconName} to={e.to}>
                          {e.title}
                        </MenuLink>
                      </Menu.Item>
                    )
                  }
                  return (
                    <Menu.Item key={e.id} icon={<i className={e.iconName} />} disabled={e.disabled} onClick={e.onClick}>
                      {e.title}
                    </Menu.Item>
                  )
                })}
                {!!props.getExportableData && (
                  <SubMenu
                    key="export"
                    icon={
                      <i>
                        <Download02Icon />
                      </i>
                    }
                    title="Exportar"
                  >
                    <Menu.Item
                      key="export-csv"
                      icon={
                        <i>
                          <Csv02Icon />
                        </i>
                      }
                      onClick={() => {
                        exportTableData(props.tableProps.data, props.getExportableData!)
                      }}
                    >
                      CSV
                    </Menu.Item>
                    <Menu.Item
                      key="export-xlsx"
                      icon={
                        <i>
                          <Xls02Icon />
                        </i>
                      }
                      onClick={async () => {
                        exportTableData(props.tableProps.data, props.getExportableData!, { xls: true })
                      }}
                    >
                      Excel
                    </Menu.Item>
                  </SubMenu>
                )}
              </Menu>
            }
            trigger={["click"]}
          >
            <Button type="text" className="rounded-xl" icon={<i className="far fa-ellipsis" />} />
          </Dropdown>
        ) : undefined
      }
      className={props.cardLayoutClassName}
      contentClassName="h-full"
    >
      {props.top && <div className="px-4">{props.top}</div>}

      {!props.tableProps?.isFetching && props.tableProps?.data.length === 0 ? (
        <div className="flex justify-center items-center flex-col pt-12 pb-24">
          {props.tableProps?.isFiltering ? (
            <>
              <SearchEmpty className="h-52 w-52" />
              <span className="-mt-2">Não encontramos nenhum registro com esses filtros...</span>
            </>
          ) : (
            <>
              <Empty className="h-52 w-52" />
              {/* <img
                src="https://static.vecteezy.com/system/resources/previews/003/274/386/large_2x/folder-is-empty-flat-illustration-vector.jpg"
                alt=""
                className="w-80 h-80"
              /> */}
              <span className="-mt-2">Parece que você ainda não adicionou nenhum registro...</span>
            </>
          )}
        </div>
      ) : props.dnd ? (
        <DnDInfiniteTable<T> className="px-2 mobile:px-0" {...props.tableProps}>
          {props.children}
        </DnDInfiniteTable>
      ) : (
        <VirtualInfiniteTable<T> className="px-2 mobile:px-0" {...props.tableProps}>
          {props.children}
        </VirtualInfiniteTable>
      )}
      {/* {props.tableProps?.isFetching && (!props.tableProps?.local || !props.tableProps?.data?.length) && ( */}
      {/* {props.tableProps?.isFetching && !props.tableProps?.data?.length && (
        <div className="px-2 mobile:px-0 absolute top-0 bg-red-400">
          <Skeleton
            loading={props.tableProps?.isFetching}
            active
            style={{
              marginBottom: 20,
            }}
          />
        </div>
      )} */}
    </CardLayout>
  )
}
