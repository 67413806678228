import { Action, action, Thunk, thunk } from "easy-peasy"
import request from "lib/request"
import _ from "lodash"
import { message } from "antd"
import { capitalizeString } from "lib/helper"
import { Privacy } from "lib/constants"
import { PrivacyType } from "components-old/ui/PrivacyField"

export interface IPaginatedResponse<T> {
  totalCount: number
  data: T[]
}

export interface GenericResource {
  id: string
  _id: string
  createdAt: string
  updatedAt: string
  workspaceId: string
}

export interface PrivateGenericResource extends GenericResource {
  privacy: PrivacyType
  authorId: string
}

interface ILoading {
  all: boolean
  more: boolean
  one: boolean
  saving: boolean
  custom: string
}

export interface IMetaConfig {
  metaConfigs?: IMetaConfigInner
}
export interface IMetaConfigInner {
  saveOnEnd?: boolean
  saveWithPatch?: boolean
}

export interface IGenericModel<T> {
  all: T[]
  model: any
  setAll: Action<IGenericModel<T>, T[]>
  addAll: Action<IGenericModel<T>, T[]>
  loading: ILoading
  cacheExpired: boolean
  patchLoading: Action<IGenericModel<T>, Partial<ILoading>>
  setCacheExpired: Action<IGenericModel<T>, boolean>
  setTotalCount: Action<IGenericModel<T>, number>
  setModel: Action<IGenericModel<T>, Partial<T> | null>
  setElementOnAll: Action<IGenericModel<T>, T & IMetaConfig>
  patchElementOnAll: Action<IGenericModel<T>, Partial<T>>
  patchModel: Action<IGenericModel<T>, Partial<T>>
  removeElementFromAll: Action<IGenericModel<T>, { id: string }>
  fetchAll: Thunk<
    IGenericModel<T>,
    {
      limit?: number
      offset?: number
      config?: {
        reset?: boolean
        loading?: string
        getLocal?: string
      }
      [param: string]: any
    },
    any,
    any,
    Promise<IPaginatedResponse<T>>
  >
  fetchOne: Thunk<IGenericModel<T>, { id: string }>
  getGenericItemById: Thunk<IGenericModel<T>, { id: string | undefined }, any, any, T | undefined>
  getGenericItemsByIds: Thunk<IGenericModel<T>, string[], any, any, T[]>
  save: Thunk<IGenericModel<T>, Partial<T & IMetaConfig>>
  patch: Thunk<IGenericModel<T>, Partial<T>>
  saveAllPositions: Thunk<IGenericModel<T>, Partial<T>[]>
  remove: Thunk<IGenericModel<T>, { id: string; showMessage?: boolean }>
  totalCount?: number
  getAll: Thunk<
    IGenericModel<T>,
    {
      limit?: number
      offset?: number
      config?: { getLocal?: string }
      [param: string]: any
    }
  >
  reset: Action<IGenericModel<T>>
  fetchDependency: Thunk<
    IGenericModel<T>,
    {
      id: string
      dependencyArrayKey: string
      endpoint?: string
    }
  >
  endpoint: string
}

export const generic = <T extends { id?: string; position?: number }>(endpoint: string): IGenericModel<T> => ({
  endpoint,
  model: undefined,
  all: [],
  cacheExpired: false,
  setAll: action((state, payload) => {
    state.all = payload
  }),
  addAll: action((state, payload) => {
    state.all = _.uniqBy([...state.all, ...payload], "id")
  }),
  setTotalCount: action((state, payload) => {
    state.totalCount = payload
  }),
  setModel: action((state, payload) => {
    state.model = payload
  }),
  totalCount: undefined,
  loading: {
    all: false,
    more: false,
    custom: "",
    one: false,
    saving: false,
  },
  patchLoading: action((state, payload) => {
    state.loading = { ...state.loading, ...payload }
  }),
  setCacheExpired: action((state, payload) => {
    state.cacheExpired = payload
  }),
  fetchAll: thunk(async (actions, payloadParam) => {
    const { config, ...payload } = payloadParam || {}
    if (config?.getLocal) {
      try {
        const localData = JSON.parse(localStorage.getItem(config.getLocal) || "[]")
        actions.setAll(localData)
        actions.setTotalCount(localData?.length || 0)
      } catch (e) {
        console.error(e)
      }
    }
    actions.patchLoading({
      all: !payloadParam?.offset || payloadParam?.offset! == 0,
      more: payloadParam?.offset! > 0,
    })
    return request
      .get(endpoint, { params: payload })
      .then((response) => {
        const paginatedResponse: IPaginatedResponse<T> = response.data
        actions.setTotalCount(paginatedResponse.totalCount)
        actions.setCacheExpired(false)
        if (config?.reset || !!config?.getLocal) {
          actions.setAll(paginatedResponse.data)
        } else {
          actions.addAll(paginatedResponse.data)
        }
        return paginatedResponse
      })
      .finally(() => {
        actions.patchLoading({
          all: false,
          more: false,
        })
      })
  }),
  fetchOne: thunk(async (actions, { id }) => {
    actions.patchLoading({
      one: true,
    })
    return request
      .get(endpoint + "/" + id)
      .then((response) => {
        if (response?.data?.id || response?.data?._id) {
          actions.setModel(response.data)
        }
        return response.data
      })
      .finally(() => {
        actions.patchLoading({
          one: false,
        })
      })
  }),
  save: thunk(async (actions, model) => {
    actions.patchLoading({
      saving: true,
    })
    const { metaConfigs, ...rest } = model
    const saveEndpoint = rest.id ? endpoint + "/" + rest.id : endpoint
    const method = metaConfigs?.saveWithPatch && rest?.id ? "patch" : "save"
    return request[method](saveEndpoint, rest)
      .then((response) => {
        actions.setElementOnAll({ ...response.data, metaConfigs })
        return response.data
      })
      .finally(() => {
        actions.patchLoading({
          saving: false,
        })
      })
  }),
  patch: thunk(async (actions, model, { getState }) => {
    actions.patchLoading({
      saving: true,
    })
    // const saveEndpoint = model.id ? endpoint + "/" + model.id : endpoint
    const patchEndpoint = endpoint + "/" + model.id
    const oldModel = { ...getState().model }
    actions.patchModel(model)
    actions.patchElementOnAll(model)
    return request
      .patch(patchEndpoint, model)
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        actions.patchModel(oldModel)
        actions.patchElementOnAll(oldModel)
      })
      .finally(() => {
        actions.patchLoading({
          saving: false,
        })
      })
  }),
  remove: thunk(async (actions, { id, showMessage = true }) => {
    actions.patchLoading({
      saving: true,
      custom: id,
    })
    return request
      .delete(endpoint + "/" + id)
      .then(() => {
        actions.removeElementFromAll({ id })
        if (showMessage) {
          message.info("Excluído.")
        }
      })
      .finally(() => {
        actions.patchLoading({
          saving: false,
          custom: "",
        })
      })
  }),
  setElementOnAll: action((state, payload) => {
    const { metaConfigs, ...item } = payload
    const index = state.all.findIndex((x) => x.id == item.id)
    if (index > -1) {
      state.all[index] = item as T
      state.all = [...state.all]
    } else {
      if (metaConfigs?.saveOnEnd != false) {
        state.all = [...state.all, item as T]
      } else {
        state.all = [item as T, ...state.all]
      }
      // if (state.totalCount) {
      state.totalCount = (state.totalCount || 0) + 1
      // }
    }
    // return { ...state }
  }),
  patchElementOnAll: action((state, item) => {
    const index = state.all.findIndex((x) => x.id == item.id)
    if (index > -1) {
      state.all[index] = { ...state.all[index], ...item }
      state.all = [...state.all]
    }
    // return { ...state }
  }),
  patchModel: action((state, item) => {
    state.model = { ...state.model, ...item }
  }),
  removeElementFromAll: action((state, { id }) => {
    if (state.totalCount) {
      state.totalCount = state.totalCount - 1
    }
    state.all = [...state.all.filter((item) => item.id != id)]
    // return { ...state }
  }),
  saveAllPositions: thunk(async (actions, payload, { getState }) => {
    actions.patchLoading({
      saving: true,
    })
    return request
      .patch(
        endpoint,
        (payload || getState().all).map((x, index) => ({
          id: x.id,
          position: index,
        }))
      )
      .then((response) => {
        // actions.setElementOnAll(response.data)
        return response.data
      })
      .finally(() => {
        actions.patchLoading({
          saving: false,
        })
      })
  }),
  getAll: thunk(async (actions, payload, { getState }) => {
    if (getState().cacheExpired || (!getState().all.length && !getState().loading.all)) {
      return actions.fetchAll(payload)
    }
  }),
  getGenericItemById: thunk((actions, { id }, { getState }) => {
    if (!id) return undefined
    actions.getAll({})
    return getState().all.find((item) => item.id == id)
  }),
  getGenericItemsByIds: thunk((actions, ids, { getState }) => {
    if (!ids || !ids.length) return []
    actions.getAll({})
    const items: any = _.compact(ids.map((id) => getState().all.find((item) => item.id == id)))
    return items || []
  }),
  reset: action((state) => {
    state.all = []
  }),
  fetchDependency: thunk(async (actions, payloadParam, { getState }) => {
    const { dependencyArrayKey, endpoint: paramEndpoint, ...payload } = payloadParam || {}
    if (payload.id) {
      actions.patchLoading({
        custom: "dependency",
      })
      return request
        .get(paramEndpoint || endpoint + "/" + payload.id + "/" + dependencyArrayKey, {
          params: payload,
        })
        .then((response) => {
          ;(actions.patchElementOnAll as any)({
            id: payloadParam.id,
            [dependencyArrayKey]: response.data.data,
          })
          actions.setModel({
            ...getState().model,
            [dependencyArrayKey]: response.data.data,
          })
          return response.data
        })
        .finally(() => {
          actions.patchLoading({
            custom: "",
          })
        })
    }
  }),
})
