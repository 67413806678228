import React, { useMemo } from "react"
import { getProps } from "pages/settings/channels"
import { useStore, useStoreState } from "hooks"
import { session } from "store"
import Avatar from "components-old/ui/Avatar"
import { getChannelType } from "lib/helper"
import { ContactIcon, InboxIcon } from "components-old/icons"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import ConversationNodeCore, { NodeField } from "../ConversationNodeCore"
import { Handle, Position } from "react-flow-renderer"
import cx from 'classnames'
import styles from "../../../actions/style.module.css"

interface CreateConversationNodeProps extends BaseNodeProps {}

function CreateConversationNode(props: CreateConversationNodeProps) {
  const { all: channels } = useStoreState((state) => state.channels)
  const { workspace } = useStore(session)

  const { fields } = useMemo(() => {
    // Channel
    let channelContent: any = null
    const channelId = props.data?.action?.conversation?.channelId

    if (channelId) {
      const channel = channels?.find((c) => c.id == channelId)

      if (channel) {
        const channelProps = getProps(channel, workspace)

        channelContent = (
          <div className="flex items-center gap-2 shadow dark:bg-base-200 px-2 rounded-full">
            <Avatar name={"WP"} index={"0"} fontSize={10} size={16} imgSrc={getChannelType(channel!.channelId).image} notRounded />
            <span className="truncate">{channelProps?.name}</span>
          </div>
        )
      }
    }

    let fields: NodeField[] = []
    const conversation = props.data?.action?.conversation
    if (conversation?.contactReference) {
      fields.push({
        icon: ContactIcon,
        // value: "Contato #" + conversation?.contactReference,
        value: (
          <span>
            Contato <span className="text-sky-600">#{conversation?.contactReference}</span>
          </span>
        ),
        label: "Contato",
      })
    }
    if (conversation?.channelId) {
      fields.push({
        icon: InboxIcon,
        value: channelContent,
        label: "Canal",
      })
    }

    return {
      fields,
    }
  }, [channels, props.data?.action?.conversation, workspace])

  return (
    <BaseNode
      {...props}
      title="Criar conversa"
      handlesAfter={
        <div className="flex justify-end items-center h-6 mb-2 -mt-4">
          <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">
            Em caso de falha
          </span>
          <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
            <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
          </Handle>
        </div>
      }
    >
      <ConversationNodeCore data={props.data} fields={fields} />
    </BaseNode>
  )
}

export default CreateConversationNode
