import React from "react"
import { Emoji } from "emoji-mart"
import cx from "classnames"

function ColorEmojiIcon(props) {
    const { icon, iconStyle, size = 8, iconName = "fas fa-circle" } = props
    if (!icon) return null
    if (typeof icon == "function") return <i>{icon()}</i>
    if (icon.indexOf("fa-") > -1 || icon.indexOf("css-") > -1)
        return <i className={icon} style={iconStyle} />
    if (icon.indexOf("#") > -1)
        return (
            <i
                className={cx(iconName, { roundeable: props.roundeable })}
                style={{ color: icon, fontSize: 9, ...iconStyle }}
            />
        )
    return (
        <i style={iconStyle} className={cx("icon", props.className, { roundeable: props.roundeable })}>
            <Emoji emoji={icon} size={size} native />
        </i>
    )
}

export default ColorEmojiIcon
