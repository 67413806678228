import React, { useEffect, useMemo, useState } from "react"
import FormModal from "components-old/forms/FormModal"
import locale from "antd/es/date-picker/locale/pt_BR"
import { Alert, Checkbox, Col, DatePicker, Form, InputNumber, Row, Select } from "antd"
import request from "lib/request"
import { formatterCurrencyNew } from "lib/masks"
import QRCode from "qrcode.react"
import { endpoints } from "config/endpoints.config"
import { IBuySMSTransaction } from "models/adminTransactions"
import { useStore } from "hooks"
import { session } from "store"

interface IBuySmsModalProps {
    transaction?: IBuySMSTransaction
    onBack?: Function
}

const getSmsPrice = (quantity: number) => {
    let unityPrice = 0.12
    if (quantity >= 5000) {
        unityPrice = 0.1
    }
    if (quantity >= 30000) {
        unityPrice = 0.078
    }
    if (quantity >= 100000) {
        unityPrice = 0.07
    }
    if (quantity >= 500000) {
        unityPrice = 0.06
    }
    if (quantity >= 1000000) {
        unityPrice = 0.058
    }

    return (
        <span>
            {formatterCurrencyNew(quantity * unityPrice)}{" "}
            {unityPrice != 0.12 && (
                <span
                    style={{
                        color: "#2ecc71",
                    }}
                >
                    (-{((1 - unityPrice / 0.12) * 100).toFixed(0)}%)
                </span>
            )}
        </span>
    )
}

let interval

function BuySmsModal(props: IBuySmsModalProps) {
    const { user } = useStore(session)
    const [form] = Form.useForm()
    const [step, setStep] = useState(props.transaction ? 1 : 0)
    const [transaction, setTransaction] = useState<IBuySMSTransaction | null>(
        props.transaction || null
    )

    useEffect(() => {
        if (transaction && transaction.status == "Aguardando pagamento") {
            interval = setInterval(() => {
                request
                    .get<IBuySMSTransaction>(endpoints.SMS_TRANSACTIONS + "/" + transaction?._id)
                    .then((res) => {
                        setTransaction(res.data)
                    })
            }, 10000)
        } else {
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [transaction])

    async function onSubmit(model: any) {}

    return (
        <FormModal
            form={form}
            onSubmit={onSubmit}
            onBack={props.onBack}
            type="drawer"
            titleOverwrite={"Comprar créditos"}
            // successMessage="Agendamento realizado."
            item={props.transaction}
            drawerStyle={{
                zIndex: 1002,
            }}
            okText="Comprar com PIX"
            width={600}
            okStyle={step == 1 ? { display: "none" } : undefined}
            formProps={{
                initialValues: {
                    quantity: 1000,
                },
            }}
            onSubmitBefore={(model, onSubmit, options) => {
                options?.setSaving?.(true)
                console.log(model)

                request
                    .post<IBuySMSTransaction>(endpoints.BUY_SMS, model)
                    .then((res) => {
                        setTransaction(res.data)
                        setStep(1)
                    })
                    .finally(() => {
                        options?.setSaving?.(false)
                    })
            }}
            saved={false}
        >
            {step == 1 && (
                <>
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: 15,
                        }}
                    >
                        Leia o QRCode abaixo no app do seu banco para pagar via PIX:
                    </p>
                    <br />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {transaction?.extraInfo?.pix_qr_code && (
                            <div style={{ width: 300, height: 300, position: "relative" }}>
                                {transaction?.status != "Aguardando pagamento" && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            backgroundColor: "rgba(255,255,255,0.9)",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                <i
                                                    className="fas fa-check-circle"
                                                    style={{ color: "#27ae60", marginRight: 10 }}
                                                ></i>
                                                Pago
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <QRCode value={transaction?.extraInfo?.pix_qr_code!} size={300} />
                            </div>
                        )}
                    </div>
                </>
            )}
            {step == 0 && (
                <>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item
                                label="Quantidade de créditos"
                                name="quantity"
                                rules={[
                                    {
                                        required: true,
                                        message: "Obrigatório",
                                    },
                                ]}
                            >
                                <Select>
                                    {user.isSystemAdmin && (
                                        <Select.Option value={10}>10</Select.Option>
                                    )}
                                    <Select.Option value={1000}>1000</Select.Option>
                                    <Select.Option value={3000}>3000</Select.Option>
                                    <Select.Option value={5000}>5000</Select.Option>
                                    <Select.Option value={10000}>10k</Select.Option>
                                    <Select.Option value={15000}>15k</Select.Option>
                                    <Select.Option value={20000}>20k</Select.Option>
                                    <Select.Option value={30000}>30k</Select.Option>
                                    <Select.Option value={50000}>50k</Select.Option>
                                    <Select.Option value={75000}>75k</Select.Option>
                                    <Select.Option value={100000}>100k</Select.Option>
                                    <Select.Option value={500000}>500k</Select.Option>
                                    <Select.Option value={1000000}>1kk</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item label="Total" shouldUpdate>
                                {({ getFieldValue }) => getSmsPrice(getFieldValue("quantity"))}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Alert
                        showIcon
                        message="Cada disparo de SMS consome uma unidade de crédito. Para mais adquirir acima de 100 mil créditos entrar em contato com suporte. Após confirmação de pagamento pode demorar até 1 dia útil para liberar seus créditos."
                        type="info"
                    />
                </>
            )}
            {/* <Row>
                <Col>
                    <Form.Item name={["everyMonth"]} valuePropName="checked" style={{ marginBottom: 0 }}>
                        <Checkbox disabled={props.item?.isSent}>Repetir mensalmente</Checkbox>
                    </Form.Item>
                </Col>
            </Row> */}
        </FormModal>
    )
}

export default BuySmsModal
