import React, { useState, useEffect } from "react"
import cx from "classnames"
import { Row, Col, Space, Tooltip, Spin, Button } from "antd"
import { Link, NavLink, RouteComponentProps, useHistory } from "react-router-dom"
import { useBreadcrumb, useStore, useStoreActions, useStoreState } from "hooks"

import styles from "./style.module.css"
import FiltersPanel, { IAdvancedFilter } from "./FiltersPanel"
import { general } from "store"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"
import MobileHeader from "components-old/header/MobileHeader"
import CardBox, { IExtraMore } from "components-old/CardBox"
import usePermission from "hooks/usePermission"

interface ISettingsPageSimpleProps {
    title: string | React.ReactNode
    pageSubtitle?: string | React.ReactNode
    tabTitle?: string
    filters?: React.ReactNode
    advancedFilters?: IAdvancedFilter[]
    helpText?: string | React.ReactNode
    actions?: React.ReactNode[]
    children?: React.ReactNode | string
    onSubmitFilter?: Function
    centerTitle?: string | React.ReactNode
    description?: string | React.ReactNode
    center?: boolean
    loading?: boolean
    noSeparator?: boolean
    backTo?: string
    className?: string
    style?: React.CSSProperties
    innerStyle?: React.CSSProperties
    headerStyle?: React.CSSProperties
    mobileBackTo?: string
    extraMore?: IExtraMore[]
    after?: React.ReactNode
    onBack?: () => void
}

function SettingsPageSimple(props: ISettingsPageSimpleProps) {
    const { setPageSubtitle } = useStoreActions((actions) => actions.general)
    const { breadcrumb } = useStore(general)

    const [clearFiltersComponent, setClearFiltersComponent] = useState()
    const { pageTitle, pageSubtitle, tabs } = useStoreState((state) => state.general)
    const [isMobile] = useRecoilState(isMobileAtom)
    const history = useHistory()

    useBreadcrumb({
        title: props.title,
        tabTitle: props.tabTitle,
        subtitle: props.helpText && (
            <div>
                <Tooltip title={props.helpText}>
                    <i className="far fa-question-circle" style={{ fontSize: 16 }} />
                </Tooltip>
            </div>
        ),
        backTo: props.backTo,
    })

    // Remover Subtitle
    useEffect(() => {
        return () => {
            setPageSubtitle("")
        }
    }, [])

    const Filters = (props.filters || props.advancedFilters) && (
        <FiltersPanel
            onSubmit={(filtersObj: any) => {
                props.onSubmitFilter?.(filtersObj)
            }}
            advancedFilters={props.advancedFilters}
            setClearFiltersComponent={setClearFiltersComponent}
            // noDebounce
        >
            {props.filters}
        </FiltersPanel>
    )

    return (
        <section className={cx(styles.settings_page, props.className)} style={props.style}>
            {!!tabs?.length && (
                <CardBox headStyle={{ display: "none" }} style={{ marginBottom: 16 }} bodyStyle={{ padding: "8px 8px 8px 16px" }}>
                    <Row
                        style={
                            isMobile
                                ? {
                                      flexDirection: "column",
                                  }
                                : {}
                        }
                    >
                        {tabs
                            ?.filter((tab) => tab.permission != false)
                            .map((tab) => (
                                <Col key={tab.name}>
                                    <NavLink
                                        to={tab.to}
                                        className={cx(styles.tab, {
                                            [styles.disabled]: tab?.disabled,
                                        })}
                                    >
                                        {tab.name}
                                    </NavLink>
                                </Col>
                            ))}
                    </Row>
                </CardBox>
            )}
            {isMobile && (
                <MobileHeader title={pageTitle} backTo={breadcrumb.backTo || props.mobileBackTo || "/settings"} className="global-settings-mobile-header" />
            )}
            {/* {isMobile ? (
                <MobileHeader
                    title={pageTitle}
                    backTo={breadcrumb.backTo || props.mobileBackTo || "/settings"}
                    className="global-settings-mobile-header"
                />
            ) : (
                <div className={styles.context_header_sticky} style={props.headerStyle}>
                    <Row wrap={false} align="middle" justify="space-between">
                        <Col>
                            <Row align="middle" gutter={10} wrap={false}>
                                {breadcrumb.backTo && (
                                    <Col>
                                        <Link to={breadcrumb.backTo}>
                                            <Button
                                                icon={<i className="far fa-arrow-left" />}
                                                type="text"
                                                style={{
                                                    width: 33,
                                                    height: 33,
                                                    boxSizing: "border-box",
                                                }}
                                            />
                                        </Link>
                                    </Col>
                                )}
                                <Col>
                                    <Row align="middle">
                                        <h1>{pageTitle}</h1>
                                        <span className={styles.page_subtitle}>{pageSubtitle}</span>
                                    </Row>
                                    <Row>
                                        <span
                                            style={{
                                                color: "var(--content-secondary)",
                                                fontSize: 16,
                                            }}
                                        >
                                            {props.pageSubtitle}
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row align="middle" gutter={15}>
                                <Row gutter={20} align="middle">
                                    <Col>
                                        <Space size="small">{props.actions}</Space>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    {!!tabs?.length && (
                        <Row style={{ marginTop: 5 }}>
                            {tabs
                                ?.filter((tab) => tab.permission != false)
                                .map((tab) => (
                                    <Col key={tab.name}>
                                        <NavLink to={tab.to} className={styles.tab}>
                                            {tab.name}
                                        </NavLink>
                                    </Col>
                                ))}
                        </Row>
                    )}
                    {!props.noSeparator && (
                        <div
                            className={styles.page_separator}
                            style={tabs?.length ? { marginTop: 10 } : {}}
                        />
                    )}
                </div>
            )} */}
            <CardBox
                title={props.title}
                helpText={props.helpText}
                extra={props.actions}
                extraMore={props.extraMore}
                extraLeft={[Filters]}
                onBack={props.onBack}
            >
                <div className={cx({ [styles.center]: props.center && !isMobile })}>
                    {props.centerTitle && !isMobile && (
                        <div style={{ marginBottom: 30 }}>
                            <h1>{props.centerTitle}</h1>
                            <div className={styles.description}>{props.description}</div>
                        </div>
                    )}
                    <Spin spinning={!!props.loading}>{props.children}</Spin>
                </div>
            </CardBox>
            {props.after}
        </section>
    )
}

export default SettingsPageSimple
