import FormModal from "components-old/forms/FormModal"
import { Form, Input, Spin } from "antd"
import { LabelHelp } from "../../LabelHelp"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import "./json-picker.css"
import IconTag from "components-old/shared/IconTag"
import { colors } from "lib/colors"
import { debounce } from "lodash"
import TagColor from "components-old/shared/TagColor"
import { Spin01Icon } from "components-old/icons"
import { useRecoilState } from "recoil"
import { themeAtom, variableAtom } from "atoms/app.atom"
import { receivedWebhookMonitorAtom } from "atoms/workflow.atom"
import { VariableEnum } from "pages/settings/workflows/workflow.enums"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface JsonPathModalProps {
  onBack: () => void
  onSubmit: (jsonPath: string) => void
  type: VariableEnum
  jsonPath?: string
}

const getPathWith$ = (path: string) => {
  if (path.startsWith("$")) {
    return path
  }
  if (path[0] === "[") {
    return "$" + path
  }

  return "$." + path
}

function JsonPathModal(props: JsonPathModalProps) {
  return (
    <Suspense
      fallback={
        <div className="fixed inset-0 flex items-center justify-center z-[1002] shadow">
          <Spin className="m-2 fixed p-4 bg-base-100 rounded-lg" />
        </div>
      }
    >
      <JsonPathModalCore {...props} />
      {/* <div className="fixed inset-0 flex items-center justify-center z-50 shadow">
        <Spin className="m-2 fixed p-4 bg-base-100 rounded-lg" />
      </div> */}
    </Suspense>
  )
}

function JsonPathModalCore(props: JsonPathModalProps) {
  const [form] = Form.useForm()
  const [dynamicVariablesDef] = useRecoilState(variableAtom)
  const preRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const variableRecord = dynamicVariablesDef.variablesRecord[props.type]
  const categoryRecord = WorkflowDefs.CategoryRecord[variableRecord.category]
  const [pathInvalid, _setPathInvalid] = useState("")
  const [verifyingPath, setVerifyingPath] = useState(false)
  const [theme] = useRecoilState(themeAtom)
  const [received, setReceived] = useRecoilState(receivedWebhookMonitorAtom)

  const json = useMemo(() => {
    if (variableRecord.json) {
      return variableRecord.json
    }

    if (variableRecord.type === VariableEnum.WEBHOOK_QUERY_PARAMS) {
      return received?.query || {}
    }

    if (variableRecord.type === VariableEnum.WEBHOOK_BODY) {
      return received?.body || {}
    }
  }, [received?.body, received?.query, variableRecord.json, variableRecord.type])

  function setPathInvalid(value) {
    setVerifyingPath(false)
    if (json) {
      _setPathInvalid(value)
    }
  }

  const verifyPathValidDebounce = useMemo(
    () =>
      debounce(
        async (value) => {
          // console.log("VERIFICANDO", value, json)
          // const result = JSONPath?.({ path: value || "", json: json || {} })
          try {
            const jsonpath = await import("jsonpath")
            const result = jsonpath?.query?.(json, value)

            if (!result || result.length === 0) {
              setPathInvalid("invalid")
            } else {
              setPathInvalid("valid")
            }
          } catch (e) {
            console.log(e)
            setPathInvalid("invalid")
          }
        },
        500,
        {
          maxWait: 2000,
        }
      ),
    []
  )

  const verifyPathValid = (value) => {
    setVerifyingPath(true)
    verifyPathValidDebounce(getPathWith$(value))
  }

  async function onSubmit(model: any) {
    props.onSubmit(model.jsonPath ? getPathWith$(model.jsonPath) : "")
  }

  useEffect(() => {
    const load = async () => {
      const source = document.querySelector("#json-renderer")
      const dest = document.querySelectorAll(".json-picker-input")
      const data = json || {}

      // ;(window as any).JPPicker.render(source, data, dest)
      // import JPP dynamic
      const JPP = await import("jsonpath-picker-vanilla")
      JPP.jsonPathPicker(source, data, dest)

      // preRef?.current?.addEventListener("input", () => {
      // outputElement.textContent = `Current value: ${inputElement.value}`
      // })

      const handleClick = (event) => {
        if (preRef.current && preRef.current.contains(event.target)) {
          if (event.target.classList.contains("pick-path")) {
            setTimeout(() => {
              form.setFieldsValue({ jsonPath: inputRef?.current?.input?.value })
              setPathInvalid("valid")
            }, 100)
          }
        }
      }

      preRef?.current?.addEventListener("click", handleClick)

      if (props.jsonPath) {
        verifyPathValid(props.jsonPath)
      }

      return () => {
        preRef?.current?.removeEventListener("click", handleClick)
      }
    }
    load()
  }, [])

  return (
    <FormModal
      form={form}
      onSubmit={onSubmit}
      onBack={props.onBack}
      type="modal"
      saved={false}
      formProps={{
        initialValues: {
          jsonPath: props.jsonPath?.replace("$.", "")?.replace("$[", "[") || "",
        },
        onValuesChange(changedValues, values) {
          if (json) {
            verifyPathValid(values.jsonPath)
          }
        },
      }}
      titleOverwrite={
        <div className="flex items-center gap-2">
          <div>
            <IconTag size="lg" icon={categoryRecord.icon} palette={categoryRecord?.palette}></IconTag>
          </div>
          JSON do {categoryRecord?.label}
        </div>
      }
      hideSuccessMessage
      width={700}
      okText="Salvar"
    >
      <div className="relative mb-4">
        <Form.Item
          name="jsonPath"
          label={
            <LabelHelp
              help={
                <div className="space-y-2">
                  <p>JSONPath é uma linguagem de consulta para JSON.</p>
                  <p>Use o JSONPath para acessar valores em um objeto JSON.</p>
                  <p>
                    Exemplo: <span className="text-amber-400">$.customFields.idade.value</span>
                  </p>
                  <a className="!underline !text-white" href="https://jsonpath.com/" target="_blank" rel="noreferrer">
                    Saiba mais
                  </a>
                </div>
              }
            >
              JSONPath
            </LabelHelp>
          }
          // name="jsonPath"
          className="mb-2"
          // rules={[{ required: true, message: "O JSONPath deve começar com $. ou $[" }, { validator: validateInput }]}
        >
          <Input
            placeholder="path.to.value"
            className="json-picker-input !pl-[22px]"
            ref={inputRef}
            size="large"
            // value={props.currentMentionSelected?.id}
            //   className="h-8 min-h-[32px] text-sm"
            //   defaultValue={props.jsonPath}
            //   onChange={(e) => {
            //   }}
          />
        </Form.Item>
        <div
          className="absolute top-[38px] left-2 text-[16px]"
          style={{
            color: colors[categoryRecord.palette][600],
          }}
        >
          $.
        </div>
        {verifyingPath ? (
          <TagColor
            className="absolute top-[40px] right-2"
            palette={"slate"}
            animated
            lightTone={{
              bg: 0,
            }}
          >
            <Spin01Icon className="w-5 h-5 text-primary" />
            Analisando
          </TagColor>
        ) : pathInvalid === "invalid" ? (
          <TagColor className="absolute top-[40px] right-2" palette={"red"} animated>
            <i
              className="fas fa-exclamation-triangle"
              style={{
                color: colors.red[theme === "dark" ? 50 : 600],
              }}
            ></i>
            Caminho inválido
          </TagColor>
        ) : (
          pathInvalid === "valid" && (
            <TagColor className="absolute top-[40px] right-2" palette={"sky"} animated>
              <i
                className="fas fa-check-circle"
                style={{
                  color: colors.sky[theme === "dark" ? 50 : 600],
                }}
              ></i>
              Válido
            </TagColor>
          )
        )}
      </div>
      {/* <JsonPathPicker json={'{"b":{"c":1},"d":3}'} onChoose={() => {}} path={""} /> */}
      <Form.Item
        label={
          <LabelHelp
            help={
              <div className="space-y-2">
                <p>Esse é um exemplo de estrutura json.</p>
                <p>Clique em um ponto para preencher o caminho até ele no JSONPath.</p>
              </div>
            }
          >
            Exemplo
          </LabelHelp>
        }
      >
        <div className="rounded-lg bg-base-200 p-3 overflow-auto max-h-[500px] pretty-scroll pretty-scroll-hoverable">
          <pre id="json-renderer" ref={preRef}></pre>
        </div>
      </Form.Item>
    </FormModal>
  )
}

export default JsonPathModal
