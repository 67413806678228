import { Button, Modal, Progress } from "antd"
import React from "react"

interface ExecutinActionModalProps {
  executingLabel: string
  doneCount: number
  totalCount: number
  progress: number
  onCancel: () => void
}

function ExecutinActionModal(props: ExecutinActionModalProps) {
  const [aborted, setAborted] = React.useState(false)

  return (
    <Modal
      visible
      footer={null}
      closable={false}
      closeIcon={null}
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div className="flex flex-col items-center gap-2 p-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24" className="text-primary">
          <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
            <path strokeDasharray="2 4" strokeDashoffset="6" d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3">
              <animate attributeName="stroke-dashoffset" dur="0.6s" repeatCount="indefinite" values="6;0"></animate>
            </path>
            <path strokeDasharray="30" strokeDashoffset="30" d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21">
              <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.1s" dur="0.3s" values="30;0"></animate>
            </path>
            <path strokeDasharray="10" strokeDashoffset="10" d="M12 16v-7.5">
              <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="10;0"></animate>
            </path>
            <path strokeDasharray="6" strokeDashoffset="6" d="M12 8.5l3.5 3.5M12 8.5l-3.5 3.5">
              <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0"></animate>
            </path>
          </g>
        </svg>
        {props.executingLabel && (
          <div className="flex items-center gap-2">
            {props.executingLabel}{" "}
            {!!props.totalCount && (
              <span className="text-primary font-semibold">
                ({props.doneCount} / {props.totalCount})
              </span>
            )}
          </div>
        )}
        {/* </div> */}
        <div className="flex gap-2 items-center h-full w-full max-w-xs">
          <Progress percent={props.progress} showInfo={false} strokeWidth={5} strokeColor={"var(--primary)"} />
        </div>
        <Button
          className="mt-2"
          loading={aborted}
          onClick={() => {
            setAborted(true)
            props.onCancel()
            // cancel = true
          }}
        >
          Abortar
        </Button>
      </div>
    </Modal>
  )
}

export default ExecutinActionModal
