import React from "react"
import cx from "classnames"

type IIcon = {
  name: string
  marginRight?: number
  marginLeft?: number
  style?: object
  className?: string
}

function Icon({ name, marginRight, marginLeft, style, className }: IIcon) {
  return <i className={cx(name, className)} style={{ marginRight, marginLeft, ...style }} />
}

export default Icon
