import React, { CSSProperties } from "react"
import TableHead from "../TableHead"
import { GenericResource } from "models/generic"
import { Column, Row, Table, flexRender } from "@tanstack/react-table"
import { Virtualizer } from "@tanstack/react-virtual"
import { twMerge } from "tailwind-merge"
import { Skeleton } from "antd"
import VirtualInfiniteTableRow from "./VirtualInfiniteTableRow"

interface VirtualInfiniteTableTableProps<T extends GenericResource> {
  table: Table<T>
  rowVirtualizer: Virtualizer<any, Element>
  rows: Row<T>[]
  onClickRow?: (row: T) => void
  transparentHeader?: boolean
  skeleton?: boolean
}

const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left")
  const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right")

  return {
    // boxShadow: isLastLeftPinnedColumn ? "-4px 0 4px -4px gray inset" : isFirstRightPinnedColumn ? "4px 0 4px -4px gray inset" : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}

function VirtualInfiniteTableTable<T extends GenericResource>(props: VirtualInfiniteTableTableProps<T>) {
  return (
    <>
      <table
        className="w-full"
        style={{
          minHeight: `${props.rowVirtualizer.getTotalSize() + 32}px`, // necessário no safari
        }}
      >
        <TableHead table={props.table} transparentHeader={props.transparentHeader} getCommonPinningStyles={getCommonPinningStyles} />

        <tbody
          style={{
            height: `${props.rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: "relative", //needed for absolute positioning of rows
          }}
        >
          {props.rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = props.rows[virtualRow.index] as Row<T>
            return (
              <VirtualInfiniteTableRow
                virtualRow={virtualRow}
                key={row.id}
                row={row}
                onClickRow={props.onClickRow}
                getCommonPinningStyles={getCommonPinningStyles}
              />
            )
          })}
        </tbody>
      </table>
      {props.skeleton && (
        <div className="px-2 mobile:px-0">
          <Skeleton
            loading={props.skeleton}
            active
            style={{
              marginBottom: 20,
            }}
          />
        </div>
      )}
    </>
  )
}

export default VirtualInfiniteTableTable
