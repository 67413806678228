import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

interface IBusinessHoursDay {
  active: boolean
  hours: [string, string]
}

export interface IBusinessHours extends GenericResource {
  updatedAt: "2020-07-17T14:39:20.552Z"
  name: "Incredible Fresh Ball Lead"
  fri: IBusinessHoursDay
  mon: IBusinessHoursDay
  sat: IBusinessHoursDay
  sun: IBusinessHoursDay
  thu: IBusinessHoursDay
  tue: IBusinessHoursDay
  wed: IBusinessHoursDay
}

export const businessHours: IGenericModel<IBusinessHours> = {
  ...generic(endpoints.BUSINESS_HOURS),
}
