import React, { memo, useEffect, useMemo } from "react"
import { Column } from "@ant-design/plots"
import { Workflow, WorkflowRunStatus } from "models/workflows"
import { colors } from "lib/colors"
import { formatDateVeryAbbr } from "lib/helper"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"
import dark_theme_antchart from "lib/dark_theme_antchart.json"

export interface AnalyticsChartProps {
  workflow?: Workflow
  todayReport: {
    pending: number
    awaiting: number
    running: number
    done: number
    operationsConsumed: number
    failed: number
  }
  maxDays: number
}

// const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
// const data = [1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7]
//   .map((item, index) => {
//     return [
//       {
//         period: index.toString(),
//         value: Math.random() * 10,
//         status: WorkflowRunStatus.RUNNING,
//       },
//       {
//         period: index.toString(),
//         value: Math.random() * 10,
//         status: WorkflowRunStatus.PENDING,
//       },
//       {
//         period: index.toString(),
//         value: Math.random() * 10,
//         status: WorkflowRunStatus.FAILED,
//       },
//       {
//         period: index.toString(),
//         value: Math.random() * 10,
//         status: WorkflowRunStatus.DONE,
//       },
//     ]
//   })
//   ?.flat()

function AnalyticsChart(props: AnalyticsChartProps) {
  const [theme] = useRecoilState(themeAtom)
  const [animation, setAnimation] = React.useState(true)

  useEffect(() => {
    if (props.workflow?.reportDays?.length) {
      setTimeout(() => {
        setAnimation(false)
      }, 1000)
    }
  }, [props.workflow?.reportDays?.length])

  const config = useMemo(() => {
    const reportDays = [...(props.workflow?.reportDays || [])].reverse().slice(0, props.maxDays - 1)

    // Definindo a ordem desejada
    // const order = [WorkflowRunStatus.RUNNING, WorkflowRunStatus.PENDING, WorkflowRunStatus.DONE, WorkflowRunStatus.FAILED]
    // .sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status))

    const data = [
      ...(reportDays
        ?.map((day) => {
          const acc: any = []
          const statuses = [WorkflowRunStatus.DONE, WorkflowRunStatus.FAILED]
          statuses.forEach((status) => {
            if (day[status] === 0) return
            acc.push({
              period: formatDateVeryAbbr(day.date),
              value: day[status],
              status,
            })
          })
          return acc
        })
        ?.flat() || []),
      {
        period: "Hoje",
        value: props.todayReport.running || 0,
        status: WorkflowRunStatus.RUNNING,
      },
      {
        period: "Hoje",
        value: props.todayReport.pending || 0,
        status: WorkflowRunStatus.PENDING,
      },
      {
        period: "Hoje",
        value: props.todayReport.awaiting || 0,
        status: WorkflowRunStatus.AWAITING,
      },
      {
        period: "Hoje",
        value: props.todayReport.done || 0,
        status: WorkflowRunStatus.DONE,
      },
      {
        period: "Hoje",
        value: props.todayReport.failed || 0,
        status: WorkflowRunStatus.FAILED,
      },
    ]

    return {
      data,
      isStack: true,
      animation,
      xField: "period",
      yField: "value",
      seriesField: "status",
      intervalPadding: 2,
      tooltip: {
        formatter: (datum) => {
          switch (datum.status) {
            case WorkflowRunStatus.PENDING:
              return { name: "Pendente", value: datum.value }
            case WorkflowRunStatus.AWAITING:
              return { name: "Aguardando", value: datum.value }
            case WorkflowRunStatus.DONE:
              return { name: "Concluído", value: datum.value }
            case WorkflowRunStatus.RUNNING:
              return { name: "Executando", value: datum.value }
            case WorkflowRunStatus.FAILED:
              return { name: "Falhou", value: datum.value }
          }

          return { name: datum.type, value: datum.value }
        },
      },
      columnStyle: {
        stroke: theme === "dark" ? "#292b2f" : "#ffffff", // White border
        lineWidth: 1, // Thickness of the border
      },
      //   legend: {
      //     position: "top-left",
      //   },
      yAxis: {
        // line: null, // Remove a linha do eixo Y
        // grid: null, // Remove as linhas de grade do eixo Y
        grid: {
          line: {
            style: {
              stroke: theme === "dark" ? "#3c3f44" : "#ddd", // Cor mais clara
              lineWidth: 1, // Espessura da linha
              lineDash: [4, 2], // Padrão tracejado
              opacity: 0.5, // Transparência
            },
          },
        },
      },
      xAxis: {
        line: null, // Remove a linha do eixo X
        grid: null, // Remove as linhas de grade do eixo X
      },
      maxColumnWidth: 30,
      //   columnStyle: ({ status, name }) => {
      //     const isLast = (data as any).filter((d) => d.status === status).pop().name === name
      //     const isFirst = (data as any).filter((d) => d.status === status)[0].name === name
      //     if (isFirst || isLast) {
      //       return {
      //         radius: isFirst ? [4, 4, 0, 0] : [0, 0, 0, 0], // Aplica arredondamento ao primeiro e último elemento
      //       }
      //     }
      //     return {}
      //   },
      legend: false,
      color: (datum) => {
        switch (datum.status) {
          case WorkflowRunStatus.PENDING:
            return colors.orange[400]
          case WorkflowRunStatus.AWAITING:
            return colors.amber[400]
          case WorkflowRunStatus.RUNNING:
            return colors.amber[400]
          case WorkflowRunStatus.DONE:
            // return colors.emerald[500]
            return "#12CBC4"
          case WorkflowRunStatus.FAILED:
            // return colors.red[500]
            return "#ED4C67"
        }

        return "#36393f"
      },
      theme: theme === "dark" ? dark_theme_antchart : undefined,
    }
  }, [
    animation,
    props.maxDays,
    props.todayReport.awaiting,
    props.todayReport.done,
    props.todayReport.failed,
    props.todayReport.pending,
    props.todayReport.running,
    props.workflow?.reportDays,
    theme,
  ])

  return (
    <div className="h-36">
      <Column {...(config as any)} />
    </div>
  )
}

export default memo(AnalyticsChart)
