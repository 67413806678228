import { endpoints } from "config/endpoints.config"
import { InputType } from "lib/constants"
// import { channelsTypes, CHANNEL_TYPES } from "lib/constants"
import { IGenericModel, generic } from "models/generic"

export type IChannelStatus =
  | "connected"
  | "disconnected"
  | "processing_channel"
  | "restarting_channel"
  | "login_required"
  | "restart_required"
  | "qr_code"
  | "invalid_credentials"
  | "invalid_code"
  | "challenge_required"
  | "recreate_required"
export type IChannelTypeIds = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8"

export interface IChannel {
  id: string
  channelId: IChannelTypeIds
  updatedAt: string
  createdAt: string
  name: string
  description?: string
  avatar?: string
  isConnected: boolean
  disable_active_chat: boolean
  status: IChannelStatus
  isBanned?: boolean

  // WhatsApp
  phone?: string
  version?: number
  lastCreatedAt?: string

  // Facebook
  pageId?: string

  // Email
  identifier?: string

  isPrivate?: boolean
  groupIds?: string[]

  customFields?: {
    [key: string]: {
      type: InputType
      value: string | null
    }
  }
  extraData?: {
    alertMesaage?: string
  }
}

export interface IChannelTelegram {
  id: string
  avatar: string
  description: string
  identifier: string
}

export interface IChannelEmail {
  id: string
  description: string
  identifier: string
}

export interface IChannelSms {
  id: string
  description: string
}

export interface IChannelCustom {
  id: string
  description: string
  identifier: string
  hashIdentifier: string
  avatar: string
}

export interface IChannelInstagram extends IChannel {
  id: string
  identifier: string
  avatar: string
  description: string
  pageId: string
  username: string
  ignore_story_mention: boolean
  ignore_story_reply: boolean
  customFields: any
}

export interface IChannelML {
  id: string
  description: string
}

export interface IChannelFacebook extends IChannel {
  id: string
  accessToken: string
  avatar: string
  pageId: string
  customFields: any
}

export const channelsTelegram: IGenericModel<IChannelTelegram> = {
  ...generic(endpoints.TELEGRAM),
}

export const channelsCustom: IGenericModel<IChannelCustom> = {
  ...generic(endpoints.CUSTOM),
}

export const channelsEmail: IGenericModel<IChannelEmail> = {
  ...generic(endpoints.EMAIL),
}

export const channelsSms: IGenericModel<IChannelEmail> = {
  ...generic(endpoints.SMS),
}

export const channelsInstagram: IGenericModel<IChannelInstagram> = {
  ...generic(endpoints.INSTAGRAM),
}

export const channelsML: IGenericModel<IChannelML> = {
  ...generic(endpoints.MERCADO_LIVRE),
}

export const channelsFacebook: IGenericModel<IChannelFacebook> = {
  ...generic(endpoints.FACEBOOK),
}

export const channels: IGenericModel<IChannel> = {
  ...generic(endpoints.CHANNEL),
}
