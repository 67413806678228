import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IChannel } from "./channels"

export interface IChannelWhatsApp extends IChannel {
    lastCreatedAt?: string
}

export const channelsWhatsapp: IGenericModel<IChannelWhatsApp> = {
    ...generic(endpoints.WHATSAPP),
}
