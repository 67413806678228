import { Button, Col, Row, Space, Spin, Tooltip } from "antd"
import { FormInstance } from "antd/lib/form"
import { ColumnProps } from "antd/lib/table"
import { IStorePath, useBreadcrumb, useStoreActions, useStoreState } from "hooks"
import { buildUrlQuery, verifyPermission } from "lib/helper"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { NavLink, Route, RouteComponentProps, useHistory } from "react-router-dom"
import FiltersPanel, { IAdvancedFilter } from "../SettingsPage/FiltersPanel"
import cx from "classnames"
import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"

import settingsStyles from "../SettingsPage/style.module.css"
import styles from "./style.module.scss"
import ListPageItem from "./ListPageItem"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"
import NoData from "components-old/ui/NoData"
import CardBox, { IExtraMore } from "components-old/CardBox"
import ListPageHeader from "./ListPageHeader"
import { debounce } from "lodash"
import MobileHeader from "components-old/header/MobileHeader"
import { createPortal } from "react-dom"
import ListPageDraggableItem from "./ListPageDraggableItem"
import toast from "lib/toast"
import { IRolePermissions } from "models/roles"
import usePermission from "hooks/usePermission"
import usePrivacy from "hooks/usePrivacy"

export interface IColumnProps<T> extends ColumnProps<T> {
  span?: number
  hide?: () => boolean
}

export type IFilters = {
  search?: string
}

interface IListPageProps<T> extends RouteComponentProps {
  title: string
  pageSubtitle?: string
  data?: T[]
  helpText?: string | React.ReactNode
  storePath?: IStorePath
  path?: string
  pathName?: string
  basePathName?: string
  manualRouting?: boolean
  columns: IColumnProps<T>[]
  actionsTop?: React.ReactNode[]
  headerChildren?: React.ReactNode
  filters?: React.ReactNode
  advancedFilters?: IAdvancedFilter[]
  filtersObj?: any
  onSubmitFilters?: Function
  onSubmitFiltersHOC?: Function
  onApplyFilters?: (item: T, filters: IFilters) => boolean
  leftActions?: React.ReactNode[]
  actions?: React.ReactNode[]
  noData?: {
    title: string | React.ReactNode
    buttonText?: string
    to?: string
    img?: string
    iconName?: string
  }

  gray?: boolean
  defaultFiltersValues?: {
    [a: string]: any
  }

  setFiltersForm?: (form: FormInstance) => void
  pageSize?: number
  children?: React.ReactNode
  showNoData?: boolean
  EditComponent?: any
  manualFilter?: boolean
  loading?: boolean
  empty?: any
  className?: string
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  draggable?: boolean
  withSeparator?: boolean
  sort?: (items: T[]) => T[]
  extraMore?: IExtraMore[]
  showHeader?: boolean
  debounceTime?: number
  maxItems?: number
  localFiltering?: boolean
  cardStyle?: React.CSSProperties
  manualFetch?: boolean
  count?: boolean
  fixedParams?: any
  usePrivacy?: boolean
  permission?: keyof IRolePermissions
  getSetFiltersObj?: (setFiltersObj: React.Dispatch<React.SetStateAction<IFilters>>) => void
  getSetFiltering?: (setFiltering: React.Dispatch<React.SetStateAction<boolean>>) => void
}

export const dropAnimation: any = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
}

function ListPage<
  T extends {
    id?: string
    [key: string]: any
  }
>(props: IListPageProps<T>) {
  usePermission(props.permission)
  const defaultLimit = props.pageSize ?? 20
  const { pageTitle, pageSubtitle, tabs } = useStoreState((state) => state.general)
  const [filtersObj, setFiltersObj] = useState<IFilters>({})
  const [filtering, setFiltering] = useState<boolean>(false)
  const { fetchAll, setAll, saveAllPositions } = useStoreActions(props.storePath!)
  const [clearFiltersComponent, setClearFiltersComponent] = useState<any>()
  const [loadingAll, setLoadingAll] = useState(false)
  const scrollRef = useRef<any>(null)
  //@ts-ignore
  const { all, totalCount, loading, endpoint } = useStoreState(props.storePath as any)
  const [activeEvent, setActiveEvent] = useState<any>(null)
  const [items, setItems] = useState<any[]>([])
  const [counterRefresher, setCounterRefresher] = useState(0)
  const [isMobile] = useRecoilState(isMobileAtom)
  const [maxItems, setMaxItems] = useState(props.maxItems || 30)
  const history = useHistory()

  const showNoData = totalCount == 0 && !loading?.all && props.showNoData != false
  const showEmpty = !loadingAll && !props.loading && props.data?.length == 0

  const { filterPrivatedItems } = usePrivacy<T>()

  const editPathname = useMemo(() => {
    return props.basePathName == "" ? `/${props?.pathName}/:id` : `/${props.basePathName || "settings"}/${props.pathName}/:id`
  }, [])

  useEffect(() => {
    if (props.getSetFiltersObj) {
      props.getSetFiltersObj(setFiltersObj)
    }
    if (props.getSetFiltering) {
      props.getSetFiltering(setFiltering)
    }
  }, [props.getSetFiltersObj, props.getSetFiltering])

  useBreadcrumb({
    title: props.title,
    subtitle: props.helpText && (
      <div>
        <Tooltip title={props.helpText}>
          <i className="fal fa-question-circle" style={{ fontSize: 16 }} />
        </Tooltip>
      </div>
    ),
  })

  useEffect(() => {
    let parsedData = (props.data || all).filter((item: T) => {
      return props.onApplyFilters ? props.onApplyFilters?.(item, { ...filtersObj, ...props.filtersObj }) : true
    })

    if (props.sort) {
      parsedData = props.sort(parsedData)
    }

    if (props.usePrivacy) {
      parsedData = filterPrivatedItems(parsedData)
    }

    setItems(parsedData)
    // }, [all, filtersObj, props?.sort, props.onApplyFilters])
  }, [all, filtersObj, props.filtersObj, props.onApplyFilters, props.data, filterPrivatedItems, props.usePrivacy])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragStart(event: any) {
    const { active } = event
    setActiveEvent(active)
  }

  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event

      if (active.id != over.id) {
        const oldIndex = items.findIndex((x) => x.id == active.id)
        const newIndex = items.findIndex((x) => x.id == over.id)
        const newArray = arrayMove(items, oldIndex, newIndex)
        setAll(newArray)
        saveAllPositions(newArray)
        setCounterRefresher((s) => s + 1)
      }
    },
    [items, saveAllPositions, setAll]
  )

  // const onSubmitFilters = useCallback(
  //     (filtersObj: any) => {
  //         if (props.onSubmitFiltersHOC) {
  //             setFiltersObj(props.onSubmitFiltersHOC(filtersObj))
  //             props.onSubmitFilters?.(props.onSubmitFiltersHOC(filtersObj))
  //         } else {
  //             setFiltersObj(filtersObj)
  //             props.onSubmitFilters?.(filtersObj)
  //         }
  //     },
  //     [props]
  // )

  const debounceSubmitFilters = React.useMemo(() => {
    const submitFilters = (filtersObj: any) => {
      if (props.onSubmitFiltersHOC) {
        setFiltersObj(props.onSubmitFiltersHOC(filtersObj))
        props.onSubmitFilters?.(props.onSubmitFiltersHOC(filtersObj))
      } else {
        setFiltersObj(filtersObj)
        props.onSubmitFilters?.(filtersObj)
      }
    }
    return debounce(submitFilters, props.debounceTime || 50)
  }, [props])

  useEffect(() => {
    // if (props.permission && !verifyPermission(props.permission)) {
    //     toast.error("Você não tem permissão para acessar essa página")
    //     history.replace("/")
    // }

    if (!props.manualFetch) {
      if ((fetchAll && !props.defaultFiltersValues) || (props.defaultFiltersValues && Object.keys(filtersObj).length)) {
        setLoadingAll(true)
        fetchAll({
          limit: defaultLimit,
          offset: 0,
          config: {
            reset: true,
          },
          ...filtersObj,
          ...props.fixedParams,
        }).finally(() => {
          setLoadingAll(false)
        })
      }
    }
    // }
  }, [])

  const Filters = (props.advancedFilters || props.filters) && (
    <Row key="filters" align="middle">
      <FiltersPanel
        onSubmit={(filtersObj: any) => {
          // onSubmitFilters(filtersObj)
          debounceSubmitFilters(filtersObj)
          if (props.localFiltering) {
            const isClearing = Object.keys(filtersObj).every((key) => {
              return filtersObj[key] == "" || filtersObj[key] == null || filtersObj[key] == undefined
            })
            if (isClearing) {
              setFiltering(false)
            } else {
              setFiltering(true)
            }
          }
        }}
        advancedFilters={props.advancedFilters}
        defaultFiltersValues={props.defaultFiltersValues}
        setFiltersForm={props.setFiltersForm}
        setClearFiltersComponent={setClearFiltersComponent}
        autoSearchSubmit
        filtering={filtering}
        setFiltering={setFiltering}
      >
        {props.filters}
      </FiltersPanel>
      {props.leftActions}
    </Row>
  )

  const activeItem = useMemo(() => {
    return items.find((item) => (item?.id || item?._id) == activeEvent?.id) || {}
  }, [activeEvent, items])

  // First 30 elementss from items
  const itemsToRender = useMemo(() => {
    return items.slice(0, maxItems)
  }, [items, maxItems])

  return (
    <div className={cx(settingsStyles.settings_page, props.className, "g_list_page")} style={props.style}>
      {isMobile && (
        <>
          <MobileHeader title={pageTitle} backTo="/settings" className="global-settings-mobile-header" />
          {props.actions?.[0]}
        </>
      )}
      {!!tabs?.length && (
        <CardBox headStyle={{ display: "none" }} style={{ marginBottom: 16 }} bodyStyle={{ padding: "8px 8px 8px 16px" }}>
          <Row
            style={
              isMobile
                ? {
                    flexDirection: "column",
                  }
                : {}
            }
          >
            {tabs
              ?.filter((tab) => tab.permission != false)
              .map((tab) => (
                <Col key={tab.name}>
                  <NavLink
                    to={tab.to}
                    className={cx(settingsStyles.tab, {
                      [styles.disabled]: tab?.disabled,
                    })}
                  >
                    {tab.name}
                  </NavLink>
                </Col>
              ))}
          </Row>
        </CardBox>
      )}
      {/* <div className={settingsStyles.settings_page_inner} style={props.innerStyle}> */}
      {/* {isMobile ? (
                <>
                    <MobileHeader title={pageTitle} backTo="/settings" className="global-settings-mobile-header" />
                    {props.actions?.[0]}
                </>
            ) : (
                <div className={settingsStyles.context_header_sticky} style={props.headerStyle}>
                    <Row wrap={false} align="middle" justify="space-between">
                        <Col>
                            <Row align="middle" gutter={10}>
                                <Col>
                                    <Row align="middle">
                                        <h1>{pageTitle}</h1>
                                        <span className={settingsStyles.page_subtitle}>{pageSubtitle}</span>
                                    </Row>
                                    <Row>
                                        <span
                                            style={{
                                                color: "var(--content-secondary)",
                                                fontSize: 16,
                                            }}
                                        >
                                            {props.pageSubtitle}
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row align="middle" gutter={15}>
                                <Row gutter={20} align="middle">
                                    <Col>
                                        <Space size="small">{props.actionsTop}</Space>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    {!!tabs?.length && (
                        <Row style={{ marginTop: 5 }}>
                            {tabs
                                ?.filter((tab) => tab.permission != false)
                                .map((tab) => (
                                    <Col key={tab.name}>
                                        <NavLink to={tab.to} className={settingsStyles.tab}>
                                            {tab.name}
                                        </NavLink>
                                    </Col>
                                ))}
                        </Row>
                    )}
                    {(!!tabs?.length || props.withSeparator) && <div className={styles.page_separator} style={tabs?.length ? { marginTop: 10 } : {}} />}
                    {(props.advancedFilters || props.filters) && (
                        <Space
                            size="middle"
                            className={cx(settingsStyles.context_header, {
                                [styles.gray]: props.gray,
                            })}
                        >
                            <Col>
                                <Space size="small">
                                    <FiltersPanel
                                        onSubmit={(filtersObj: any) => {
                                            onSubmitFilters(filtersObj)
                                        }}
                                        advancedFilters={props.advancedFilters}
                                        defaultFiltersValues={props.defaultFiltersValues}
                                        setFiltersForm={props.setFiltersForm}
                                        setClearFiltersComponent={setClearFiltersComponent}
                                        autoSearchSubmit
                                    >
                                        {props.filters}
                                    </FiltersPanel>
                                    {props.leftActions}
                                </Space>
                            </Col>
                            <Col>
                                <Row gutter={20} align="middle">
                                    <Space size="small">{props.actions}</Space>
                                </Row>
                            </Col>
                        </Space>
                    )}
                    {props.headerChildren}
                </div>
            )} */}
      {/* {clearFiltersComponent} */}
      <CardBox
        title={props.title}
        // title={
        //     <>
        //         {props.title}{" "}
        //         <span
        // style={{
        //     fontSize: 14,
        //     color: "var(--content-secondary)",
        // }}
        //         >
        //             ({items?.length})
        //         </span>
        //     </>
        // }
        count={props.count !== false ? items?.length : 0}
        helpText={props.helpText}
        extra={props.actions}
        extraMore={props.extraMore}
        extraLeft={[Filters]}
        loading={props.loading || (loading.all && all?.length <= 0)}
        style={props.cardStyle}
      >
        <Row style={{ flexFlow: "nowrap", position: "relative" }}>
          <Col flex={1} className={settingsStyles.left_col} ref={scrollRef}>
            {props.children}
            {showEmpty && props.empty}
            {showNoData ? (
              <NoData
                nodata={{
                  to: buildUrlQuery("new", {
                    match: props.match,
                    location: props.location,
                  }),
                  ...props.noData,
                }}
                totalCount={totalCount}
              />
            ) : (
              <>
                {props.showHeader && all?.length >= 0 && <ListPageHeader columns={props.columns} />}
                {/* <Spin
                                    spinning={(loadingAll && !all.length) || !!props.loading}
                                    style={{ paddingTop: loading?.all || props.loading ? 80 : 0 }}
                                > */}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext items={itemsToRender}>
                    {/* <div className={styles.items}> */}
                    {itemsToRender.map((item: T, i) => (
                      <ListPageDraggableItem
                        key={item?.id || item?._id}
                        item={item}
                        path={props.path!}
                        columns={props.columns!}
                        draggable={props.draggable && !filtersObj?.search}
                        // style={props.showHeader && i == 0 ? { borderTop: "none" } : {}}
                        style={i == 0 ? { borderTop: "none" } : {}}
                      />
                    ))}
                    {/* </div> */}
                    {createPortal(
                      <DragOverlay adjustScale dropAnimation={dropAnimation}>
                        {activeEvent ? (
                          <ListPageItem
                            key={activeEvent?.id}
                            item={activeItem}
                            path={props.path!}
                            columns={props.columns!}
                            draggable
                            style={{ borderTop: "none" }}
                            dragOverlay
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}
                  </SortableContext>
                </DndContext>
                {/* </Spin> */}
                {items?.length > maxItems && (
                  <Button
                    // type="text"
                    style={{ margin: "20px auto 5px", display: "block" }}
                    onClick={() => {
                      setMaxItems((m) => m + (maxItems || 30))
                    }}
                  >
                    Mostrar Mais
                  </Button>
                )}
                {/* {!!items?.length && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: 10,
                                            right: 0,
                                        }}
                                    >
                                        {items?.length} registros
                                    </div>
                                )} */}
              </>
            )}
          </Col>
        </Row>
      </CardBox>
      {/* </div> */}
      {!props.manualRouting && <Route path={editPathname} component={props.EditComponent} />}
    </div>
  )
}

export default ListPage
