import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IConversation } from "./conversations"

export interface ISearch extends IConversation {
    type: "conversation" | "message"
}

export const search: IGenericModel<ISearch> = {
    ...generic(endpoints.SEARCH)
}