import React, { useEffect } from "react"

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
const useScrollTop = () => {
  // just run the effect on pathname and/or search change
  useEffect(() => {
    const scrollToTop = () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
        })
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0)
      }
    }
    scrollToTop()
    setTimeout(() => {
      scrollToTop()
    }, 50)
  }, [])

  // renders nothing, since nothing is needed
  return null
}

export default useScrollTop
