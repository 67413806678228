import { Row } from "antd"
import React from "react"

interface IMenuItemInnerSelectableProps {
  children: React.ReactNode
  selected?: boolean
  title?: string
}

function MenuItemInnerSelectable(props: IMenuItemInnerSelectableProps) {
  return (
    <div className="flex justify-between items-center gap-2 w-full flex-nowrap" title={props.title}>
      <div className="truncate">{props.children}</div>
      {props.selected && (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className="shrink-0">
          <path
            fill="var(--primary)"
            d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10.001-10.002 10.001c-5.524 0-10.002-4.478-10.002-10.001C1.998 6.477 6.476 1.999 12 1.999Zm0 1.5a8.502 8.502 0 1 0 0 17.003A8.502 8.502 0 0 0 12 3.5Zm-.004 2.5a5.998 5.998 0 1 1 0 11.996a5.998 5.998 0 0 1 0-11.996Z"
          />
        </svg>
      )}
    </div>
  )
}

export default MenuItemInnerSelectable
