import { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import cx from "classnames"
import Tooltip from "components-old/Tooltip"
import { Button } from "antd"
import { Copy01Icon } from "components-old/icons"
import toast from "lib/toast"

interface AnalyticsJSONViewerProps {
  json?: any
  id: string
  className?: string
}

function AnalyticsJSONViewer(props: AnalyticsJSONViewerProps) {
  const preRef = useRef<any>(null)
  const [structure, setStructure] = useState<any>()
  const [refresher, setRefresher] = useState(0)

  useEffect(() => {
    if (props.json) {
      setRefresher((prev) => prev + 1)
      setStructure(props.json)

      // console.log("props.json", props.json)
    }
  }, [props.json])

  useEffect(() => {
    const load = async () => {
      if (structure) {
        const source = document.querySelector("#" + props.id)
        const dest = document.querySelectorAll(".json-picker-" + props.id)
        try {
          const JPP = await import("jsonpath-picker-vanilla")
          JPP.jsonPathPicker(source, structure || {}, dest)
          setTimeout(() => {
            JPP.jsonPathPicker(source, structure || {}, dest)
          }, 100)
          // ;(window as any).JPPicker.render(source, structure || {}, dest)
          // setTimeout(() => {
          //   ;(window as any).JPPicker.render(source, structure || {}, dest)
          // }, 100)
        } catch (e) {
          console.error(e)
        }
      }
    }
    load()
  }, [props.id, structure])

  return (
    <div className="relative group">
      <div
        className={twMerge(
          "rounded-lg bg-base-200 dark:bg-base-500 p-3 overflow-auto determine-structure mt-1 relative",
          !structure && "hidden",
          props.className
        )}
      >
        <pre id={props.id} className="mb-0 pretty-scroll pretty-scroll-hoverable max-h-[600px]" ref={preRef} key={refresher}></pre>
        <input className={cx("json-picker-" + props.id, "json-picker-input hidden")} />
      </div>
      {props.json && (
        <div className="absolute top-3 right-5">
          <div>
            <Tooltip title="Copiar" mouseEnterDelay={0}>
              <Button
                icon={<Copy01Icon className="w-5 h-5" />}
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(props.json))
                  toast.success("Copiado para a área de transferência")
                }}
                type="text"
                className="!rounded-lg opacity-0 group-hover:opacity-100 transition-opacity"
              />
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  )
}

export default AnalyticsJSONViewer
