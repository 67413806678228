import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export type ITrigger =
  | "trigger:conversation:resolved"
  | "trigger:conversation:reopened_resolved"
  | "trigger:conversation:new-conversation"
  | "trigger:conversation:new-active-conversation"
  | "trigger:conversation:grouped"
  | "trigger:conversation:assigned"
  | "trigger:conversation:tagged"
  | "trigger:conversation:transfer-conversation"
  | "trigger:conversation:time-unanswered"
  | "trigger:conversation:reopen-conversation"
  | "trigger:conversation:time-without-reply"
  | "trigger:conversation:time-pending"
  | "trigger:conversation:deal-updated"
  | "trigger:conversation:lost-call"
  | "trigger:operator:online"
  | "trigger:contact:new-contact"
  | "trigger:deal:updated"
export type IMacroAction = "run-bot" | "send-message"

export interface IAutomation extends GenericResource {
  id: string
  name: string
  createdAt: string
  trigger: ITrigger
  active: boolean
  actions: {
    type: IMacroAction
    botId: string
    message: string
  }[]
}

export const automations: IGenericModel<IAutomation> = {
  ...generic(endpoints.AUTOMATION),
}
