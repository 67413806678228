import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
// import { IWorkspace } from "./workspaces"

export interface IPartnerPayment {
    id: string
    createdAt: string
    workspaceName: string
    paymentDate: string
    paymentAmount: number
    commission: number
}

export const partnerPayments: IGenericModel<IPartnerPayment> = {
    ...generic(endpoints.PARTNER_PAYMENT),
}
