import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import InputVariableFormItem from "components-old/forms/InputVariable/InputVariableFormItem"
import { InputType } from "lib/constants"

interface FindContactActionProps extends BaseEditActionChildrenProps {}

const FindContactAction = (props: FindContactActionProps) => {
  const fieldName = [...props.actionFieldName, "contact"]

  return (
    <div>
      <InputVariableFormItem field={WorkflowDefs.Fields.contact.record.id} name={[...fieldName, "id"]} />
      <InputVariableFormItem field={WorkflowDefs.Fields.contact.record.phone} name={[...fieldName, "phone"]} />
    </div>
  )
}

export default FindContactAction
