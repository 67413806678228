import React from "react"
import ResizableDrawer, { ResizableDrawerProps } from "../ResizableDrawer"
import { Button, Drawer } from "antd"
import { twMerge } from "tailwind-merge"
import cx from "classnames"

export interface CustomDrawerProps extends Partial<ResizableDrawerProps> {
  title: React.ReactNode
  titleClassname?: string
  resizable?: boolean
  onClose: () => void
  shadowHeader?: boolean
  painted?: boolean
  className?: string
}

function CustomDrawer(props: CustomDrawerProps) {
  const { resizable, shadowHeader, titleClassname, painted, extra, ...rest } = props
  const Component = resizable ? ResizableDrawer : Drawer

  return (
    <Component
      {...(rest as any)}
      className={cx(
        "custom-drawer-overflow",
        {
          "custom-drawer-painted": painted,
        },
        props.className
      )}
      closeIcon={null}
      maskClosable
      closable={false}
      title={
        <div
          className={twMerge(
            "p-5 flex justify-between items-center",
            shadowHeader && "px-5 py-4 mb-6",
            props.painted && "text-white min-h-[68px]",
            titleClassname
          )}
          style={{
            ...(shadowHeader ? { boxShadow: "0px 4px 6px rgba(33,36,41,0.09)" } : {}),
            ...(painted ? { backgroundImage: "linear-gradient(-303deg,#00a4bd,#00afb2 56%,#00bda5)" } : {}),
          }}
        >
          {props.title}
          <div className="flex gap-2">
            {props.extra}
            <Button
              // type="text"
              className={twMerge("rounded-full", painted && "!bg-transparent hover:!bg-[rgba(255,255,255,0.1)] !text-white")}
              icon={<i className={twMerge("far fa-times", painted && "!text-2xl")} />}
              onClick={props.onClose}
            ></Button>
          </div>
          {/* {props.visible && (
                        <Button
                            type="text"
                            className="absolute rounded-full shadow-lg bg-base-100 hover:bg-base-100 hover:font-bold top-5 -left-5 group"
                            style={{
                                zIndex: 101,
                            }}
                            icon={<i className="far fa-times !text-md group-hover:font-bold" />}
                            onClick={props.onClose}
                        />
                    )} */}
        </div>
      }
    />
  )
}

export default CustomDrawer
