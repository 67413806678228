import { InboxDownload, MailSend02Icon, Message01Icon, MessageOutgoing01Icon, MoreIcon, Target02Icon, WebhookIcon } from "components-old/icons"
import EditWebhookReceived from "./trigger/editable-triggers/EditWebhookReceived"
import { CategoryEnum } from "../workflow.enums"
import EditCampaignMessageReceived from "./trigger/editable-triggers/EditCampaignMessageReceived"
import EditNewSession from "./trigger/editable-triggers/EditNewSession"

/**********************************
 * ENUMS
 **********************************/
export enum WorkflowTriggerCategoryTypeEnum {
  OTHER = "other",
  CHAT = "chat",
  CONTACT = "contact",
  CAMPAIGN = "campaign",
}

export enum WorkflowTriggerTypeEnum {
  MANUAL = "manual",
  CAMPAIGN_MESSAGE_SENT = "campaign:message_sent",
  CONVERSATION_NEW_SESSION = "conversation:new_session",
  WEBHOOK_INBOUND = "webhook_inbound",
  DEAL_CADENCE_UPDATED = "deal:cadence-updated",
}

/**********************************
 * TYPES
 **********************************/
export interface WorkflowTriggerItem {
  type: WorkflowTriggerTypeEnum
  icon: React.ElementType
  name: string
  category: WorkflowTriggerCategoryTypeEnum
  variableCategory?: CategoryEnum
  edit: React.ElementType
}

export interface WorkflowTriggerCategoryItem {
  type: WorkflowTriggerCategoryTypeEnum
  label: string
  icon: React.ElementType
  description: string
}

/**********************************
 * ARRAYS
 **********************************/
const triggers: WorkflowTriggerItem[] = [
  // {
  //   type: WorkflowTriggerTypeEnum.MANUAL,
  //   icon: TouchInteraction04Icon,
  //   name: "Disparado manualmente",
  //   category: WorkflowTriggerCategoryTypeEnum.OTHER,
  //   edit: EditWebhookReceived,
  // },
  {
    type: WorkflowTriggerTypeEnum.WEBHOOK_INBOUND,
    icon: WebhookIcon,
    name: "Webhook recebido",
    category: WorkflowTriggerCategoryTypeEnum.OTHER,
    edit: EditWebhookReceived,
    variableCategory: CategoryEnum.WEBHOOK,
  },
  {
    type: WorkflowTriggerTypeEnum.CAMPAIGN_MESSAGE_SENT,
    icon: MailSend02Icon,
    name: "Mensagem de campanha enviada",
    category: WorkflowTriggerCategoryTypeEnum.CAMPAIGN,
    edit: EditCampaignMessageReceived,
    variableCategory: CategoryEnum.CAMPAIGN_MESSAGE_SENT,
  },
  {
    type: WorkflowTriggerTypeEnum.CONVERSATION_NEW_SESSION,
    icon: InboxDownload,
    name: "Nova Sessão",
    category: WorkflowTriggerCategoryTypeEnum.CHAT,
    edit: EditNewSession,
    variableCategory: CategoryEnum.CONVERSATION,
  },
]

const categories: WorkflowTriggerCategoryItem[] = [
  {
    type: WorkflowTriggerCategoryTypeEnum.CHAT,
    label: "Conversa",
    icon: Message01Icon,
    description: "Eventos específicos de conversa que iniciam o cenário",
  },
  {
    type: WorkflowTriggerCategoryTypeEnum.CAMPAIGN,
    label: "Campanha",
    icon: Target02Icon,
    description: "Eventos específicos de campanha do lado do Kinbox que iniciam o cenário",
  },
  {
    type: WorkflowTriggerCategoryTypeEnum.OTHER,
    label: "Outros",
    icon: MoreIcon,
    description: "Gatilhos que não se encaixam em outras categorias",
  },
  // {
  //   type: WorkflowTriggerCategoryTypeEnum.CHAT,
  //   label: "Eventos de Conversa",
  //   icon: Message01Icon,
  //   description: "Eventos específicos de conversa do lado do Kinbox que iniciam o cenário",
  // },
  // {
  //   type: WorkflowTriggerCategoryTypeEnum.CONTACT,
  //   label: "Eventos de Contato",
  //   icon: ContactIcon,
  //   description: "Eventos específicos de contato do lado do Kinbox que iniciam o cenário",
  // },
]

/**********************************
 * RECORDS
 **********************************/
const TriggerRecord = triggers.reduce((acc, item) => {
  acc[item.type] = item
  return acc
}, {}) as Record<WorkflowTriggerTypeEnum, WorkflowTriggerItem>

const CategoryRecord = categories.reduce((acc, item) => {
  acc[item.type] = item
  return acc
}, {}) as Record<WorkflowTriggerCategoryTypeEnum, WorkflowTriggerCategoryItem>

/**********************************
 * EXPORTS
 **********************************/
export const WorkflowTriggerDefs = {
  // Enums
  Type: WorkflowTriggerTypeEnum,
  Category: WorkflowTriggerCategoryTypeEnum,

  // Arrays
  triggers,
  categories,

  // Records
  TriggerRecord,
  CategoryRecord,
}
