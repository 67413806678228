import { Button, Form, Input, InputProps } from "antd"
import Tooltip from "components-old/Tooltip"
import { Copy01Icon } from "components-old/icons"
import toast from "lib/toast"

interface CopyableInputProps {
  name?: string | string[]
  className?: string
  inputProps: InputProps
  copyableValue: string
  disabled?: boolean
  label: string
}

function CopyableInput(props: CopyableInputProps) {
  const copyCode = () => {
    navigator.clipboard.writeText(props.copyableValue)
    toast.info("Copiado para a área de transferência")
  }

  return (
    <div className="relative group">
      <Form.Item label={props.label} name={props.name} className={props.className}>
        <Input onClick={copyCode} {...props.inputProps} />
      </Form.Item>
      <Tooltip title="Copiar" mouseEnterDelay={0}>
        <Button
          icon={<Copy01Icon className="w-5 h-5" />}
          onClick={copyCode}
          className="my-2 text-xs absolute top-[26px] right-1 !rounded-lg !w-7 !h-7 opacity-0 group-hover:opacity-100"
          size="small"
        />
      </Tooltip>
    </div>
  )
}

export default CopyableInput
