import { Form } from "antd"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import SelectReference from "../../../actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface ArchiveConversationActionProps extends BaseEditActionChildrenProps {}

const ArchiveConversationAction = (props: ArchiveConversationActionProps) => {
  const fieldName = [...props.actionFieldName, "conversation"]

  return (
    <div>
      <Form.Item label="Conversa" name={[...fieldName, "conversationReference"]} required>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.conversation} excludeId={props.editNode.id} />
      </Form.Item>
    </div>
  )
}

export default ArchiveConversationAction
