import React from "react"
import cx from "classnames"
import styles from "./style.module.css"
import { Button, Dropdown } from "antd"

interface ITableActionsDropdown {
    children: any
    dropdownProps?: any
    triggerStyle?: React.CSSProperties
}

function TableActionsDropdown(props: ITableActionsDropdown) {
    return (
        <Dropdown
            {...props.dropdownProps}
            overlay={props.children}
            trigger={["click"]}
            className={cx("common_dropdown", styles.table_dropdown)}
        >
            <Button
                icon={<i className={"fal fa-ellipsis"} style={{ fontSize: 22 }} />}
                type="text"
                onClick={(e) => {
                    e.stopPropagation()
                }}
                style={{
                    marginLeft: "auto",
                    padding: 2,
                    width: 36,
                    minWidth: 36,
                    height: 36,
                    boxSizing: "border-box",
                    ...props.triggerStyle,
                }}
            />
        </Dropdown>
    )
}

export default TableActionsDropdown
