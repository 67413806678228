import { Checkbox, Form } from "antd"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import SelectReference from "../../../actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import BotSelect from "components-old/ui/BotSelect"
import { LabelHelp } from "components-old/forms/LabelHelp"

interface RunBotActionProps extends BaseEditActionChildrenProps {}

const RunBotAction = (props: RunBotActionProps) => {
  const fieldName = [...props.actionFieldName, "runBot"]

  return (
    <div>
      <Form.Item label="Conversa" name={[...fieldName, "conversationReference"]} required>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.conversation} excludeId={props.editNode.id} />
      </Form.Item>
      <Form.Item label="Bot" name={[...fieldName, "botId"]} required>
        <BotSelect />
      </Form.Item>
      <Form.Item label="Opções">
        <Form.Item name={[...fieldName, "reopenConversation"]} valuePropName="checked" className="mb-1">
          <Checkbox>
            <LabelHelp help={"Caso conversa esteja encerrada, ela será reaberta ao rodar o bot"}>Reabrir conversa </LabelHelp>
          </Checkbox>
        </Form.Item>
      </Form.Item>
    </div>
  )
}

export default RunBotAction
