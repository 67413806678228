import React, { useState } from "react"
import useLocalStorage from "react-use/lib/useLocalStorage"
import { stores } from "store"
import req from "lib/req"
import config from "config"
import { channelsTypes, CHANNEL_TYPES } from "lib/constants"
import { store as easyStore } from "hooks"
import { channelIsInUserGroups } from "components-old/ui/ChannelsSelect"

function GeneralStore() {
    const _isProduction = import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging"
    const [breadcrumb, setBreadcrumb] = useState({ title: "", backTo: "" })
    const [loaders, setLoaders] = useState([])
    const [dialog, setDialog] = useState()
    const [modal, setModal] = useState()
    const [bannerNotification, setBannerNotification] = useState()
    const [channelNotification, setChannelNotification] = useState()
    const [modalConfigs, setModalConfigs] = useState({})
    const [_domain, setDomain] = useState({})
    const [_reqModel, setReqModel] = useState({ sent: {}, response: {} })
    const [localFilters, setLocalFilters] = useState(
        JSON.parse(localStorage.getItem("filters")) || {}
    )
    const [sidebarMinimized, setSidebarMinimized] = useLocalStorage("sidebarMinimized", false)
    const [chatSideOpened, setChatSideOpened] = useLocalStorage("chatSideWidthOpened", true)
    // const [themeColors, setThemeColors] = useLocalStorage("themeColors", defaultThemes[0].colors)
    // const [themeColors, setThemeColors] = useState(defaultThemes[0].colors)
    const [activeIntegration, setActiveIntegration] = useLocalStorage("activeIntegration", {})
    const [chatPanelOpen, setChatPanelOpen] = useLocalStorage(
        "chatPanelOpen",
        window.innerWidth < 768 ? false : true
    )
    const [panelHelperOpen, setPanelHelperOpen] = useLocalStorage("panelHelperOpen", false)
    // const channelState = useStoreState((state) => state.channels)

    function changeBreadcrumb(text) {
        setBreadcrumb(text)
    }

    const addLoader = (loader) => {
        setLoaders((loaders) => [...loaders, loader])
    }

    function removeLoader(loader) {
        setLoaders((loaders) => loaders.filter((x) => x != loader))
    }

    function addDialog({ confirmText = "Salvar", cancelText = "Cancelar", ...payload }) {
        setDialog({ confirmText, cancelText, ...payload })
    }

    function removeDialog(loader) {
        setDialog(null)
    }

    function addModal(payload, configs = {}) {
        setModal(payload)
        setModalConfigs(configs)
    }

    function removeModal() {
        setModal(null)
        setModalConfigs({})
        if (modal) {
            const cb = modal.cb
            if (cb) cb()
        }
    }

    function addBannerNotification(payload) {
        setBannerNotification(payload)
    }

    function removeBannerNotification() {
        setBannerNotification(null)
    }

    function addChannelNotification(payload) {
        setChannelNotification(payload)
    }

    function removeChannelNotification() {
        setChannelNotification(null)
    }

    async function uploadFile(payload) {
        const response = await req(config.endpoints.UPLOAD_FILE, {
            method: "post",
            loader: "put/session",
            customHeaders: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            ...payload,
            // dispositionName: (payload?.fileName || payload?.name)
            //     .normalize("NFD")
            //     .replace(/\s/g, "_")
            //     .replace(/[^.a-zA-z0-9s]/g, ""),
        })
        return response
    }

    function getAsset(assetName) {
        return "https://zapiko.s3-sa-east-1.amazonaws.com/" + assetName
    }

    function uploadAssets(assets) {
        const formData = new FormData()
        assets.forEach((x, i) => formData.append("file" + i, x))
        return uploadFile({
            data: formData,
            loader: "post/assets",
        })
    }

    function toggleSidebar() {
        // if (window.innerWidth <= 1366 && sidebarMinimized) {
        //     setChatSideOpened(false)
        // }
        setSidebarMinimized((minimized) => !minimized)
    }

    function toggleSideChat() {
        if (window.innerWidth <= 1366 && !chatSideOpened) {
            setSidebarMinimized(true)
        }
        setChatSideOpened((opened) => !opened)
    }

    function getColors() {
        // const theme = themeColors.split(",")
        const theme = stores?.session.workspace?.themeColors?.split(",") || []
        return {
            SIDEBAR_BG: theme[0],
            MENU_BG_HOVER: theme[1],
            ACTIVE_ITEM: theme[2],
            ACTIVE_ITEM_TEXT: theme[3],
            HOVER_ITEM: theme[4],
            TEXT_COLOR: theme[5],
            ACTIVE_PRESENCE: theme[6],
            MENTION_BADGE: theme[7],
        }
    }

    function showChannelDisconnectedBanner({ id, channelId, status, description, isBanned }) {
        const channelType = channelsTypes[channelId]
        addChannelNotification({
            channelType,
            status,
            isBanned,
            id,
            channelId,
            description,
            type: "warning",
        })
    }

    function verifyAllChannelStatus(platformId) {
        let groups = easyStore.getState().groups.all || []
        let disconnectedZaps = easyStore.getState().channels.all.filter(
            (x) =>
                x.channelId == CHANNEL_TYPES.WHATSAPP &&
                (x.status == "disconnected" ||
                    x.status == "restart_required" ||
                    x.status == "qr_code" ||
                    x.isBanned) &&
                channelIsInUserGroups({
                    channel: x,
                    groups,
                    workspace: stores?.session.workspace,
                    user: stores?.session.user,
                })
        )

        // Se enviou mensagem por um canal checa só ele, caso ele esteja ok, permanece o estado atual
        if (platformId) {
            disconnectedZaps = disconnectedZaps.filter((x) => x.id == platformId)
            if (disconnectedZaps?.length) {
                showChannelDisconnectedBanner(disconnectedZaps[0])
            }
            return
        }
        if (disconnectedZaps?.length) {
            showChannelDisconnectedBanner(disconnectedZaps[0])
        } else {
            removeChannelNotification(null)
        }
    }

    /* Developer
    =================================================================== */
    // function _changeDomain(newDomain) {
    //     setDomain(newDomain)
    //     localStorage.setItem("dev", JSON.stringify({ domain: newDomain }))
    // }

    function _changeReqModel(payload) {
        setReqModel({ ..._reqModel, [payload.method]: payload.data })
    }

    // const _recoverDev = state => {
    //     if (_isProduction) {
    //         setDomain(getDefaultApiEndpoints(_isProduction).find(x => x.label == "production"))
    //     } else {
    //         const devString = localStorage.getItem("dev")
    //         const devParsed = JSON.parse(devString)
    //         if (devParsed && devParsed.domain) {
    //             setDomain(devParsed.domain)
    //         } else {
    //             setDomain(getDefaultApiEndpoints(_isProduction)[0])
    //         }
    //     }
    // }
    const recoverDomain = () => {
        if (_isProduction) {
            setDomain(config.envs[1])
        } else {
            setDomain(JSON.parse(localStorage.getItem("nanui-env")) || config.envs[0])
        }
    }

    return {
        breadcrumb,
        changeBreadcrumb,

        // Loader
        loaders,
        addLoader,
        removeLoader,

        // Dialog
        dialog,
        addDialog,
        removeDialog,

        // Modal
        modal,
        addModal,
        removeModal,
        setLoaders,
        modalConfigs,

        // Upload/Download
        uploadFile,
        uploadAssets,
        getAsset,

        chatSideOpened,
        setChatSideOpened,
        sidebarMinimized,
        setSidebarMinimized,
        toggleSidebar,
        toggleSideChat,
        // themeColors,
        // setThemeColors,
        activeIntegration,
        setActiveIntegration,
        panelHelperOpen,
        setPanelHelperOpen,
        bannerNotification,
        channelNotification,
        addBannerNotification,
        removeBannerNotification,
        addChannelNotification,
        removeChannelNotification,
        chatPanelOpen,
        setChatPanelOpen,
        verifyAllChannelStatus,

        showChannelDisconnectedBanner,

        addLocalFilters: (newParams) => {
            setLocalFilters((localFilters) => {
                const query = {
                    ...localFilters,
                    ...newParams,
                }
                localStorage.setItem("filters", JSON.stringify(query))
                return query
            })
        },

        localFilters,

        theme: getColors(),

        // Dev
        _domain,
        setDomain,
        recoverDomain,
        _reqModel,
        // _changeDomain,
        _changeReqModel,
        // _recoverDev,
        _isProduction,
    }
}

export default GeneralStore
