import Tooltip from "components-old/Tooltip"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import { CodeIcon } from "components-old/icons"
import { getVariablesDrop } from "./variables-suggestion"
import React from "react"

interface VariableDropTriggerProps {
  editor?: any
  command?: any
  onClick?: any
  floatingTrigger?: boolean
}

const VariableDropTrigger = (props: VariableDropTriggerProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const buttonClass = twMerge(
    "text-md text-secondary hover:text-content-100 w-8 h-8 flex justify-center items-center rounded-lg hover:bg-[var(--hover-inner-button)] disabled:flex disabled:!bg-transparent disabled:!shadow-none disabled:opacity-50"
  )

  return (
    <div className={twMerge("variable-trigger absolute -top-9 right-[3px]", props.floatingTrigger && "top-[3px] hidden group-focus-within/input:block z-10")}>
      <Tooltip mouseEnterDelay={0} title="Usar variável">
        <button
          onClick={() => {
            props.onClick?.() ||
              getVariablesDrop({
                editor: props.editor,
                command: props.command,
                clientRect: () => buttonRef.current?.getBoundingClientRect(),
              } as any)
          }}
          className={cx(buttonClass)}
          type="button"
          ref={buttonRef}
        >
          <CodeIcon className="w-[18px] h-[18px] text-content-100" />
        </button>
      </Tooltip>
    </div>
  )
}

export default VariableDropTrigger
