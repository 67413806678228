import { endpoints } from "config/endpoints.config"
import { IGenericModel, PrivateGenericResource, generic } from "models/generic"

export interface ITag extends PrivateGenericResource {
  name: string
  color: string
  textColor: string
  isActive: boolean
}

export const tags: IGenericModel<ITag> = {
  ...generic(endpoints.LABEL),
}
