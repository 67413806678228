import { Button, Card, Col, Dropdown, Menu, Skeleton, Space } from "antd"
import { CardProps } from "antd/lib/card"
import React from "react"
import cx from "classnames"
import MenuLink from "../menu/MenuLink"
import Tooltip from "../Tooltip"
import styles from "./style.module.css"
import Separator from "components/navigation/Separator"

export interface IExtraMore {
  id: string
  title?: string
  iconName?: string
  to?: string
  onClick?: () => void
  custom?: React.ReactNode
  disabled?: boolean
  separator?: boolean
}

interface ICardProps extends CardProps {
  count?: number
  children: React.ReactNode
  helpText?: React.ReactNode
  extraMore?: IExtraMore[]
  extraLeft?: React.ReactNode[]
  onBack?: () => void
  loading?: boolean
}

function CardBox(props: ICardProps) {
  const { helpText, extraMore, extraLeft, loading, onBack, ...rest } = props
  // var loading = true

  return (
    <Card
      className={cx(styles.card_box, "g-card-box", props.className)}
      bordered={false}
      bodyStyle={{
        paddingTop: 0,
      }}
      {...rest}
      extra={
        <Space size="small" style={{ alignItems: "flex-start" }}>
          {props.extraLeft}
          {props.extra}
          {props.extraMore && (
            <Dropdown
              key={"drop"}
              placement="bottomRight"
              overlay={
                <Menu>
                  {props.extraMore?.map((e) => {
                    if (e.custom) {
                      return e.custom
                    }
                    if (e.separator) {
                      return <Separator key={e.id || "separator-menu"} />
                    }
                    if (e.to) {
                      return (
                        <Menu.Item key={e.id} disabled={e.disabled}>
                          <MenuLink iconName={e.iconName} to={e.to}>
                            {e.title}
                          </MenuLink>
                        </Menu.Item>
                      )
                    }
                    return (
                      <Menu.Item key={e.id} icon={<i className={e.iconName} />} disabled={e.disabled} onClick={e.onClick}>
                        {e.title}
                      </Menu.Item>
                    )
                  })}
                </Menu>
              }
              trigger={["click"]}
              className={cx("common_dropdown")}
            >
              <Button icon={<i className="far fa-ellipsis" />} />
            </Dropdown>
          )}
        </Space>
      }
      title={
        <Space size={"small"} style={{ marginRight: 10, position: "relative" }}>
          {onBack && <Button icon={<i className="far fa-angle-left" />} className="rounded-full" onClick={onBack} />}
          <span>
            <div>
              {props.title} {!!props.count && <span style={{ fontSize: 16, fontWeight: 500 }}>({props.count})</span>}
            </div>
          </span>
          {props.helpText && (
            <div>
              <Tooltip title={props.helpText}>
                <i className="fal fa-question-circle" style={{ fontSize: 16 }} />
              </Tooltip>
            </div>
          )}
        </Space>
      }
    >
      {props.children}
      <Skeleton
        loading={loading}
        active
        style={{
          marginBottom: 10,
        }}
      />
    </Card>
  )
}

export default CardBox
