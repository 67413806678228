import React, { useEffect, useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Alert, Col, Form, Input, Row, Select, Spin } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStore, useStoreActions, useStoreState } from "hooks"
import WhatsappSvg from "../svgs/WhatsappSvg"
import { IChannel } from "models/channels"
import QRCode from "qrcode.react"
import request from "lib/request"
import { endpoints } from "config/endpoints.config"
import toast from "lib/toast"
import { general } from "store"
import SyncZapHistoryModal from "./SyncZapHistoryModal"
import ForwardZapModal from "./ForwardZapModal"
import { ChannelStatus } from ".."
import EditableField from "components-old/common/EditableField"
import { formatDateBarTime, getHost, showDeleteModal, verifyPermission } from "lib/helper"
import HorizontalSwitch from "components-old/ui/HorizontalSwitch"
import WhatsappGroups from "./WhatsappGroups"
import GroupsSelect from "components-old/ui/GroupsSelect"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"
import { useSetRecoilState } from "recoil"
import { modalState } from "atoms/app.atom"

interface IEditWhatsAppProps extends RouteComponentProps<{ id: string }> {
  onChange: Function
}

let timeout: number
function EditWhatsApp(props: IEditWhatsAppProps) {
  const [form] = Form.useForm()
  const { all: channels } = useStoreState((state) => state.channels)
  const { all: channelsWhatsapp } = useStoreState((state) => state.channelsWhatsapp)
  const { addModal, removeModal } = useStore(general)
  const { patchElementOnAll } = useStoreActions((actions) => actions.channels)
  const setModal = useSetRecoilState(modalState)
  const [item, setItem] = useState<any>()
  const isChannelsGroupLimited = verifyPermission("only_can_see_channels_if_in_channel_group", {
    restrictive: true,
  })

  const [qrcodeRefreshCount, setQrcodeRefreshCount] = useState(0)
  const { id } = useParams<any>()
  const [qrcode, setQrcode] = useState<{
    qrcode: string
    isBase64: boolean
  }>()

  const channel = useMemo(() => {
    return channels.find((c) => c.id == id)
  }, [channels, id])

  // const channelZap = useMemo(() => {
  //     return channelsWhatsapp.find((c) => c.id == id)
  // }, [channelsWhatsapp, id])

  const { subdomain } = getHost()

  function getQrcode() {
    request.get(endpoints.WHATSAPP_QRCODE + "/" + id).then(({ data }) => {
      if (data.isQrCodeExpired) {
        toast.error("QRCode expirou, necessário reiniciar o canal.")
      }
      setQrcode(data)
    })
  }

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (channel?.status == "qr_code") {
      getQrcode()
      timeout = setTimeout(() => {
        setQrcodeRefreshCount((c) => c + 1)
      }, 10000)
    }

    return () => clearTimeout(timeout)
  }, [qrcodeRefreshCount, channel?.status])

  return (
    <SettingsPageEdit<IChannel>
      {...props}
      pageComponent="page"
      routeName="channels"
      title="Canal de WhatsApp"
      okProps={{
        disabled: isChannelsGroupLimited,
      }}
      onSubmitSuccess={() => {
        props.onChange()
      }}
      width={600}
      titleOverwrite={
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <Row wrap={false}>
              <Col>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <WhatsappSvg />
                </div>
              </Col>
              <Col
                className="truncate"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {channel?.description}
              </Col>
            </Row>
          </Col>
        </Row>
      }
      storePath={(store) => store.channelsWhatsapp}
      onChangeItem={(item) => setItem(item)}
      form={form}
      hideFooterWhenSaved
      formProps={{
        onValuesChange(changedValues, values) {
          if (changedValues?.isPrivate && !form.getFieldValue("groupIds")) {
            form.setFieldsValue({
              groupIds: [],
            })
          }
          if (changedValues?.allowGroupMessages && !form.getFieldValue("groups")) {
            form.setFieldsValue({
              groups: [],
            })
          }
        },
      }}
    >
      {channel?.extraData?.alertMesaage && (
        <Alert
          showIcon
          message={channel?.extraData?.alertMesaage}
          description="Em caso de dúvida entre em contato com o nosso suporte"
          type="warning"
        />
      )}
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item label="Status">
            <ChannelStatus status={channel?.status} channel={channel} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Última vez recriado" help="Recomendamos recriar a cada 60 dias">
            <Input value={formatDateBarTime(item?.lastCreatedAt)} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Descrição"
        name="description"
        rules={[
          {
            required: true,
            message: "Por favor digite um nome.",
          },
        ]}
      >
        <Input placeholder="e.g. Número principal" />
      </Form.Item>
      {channel?.isBanned && (
        <Alert
          showIcon
          message={
            <span>
              Esse número possivelmente foi bloqueado pelo WhatsApp.{" "}
              <b>
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://kinbox.notion.site/O-que-fazer-se-meu-n-mero-foi-bloqueado-b3ecd454296247c4819223c4011d8ee4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Veja aqui
                </a>
              </b>{" "}
              o que fazer e{" "}
              <b style={{ textDecoration: "underline" }}>
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://kinbox.notion.site/Como-n-o-ter-o-n-mero-bloqueado-pelo-WhatsApp-6e61f76794f34302b6e4802caa0be8af"
                  target="_blank"
                  rel="noreferrer"
                >
                  clique aqui
                </a>
              </b>{" "}
              para dicas de como evitar que seu número seja bloqueado.
            </span>
          }
          type="warning"
        />
      )}
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label="Número"
            name="phone"
            rules={[
              {
                required: true,
                message: "Por favor digite um número de celular.",
              },
            ]}
          >
            <Input placeholder="+55 99 999999999" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Versão" name="version">
            <Select disabled>
              <Select.Option value={3}>Multidevice</Select.Option>
              <Select.Option value={2}>Versão antiga</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {channel?.status == "qr_code" && (
        <Form.Item label="QRCode">
          <Spin spinning={channel?.status == "qr_code" && !qrcode}>
            <div
              style={{
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {qrcode?.isBase64 ? (
                <img
                  src={qrcode?.qrcode}
                  alt="qrcode"
                  style={{
                    width: 300,
                    height: 300,
                    display: "block",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: 300,
                    height: 300,
                    display: "block",
                  }}
                >
                  {qrcode && <QRCode value={qrcode?.qrcode} size={300} />}
                </div>
              )}
            </div>
          </Spin>
        </Form.Item>
      )}
      <Form.Item label="Ações">
        <div
          style={{
            padding: 8,
            border: "2px dashed var(--divider)",
            borderRadius: " var(--radius-large)",
          }}
        >
          <EditableField
            label={
              <Row align="middle">
                <i
                  className="fal fa-wifi-slash"
                  style={{
                    marginRight: 15,
                    fontSize: 16,
                    width: 20,
                  }}
                ></i>
                <span>Desconectar</span>
              </Row>
            }
            value={""}
            onClick={() => {
              showDeleteModal({
                title: "Desconectar",
                description: "Tem certeza que deseja desconectar esse canal?",
                onConfirm: () =>
                  request.post(endpoints.WHATSAPP_LOGOUT + "/" + id).then(() => {
                    toast.warning("Desconectado o canal de WhatsApp " + channel?.description)
                  }),
                okText: "Desconectar",
              })
            }}
            notCopyable
            disabled={channel?.status == "processing_channel" || channel?.status == "restarting_channel"}
          />
          <EditableField
            label={
              <Row align="middle">
                <i
                  className="fal fa-undo"
                  style={{
                    marginRight: 15,
                    fontSize: 16,
                    width: 20,
                  }}
                ></i>
                <span>Reiniciar canal</span>
              </Row>
            }
            value={""}
            onClick={() => {
              if (channel?.status == "recreate_required") {
                toast.error("Necessário recriar o canal para reiniciar")
              } else {
                showDeleteModal({
                  title: "Reiniciar",
                  description: "Tem certeza que deseja reiniciar esse canal?",
                  onConfirm: () => {
                    // toast.info(
                    //     "Reiniciando canal de WhatsApp " +
                    //         channel?.description
                    // )
                    return request.post(endpoints.RESTART_CONTAINER + "/" + id)
                  },
                  okText: "Reiniciar",
                })
              }
            }}
            notCopyable
            disabled={channel?.status == "processing_channel"}
          />
          <EditableField
            label={
              <Row align="middle" wrap={false} style={{ flex: 1 }}>
                <i
                  className="fal fa-cloud-download"
                  style={{
                    marginRight: 15,
                    fontSize: 16,
                    width: 20,
                  }}
                ></i>
                <span>Sincronizar histórico</span>
              </Row>
            }
            value={""}
            onClick={() => {
              addModal(<SyncZapHistoryModal item={channel} onBackModal={() => removeModal()} />)
            }}
            notCopyable
            disabled={channel?.status != "connected"}
          />
          <EditableField
            label={
              <Row align="middle">
                <i
                  className="fal fa-angle-double-right"
                  style={{
                    marginRight: 15,
                    fontSize: 16,
                    width: 20,
                  }}
                ></i>
                <span>Migrar</span>
              </Row>
            }
            value={""}
            onClick={() => {
              setModal(<ForwardZapModal channel={channel!} onBack={() => setModal(null)} />)
            }}
            notCopyable
            disabled={isChannelsGroupLimited}
          />
          <EditableField
            label={
              <Row align="middle">
                <i
                  className="fal fa-power-off"
                  style={{
                    marginRight: 15,
                    fontSize: 16,
                    width: 20,
                  }}
                ></i>
                <span>Recriar</span>
              </Row>
            }
            value={""}
            onClick={() => {
              showDeleteModal({
                description:
                  "Tem certeza que deseja recriar esse canal? Esse processo demora até 10 minutos, seu canal ficará indisponível durante esse tempo e você deverá ler o QRCode novamente no final do processo.",
                onConfirm: () => {
                  return request
                    .post(endpoints.RECREATE_CHANNEL + "/" + channel?.id, {
                      description: channel?.description,
                    })
                    .then((response) => {
                      patchElementOnAll(response.data)
                    })
                },
                okText: "Recriar",
              })
            }}
            notCopyable
            disabled={channel?.status == "processing_channel"}
          />
        </div>
      </Form.Item>
      <HorizontalSwitch
        title="Apenas Receptivo"
        description="Com essa opção marcada, o canal não pode enviar mensagem ativa e nem ser usado em campanhas."
        name="disable_active_chat"
      />
      <HorizontalSwitch
        title="Permitir Grupos de WhatsApp"
        description="Selecione os grupos de WhatsApp que você gostaria de ler e enviar mensagens pelo Kinbox. Por padrão o Kinbox ignora mensagens de grupos a menos que você marque um grupo aqui."
        name="allowGroupMessages"
      >
        <WhatsappGroups />
      </HorizontalSwitch>
      <div
        style={{
          marginTop: 25,
        }}
      >
        <HorizontalSwitch
          title="Restringir visibilidade"
          description="Selecione os grupos que tem acesso a visualizar esse canal"
          name="isPrivate"
        >
          <Form.Item name="groupIds">
            <GroupsSelect mode="multiple" disabled={isChannelsGroupLimited} />
          </Form.Item>
        </HorizontalSwitch>
      </div>
      {/* {(channel?.status == "disconnected" || channel?.status == "connected") && (
                <>
                    <br />
                    <Alert
                        message={
                            <span>
                                Para evitar que o canal fique desconectando, siga o tutorial:{" "}
                                <a
                                    href="https://kinbox.notion.site/Como-evitar-que-celular-desconecte-56856ae4f84c45469de8d993b6fa6724"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        textDecoration: "underline",
                                        color: "rgb(0 64 181)",
                                        fontWeight: 600,
                                    }}
                                >
                                    Clique aqui
                                </a>
                            </span>
                        }
                        type="warning"
                        style={{ maxWidth: 600 }}
                    />
                </>
            )} */}
      <EditEntityCustomFields form={form} item={item} entity="channel" />
    </SettingsPageEdit>
  )
}

export default EditWhatsApp
