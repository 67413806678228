import Icon from "components-old/common/Icon"
import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import styles from "./style.module.css"

interface IMenuLinkProps {
  iconName?: string
  icon?: React.ReactNode
  to?: string
  href?: string
  children: React.ReactNode | string
  className?: string
  innerClassName?: string
}

function MenuLink(props: IMenuLinkProps) {
  if (props.href) {
    return (
      <a className={cx(styles.menu_link, props.className)} href={props.href} target="_blank" rel="noreferrer">
        {props.icon}
        {props.iconName && <Icon name={props.iconName} />}
        <span>{props.children}</span>
      </a>
    )
  }

  return (
    <Link className={cx(styles.menu_link, props.className)} to={props.to as any}>
      {props.icon}
      {props.iconName && <Icon name={props.iconName} />}
      <span className={props.innerClassName}>{props.children}</span>
    </Link>
  )
}

export default MenuLink
