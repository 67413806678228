import { endpoints } from "config/endpoints.config"
import { Thunk, thunk } from "easy-peasy"
import { InputType } from "lib/constants"
import request from "lib/request"
import { GenericResource, IGenericModel, generic } from "models/generic"
import { stores } from "store"

export interface IMember extends GenericResource {
  email: string
  name: string
  accessLevel: number
  avatar: string
  isActive: boolean
  isJoined: boolean
  isOwner: boolean
  phone: string
  roleId: string
  status: "online" | "offline"
  isAvailable: boolean
  lastLoginAt: string
  onlineAt: string
  sharedFromIds: string[]
  availabilityChangeReason: string
  userConfigs: {
    segmentOrdersIds: string[]
  }
  ratingAverage: number
  customFields?: {
    [key: string]: {
      type: InputType
      value: string | null
    }
  }
}

interface IUserConfigs {
  segmentOrdersIds: string[]
}

export interface IMemberModel extends IGenericModel<IMember> {
  // getUserConfigs: any,
  saveUserConfigs: Thunk<IMemberModel, any>
}

export const members: IMemberModel = {
  ...generic(endpoints.OPERATOR),
  saveUserConfigs: thunk(async (actions, payload: IUserConfigs, { getState, getStoreState }) => {
    const operator = getState().all.find((operator: any) => operator.id == stores.session.user.id)
    const newOperator = {
      id: operator?.id,
      userConfigs: {
        ...operator?.userConfigs,
        ...payload,
      },
    }
    actions.patchElementOnAll(newOperator)
    return request.save(endpoints.WORKSPACE_OPERATOR, newOperator)
  }),
}
