import { Form, Switch } from "antd"
import { LabelHelp } from "components-old/forms/LabelHelp"
import React from "react"
import Card from "./Card"

interface ExpandableSwitchInputProps {
  name: string | string[]
  label: string
  icon?: any
  children: React.ReactNode
  help: React.ReactNode
}

function ExpandableSwitchInput(props: ExpandableSwitchInputProps) {
  return (
    <Form.Item
      // shouldUpdate={(prev, values) => {
      //   return prev[props.name] != values[props.name]
      // }}
      shouldUpdate
      noStyle
    >
      {({ getFieldValue }) => (
        <Card
          title={
            <LabelHelp help={props.help} className="text-sm">
              <div className="flex items-center gap-2.5">
                {props.icon && <props.icon className="w-4 h-4" />}
                {props.label}
              </div>
            </LabelHelp>
          }
          extra={
            <Form.Item name={props.name} noStyle valuePropName="checked">
              <Switch />
            </Form.Item>
          }
          style={{ marginBottom: 15 }}
          headerClassName="px-2 py-3"
          className={getFieldValue(props.name) ? "shadow border border-solid border-divider-100" : "shadow-none bg-base-200"}
        >
          {getFieldValue(props.name) && (
            <div
              style={{
                display: getFieldValue(props.name) ? "block" : "none",
              }}
            >
              {props.children}
            </div>
          )}
        </Card>
      )}
    </Form.Item>
  )
}

export default ExpandableSwitchInput
