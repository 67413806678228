import { endpoints } from "config/endpoints.config"
import { IGenericModel, PrivateGenericResource, generic } from "models/generic"

export interface IPlugin extends PrivateGenericResource {
  id: string
  name: string
  createdAt: string
  authSecret: string
  dev: boolean
  endpoint: string
  workspaceId: string
  height?: number
  width?: number
  mode?: "normal" | "drawer" | "modal" | "drawer-page" | "new-page" | "popup"
}

export const plugins: IGenericModel<IPlugin> = {
  ...generic(endpoints.PLUGIN),
}
