import React, { useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStoreState } from "hooks"
import { IChannel } from "models/channels"
import { ChannelStatus } from ".."
import TelegramSvg from "../svgs/TelegramSvg2"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"

interface IEditTelegramProps extends RouteComponentProps<{ id: string }> {
    onChange: Function
}

function EditTelegram(props: IEditTelegramProps) {
    const [form] = Form.useForm()
    const { all: channels } = useStoreState((state) => state.channels)
    const { id } = useParams<any>()
    const [item, setItem] = useState<IChannel>()

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    return (
        <SettingsPageEdit<IChannel>
            {...props}
            pageComponent="modal"
            routeName="channels"
            onChangeItem={(item) => setItem(item)}
            title="Canal de Telegram"
            onSubmitSuccess={() => {
                props.onChange()
            }}
            titleOverwrite={
                <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                        <Row wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        width: 24,
                                        height: 24,
                                        display: "flex",
                                        marginRight: 10,
                                    }}
                                >
                                    <TelegramSvg />
                                </div>
                            </Col>
                            <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                                {channel?.description}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            storePath={(store) => store.channelsTelegram}
            form={form}
            hideFooterWhenSaved
        >
            <Form.Item label="Status">
                <ChannelStatus status={channel?.status} />
            </Form.Item>
            <Form.Item
                label="Descrição"
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Campo obrigatório",
                    },
                ]}
            >
                <Input placeholder="Meu canal de Telegram" />
            </Form.Item>
            <Form.Item
                label="Token"
                name="identifier"
                rules={[
                    {
                        required: true,
                        message: "Campo obrigatório",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <EditEntityCustomFields form={form} item={item} entity="channel" />
        </SettingsPageEdit>
    )
}

export default EditTelegram
