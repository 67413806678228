import { Table, flexRender } from "@tanstack/react-table"
import React from "react"
import { twMerge } from "tailwind-merge"

interface TableHeadProps<T> {
  table: Table<T>
  transparentHeader?: boolean
  getCommonPinningStyles?: (column: any) => React.CSSProperties
}

function TableHead<T>(props: TableHeadProps<T>) {
  return (
    <thead
      style={{
        // display: "grid",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
      className="mobile:!hidden"
    >
      {props.table.getHeaderGroups().map((headerGroup) => {
        return (
          <tr
            key={headerGroup.id}
            style={{ display: "flex", width: "100%" }}
            className={twMerge(
              "bg-base-300 text-content-200 text-[13px] h-10 overflow-hidden rounded-lg flex-1 w-full",
              props.transparentHeader && "bg-base-100 text-content-100 text-xs h-8"
            )}
          >
            {headerGroup.headers.map((header) => {
              const isPin = header.column.getIsPinned()

              return (
                <th
                  key={header.id}
                  style={{
                    display: "flex",
                    width: header.getSize(),
                    ...(isPin && props.getCommonPinningStyles?.(header.column)),
                  }}
                  className={isPin ? "flex-1" : ""}
                >
                  <div
                    className={twMerge(
                      "flex h-full w-full items-center",
                      header.column.getCanSort() && "cursor-pointer select-none hover:bg-base-400 rounded-lg px-3.5 py-2"
                      // isPin && "flex-1"
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default TableHead
