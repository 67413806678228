import { IGenericModel } from "./generic"
import { IRole, roles } from "./roles"
import { IContact, contacts } from "./contacts"
import { ICustomField, customFields } from "./customFields"
import { INews, news } from "./news"
import { IUser, users } from "./users"
import { IWorkspace, workspaces } from "./workspaces"
import { IPartnerCustomer, partnerCustomers } from "./partnerCustomers"
import { IPartnerWithdrawal, partnerWithdrawals } from "./partnerWithdrawals"
import { IPartnerPayment, partnerPayments } from "./partnerPayments"
import { IAgentMessage, agentMessages } from "./agentMessages"
import { IGeneralModel, general } from "./general"
import { ICampaign, campaigns } from "./campaigns"
import { ISegmentsModel, segments } from "./segments"
import { IAssignmentRule, assignmentRules } from "./assignmentRules"
import { IIntegration, integrations } from "./integrations"
import { ISubscription, subscriptions } from "./subscriptions"
import { IQuickPhrase, quickPhrases } from "./quickPhrases"
import { IMessageSchedule, messageSchedules } from "./messageSchedules"
import { IMemberModel, members } from "./members"
import { IGroup, groups } from "./groups"
import { ITag, tags } from "./tags"
import { IBusinessHours, businessHours } from "./businessHours"
import { IPlugin, plugins } from "./plugins"
import { IKnowledgeBase, knowledgeBases } from "./knowledgeBases"
import { IWebhook, webhooks } from "./webhooks"
import { IAdminLog, adminLogs } from "./adminLogs"
import {
  IChannel,
  channels,
  channelsEmail,
  channelsSms,
  channelsTelegram,
  channelsCustom,
  channelsInstagram,
  channelsML,
  channelsFacebook,
  IChannelTelegram,
  IChannelEmail,
  IChannelCustom,
  IChannelInstagram,
  IChannelFacebook,
  IChannelML,
  IChannelSms,
} from "./channels"
import { IChannelWhatsApp, channelsWhatsapp } from "./channelsWhatsapp"
import { ITransaction, transactions } from "./transactions"
import { bots, IBotModel } from "./bots"
import { IConversationModel, conversations } from "./conversations"
import { IAdminApproval, adminApprovals } from "./adminApprovals"
import { IMessageAPI, messagesAPI } from "./messagesAPI"
import { ISearch, search } from "./search"
import { automations, IAutomation } from "./automations"
import { IReportRating, reportRating } from "./reportRating"
import { channelsWhatsappCloudApi } from "./channelsWhatsappCloudApi"
import { coupons, ICoupom } from "./coupons"
import { adminTransactions, IBuySMSTransaction } from "./adminTransactions"
import { adminPayments, IPaymentLink } from "./adminPayments"
import { IWalletTransaction, wallet } from "./wallet"
import { adminWallets } from "./adminWallets"
import { IPartnerWorkspace, partnerWorkspaces } from "./partnerWorkspaces"
import { ICommissionTransaction, partnerCommissions } from "./partnerCommissions"
import { ITransactionDetail, transactionsDetails } from "./transactionsDetails"
import { adminCommissions } from "./adminCommissions"
import { IAuditLog, audit } from "./audit"
import { IView, views } from "./views"
import { Campaign, campaignsv2 } from "./campaignsv2"
import { Pipeline, pipelines } from "./pipelines"
import { Deal, deals } from "./deals"
import { Activity, activities } from "./activities"
import { Presence, presence } from "./presence"
import { Product, products } from "./products"
import { Task, tasks } from "./tasks"
import { Workflow, WorkflowLog, workflowLogs, workflows } from "./workflows"
import { Goal, goals } from "./goals"
import { warnings, Warning } from "./warnings"
import { Dashboard, dashboards } from "./dashboards"

export interface IStoreModel {
  roles: IGenericModel<IRole>
  integrations: IGenericModel<IIntegration>
  dashboards: IGenericModel<Dashboard>
  workflows: IGenericModel<Workflow>
  workflowLogs: IGenericModel<WorkflowLog>
  contacts: IGenericModel<IContact>
  customFields: IGenericModel<ICustomField>
  news: IGenericModel<INews>
  warnings: IGenericModel<Warning>
  coupons: IGenericModel<ICoupom>
  users: IGenericModel<IUser>
  workspaces: IGenericModel<IWorkspace>
  partnerCustomers: IGenericModel<IPartnerCustomer>
  partnerWorkspaces: IGenericModel<IPartnerWorkspace>
  partnerCommissions: IGenericModel<ICommissionTransaction>
  partnerPayments: IGenericModel<IPartnerPayment>
  partnerWithdrawals: IGenericModel<IPartnerWithdrawal>
  campaigns: IGenericModel<ICampaign>
  campaignsv2: IGenericModel<Campaign>
  agentMessages: IGenericModel<IAgentMessage>
  assignmentRules: IGenericModel<IAssignmentRule>
  segments: ISegmentsModel
  subscriptions: IGenericModel<ISubscription>
  quickPhrases: IGenericModel<IQuickPhrase>
  messageSchedules: IGenericModel<IMessageSchedule>
  members: IMemberModel
  groups: IGenericModel<IGroup>
  tags: IGenericModel<ITag>
  views: IGenericModel<IView>
  businessHours: IGenericModel<IBusinessHours>
  automations: IGenericModel<IAutomation>
  plugins: IGenericModel<IPlugin>
  knowledgeBases: IGenericModel<IKnowledgeBase>
  webhooks: IGenericModel<IWebhook>
  adminLogs: IGenericModel<IAdminLog>
  reportRating: IGenericModel<IReportRating>
  channels: IGenericModel<IChannel>
  channelsWhatsapp: IGenericModel<IChannelWhatsApp>
  channelsWhatsappCloudApi: IGenericModel<IChannelWhatsApp>
  channelsTelegram: IGenericModel<IChannelTelegram>
  channelsEmail: IGenericModel<IChannelEmail>
  channelsSms: IGenericModel<IChannelSms>
  channelsCustom: IGenericModel<IChannelCustom>
  channelsInstagram: IGenericModel<IChannelInstagram>
  channelsML: IGenericModel<IChannelML>
  channelsFacebook: IGenericModel<IChannelFacebook>
  transactions: IGenericModel<ITransaction>
  adminApprovals: IGenericModel<IAdminApproval>
  messagesAPI: IGenericModel<IMessageAPI>
  search: IGenericModel<ISearch>
  adminTransactions: IGenericModel<IBuySMSTransaction>
  adminPayments: IGenericModel<IPaymentLink>
  adminWallets: IGenericModel<IWalletTransaction>
  adminCommissions: IGenericModel<ICommissionTransaction>
  transactionsDetails: IGenericModel<ITransactionDetail>
  wallet: IGenericModel<IWalletTransaction>
  audit: IGenericModel<IAuditLog>
  tasks: IGenericModel<Task>
  bots: IBotModel
  general: IGeneralModel
  conversations: IConversationModel
  pipelines: IGenericModel<Pipeline>
  deals: IGenericModel<Deal>
  activities: IGenericModel<Activity>
  presence: IGenericModel<Presence>
  products: IGenericModel<Product>
  goals: IGenericModel<Goal>
}

const model: IStoreModel = {
  general,
  roles,
  dashboards,
  warnings,
  goals,
  deals,
  pipelines,
  workflows,
  workflowLogs,
  knowledgeBases,
  campaigns,
  campaignsv2,
  integrations,
  assignmentRules,
  contacts,
  customFields,
  news,
  coupons,
  users,
  workspaces,
  partnerCustomers,
  partnerWithdrawals,
  partnerPayments,
  agentMessages,
  segments,
  subscriptions,
  quickPhrases,
  messageSchedules,
  members,
  groups,
  tags,
  businessHours,
  plugins,
  adminLogs,
  adminTransactions,
  adminPayments,
  adminCommissions,
  webhooks,
  reportRating,
  channels,
  channelsWhatsapp,
  channelsWhatsappCloudApi,
  channelsTelegram,
  channelsEmail,
  channelsSms,
  channelsCustom,
  channelsInstagram,
  channelsFacebook,
  channelsML,
  transactions,
  bots,
  conversations,
  adminApprovals,
  transactionsDetails,
  messagesAPI,
  automations,
  search,
  wallet,
  adminWallets,
  partnerWorkspaces,
  partnerCommissions,
  audit,
  views,
  activities,
  presence,
  products,
  tasks,
}

export default model
