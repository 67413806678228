import { Form, Select } from "antd"
import { endpoints } from "config/endpoints.config"
import request from "lib/request"
import React, { useEffect, useState, memo } from "react"
import { useParams } from "react-router-dom"

export interface IWhatsappGroup {
    name: string
    id: string
}

function WhatsappGroups() {
    const { id } = useParams<any>()
    const [groups, setGroups] = useState<IWhatsappGroup[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        request
            .get(endpoints.WHATSAPP_GROUPS + "/" + id)
            .then((res) => {
                setGroups(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Form.Item name="groups">
            <Select mode="multiple" loading={loading}>
                {groups.map((group) => (
                    <Select.Option key={group?.id} value={group.id}>
                        {group.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    )
}

export default memo(WhatsappGroups)
