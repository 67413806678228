import { Clock01Icon, CloudUploadIcon, Loading01Icon, Settings03Icon } from "components-old/icons"
import { VariableCategory, VariableItem, WorkflowActionsItem } from "../../workflow.defs"
import { ActionEnum, CategoryEnum } from "../../workflow.enums"
import DelayAction from "./delay/Delay.action"
import DelayNode from "./delay/Delay.node"
import { formatMilliseconds } from "lib/helper"
import MakeRequestNode from "./make-request/MakeRequest.node"
import MakeRequestAction from "./make-request/MakeRequest.action"

export const UtilsWorkflowDefs: {
  actions: WorkflowActionsItem[]
  variables: VariableItem[]
  category: VariableCategory
} = {
  /*********************************
   *  Category
   **********************************/
  category: {
    type: CategoryEnum.OTHER,
    label: "Outros",
    icon: Settings03Icon,
    palette: "gray",
  },
  /*********************************
   *  Actions
   **********************************/
  actions: [
    {
      type: ActionEnum.MAKE_REQUEST,
      name: "Requisição HTTP",
      icon: CloudUploadIcon,
      variableCategory: CategoryEnum.OTHER,
      // colors: {
      //   // bg: "#facc15",
      //   bg: "#52525b",
      //   text: "#fff",
      // },
      node: MakeRequestNode,
      edit: MakeRequestAction,
      getDescription: (dto) => {
        return (
          <span>
            Enviou requisição para{" "}
            <a className="truncate text-primary" target="_blank" href={dto.actionLog?.context?.input?.url} rel="noreferrer">
              {dto.actionLog?.context?.input?.url}
            </a>
          </span>
        )
      },
    },
    {
      type: ActionEnum.DELAY,
      name: "Esperar um tempo",
      icon: Clock01Icon,
      // icon: Loading01Icon,
      variableCategory: CategoryEnum.OTHER,
      colors: {
        // bg: "#facc15",
        bg: "#ffeca7",
        text: "#713f12",
      },
      node: DelayNode,
      edit: DelayAction,
      getDescription: (dto) => {
        return "Esperou um tempo de " + formatMilliseconds(dto.actionLog?.context.delay)
      },
    },
  ],
  /*********************************
   *  Variables
   **********************************/
  variables: [],
}
