import { InputType } from "lib/constants"
import InputVariableText, { InputVariableTextProps } from "./InputTextVariable"
import { InputField } from "pages/settings/workflows/workflow.defs"
import InputVariableCustomField from "./InputVariableCustomField"
import InputJsonVariable from "./InputJsonVariable"

export interface InputVariableProps extends InputVariableTextProps {
  field?: InputField
  floatingTrigger?: boolean
}

function InputVariable(props: InputVariableProps) {
  const field: InputField = props.field || { label: "", type: InputType.Text, name: "" }
  const multiline = field.type === InputType.Textarea || field.type === InputType.JSON || props.multiLine

  if (field.type === InputType.JSON) {
    return <InputJsonVariable multiLine={multiline} {...props} />
  }

  if (multiline || field.type === InputType.Text) {
    return <InputVariableText multiLine={multiline} {...props} />
  }

  return <InputVariableCustomField {...props} field={field} />
}

export default InputVariable
