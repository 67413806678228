import { IAsset } from "components-old/ui/FileCard"
import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum KnowledgeBaseStatus {
    ERROR = "error",
    PENDING = "pending",
    INGESTING = "ingesting",
    READY = "ready",
}

export enum KnowledgeBaseType {
    TEXT = "text",
    PDF = "pdf",
    DOCX = "docx",
    TEXT_FILE = "text_file",
    NOTION = "notion",
    NOTION_DB = "notion_db",
}

export interface IKnowledgeBase {
    id: string
    updatedAt: string
    createdAt: string
    name: string
    type: KnowledgeBaseType
    text?: string
    asset?: IAsset
    status: KnowledgeBaseStatus
}

export const knowledgeBases: IGenericModel<IKnowledgeBase> = {
    ...generic(endpoints.KNOWLEDGE_BASE),
}
