// import wppImg from "assets/images/channels/whatsapp.svg"
// import instagramImg from "assets/icons/svg/instagram.svg"
// import customImg from "assets/images/customc.png"
import wppImg from "assets/images/channels/whatsapp.svg"
import instagramImg from "assets/images/channels/instagram.svg"
import telegramImg from "assets/images/channels/telegram.svg"
import facebookRoundImg from "assets/images/channels/facebook-round.svg"
import emailImg from "assets/images/channels/email.svg"
import customImg from "assets/images/channels/custom.svg"
import mlImg from "assets/images/channels/mercado-livre.png"
import smsImg from "assets/images/channels/sms.png"
import widgetImg from "assets/images/channels/widget.svg"
import { getHost } from "./helper"
import WhatsAppIcon from "components-old/ui/icon-channels/WhatsAppIcon"
import SmsIcon from "components-old/ui/icon-channels/SmsIcon"
import {
  BarCode01Icon,
  Calendar03Icon,
  ContactIcon,
  CursorMagicSelection01Icon,
  CursorRectangleSelection01Icon,
  DollarSquareIcon,
  Invoice02Icon,
  LeftToRightListNumberIcon,
  MailReceive02Icon,
  Message01Icon,
  SourceCodeIcon,
  TextCreationIcon,
  TextSquareIcon,
  ToggleOffIcon,
  UserMultiple02Icon,
  UserSwitchIcon,
} from "components-old/icons"

export const PRICE = {
  USER: 79,
  WHATSAPP: 249,
  INSTAGRAM: 50,
}

// export const typeFilters = {
//   SEARCH: "search",
//   TABS: "tabs",
//   PERIOD: "period",
//   MORE: "period",
//   SELECT_FREQUENCY: "select-frequency",
//   SELECT_GROUP: "select-group",
//   CUSTOM_DROPDOWN: "custom-dropdown",
// }

export enum Operators {
  Is = "is",
  IsNot = "is_not",
  IsIn = "is_in",
  IsNotIn = "is_not_in",
  LessThan = "less_than",
  GreaterThan = "greater_than",
  Contains = "contains",
  NotContains = "not_contains",
  ContainsAnyWords = "contains_any_words",
  ContainsNoneWords = "contains_none_of_words",
  ContainsString = "contains_string",
  NotContainsString = "not_contains_string",
  Empty = "empty",
  NotEmpty = "not_empty",
  StartsWith = "starts_with",
  NotStartsWith = "not_starts_with",
  EndsWith = "ends_with",
  NotEndsWith = "not_ends_with",
  After = "after",
  Before = "before",
}

export enum InputType {
  Text = "text",
  Textarea = "textarea",
  Number = "number",
  Decimal = "decimal",
  Select = "select",
  MultiSelect = "multi-select",
  Boolean = "boolean",
  Date = "date",
  JSON = "json",
  CustomNode = "custom-node",

  // Especials
  Assignee = "assignee",
  Group = "group",
  Tag = "tag",
  Pipeline = "pipeline",
}

export const PrimitiveTypeFromInputType = {
  [InputType.Text]: "string",
  [InputType.Textarea]: "string",
  [InputType.Number]: "number",
  [InputType.Decimal]: "number",
  [InputType.Select]: "string",
  [InputType.MultiSelect]: "array",
  [InputType.Boolean]: "boolean",
  [InputType.Date]: "date",
  [InputType.JSON]: "json",
}

export const InputTypeFromPrimitive = {
  string: InputType.Text,
  number: InputType.Number,
  boolean: InputType.Boolean,
  date: InputType.Date,
  json: InputType.JSON,
}

export const OperatorsByInputType = {
  [InputType.Text]: [
    Operators.Is,
    Operators.IsNot,
    Operators.ContainsString,
    Operators.NotContainsString,
    Operators.StartsWith,
    Operators.NotStartsWith,
    Operators.EndsWith,
    Operators.NotEndsWith,
    Operators.Empty,
    Operators.NotEmpty,
  ],
  [InputType.Textarea]: [
    Operators.Is,
    Operators.IsNot,
    Operators.ContainsString,
    Operators.NotContainsString,
    Operators.StartsWith,
    Operators.NotStartsWith,
    Operators.Empty,
    Operators.NotEmpty,
  ],
  [InputType.Number]: [Operators.Is, Operators.IsNot, Operators.LessThan, Operators.GreaterThan, Operators.Empty, Operators.NotEmpty],
  [InputType.Decimal]: [Operators.Is, Operators.IsNot, Operators.LessThan, Operators.GreaterThan, Operators.Empty, Operators.NotEmpty],
  [InputType.Select]: [Operators.Is, Operators.IsNot, Operators.Empty, Operators.NotEmpty],
  [InputType.MultiSelect]: [Operators.Is, Operators.IsNot, Operators.Empty, Operators.NotEmpty],
  [InputType.Boolean]: [Operators.Is, Operators.IsNot, Operators.Empty, Operators.NotEmpty],
}

export const OperatorsLabel = {
  [Operators.Is]: "é igual a",
  [Operators.IsNot]: "não é igual a",
  [Operators.IsIn]: "está dentro de",
  [Operators.IsNotIn]: "está fora de",
  [Operators.LessThan]: "é menor que",
  [Operators.GreaterThan]: "é maior que",
  [Operators.Contains]: "contém",
  [Operators.NotContains]: "não contém",
  [Operators.ContainsAnyWords]: "contém pelo menos uma das seguintes palavras",
  [Operators.ContainsNoneWords]: "não contém nenhuma das seguintes palavras",
  [Operators.ContainsString]: "contém",
  [Operators.NotContainsString]: "não contém",
  [Operators.Empty]: "é vazio",
  [Operators.NotEmpty]: "não é vazio",
  [Operators.StartsWith]: "começa com",
  [Operators.NotStartsWith]: "não começa com",
  [Operators.EndsWith]: "termina com",
  [Operators.NotEndsWith]: "não termina com",
  [Operators.After]: "depois de",
  [Operators.Before]: "antes de",
}

export const OperatorsLabelPlural = {
  [Operators.Is]: "é qualquer um dentre",
  [Operators.IsNot]: "é nenhum dentre",
}

// export const permissionsEnum = {
//   // ADMIN: 10,
//   // MASTER: 8,
//   // OPERATOR: 4
//   OWNER: 10,
//   ADMIN: 9,
//   MASTER: 6,
//   OPERATOR: 3,
//   OPERATOR_RESTRICT: 2,
// }

export const messagesTypes = {
  COMMON: 0,
  SYSTEM: 1,
  PRIVATE: 2,
  BOT: 3,
  EMAIL: 4,
  ML_PERGUNTA_RESPOSTA: 6,
}

export const channelsTypes = {
  1: {
    name: "WhatsApp",
    bgLight: "#f1fbf6",
    icon: "fab fa-whatsapp",
    svg: WhatsAppIcon,
    image: wppImg,
    path: "whatsapp",
    chartColor: "#128c7e",
    iconSvg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
          <path
            fill="currentColor"
            d="M12 2C6.477 2 2 6.477 2 12c0 1.89.525 3.66 1.438 5.168L2.546 20.2A1.01 1.01 0 0 0 3.8 21.454l3.032-.892A9.957 9.957 0 0 0 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2M9.738 14.263c2.023 2.022 3.954 2.289 4.636 2.314c1.037.038 2.047-.754 2.44-1.673a.696.696 0 0 0-.088-.703c-.548-.7-1.289-1.203-2.013-1.703a.711.711 0 0 0-.973.158l-.6.915a.229.229 0 0 1-.305.076c-.407-.233-1-.629-1.426-1.055c-.426-.426-.798-.992-1.007-1.373a.227.227 0 0 1 .067-.291l.924-.686a.712.712 0 0 0 .12-.94c-.448-.656-.97-1.49-1.727-2.043a.695.695 0 0 0-.684-.075c-.92.394-1.716 1.404-1.678 2.443c.025.682.292 2.613 2.314 4.636"
          ></path>
        </g>
      </svg>
      // <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
      //   <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      //     <path d="m3 21l1.65-3.8a9 9 0 1 1 3.4 2.9z"></path>
      //     <path d="M9 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0za5 5 0 0 0 5 5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 0 1"></path>
      //   </g>
      // </svg>
    ),
  },
  2: {
    name: "Telegram",
    icon: "fab fa-telegram-plane",
    image: telegramImg,
    // image:
    //     "https://rankingdigitalrights.org/wp-content/uploads/2017/10/512px-Telegram_logo.svg_.png",
    path: "telegram",
    chartColor: "#0088cc",
  },
  3: {
    name: "Facebook",
    icon: "fab fa-facebook",
    bgLight: "#eff5fc",
    color: "#2356b3",
    chartColor: "#6499E9",
    path: "facebook",
    image: facebookRoundImg,
  },
  // 4: {
  //     name: "Widget",
  //     icon: "",
  //     bgLight: "",
  //     color: "",
  //     path: '',
  //     image: emailImg,
  // },
  5: {
    name: "Email",
    icon: "fas fa-envelope",
    bgLight: "#eff5fc",
    color: "#2356b3",
    path: "email",
    image: emailImg,
    chartColor: "#2356b3",
  },
  6: {
    name: "Custom",
    icon: "far fa-code",
    bgLight: "#34495e",
    color: "#fff",
    // image: customImg,
    path: "custom",
    image: customImg,
    chartColor: "#8451b8",
  },
  7: {
    name: "Instagram",
    bgLight: "#f1fbf6",
    icon: "fab fa-instagram",
    // image: instagramImg,
    path: "instagram",
    image: instagramImg,
    chartColor: "#FFC7EA",
  },
  8: {
    name: "Mercado Livre",
    bgLight: "#f1fbf6",
    icon: "fab fa-instagram",
    // image: instagramImg,
    path: "mercado-livre",
    image: mlImg,
    chartColor: "#fdf202",
  },
  9: {
    name: "SMS",
    chartColor: "#eba3f9",
    bgLight: "#f1fbf6",
    icon: "fab fa-message-sms",
    // image: instagramImg,
    path: "sms",
    image: smsImg,
    svg: SmsIcon,
  },
  10: {
    name: "Widget",
    chartColor: "#eba3f9",
    bgLight: "#f1fbf6",
    icon: "fab fa-comment-dots",
    // image: instagramImg,
    path: "widget",
    image: widgetImg,
    // svg: SmsIcon,
  },
}

// export const analyticsCategory = {
//   VISITOR: "Visitante",
//   AGENT: "Agente",
// }

export const CHANNEL_TYPES = {
  WHATSAPP: "1",
  TELEGRAM: "2",
  FACEBOOK: "3",
  WIDGET: "10",
  EMAIL: "5",
  CUSTOM: "6",
  INSTAGRAM: "7",
  MERCADO_LIVRE: "8",
  SMS: "9",
}

export const WhatsappVersion = {
  "360DIALOG": 0,
  BAILEYS: 3,
  API_CLOUD: 4,
}

const { subdomain } = getHost()

export const defaultThemes = [
  {
    name: "light",
    color: "#fff",
    bg: "white",
    selectedBg: "#f7f9fa",
    selectedColor: "#2e5cdb",
    headerStyle: {
      borderBottom: "1px solid #e6e6e6",
    },
  },
  {
    name: "blue",
    color: subdomain == "mola" ? "#282167" : "#0078d4",
    bg: "white",
    selectedBg: "#f7f9fa",
    selectedColor: "#2e5cdb",
  },
  {
    name: "noir",
    // color: "#3d3d3d",
    color: "#24292f",
    bg: "white",
    selectedBg: "#f7f9fa",
    selectedColor: "#2e5cdb",
  },
  {
    name: "dark",
    color: "#2f3136",
    bg: "#36393f",
    selectedBg: "rgba(255, 255, 255, 0.1)",
    selectedColor: "#fff",
  },
  {
    name: "purple",
    color: "#444791",
    bg: "white",
    selectedBg: "#f7f9fa",
    selectedColor: "#2e5cdb",
  },
  {
    name: "port",
    color: "#405584",
    bg: "white",
    selectedBg: "#f7f9fa",
    selectedColor: "#2e5cdb",
  },
]

export enum ValidationType {
  NO_VALIDATION = "",
  CELLPHONE = "cellphone",
  EMAIL = "email",
  CPF = "cpf",
  MASK = "mask",
  REGEX = "regex",
}

// export const InputTypeIconOld = {
//   [InputType.Text]: "TextField",
//   [InputType.Textarea]: "TextBox",
//   [InputType.Number]: "NumberField",
//   [InputType.Decimal]: "NumberField",
//   [InputType.Select]: "Dropdown",
//   [InputType.MultiSelect]: "Dropdown",
//   [InputType.Boolean]: "CheckMark",
//   [InputType.Date]: "EventDate",
// }
// export const InputTypeIcon = {
//   [InputType.Text]: "fal fa-dot-circle",
//   [InputType.Textarea]: "fal fa-dot-circle",
//   [InputType.Number]: "fal fa-dot-circle",
//   [InputType.Decimal]: "fal fa-dollar-sign",
//   [InputType.Select]: "fal fa-ballot-check",
//   [InputType.MultiSelect]: "fal fa-ballot-check",
//   [InputType.Boolean]: "fal fa-check-square",
//   [InputType.Date]: "fal fa-calendar-day",
// }
export const InputTypeIconNew = {
  [InputType.Text]: TextSquareIcon,
  [InputType.Textarea]: TextCreationIcon,
  [InputType.Number]: LeftToRightListNumberIcon,
  [InputType.Decimal]: DollarSquareIcon,
  [InputType.Select]: CursorMagicSelection01Icon,
  [InputType.MultiSelect]: CursorRectangleSelection01Icon,
  [InputType.Boolean]: ToggleOffIcon,
  [InputType.Date]: Calendar03Icon,
  [InputType.JSON]: SourceCodeIcon,
}
// export const InputTypeIconLight = {
//   [InputType.Text]: "fat fa-align-left",
//   [InputType.Textarea]: "fat fa-align-left",
//   [InputType.Number]: "fat fa-align-left",
//   [InputType.Decimal]: "fat fa-dollar-sign",
//   [InputType.Select]: "fal fa-ballot-check",
//   [InputType.MultiSelect]: "fal fa-ballot-check",
//   [InputType.Boolean]: "fat fa-check-square",
//   [InputType.Date]: "fat fa-calendar-day",
// }

export const InputTypeDescription = {
  [InputType.Text]: "Texto",
  [InputType.Textarea]: "Área",
  [InputType.Number]: "Número",
  [InputType.Decimal]: "Decimal",
  [InputType.Select]: "Seleção",
  [InputType.MultiSelect]: "Multiseleção",
  [InputType.Boolean]: "Booleano",
  [InputType.Date]: "Data",
  [InputType.JSON]: "Json",
}

export const EntityIcon = {
  contact: ContactIcon,
  session: Message01Icon,
  group: UserSwitchIcon,
  member: UserMultiple02Icon,
  channel: MailReceive02Icon,
  product: BarCode01Icon,
  deal: Invoice02Icon,
}

export enum ChatPresence {
  unavailable = "unavailable",
  available = "available",
  composing = "composing",
  recording = "recording",
  paused = "paused",
}

export const avatarColors = [
  "#D2EFF3",
  "#FFEDEF",
  "#E0DDFF",
  "#F9C9B6",
  "#F4D150",
  "#FC909F",
  "#6BD9E9",
  "#FFEBA4",
  "#6be98a",
  "#c48efa",
  "#ddd8e3",
  "#d8e3e0",
  "#fac069",
]

export const optionsColors = ["#c6f8ff", "#ffe78d", "#d6fcb6", "#ffb9c3", "#e9d2f3", "#f2f3d2", "#d8edff", "#F9C9B6"]

// export const lightPalette = [
//     "#ACDDDE",
//     "#CAF1DE",
//     "#E1F8DC",
//     "#FEF8DD",
//     "#FFE7C7",
//     "#F7D8BA",
//     "#BAF7F7",
//     "#CAF1F1",
//     "#F7BABA",
//     "#F1CACA",
//     "#DEACAC",
//     "#F8DCDC",
//     "#C7FFFF",
//     "#DCF8F8",
//     "#FEDDDD",

//     // "#ACDEDE",
//     "#c2c7f2",
//     "#DDFEFE",
//     "#cec7ff",
// ]

// export const darkVividPalette = ["#","#","#","#","#","#","#","#","#","#",]
// export const greenYellowPalete = ["#ebc167", "#ebd967", "#f5ef7d", "#ffe06e", "#ffe2a6", "#ffd06e", "#ffc06e"]
// export const greenYellowPalete = ["#ffea8a", "#FFE382", "#FFC47E", "#FFAD84"]
export const greenYellowPalete = ["#FFF8D6", "#F7E1AE", "#A4D0A4", "#8bb378"]
export const greenYellowPaleteDarker = ["#e6d99e", "#edd69f", "#A4D0A4", "#8bb378"]
// export const greenYellowPalete = ["#F5F0BB", "#DBDFAA", "#B3C890", "#73A9AD"]
export const bluePalette = ["#00b4d8", "#48cae4", "#90e0ef", "#ade8f4", "#caf0f8"]
export const violetPalette = ["#f5f0bb", "#dbdfa9", "#b3c890", "#73a9ad"]
export const pinkPalette = ["#ffe0e9", "#ffc2d4", "#ff9ebb", "#ff7aa2", "#e05780"]
export const greenPalette = ["#99e2b4", "#88d4ab", "#78c6a3", "#67b99a", "#56ab91", "#469d89"]
export const greenPaletteWhatsapp = ["#B0D9B1", "#79AC78", "#96C291", "#CEDEBD"]
// export const testePalette = ["#95cbff", "#3499ea", "#d7c3ff", "#7363e0", "#ffcd1e", "#fff095"]

// TESTE
// export const yellowOrangePalette = [
//     "#ff7b00",
//     "#ff8800",
//     "#ff9500",
//     "#ffa200",
//     "#ffaa00",
//     "#ffb700",
//     "#ffc300",
//     "#ffd000",
//     "#ffdd00",
//     "#ffea00",
// ]
// export const greenPalette2 = [
//     "#52b788",
//     "#74c69d",
//     "#95d5b2",
//     "#b7e4c7",
//     "#d8f3dc",
//     "#dff5e2",
//     "#ebf5ec",
// ]

export const darkVividPalette = [
  // "#f5f3ff",
  // "#ede9fe
  "#ddd6fe",
  // "#c4b5fd",
  "#c4b1fc",
  // "#b7a0fa",
  "#a78bfa",
  // "#9d7ff5",
  "#9774fc",
  // "#6f48e0",
]

export const darkVividPaletteGreen = ["#9be9a8", "#40c463", "#30a14e", "#216e39"]

export const tailwindColors300 = [
  "#cbd5e1",
  "#d1d5db",
  "#d4d4d8",
  "#d4d4d4",
  "#d6d3d1",
  "#fca5a5",
  "#fdba74",
  "#e9d07e",
  // "#fde047",
  "#bfe37f",
  "#90e3ae",
  "#7bdfb7",
  "#7adbcc",
  "#89d7e1",
  "#7dd3fc",
  "#93c5fd",
  "#a5b4fc",
  "#c4b5fd",
  "#d8b4fe",
  "#e0b3e7",
  "#f9a8d4",
  "#fda4af",
  "#dda2a2",
]

export const lightPalette = darkVividPalette
export const defaultPalette = tailwindColors300

// export const darkVividPalette = [
//     "#28ced1",
//     "#72dba9",
//     "#59c942",
//     "#fab905",
//     "#f5d533",
//     "#f27844",
//     "#fdb689",
//     "#34e0e0",
//     "#ff7161",
//     "#de498f",
//     "#ff7f7f",
//     "#ff7676",
//     "#44dbb0",
//     "#95dbdb",
//     "#6dc3e3",
//     "#51a3d6",
//     "#bdb6ff",
// ]

export const WHATSAPP_CLOUD_API_WEBHOOK = "https://api.kinbox.com.br/api/wba-hook/cloud"

export const reportsColors = {
  TME: "#ff6b6b",
  TMP: "#fc8803",
  TMA: "#feca57",
  NEW_SESSION: "#10ac84",
  FINISHED_SESSION: "#fed330",
}

// ██████╗ ██████╗ ██╗██╗   ██╗ █████╗  ██████╗██╗   ██╗
// ██╔══██╗██╔══██╗██║██║   ██║██╔══██╗██╔════╝╚██╗ ██╔╝
// ██████╔╝██████╔╝██║██║   ██║███████║██║      ╚████╔╝
// ██╔═══╝ ██╔══██╗██║╚██╗ ██╔╝██╔══██║██║       ╚██╔╝
// ██║     ██║  ██║██║ ╚████╔╝ ██║  ██║╚██████╗   ██║
// ╚═╝     ╚═╝  ╚═╝╚═╝  ╚═══╝  ╚═╝  ╚═╝ ╚═════╝   ╚═╝
//

export enum Privacy {
  PRIVATE = "private",
  PUBLIC = "public",
  GROUP = "group",
  USER = "user",
}

// export const PrivacyLabel = {
//   [Privacy.PRIVATE]: "Private",
//   [Privacy.GROUP]: "Group",
//   [Privacy.PUBLIC]: "Public",
//   [Privacy.USER]: "User",
// }

// export const PrivacyIcon = {
//   [Privacy.PRIVATE]: "fal fa-lock",
//   [Privacy.GROUP]: "fal fa-users",
//   [Privacy.PUBLIC]: "fal fa-globe",
//   [Privacy.USER]: "fal fa-user",
// }

// export const safeCharsToShortId = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
