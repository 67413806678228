import {
  ContactIcon,
  Mail02Icon,
  SmartPhone01Icon,
  StickyNote02Icon,
  UserAdd01Icon,
  UserEdit01Icon,
  UserSearch01Icon,
} from "components-old/icons"
import { CategoryEnum, VariableEnum, ActionEnum } from "../../workflow.enums"
import { getDescriptionFormatted } from "../../workflow.helper"
import CreateContactNode from "./create-contact/CreateContact.node"
import CreateContactAction from "./create-contact/CreateContact.action"
import UpdateContactNode from "./update-contact/UpdateContact.node"
import UpdateContactAction from "./update-contact/UpdateContact.action"
import { InputType } from "lib/constants"
import { WorkflowEntityDef } from "../../workflow.defs"
import FindContactNode from "./find-contact/FindContact.node"
import FindContactAction from "./find-contact/FindContact.action"

export const ContactWorkflowDefs: WorkflowEntityDef = {
  /*********************************
   *  Category
   **********************************/
  category: {
    type: CategoryEnum.CONTACT,
    label: "Contato",
    icon: ContactIcon,
    palette: "pink",
  },
  /*********************************
   *  Fields
   **********************************/
  fields: [
    {
      label: "ID",
      icon: ContactIcon,
      type: InputType.Text,
      name: "id",
      selectable: false,
    },
    {
      label: "Nome",
      icon: ContactIcon,
      type: InputType.Text,
      name: "name",
    },
    {
      label: "Celular",
      icon: SmartPhone01Icon,
      type: InputType.Text,
      name: "phone",
    },
    {
      label: "E-mail",
      icon: Mail02Icon,
      type: InputType.Text,
      name: "email",
    },
    {
      label: "Notas",
      icon: StickyNote02Icon,
      type: InputType.Textarea,
      name: "notes",
    },
  ],
  /*********************************
   *  Actions
   **********************************/
  actions: [
    {
      type: ActionEnum.CONTACT_FIND,
      name: "Buscar contato",
      icon: UserSearch01Icon,
      variableCategory: CategoryEnum.CONTACT,
      isReferenceable: true,
      node: FindContactNode,
      edit: FindContactAction,
      getDescription: (dto) => {
        const contact = dto.actionLog?.context?.contact?.id

        if (!contact) return "Contato não encontrado."

        return getDescriptionFormatted({
          preffix: "O contato ",
          suffix: "foi encontrado.",
          avatar: dto.actionLog?.context?.contact?.avatar || "",
          name: dto.actionLog?.context?.contact?.name,
          palette: dto.category.palette,
        })
      },
    },
    {
      type: ActionEnum.CONTACT_UPDATE,
      name: "Atualizar contato",
      icon: UserEdit01Icon,
      variableCategory: CategoryEnum.CONTACT,
      node: UpdateContactNode,
      edit: UpdateContactAction,
      getDescription: (dto) => {
        return getDescriptionFormatted({
          preffix: "O contato ",
          suffix: "foi atualizado.",
          avatar: dto.actionLog?.context?.contact?.avatar || "",
          name: dto.actionLog?.context?.contact?.name,
          palette: dto.category.palette,
        })
      },
    },
    {
      type: ActionEnum.CONTACT_CREATE,
      name: "Criar contato",
      icon: UserAdd01Icon,
      variableCategory: CategoryEnum.CONTACT,
      isReferenceable: true,
      node: CreateContactNode,
      edit: CreateContactAction,
      getDescription: (dto) => {
        const wasCreated = new Date(dto.actionLog?.context?.contact?.createdAt) > new Date(dto.executionLog.startedAt)
        return getDescriptionFormatted({
          preffix: "O contato ",
          suffix: wasCreated ? "foi criado." : "foi encontrado.",
          avatar: dto.actionLog?.context?.contact?.avatar || "",
          name: dto.actionLog?.context?.contact?.name,
          palette: dto.category.palette,
        })
      },
    },
  ],
  /*********************************
   *  Variables
   **********************************/
  variables: [
    {
      type: VariableEnum.CONTACT_JSON,
      category: CategoryEnum.CONTACT,
      label: "$json",
      primitiveType: "json",
      description: "Contato em formato JSON.",
      json: {
        name: "string",
        email: "string",
        phone: "string",
        origin: "string",
        notes: "string",
        avatar: "string",
        externalId: "string",
        createdAt: "date",
        isPrivate: "boolean",
        newContact: "boolean",
        isImported: "boolean",
        hasConversation: "boolean",
        crmData: {
          referrals: [
            {
              utm_campaign: "string",
              utm_source: "string",
              utm_medium: "string",
              utm_term: "string",
              utm_content: "string",
              utm_campaign_name: "string",
              utm_ad_name: "string",
              gad_source: "string",
              gclid: "string",
              origin: "string",
              utm_ad_collection: "string",
              utm_ad: "string",
              headline: "string",
              ctwa_clid: "string",
              image_url: "string",
              video_url: "string",
              source_id: "string",
              media_type: "string",
              source_url: "string",
              thumbnail_url: "string",
              source_type: "ad",
              body: "string",
              fbclid: "string",
            },
          ],
          // utmCampaigns: [
          //   {
          //     utm_campaign: "string",
          //     utm_source: "string",
          //     utm_medium: "string",
          //     utm_term: "string",
          //     utm_content: "string",
          //     origin: "string",
          //   },
          // ],
        },
        customFields: {},
      },
    },
    {
      type: VariableEnum.CONTACT_ID,
      category: CategoryEnum.CONTACT,
      label: "ID",
      primitiveType: "string",
      description: "Identificador único do contato.",
    },
    {
      type: VariableEnum.CONTACT_NAME,
      category: CategoryEnum.CONTACT,
      label: "Nome",
      primitiveType: "string",
      description: "Nome do contato.",
    },
    {
      type: VariableEnum.CONTACT_PHONE,
      category: CategoryEnum.CONTACT,
      label: "Telefone",
      primitiveType: "string",
      description: "Telefone do contato.",
    },
    {
      type: VariableEnum.CONTACT_EMAIL,
      category: CategoryEnum.CONTACT,
      label: "E-mail",
      primitiveType: "string",
      description: "E-mail do contato.",
    },
    {
      type: VariableEnum.CONTACT_ORIGIN,
      category: CategoryEnum.CONTACT,
      label: "Origem",
      primitiveType: "string",
      description: "Origem do contato.",
    },
    {
      type: VariableEnum.CONTACT_CREATED_AT,
      category: CategoryEnum.CONTACT,
      label: "Criado em",
      primitiveType: "string",
      description: "Data de criação do contato.",
    },
    {
      type: VariableEnum.CONTACT_EXTERNAL_ID,
      category: CategoryEnum.CONTACT,
      label: "ID Externo",
      primitiveType: "string",
      description: "ID Externo do contato.",
    },
    {
      type: VariableEnum.CONTACT_HAS_CONVERSATION,
      category: CategoryEnum.CONTACT,
      label: "Tem conversas",
      primitiveType: "boolean",
      description: "Se contato tem conversa ou não.",
    },
    {
      type: VariableEnum.CONTACT_IS_IMPORTED,
      category: CategoryEnum.CONTACT,
      label: "Foi importado",
      primitiveType: "boolean",
      description: "Se contato foi criado a partir de uma importação.",
    },
    {
      type: VariableEnum.CONTACT_IS_NEW,
      category: CategoryEnum.CONTACT,
      label: "É novo",
      primitiveType: "boolean",
      description: "Se contato só tem uma sessão de conversa associada a ele.",
    },
    {
      type: VariableEnum.CONTACT_IS_PRIVATE,
      category: CategoryEnum.CONTACT,
      label: "É privado",
      primitiveType: "boolean",
      description: "Se contato é privado.",
    },
    {
      type: VariableEnum.CONTACT_NOTES,
      category: CategoryEnum.CONTACT,
      label: "Notas",
      primitiveType: "string",
      description: "Notas do contato.",
    },
    // {
    //   type: VariableEnum.CONTACT_UTM_CAMPAIGNS,
    //   category: CategoryEnum.CONTACT,
    //   label: "Google Ads",
    //   primitiveType: "json",
    //   description: "Campanhas do google ads associadas ao contato",
    // },
  ],
}
