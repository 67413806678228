import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface Product extends GenericResource {
  name: string
  description: string
  price?: number
}

export const products: IGenericModel<Product> = {
  ...generic(endpoints.PRODUCTS),
}
