import { atom } from "recoil"

export interface EventWorkspace {
  users: Record<
    number,
    {
      viewingConversationIds: Record<string, number>
      userId: number
      writing: {
        conversationId: number
        type: "text" | "audio"
        refresher: boolean
      }
    }
  >
}

export interface EventUserWriting {
  userId: number
  conversationId: number
  type: "text" | "audio"
}

export interface WorkspaceState {
  conversations: Record<
    number,
    {
      viewingUserIds: Record<number, boolean>
    }
  >
  users: EventWorkspace["users"]
}

export const workspaceStateAtom = atom<WorkspaceState | null>({
  key: "workspaceStateAtom",
  default: null,
})

export const writingAtom = atom<EventUserWriting | null | undefined>({
  key: "writingAtom",
  default: null,
})
