import React from "react"

function Empty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" id="svg2" width="2666.6667" height="2666.6667" viewBox="0 0 2666.6667 2666.6667" {...props}>
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath22">
          <path
            d="m 17031.7,10000 c 0,-3883.5 -3148.2,-7031.7 -7031.7,-7031.7 -3883.5,0 -7031.7,3148.2 -7031.7,7031.7 0,3883.5 3148.2,7031.7 7031.7,7031.7 3883.5,0 7031.7,-3148.2 7031.7,-7031.7 z"
            id="path20"
          />
        </clipPath>
      </defs>
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,2666.6667)">
        <g id="g10" transform="scale(0.1)">
          <path
            d="m 17031.7,10000 c 0,-3883.5 -3148.2,-7031.7 -7031.7,-7031.7 -3883.5,0 -7031.7,3148.2 -7031.7,7031.7 0,3883.5 3148.2,7031.7 7031.7,7031.7 3883.5,0 7031.7,-3148.2 7031.7,-7031.7"
            style={{
              fill: "#eaf2fd",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path14"
            className="empty_color_1"
          />
          <g id="g16">
            <g id="g18" clipPath="url(#clipPath22)">
              <path
                d="M 15212,9999.9 V 2926.6 l -5210.8,-499.7 -0.1,7073.5 v 999.3 L 15212,9999.9"
                style={{
                  fill: "#3d77d3",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path24"
                className="empty_color_2"
              />
              <path
                d="m 10001.1,10499.7 v -999.3 l 0.1,-7073.5 -5210.86,499.7 v 7073.3 l 5210.76,499.8"
                style={{
                  fill: "#3d77d3",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path26"
                className="empty_color_2"
              />
              <path
                d="M 15212,10000 V 2926.6 l -1760,-168.8 -114.6,-10.9 -287.6,-27.7 -261.9,-25.1 -78.4,-7.5 -546,-52.3 -2162.3,-207.4 V 9500.4 L 15212,10000"
                style={{
                  fill: "#3d77d3",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path28"
                className="empty_color_2"
              />
              <path
                d="m 10431,7296.3 5221,525.8 -432.2,2182.7 L 9998.83,9479 10431,7296.3"
                style={{
                  fill: "#669ef4",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path30"
                className="empty_color_3"
              />
              <path
                d="m 9740.95,13629 c -267.71,-116.2 -505.92,-229.7 -713.79,-340 -808.03,-428.8 -907.98,-682.6 -913.44,-771.8 -4.27,-69.6 40.82,-126.1 133.99,-168.1 184.45,-83.1 509.97,-89.3 849.52,-16.2 385.87,83.1 719.47,250 939.37,469.8 146.6,146.6 192,295 135,441.1 -55.6,142.6 -207.93,278.6 -430.65,385.2 z M 8648.61,12169.7 c -178.19,0 -334.81,25.6 -447.34,76.3 -136.44,61.5 -207.6,160.3 -200.38,278.1 14.63,238.8 342.09,529.8 973.28,864.7 184.63,98 392.69,198.3 623.85,300.9 -225.73,84.7 -492.24,136.6 -763.83,147.9 -934.07,38.9 -1704.66,-373.3 -2061.53,-1102.5 -13.72,-28 -47.55,-39.7 -75.61,-25.9 -28.04,13.7 -39.64,47.5 -25.92,75.6 204.85,418.6 524.63,732.8 950.47,934 359.35,169.8 780.25,250 1217.29,231.8 326.11,-13.6 646.29,-83.8 903.57,-197.8 693.64,298.4 1578.14,614.8 2642.14,945 29.8,9.3 61.5,-7.4 70.8,-37.2 9.2,-29.8 -7.5,-61.5 -37.3,-70.7 -1015.9,-315.3 -1866.7,-617.6 -2542.57,-903.2 202.67,-112.8 343.27,-252.7 401.37,-401.8 51.4,-131.8 65.4,-336.3 -160.4,-562.1 -235.06,-235 -588.59,-412.8 -995.47,-500.4 -163.64,-35.2 -325.39,-52.7 -472.42,-52.7"
                style={{
                  fill: "#cccccc",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path32"
              />
              <path
                d="m 8233.1,9219.1 c -8.86,0 -17.86,2.1 -26.25,6.5 -22.8,12 -563.91,299.6 -1050.39,823.9 -287.09,309.5 -494.03,636.8 -615.06,973 -152.07,422.4 -168.14,859.1 -47.74,1297.9 8.26,30 39.36,47.8 69.46,39.5 30.1,-8.3 47.81,-39.4 39.55,-69.5 -207.43,-755.9 6.08,-1483.2 634.57,-2161.7 473.26,-511 1016.8,-800.2 1022.23,-803 27.61,-14.6 38.23,-48.8 23.69,-76.4 -10.11,-19.2 -29.74,-30.2 -50.06,-30.2"
                style={{
                  fill: "#cccccc",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path34"
              />
              <path
                d="M 10001.2,9500.4 V 2426.9 l -2308.66,221.5 -598.83,57.3 -2303.37,220.9 V 10000 l 4853.63,-465.3 357.23,-34.3"
                style={{
                  fill: "#548ae7",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path36"
                className="empty_color_6"
              />
              <path
                d="M 4790.34,7777.5 9998.83,6737.6 10001.2,9479 Z"
                style={{
                  fill: "#3d77d3",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path38"
                className="empty_color_2"
              />
              <path
                d="M 9568.98,7296.3 4347.97,7822.1 4780.15,10004.8 10001.2,9479 9568.98,7296.3"
                style={{
                  fill: "#669ef4",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                id="path40"
                className="empty_color_3"
              />
            </g>
          </g>
          <path
            d="m 14343.7,15391.7 c -109.7,-90.8 -355.4,-3 -548.8,196.1 -193.4,199 -261.3,434 -151.6,524.9 109.7,90.8 355.4,3 548.8,-196 193.4,-199.1 261.3,-434.1 151.6,-525"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path42"
            className="empty_color_4"
          />
          <path
            d="m 13904,15170.3 c -60.5,137.5 86.8,332.1 328.9,434.6 242.1,102.5 487.4,74.1 547.8,-63.4 60.4,-137.5 -86.9,-332.1 -329,-434.6 -242.1,-102.5 -487.3,-74.1 -547.7,63.4"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path44"
            className="empty_color_4"
          />
          <path
            d="m 14364.1,15362.7 c -142.6,-23.4 -287.2,176.9 -323,447.3 -35.8,270.4 50.9,508.7 193.5,532 142.6,23.4 287.2,-176.8 323,-447.3 35.7,-270.4 -50.9,-508.6 -193.5,-532"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path46"
            className="empty_color_4"
          />
          <path
            d="m 14825.4,15549.6 c -7.7,-49.6 -65,-85.8 -128.1,-81 -63.1,4.9 -108,49 -100.3,98.6 7.7,49.6 65.1,85.9 128.1,81.1 63.1,-4.9 108,-49.1 100.3,-98.7"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path48"
            className="empty_color_4"
          />
          <path
            d="m 14273.3,14924.8 c -4.2,0.3 -8,2.6 -9.9,6.3 -2.9,5.8 0,12.9 6.5,15.9 153.7,70.8 320,447.1 330.9,479.9 2.1,6.3 9.3,10.1 16,8.5 6.8,-1.5 10.6,-7.9 8.5,-14.2 -1.3,-3.9 -168.2,-413.7 -345,-495.1 -2.3,-1.1 -4.7,-1.5 -7,-1.3"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path50"
            className="empty_color_4"
          />
          <path
            d="m 14028.3,14959.8 c -3.7,2.1 -6.1,5.8 -6.1,10 -0.1,6.5 5.6,11.6 12.8,11.4 169.1,-3.9 483.8,261.2 508,285.8 4.6,4.8 12.8,5.1 18.2,0.7 5.3,-4.3 5.9,-11.7 1.3,-16.5 -2.9,-3 -332.8,-297.8 -527.4,-293.3 -2.5,0 -4.8,0.7 -6.8,1.9"
            style={{
              fill: "#808080",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path52"
            className="empty_color_4"
          />
          <path
            d="m 12824.7,14716.5 c -24.3,0 -46.8,15.8 -54.2,40.3 -8.9,29.9 8,61.4 37.9,70.4 166.7,50.1 339.9,101.2 514.8,151.9 29.9,8.6 61.3,-8.6 70,-38.6 8.7,-30 -8.6,-61.3 -38.6,-70 -174.5,-50.6 -347.3,-101.6 -513.7,-151.6 -5.4,-1.6 -10.9,-2.4 -16.2,-2.4"
            style={{
              fill: "#cccccc",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path54"
            className="empty_color_5"
          />
        </g>
      </g>
    </svg>
  )
}

export default Empty
