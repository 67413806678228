import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IWorkspace } from "./workspaces"

export interface IUser {
    id: string
    email: string
    name: string
    avatar: string
    // isActive: boolean
    verificationLevel: 1 | 2
    phone: string
    status: "online" | "offline"
    workspaces: IWorkspace[]
}

export const users: IGenericModel<IUser> = {
    ...generic(endpoints.ADMIN_USERS)
}