import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Input } from "antd"
import styles from "./style.module.css"
import { ArrowRight01Icon } from "components-old/icons"
import { twMerge } from "tailwind-merge"
import { colors } from "lib/colors"
import { useRecoilState, useSetRecoilState } from "recoil"
import { editWorkflowNodeIdAtom, quickViewWorkflowNodeIdAtom, workflowVariablesAtom } from "atoms/workflow.atom"
import VariableTag from "./VariableExtension/VariableTag"
import { saveQuickViewPreviousPosition } from "pages/settings/workflows/WorkflowCanva"
import { modalState, themeAtom, variableAtom } from "atoms/app.atom"
import JsonPathModal from "./JsonPathModal"
import { VariableEnum } from "pages/settings/workflows/workflow.enums"
import { VariableItem, VariableMenuOption, WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface SelectVariableDto {
  type: VariableEnum
  reference?: string
  customFieldKey?: string
  jsonPath?: string
  fallback?: string
}

const VariableSectionItem = memo(
  (props: {
    option: VariableMenuOption
    selectVariable: (dto: SelectVariableDto) => void
    searchValue?: string
    index: number
    selectedIndex: number
  }) => {
    const setQuickViewNodeId = useSetRecoilState(quickViewWorkflowNodeIdAtom)
    const [theme] = useRecoilState(themeAtom)
    const [_collapsed, setCollapsed] = useState(true)
    // const collapsible = (props.option?.variables?.length || 0) > 1
    const collapsible = true

    const collapsed = _collapsed && !props.searchValue

    // const [type, reference] = variable?.type?.split("#")

    return (
      <div className={twMerge(collapsible && "w-full")}>
        <button
          key={props.option?.type}
          onClick={() => {
            if (collapsible) {
              setCollapsed(!collapsed)
            } else {
              props.selectVariable({
                type: props.option?.variables?.[0]?.type!,
                reference: props.option?.reference,
                // customFieldKey: props.option?.variables?.[0]?.customFieldKey,
                jsonPath: "",
              })
            }
          }}
          className={twMerge(
            // props.index === props.selectedIndex && "bg-base-200",
            "flex items-center gap-2 hover:bg-base-300 rounded-lg w-full h-9 px-2 justify-between"
          )}
          onMouseEnter={() => {
            if (props.option.reference) {
              setQuickViewNodeId(props.option.reference)
            }
          }}
          onMouseLeave={() => {
            if (props.option.reference) {
              setQuickViewNodeId(undefined)
            }
          }}
        >
          <div className="flex items-center gap-3 truncate">
            {/* <IconTag
            palette={props.option?.palette}
            lightTone={{
              bg: "var(--base-100)",
            }}
            icon={props.option?.icon || CodeIcon}
          /> */}
            <props.option.icon
              className={twMerge("w-[18px] h-[18px] shrink-0")}
              style={{
                color: colors?.[props.option.palette]?.[theme === "dark" ? 500 : 600],
              }}
            />
            <span className="truncate">{props.option.label}</span>
            {props.option?.reference && <span className="text-content-300 text-2xs">#{props.option?.reference}</span>}
          </div>
          <ArrowRight01Icon
            className={twMerge("w-3.5 h-3.5 transition-transform duration-[0.15s]", !collapsed && "rotate-90 origin-center")}
          />
          {/* <div className="rounded-lg flex items-center gap-1">
          <ArrowRight01Icon
            className={twMerge("w-3.5 h-3.5 transition-transform duration-[0.15s]", collapsed && "rotate-90 origin-center")}
          />
          {props.option.label}
        </div> */}
        </button>
        {!collapsed && collapsible && (
          <div className="flex flex-wrap gap-1 px-2 mb-2 mx-2 mt-1 bordered">
            {props.option?.variables?.map((variable, index) => (
              <button
                key={variable?.type}
                onClick={() => props.selectVariable({ type: variable?.type!, reference: props.option.reference })}
              >
                <VariableTag type={variable?.type} reference={props.option?.reference} />
                {/* <TagItem
                colors={{
                  ...tagColors.amber,
                }}
                className="variable-selectable !text-content-100 text-2xs"
                capitalize={false}
              >
                {variable?.label}
              </TagItem> */}
              </button>
            ))}
          </div>
        )}
      </div>
    )
  }
)

const VariableSection = (props: {
  section: {
    label: string
    options: VariableMenuOption[]
  }
  searchValue?: string
  index: number
  selectedIndex: number
  // variables: VariableItem[]
  selectVariable: (dto: SelectVariableDto) => void
}) => {
  const hasVariables = props.section.options.some((option) => (option?.variables?.length || 0) > 0)

  if (!hasVariables) {
    return null
  }

  return (
    <div>
      <div className="w-full rounded my-2 h-4 flex items-center">
        <p className="mx-2.5 text-3xs font-bold uppercase">{props.section.label}</p>
      </div>
      <div className="">
        {props.section.options.map((option, index) => {
          return (
            <VariableSectionItem
              key={option?.type + option.reference}
              option={option}
              selectVariable={props.selectVariable}
              searchValue={props.searchValue}
              index={props.index + index}
              selectedIndex={props.selectedIndex}
            />
          )
          // const collapsible = (category?.variables?.length || 0) > 1
          // return (
          //   <div className={twMerge(collapsible && "w-full")}>
          //     <button
          //       key={category?.type}
          //       onClick={() => {
          //         if (collapsible) {
          //           setCollapsed(!collapsed)
          //         } else {
          //           props.selectVariable(category?.variables?.[0]?.type!)
          //         }
          //       }}
          //       className={twMerge("flex items-center gap-2 rounded-lg justify-between")}
          //     >
          //       <TagItem
          //         colors={{
          //           ...tagColors.amber,
          //         }}
          //         className="variable-selectable !text-content-100 flex gap-1 items-center"
          //       >
          //         <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
          //           <path
          //             fill="currentColor"
          //             d="m213.66 101.66l-80 80a8 8 0 0 1-11.32 0l-80-80A8 8 0 0 1 48 88h160a8 8 0 0 1 5.66 13.66"
          //           ></path>
          //         </svg>
          //         {category.label}
          //         {/* {<ArrowRight01Icon className="w-3 h-3" />} ... */}
          //       </TagItem>
          //     </button>
          //     {collapsed && collapsible && (
          //       <div className="flex flex-wrap gap-2 px-2 mb-2">
          //         {category?.variables?.map((variable, index) => (
          //           <button key={variable?.type} onClick={() => props.selectVariable(variable?.type!)}>
          //             <TagItem
          //               colors={{
          //                 ...tagColors.amber,
          //               }}
          //               className="variable-selectable !text-content-100"
          //             >
          //               {variable?.label}
          //             </TagItem>
          //           </button>
          //         ))}
          //       </div>
          //     )}
          //   </div>
          // )
        })}
        {/* {props.section.options.map((category, index) => (
            <button
              key={category?.type}
              onClick={() => props.selectVariable(index)}
              className="flex items-center gap-2 hover:bg-base-300 rounded-lg w-full h-9 px-2 justify-between"
            >
              <div className="flex items-center gap-3">
                <IconTag
                  palette={category?.palette}
                  icon={category?.icon || CodeIcon}
                  lightTone={{
                    bg: 200,
                    text: 950,
                  }}
                />
                {category.label}
              </div>
            </button>
          ))} */}
        {/*  */}
        {/* <button key={variable?.id} onClick={() => props.selectItem(index)}>
             <TagItem
              colors={{
                ...tagColors.amber,
              }}
              className="variable-selectable !text-content-100"
            >
              {variable?.label}
            </TagItem>
           </button> */}
      </div>
    </div>
  )
}

export default forwardRef(
  (
    props: {
      // items: VariableItem[]
      command?: any
      hide: () => void
      children?: React.ReactNode
    },
    ref
  ) => {
    const [variablesContext] = useRecoilState(workflowVariablesAtom)
    const [editNodeId, setEditNodeId] = useRecoilState(editWorkflowNodeIdAtom)
    // const [elements] = useRecoilState(workflowElementsAtom)
    const [selectedIndex, setSelectedIndex] = useState(0)
    // const [tabSelected, setTabSelected] = useState<"context" | "workflow">("context")
    const [searchValue, setSearchValue] = useState("")
    const searchRef = useRef<any>()
    const setModal = useSetRecoilState(modalState)
    const [dynamicVariablesDef] = useRecoilState(variableAtom)

    useEffect(() => {
      saveQuickViewPreviousPosition()
    }, [])

    useEffect(() => {
      setTimeout(() => {
        searchRef?.current?.focus()
      }, 50)
    }, [])

    const sectionsMounted = useMemo(() => {
      const variablesContextFiltered = variablesContext?.filter((context) => context.nodeId !== editNodeId)
      const hasVariableContext = variablesContextFiltered?.length > 0

      const { sections, totalOptionsCount } = hasVariableContext
        ? WorkflowDefs.mountVariableMenuOptionSections({
            variablesContext: variablesContextFiltered,
            categoryWithVariablesAndCustomFieldsRecord: dynamicVariablesDef.categoryWithVariablesRecord,
          } as any)
        : WorkflowDefs.variableMenuOptionSections

      return {
        sections,
        totalOptionsCount,
      }
    }, [dynamicVariablesDef.categoryWithVariablesRecord, editNodeId, variablesContext])

    const { sections, totalOptionsCount } = useMemo(() => {
      return {
        sections: searchValue
          ? sectionsMounted?.sections?.map((section) => {
              return {
                ...section,
                options: section.options
                  .map((option) => {
                    const showSection =
                      option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
                      ("#" + option.reference)?.toLowerCase().includes(searchValue.toLowerCase())

                    if (showSection) {
                      return option
                    }

                    const variables: VariableItem[] =
                      option.variables?.filter((variable) => {
                        return variable.label.toLowerCase().includes(searchValue.toLowerCase())
                      }) || []

                    if (variables.length > 0) {
                      return {
                        ...option,
                        variables,
                      }
                    }

                    return null
                  })
                  .filter((x) => !!x) as VariableMenuOption[],
                // options: section.options.filter((option) => {
                //   const showSection =
                //     option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
                //     ("#" + option.reference)?.toLowerCase().includes(searchValue.toLowerCase())

                //   if (showSection) {
                //     return true
                //   }
                // }),
              }
            })
          : sectionsMounted?.sections,
        totalOptionsCount: sectionsMounted?.totalOptionsCount || 0,
      }
    }, [sectionsMounted, searchValue])

    const selectItem = (dto: SelectVariableDto) => {
      // const [type, reference] = typeWithReference.split("#")
      // let { type, fallback, reference, jsonPath } = splitVariableString(variableStringRaw)

      // const item = props.items?.find((x) => x.type === type)

      // console.log({ type, fallback, reference, jsonPath })

      // if (item) {

      // const variableRecord = WorkflowDefs.VariableRecord[dto.type]
      const variableRecord = dynamicVariablesDef.variablesRecord[dto.type]

      if (variableRecord?.primitiveType === "json") {
        props.hide()
        setModal(
          <JsonPathModal
            onBack={() => {
              setModal(null)
            }}
            onSubmit={(jsonPath) => {
              props.command?.({ id: dto.type, label: "", reference: dto.reference, fallback: dto.fallback, jsonPath })
              setModal(null)
            }}
            type={dto.type}
          />
        )
      } else {
        props.hide()
        props.command?.({ id: dto.type, label: "", reference: dto.reference, fallback: dto.fallback, jsonPath: dto.jsonPath })
      }

      // }
    }

    const upHandler = () => {
      setSelectedIndex((selectedIndex + totalOptionsCount - 1) % totalOptionsCount)
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % totalOptionsCount)
    }

    const enterHandler = () => {
      // selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [sections])

    // On ESC key press, close dropdown
    // useEffect(() => {
    //   if (visible) {
    //     window.removeEventListener("keydown", handleEsc)
    //     window.addEventListener("keydown", handleEsc)
    //   }
    //   return () => {
    //     window.removeEventListener("keydown", handleEsc)
    //   }
    // }, [handleEsc, visible])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === "ArrowUp") {
          upHandler()
          return true
        }

        if (event.key === "ArrowDown") {
          downHandler()
          return true
        }

        if (event.key === "Enter") {
          enterHandler()
          return true
        }

        if (event.key === "Escape") {
          event?.stopPropagation?.()
          return true
        }

        return false
      },
    }))

    return (
      <div
        className="w-[300px] rounded-xl bg-base-100 border border-solid border-divider-100"
        style={{
          boxShadow: "var(--elevation)",
        }}
      >
        <>
          <div className="flex justify-center text-center font-medium text-sm w-full pt-3">Variáveis</div>
          {/* <div
          className="flex items-center px-3 shadow h-10 rounded-t-lg"
          // style={{
          //   borderBottom: "1px solid var(--divider)",
          // }}
        >
          {tabs?.map((tab, index) => {
            return (
              <button
                className={twMerge(
                  styles.variable_tab,
                  "w-10 h-10 text-content-300 relative hover:text-primary rounded-t-lg",
                  tabSelected === tab.key && !searchValue && "active2 text-primary bg-base-100 group"
                )}
                key={index}
                onClick={() => setTabSelected(tab.key as any)}
              >
                {<tab.icon className={twMerge("w-5 h-5 [&>*]:!stroke-2 mt-0.5", tabSelected === tab.key && "[&>*]:!stroke-[2.5]")} />}
              </button>
            )
          })}
        </div> */}

          <div className="p-1">
            <div className="p-2 pb-0">
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target?.value)}
                placeholder="Pesquisar"
                // autoFocus
                size="small"
                className={styles.search_input}
                ref={searchRef}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    props.hide()

                    return true
                  }
                }}
              />
            </div>
            <div className="max-h-[350px] min-h-[60px] pretty-scroll pretty-scroll-hoverable overflow-y-auto">
              {sections?.map((section, index) => {
                return (
                  <VariableSection
                    key={section?.label}
                    section={section}
                    searchValue={searchValue}
                    selectVariable={selectItem}
                    index={index}
                    selectedIndex={selectedIndex}
                  />
                )
              })}
            </div>
            {/* <p className="mt-2 mb-4 text-sm">{searchValue ? "Resultado da busca:" : selectedTab?.label}</p> */}

            {/* <div className="flex gap-2 flex-wrap">
            {filteredItems.map((variable, index) => (
              <button key={variable?.id} onClick={() => selectItem(index)}>
                <TagItem
                  colors={{
                    ...tagColors.amber,
                  }}
                  className="variable-selectable !text-content-100"
                >
                  {variable?.label}
                </TagItem>
              </button>
            ))}
          </div> */}
          </div>
        </>
      </div>
    )
  }
)
