import { Button, Form, InputNumber, Radio, Select } from "antd"
import { FormInstance } from "antd/es/form"
import Tabs from "components-old/Tabs"
import Icon from "components-old/common/Icon"
import { LabelHelp } from "components-old/forms/LabelHelp"
import AgentsSelect from "components-old/ui/AgentsSelect"
import GroupsSelect from "components-old/ui/GroupsSelect"
import React from "react"
import { twMerge } from "tailwind-merge"

interface RoundRobinGroupsListProps {
  fieldName: string[]
}

function RoundRobinGroupsList(props: RoundRobinGroupsListProps) {
  const listClass = "flex gap-2 items-center flex-nowrap"
  const itemClass = "w-[90px] min-w-[90px] text-end truncate"

  return (
    <>
      <div className={twMerge(listClass, "mb-3 text-[13px]")}>
        <strong className="flex-1 w-full">Grupos participantes</strong>
        <strong className={itemClass}>
          <LabelHelp
            help={
              <div className="space-y-2">
                <p>Máximo de outras conversas abertas que cada agente do grupo pode ter para ser elegível a receber essa sessão.</p>
                {/* <p>Deixe 0 ou vazio para ilimitado.</p> */}
                {/* <p className="text-yellow-400 text-xs">Somente considera conversas que foram atribuídas por essa ação.</p> */}
                <p className="text-yellow-400 text-xs">Deixe 0 ou vazio para ilimitado.</p>
              </div>
            }
          >
            Capacidade
          </LabelHelp>
        </strong>
        <strong className="w-6 min-w-[24px]"></strong>
      </div>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFields, setFieldsValue, getFieldsValue }) => {
          return (
            <Form.List name={[...props.fieldName, "groups"]}>
              {(fields, { add, remove, move }) => {
                return (
                  <div className="">
                    {fields?.map((field, i) => (
                      <div key={field.key} className={twMerge(listClass, "mb-1.5")}>
                        {/* GROUP */}
                        <div className="flex-1 w-full">
                          <Form.Item
                            {...field}
                            name={[field.name, "id"]}
                            fieldKey={(field as any).fieldKey}
                            rules={[
                              {
                                required: true,
                                message: "Obrigatório",
                              },
                            ]}
                            noStyle
                            className="flex-1 w-full"
                          >
                            <GroupsSelect className="w-full" />
                          </Form.Item>
                        </div>
                        {/* CAPACIDADE */}
                        <div className={itemClass}>
                          <Form.Item
                            {...field}
                            name={[field.name, "capacity"]}
                            fieldKey={(field as any).fieldKey}
                            noStyle
                            className="flex-1"
                          >
                            <InputNumber controls={false} className="w-full input-end" step={1} min={0} />
                          </Form.Item>
                        </div>
                        <div className="w-6 min-w-[24px]">
                          <Button
                            icon={<i className="far fa-trash-alt" />}
                            size="small"
                            shape="circle"
                            style={{
                              marginLeft: 0,
                              padding: "0",
                            }}
                            type="text"
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({})
                      }}
                      // block
                      style={{
                        textAlign: "left",
                        height: 45,
                        borderWidth: 2,
                      }}
                      className="border-divider-100 !hover:border-primary rounded-lg mt-2"
                    >
                      <Icon name="fas fa-plus-circle text-primary" style={{ marginRight: 5 }} />
                      <span style={{ color: "var(--content-primary)" }}>Grupo</span>
                    </Button>
                  </div>
                )
              }}
            </Form.List>
          )
        }}
      </Form.Item>
    </>
  )
}

export default RoundRobinGroupsList
