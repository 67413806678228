import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { IWorkspace } from "./workspaces"

// type IPartnerType = "retailer" | "affiliate"

// Se for partner vem isso no login
// export interface IPartner {
//     id: string
//     type: IPartnerType
//     partnerSubscriptionType: "user" | "partner"
//     commissionPercentage: 30 | 15
//     credits: number
//     bankInfo: {
//         pix: string
//         phone: string
//         name: string
//         cpf: string
//     }
// }

// Se for cliente do partner vem isso no login
// export interface IPartnerCustomerSession {
//     partnerId: string
//     partnerSubscriptionType: "user" | "partner"
// }

export interface IPartnerCustomer extends IWorkspace {
    subscriptionControlType: "user" | "partner"
    commissionTotal: number
}

export const partnerCustomers: IGenericModel<IPartnerCustomer> = {
    ...generic(endpoints.PARTNER_CUSTOMERS),
}
