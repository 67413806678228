import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface Warning extends GenericResource {
  text: string
  active: boolean
}

export const warnings: IGenericModel<Warning> = {
  ...generic(endpoints.WARNINGS),
}
