import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export type IAdminApprovalStatus = "success" | "failed" | "canceled" | "processing"

export interface IAdminApproval {
    id: string
    name: string
    email: string
    status: IAdminApprovalStatus
    pix: string
    createdAt: string
    finishedAt: string
}

export const adminApprovals: IGenericModel<IAdminApproval> = {
    ...generic(endpoints.ADMIN_APPROVALS),
}
