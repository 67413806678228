import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "store"
import AppRouter from "./AppRouter"
import { RecoilRoot } from "recoil"
import { StoreProvider } from "easy-peasy"
import { store } from "hooks"
import { ConfigProvider } from "antd"
import locale from "antd/es/locale/pt_BR"

import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import "antd/dist/antd.css"
import "./styles/index.css"
import "./styles/sass/index.scss"
import "./styles/tailwind.css"
import "config/error.config"

ReactDOM.render(
  // <React.StrictMode>
  <StoreProvider store={store}>
    <RecoilRoot>
      <Provider>
        <Router>
          <ConfigProvider locale={locale}>
            <AppRouter />
          </ConfigProvider>
        </Router>
      </Provider>
    </RecoilRoot>
  </StoreProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
)
