import React from "react"
import { useVirtualizer } from "@tanstack/react-virtual"
import { GenericResource } from "models/generic"
import { twMerge } from "tailwind-merge"
import { useTable } from "../useTable"
import { VirtualInfiniteTableProps } from "./VirtualInfiniteTable"
import VirtualInfiniteTableTable from "./VirtualInfiniteTableTable"

// var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
var isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
export function VirtualInfiniteTableMobile<T extends GenericResource>(props: VirtualInfiniteTableProps<T>) {
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const { rows, fetchMoreOnBottomReached, table } = useTable<T>({
    ...props,
    tableContainerRef,
    scrollToTop: () => {
      rowVirtualizer.scrollToIndex?.(0)
    },
  })

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 57, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,

    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    // measureElement:
    //   typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
    //     ? (element) => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: props.overscan || 5,
  })

  return (
    <div
      className={twMerge("max-w-full overflow-x-auto", !props.local && "overflow-y-auto h-full", props.className)}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      // onScroll={(e) => requestAnimationFrame(() => fetchMoreOnBottomReached(e.target as HTMLDivElement))}
      // onScroll={(e) => requestAnimationFrame(() => fetchMoreOnBottomReached(e.target as HTMLDivElement))}
      // onScroll={(e) => requestAnimationFrame(() => fetchMoreOnBottomReached(e.target as HTMLDivElement))}
      ref={tableContainerRef}
      style={{
        position: "relative", //needed for sticky header
        ...(isSafari && props.local ? { height: "calc(100vh - 60px)" } : {}),
        // ...(isSafari && !props.local ? { height: "calc(100vh - 60px)" } : {}),
      }}
    >
      {props.children}
      <VirtualInfiniteTableTable
        table={table}
        rowVirtualizer={rowVirtualizer}
        rows={rows}
        onClickRow={props.onClickRow}
        transparentHeader={props.transparentHeader}
        skeleton={(props?.isFetching && !props?.data?.length) || props.isFetchingNextPage}
      />
    </div>
  )
}
