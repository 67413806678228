import React, { useMemo } from "react"
import { Col, Form, Row, Select } from "antd"
import { useStore, useStoreState } from "hooks"
import { CHANNEL_TYPES, channelsTypes } from "lib/constants"
import { alphabeticalSort, getAsset } from "lib/helper"
import { IChannel } from "models/channels"
import Avatar from "../Avatar"
import { session } from "store"
import { IGroup } from "models/groups"

interface IChannelsSelectProps {
  filter?: (value: IChannel, index: number, array: IChannel[]) => unknown
  mode?: "multiple"
  label?: string
  placeholder?: string
  name?: string | any[]
  required?: boolean
  allowClear?: boolean
  showSearch?: boolean
  noStyle?: boolean
  showNumber?: boolean
  disabled?: boolean
  className?: string
}

export function channelIsInUserGroups(props: { channel: IChannel; groups: IGroup[]; workspace: any; user: any }) {
  if (props.channel.isPrivate && !props.workspace.isOwner) {
    // check if current user is in one of the groups of c.groupIds
    const channelGroups = props.groups?.filter((g) => props.channel.groupIds?.includes?.(g.id))
    const userGroups = channelGroups?.filter((g) => g.members?.includes?.(props.user?.id))
    if (!userGroups?.length) return false
  }
  return true
}

function ChannelsSelect(props: IChannelsSelectProps) {
  const { all: channels } = useStoreState((state) => state.channels)
  const { all: groups } = useStoreState((state) => state.groups)
  const { user, workspace } = useStore(session)

  const filteredChannel = useMemo(() => {
    const fixedFilter = (c: IChannel) => {
      return channelIsInUserGroups({ channel: c, groups, workspace, user })
      // if (c.isPrivate && !workspace.isOwner) {
      //     // check if current user is in one of the groups of c.groupIds
      //     const channelGroups = groups?.filter((g) => c.groupIds?.includes?.(g.id))
      //     const userGroups = channelGroups?.filter((g) => g.members?.includes?.(user?.id))
      //     if (!userGroups?.length) return false
      // }
      // return true
    }
    if (props.filter) {
      return channels.filter(props.filter).filter(fixedFilter)
    }
    return channels.filter(fixedFilter)
  }, [channels, groups, props.filter, user, workspace])

  return (
    <Form.Item
      label={props.label || "Canais"}
      name={props.name || "channels"}
      rules={[
        {
          required: props.required || false,
          message: "Obrigatório",
        },
      ]}
      noStyle={props.noStyle || false}
    >
      <Select
        allowClear={props.allowClear == undefined ? true : props.allowClear}
        showSearch={props.showSearch == undefined ? true : props.showSearch}
        optionFilterProp="children"
        filterOption={(input, option: any) => {
          return option?.children?.props.children?.[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        mode={props.mode}
        placeholder={props.placeholder || "Todos os canais"}
        disabled={props.disabled}
        className={props.className}
        suffixIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
            <path fill="currentColor" d="m213.66 101.66l-80 80a8 8 0 0 1-11.32 0l-80-80A8 8 0 0 1 48 88h160a8 8 0 0 1 5.66 13.66"></path>
          </svg>
        }
      >
        {filteredChannel
          ?.sort((a, b) => {
            return alphabeticalSort(a.description, b.description)
          })
          .map((channel: IChannel) => {
            const description = channel.channelId === CHANNEL_TYPES.WIDGET ? "Widget" : channel.description
            return (
              <Select.Option value={channel.id} key={channel.id}>
                <Row align="middle" style={{ flexFlow: "nowrap", maxWidth: 300 }}>
                  <Col
                    style={{
                      marginRight: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      name={description}
                      index={channel.id}
                      fontSize={10}
                      size={20}
                      imgSrc={getAsset((channelsTypes as any)[channel.channelId]?.image)}
                      notRounded
                      style={{
                        borderRadius: 0,
                      }}
                      imageStyle={{
                        borderRadius: 0,
                      }}
                    />
                  </Col>
                  {description}
                </Row>
              </Select.Option>
            )
          })}
      </Select>
    </Form.Item>
  )
}

export default ChannelsSelect
