import { Collapse } from "antd"
import React from "react"
import cx from "classnames"

interface CollapseCardsProps {
  panels: {
    key: string
    title: string | React.ReactNode
    content: React.ReactNode
    forceRender?: boolean
  }[]
  defaultActiveKey?: string[]
  activeKey?: string[]
  onChange?: ((key: string | string[]) => void) | undefined
  transparent?: boolean
  destroyInactivePanel?: boolean
  bordered?: boolean
}

function CollapseCards(props: CollapseCardsProps) {
  return (
    <Collapse
      // accordion
      defaultActiveKey={props.defaultActiveKey}
      {...(props.activeKey && { activeKey: props.activeKey })}
      onChange={props.onChange}
      ghost
      destroyInactivePanel={props.destroyInactivePanel}
      expandIconPosition="right"
      expandIcon={(panelProps) => (
        <span className="!-mr-2">
          <i
            className={`fas fa-${panelProps.isActive ? "chevron-down" : "chevron-right"}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 28,
              height: 28,
              fontSize: 13,
            }}
          />
        </span>
      )}
      style={{
        border: "none",
      }}
      className={cx(props.transparent ? "" : "g_collapse_cards", props.bordered && "g_collapse_cards_bordered")}
    >
      {props.panels?.map((panel) => (
        <Collapse.Panel
          forceRender={panel.forceRender}
          header={
            // <div className="flex items-center gap-3">
            //   <i className="far fa-bullseye-arrow text-lg" />
            //   <span className="!text-md">{panel?.title}</span>
            // </div>
            <div
              style={{
                marginTop: -4,
              }}
            >
              {panel?.title}
            </div>
          }
          key={panel?.key}
        >
          {panel?.content}
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}

export default CollapseCards
