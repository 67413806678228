import { Button, Col, Row } from "antd"
import React, { useEffect } from "react"
import styles from "./style.module.css"
import cx from "classnames"
import CardBox, { IExtraMore } from "components-old/CardBox"
import { useScrollTop } from "hooks"

interface IEditPageProps {
    children: React.ReactNode
    title?: React.ReactNode | string
    footer?: React.ReactNode
    onBack: any
    midcolStyle?: React.CSSProperties
    contentStyle?: React.CSSProperties
    style?: React.CSSProperties
    footerStyle?: React.CSSProperties
    topActions?: React.ReactNode
    after?: React.ReactNode
    className?: string
    extraMore?: IExtraMore[]
    width?: number
    cardStyle?: React.CSSProperties
}

function EditPage(props: IEditPageProps) {
    useScrollTop()

    return (
        <div className={cx(styles.edit_page, props.className)} style={props.style}>
            <div className={cx(styles.edit_page_inner, "g_edit_page_inner")}>
                <CardBox
                    title={props.title}
                    style={{
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.2)",
                        width: props.width || undefined,
                        ...props.cardStyle
                    }}
                    onBack={props.onBack}
                    // helpText={props.helpText}
                    extra={props.topActions}
                    extraMore={props.extraMore}
                    // extraLeft={[Filters]}
                >
                    {/* <Row className={styles.header}>
                    <Col flex={1} style={{ paddingLeft: 16 }}>
                        <Button
                            type="text"
                            icon={<i className="fal fa-chevron-left" />}
                            style={{
                                fontWeight: 400,
                                color: "var(--content-secondary)",
                                height: 38,
                            }}
                            onClick={props.onBack}
                        >
                            Voltar
                        </Button>
                    </Col>
                    <Col style={{ textAlign: "center", marginLeft: -80 }}>{props.title}</Col>
                    <Col
                        flex={1}
                        style={{
                            textAlign: "right",
                            paddingRight: 15,
                        }}
                    >
                        {props.topActions}
                    </Col>
                </Row> */}
                    {props.children}
                    <div className={styles.edit_page_footer} style={props.footerStyle}>
                        <div
                            className={styles.edit_page_content_midcol}
                            style={{ display: "flex", alignItems: "center", height: "100%" }}
                        >
                            {props.footer}
                        </div>
                    </div>
                </CardBox>
                {props.after}
            </div>
        </div>
    )
}

export default EditPage
