import React from "react"
import { ColumnDef } from "@tanstack/react-table"

// needed for table body level scope DnD setup
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable"

// needed for row & cell level scope DnD setup
import { GenericResource } from "models/generic"
import { twMerge } from "tailwind-merge"
import { DragDropVerticalIcon } from "components-old/icons"
import { VirtualInfiniteTableProps } from "../virtual/VirtualInfiniteTable"
import TableHead from "../TableHead"
import { useTable } from "../useTable"
import { DraggableRow } from "./DraggableRow"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"

export interface DnDInfiniteTableProps<T extends GenericResource> extends VirtualInfiniteTableProps<T> {}

// Table Component
export function DnDInfiniteTable<T extends GenericResource>(props: DnDInfiniteTableProps<T>) {
  const [isMobile] = useRecoilState(isMobileAtom)

  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const columns = React.useMemo<ColumnDef<T>[]>(
    () => [
      // Create a dedicated drag handle column. Alternatively, you could just set up dnd events on the rows themselves.
      ...(!isMobile
        ? [
            {
              id: "drag-handle",
              cell: ({ row }) => (
                <DragDropVerticalIcon className="w-4 h-4 transition-transform duration-[0.2s] ease-[cubic-bezier(0.02,1.505,0.745,1.235)] group-hover/row:scale-125" />
              ),
              size: 40,
            },
          ]
        : []),
      ...props.columns,
    ],
    [isMobile, props.columns]
  )

  const { rows, fetchMoreOnBottomReached, table, bodyRowClassname, bodyRowCellClassname } = useTable<T>({
    ...props,
    columns,
    reactTableOptions: {
      getRowId: (row) => {
        return row.id
      },
    },
    tableContainerRef,
    scrollToTop: () => {
      // rowVirtualizer.scrollToIndex?.(0)
    },
  })

  const dataIds = React.useMemo<UniqueIdentifier[]>(() => props.data?.map(({ id }) => id), [props.data])

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id != over?.id) {
      const oldIndex = props.data.findIndex((x) => x.id == active.id)
      const newIndex = props.data.findIndex((x) => x.id == over?.id)
      const newArray = arrayMove(props.data, oldIndex, newIndex)
      props.onSaveNewPositions?.(newArray)
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  return (
    <div
      className={twMerge("max-w-full overflow-x-auto", props.className)}
      // onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      ref={tableContainerRef}
    >
      <DndContext collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
        <table className="w-full">
          <TableHead table={table} transparentHeader={props.transparentHeader} />
          <tbody
            style={{
              position: "relative", //needed for absolute positioning of rows
            }}
          >
            <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
              {rows.map((row) => (
                <DraggableRow
                  key={row.id}
                  id={row.id}
                  row={row}
                  onClickRow={props.onClickRow}
                  bodyRowClassname={bodyRowClassname}
                  bodyRowCellClassname={bodyRowCellClassname}
                />
              ))}
            </SortableContext>
          </tbody>
        </table>
      </DndContext>
    </div>
  )
}
