import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { ITransaction } from "./transactions"

type ILicense = "pro" | "trial"
type ITransactionStatus = "paid" | "pending_payment" | "unpaid" | "canceled" | "ended" | "trialing" | "waiting_payment"

export interface ISubscription {
    id: string
    createdAt: string
    expiresAt: string
    lastUpdateAt: string
    agents: number
    autorenewal: boolean
    discount: string
    instagramUnits: number
    isActive: boolean
    license: ILicense
    months: number
    numbers: number
    order: string
    status: ITransactionStatus
    total: string
    transactions: ITransaction[]
    whatsapp: boolean
    workspaceId: string
}

export const subscriptions: IGenericModel<ISubscription> = {
    ...generic(endpoints.PAYMENT)
}