import { Button } from "antd"
import { modalState } from "atoms/app.atom"
import { PlusSignIcon } from "components-old/icons"
import React from "react"
import { useSetRecoilState } from "recoil"
import SelectTriggerModal from "./SelectTriggerModal"
import { updateElementAction } from "../../workflow.helper"
import { editWorkflowNodeIdAtom, workflowElementsAtom } from "atoms/workflow.atom"

interface AddTriggerBtnProps {
  style?: React.CSSProperties
  id: string
}

function AddTriggerBtn(props: AddTriggerBtnProps) {
  const setModal = useSetRecoilState(modalState)
  const setElements = useSetRecoilState(workflowElementsAtom)
  const setEditNodeId = useSetRecoilState(editWorkflowNodeIdAtom)

  return (
    <Button
      icon={<PlusSignIcon className="!w-4 !h-4" />}
      className="w-full py-5 border-2 border-dashed border-divider-100 text-primary hover:border-primary active:bg-none focus:!bg-transparent hover:bg-sky-100 dark:hover:bg-transparent hover:text-primary"
      style={props.style}
      type="text"
      onClick={(e) => {
        e?.stopPropagation()
        setModal(
          <SelectTriggerModal
            onSelect={(trigger) => {
              updateElementAction(
                props.id,
                (oldAction) => ({
                  ...oldAction!,
                  triggers: {
                    ...oldAction?.triggers!,
                    [trigger]: {
                      active: true,
                    },
                  },
                }),
                { setElements }
              )

              setTimeout(() => {
                setEditNodeId(props.id)
              }, 100)

              setModal(null)
            }}
            onCancel={() => {
              setModal(null)
            }}
          />
        )
      }}
    >
      Novo gatilho
    </Button>
  )
}

export default AddTriggerBtn
