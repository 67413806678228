import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export type PaymentLinkMethod = "pix" | "boleto"
export interface IPaymentLink {
    id: string
    amount: number
    price: number
    url: string
    date_created: string
    date_updated: string
    status: string
    expires_at: string
    name: string
    workspaceId: number
    paid?: boolean
    days: number
    methods: PaymentLinkMethod[]
}

export const adminPayments: IGenericModel<IPaymentLink> = {
    ...generic(endpoints.ADMIN_PAYMENT_LINK),
}
