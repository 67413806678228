import { SelectProps, Select } from "antd"
import React from "react"

export interface CustomSelectProps extends SelectProps {}

function CustomSelect(props: CustomSelectProps) {
  return (
    <Select
      optionFilterProp="children"
      filterOption={(input, option: any) => {
        return option?.["data-search"]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children?.toLowerCase?.().indexOf?.(input.toLowerCase()) >= 0
      }}
      suffixIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
          <path fill="currentColor" d="m213.66 101.66l-80 80a8 8 0 0 1-11.32 0l-80-80A8 8 0 0 1 48 88h160a8 8 0 0 1 5.66 13.66"></path>
        </svg>
      }
      {...props}
    />
  )
}

export default CustomSelect
