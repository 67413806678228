import React, { useEffect } from "react"

import { useRegisterSW } from "virtual:pwa-register/react"
import { notification } from "antd"

function ReloadPrompt() {
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered")
      r &&
        setInterval(() => {
          r.update()
        }, 30 * 60 * 1000)
    },
    onRegisterError(error) {
      console.log("SW registration error", error)
    },
  })

  const close = () => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }

  useEffect(() => {
    if (offlineReady || needRefresh) {
      notification.open({
        key: "reload-prompt",
        message: "Nova versão disponível",
        icon: <IconHugeiconsGlobalRefresh className="text-primary" />,
        description: "Clique aqui para usar a nova versão disponível.",
        onClick: () => {
          updateServiceWorker(true)
          notification.close("reload-prompt")
        },
        className: "cursor-pointer",
        duration: 0,
      })
    }
  }, [offlineReady, needRefresh])

  return null
}

export default ReloadPrompt
