import React, { useEffect } from "react"
import { Button, Col, Row, Typography } from "antd"
import { InputType, ValidationType } from "lib/constants"
import EditInputModal from "components-old/chat/side/EditInputModal"
import { useSetRecoilState } from "recoil"
import { modalState } from "atoms/app.atom"
import toast from "lib/toast"
import cx from "classnames"

import styles from "./style.module.css"
import _ from "lodash"
import { formatCustomInputValue } from "lib/formatInputValue"

const { Text } = Typography

interface IEditableFieldProps {
    label?: string | React.ReactNode
    value?: string | React.ReactNode
    onClick?: Function
    onSubmit?: (model: any) => Promise<any>
    type?: InputType
    placeholder?: string
    copyableValue?: string
    notCopyable?: boolean
    multiline?: boolean
    notSelectable?: boolean
    disabled?: boolean
    icon?: React.ReactNode
    valueColStyle?: React.CSSProperties
    hiddenIfEmpty?: boolean
    labelClass?: string
    pulse?: boolean
    options?: {
        value: string
        label: string | React.ReactNode
    }[]
    format?: (value: any) => React.ReactNode | string
}

function EditableField(props: IEditableFieldProps) {
    const setModal = useSetRecoilState(modalState)
    const ref = React.useRef<any>(null)

    useEffect(() => {
        if (props.pulse) {
            setTimeout(() => {
                // scroll into view
                ref.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                })
            }, 500)
        }
    }, [props.pulse])

    if (props.hiddenIfEmpty && !props.value) {
        return null
    }

    const valueFormatted = formatCustomInputValue(props.type || InputType.Text, props.value)

    return (
        <a
            ref={ref}
            onClick={() => {
                if (!props.notSelectable) {
                    if (props.onClick) {
                        props.onClick()
                    } else {
                        setModal(
                            <EditInputModal
                                type={props.type!}
                                label={props.label!}
                                value={props.value as string}
                                placeholder={props.placeholder!}
                                onSubmit={props.onSubmit}
                                options={props.options}
                            />
                        )
                    }
                }
            }}
            className={cx(styles.editable_field, {
                [styles.multiline]: props.multiline,
                [styles.not_selectable]: props.notSelectable,
                [styles.disabled]: props.disabled,
                [styles.pulse]: props.pulse,
            })}
        >
            <Row
                wrap={false}
                style={{ width: "100%", height: "100%" }}
                align={props.multiline ? "top" : "middle"}
            >
                <Col span={valueFormatted ? 12 : 24} className={cx(styles.label, props.labelClass, "g_label")}>
                    <Row align="middle" wrap={false}>
                        {props.icon}
                        <Text
                            style={{
                                width: props.icon ? "calc(100% - 35px)" : "calc(100% - 15px)",
                            }}
                            ellipsis={
                                typeof props.label == "string" ? { tooltip: props.label } : false
                            }
                        >
                            {props.label}
                        </Text>
                    </Row>
                </Col>
                {props.multiline ? (
                    <Col span={valueFormatted ? 12 : 0} className={styles.value}>
                        {props.value}
                    </Col>
                ) : (
                    <Col
                        span={valueFormatted ? 12 : 0}
                        className={styles.value}
                        style={props.valueColStyle}
                    >
                        <Text
                            style={{ width: "100%" }}
                            ellipsis={
                                !props.format
                                    ? {
                                          tooltip: _.truncate(
                                              typeof valueFormatted == "string"
                                                  ? valueFormatted
                                                  : "",
                                              { length: 200 }
                                          ),
                                      }
                                    : undefined
                            }
                        >
                            {props.format?.(props.value) || valueFormatted}
                        </Text>
                    </Col>
                )}
                {!props.notCopyable && (
                    <Button
                        className={styles.copy}
                        icon={<i className="fal fa-copy" style={{ fontSize: 15 }} />}
                        onClick={(e) => {
                            e?.preventDefault?.()
                            e?.stopPropagation?.()
                            navigator.clipboard.writeText(
                                props.copyableValue || (props.value as string)
                            )
                            toast.info("Copiado para o clipboard")
                        }}
                    />
                )}
            </Row>
        </a>
    )
}

export default EditableField
