import { EditorContent, EditorOptions, useEditor } from "@tiptap/react"
import React, { useEffect, useMemo, useRef } from "react"
import InputVariableBar from "./InputVariableBar"
import { useRecoilState } from "recoil"
import { twMerge } from "tailwind-merge"
import OneLiner from "./OneLiner"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import Document from "@tiptap/extension-document"
import { convertVariablesToHtml, parseVariableString, updateLineNumbers } from "./variables.helper"
import { VariablesExtension } from "./VariableExtension"
import suggestions from "./variables-suggestion"
import History from "@tiptap/extension-history"
import toast from "lib/toast"
import { variableAtom } from "atoms/app.atom"
// import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
// import { Indent } from "./IndentExtension"
// import { common, createLowlight } from "lowlight"
// const lowlight = createLowlight(common)
export interface InputVariableTextProps {
  editable?: boolean
  // variables: VariableItem[]

  value?: string
  onChange?: any
  multiLine?: boolean
  className?: string
  floatingTrigger?: boolean
  placeholder?: string
  json?: boolean
  showLineNumbers?: boolean
  editorProps?: Partial<EditorOptions> | undefined
}

function InputVariableText(props: InputVariableTextProps) {
  const editable = props.editable !== false
  const lineNumberRef = useRef<any>(null)

  const editorWrapperRef = useRef<HTMLDivElement>(null)
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  // define your extension array
  const extensions = useRef([
    // StarterKit.configure({}),
    Text,
    Paragraph,
    History,
    VariablesExtension.configure({
      // suggestion: suggestions(props.variables),
      suggestion: suggestions([], props.json ? { char: "※" } : undefined),
      deleteTriggerWithBackspace: true,
    }),
    // CodeBlockLowlight.configure({
    //   lowlight,
    // }),
    // ...(props.json ? [LineNumbers] : []),
    ...(props.multiLine
      ? [
          Document.extend({
            addKeyboardShortcuts() {
              return {
                Tab: ({ editor }) => {
                  const { from, to } = editor.state.selection
                  if (from === to) {
                    editor.commands.insertContent("  ")
                  } else {
                    editor.commands.deleteSelection()
                    editor.commands.insertContent("  ")
                  }
                  return true
                },
                "Shift-Enter": ({ editor }) => {
                  console.log("shift enter")
                  editor.commands.enter()
                  return true
                },
              }
            },
          }),
          // HardBreak,
        ]
      : [OneLiner]),
  ])

  const initialContentHtml = useMemo(() => {
    return convertVariablesToHtml(props.value ?? "", {
      variablesRecord: dynamicVariablesDef.variablesRecord,
      multiLine: props.multiLine,
      json: props.json,
    })
    // return `<p className="whitespace-pre">${props.value?.replace(/\n/g, "<br>")?.replace(/\s/g, "&nbsp;") ?? ""}</p>`
  }, [dynamicVariablesDef.variablesRecord])

  const editor = useEditor({
    extensions: extensions.current,
    content: initialContentHtml,
    editable: editable !== false,
    parseOptions: {
      preserveWhitespace: true,
    },
    ...(props.json
      ? {
          onUpdate: ({ editor }) => {
            updateLineNumbers(editor as any, lineNumberRef)
            return false
          },
          onCreate: ({ editor }) => {
            updateLineNumbers(editor as any, lineNumberRef)
          },
        }
      : {}),
  })

  const beautifyJson = () => {
    try {
      const body = editor?.getText() || ""
      const parsed = parseVariableString(body?.replace(/&nbsp;/g, "")?.replace(/\u00a0/g, ""), {
        variablesRecord: dynamicVariablesDef.variablesRecord,
      })
      const formatted = JSON.stringify(JSON.parse(parsed), null, 2)
      editor?.commands.setContent(
        convertVariablesToHtml(formatted ?? "", {
          variablesRecord: dynamicVariablesDef.variablesRecord,
          multiLine: props.multiLine,
          json: props.json,
        })
      )
    } catch (e) {
      console.error(e)
      toast.error("JSON inválido, verifique se está formatado corretamente.")
      return
    }
  }

  return (
    <div className={twMerge("relative", props.floatingTrigger && "group/input")}>
      <InputVariableBar {...props} beautifyJson={beautifyJson} editor={editor} />
      <div
        className={twMerge(
          "input_variable bg-[var(--input-background)] border border-solid border-[var(--input-border-color)] rounded-lg relative min-h-[38px] pretty-scroll pretty-scroll-hoverable",
          props.className,
          props.json && props.showLineNumbers && "overflow-y-auto pt-0"
        )}
        spellCheck="false"
        ref={editorWrapperRef}
      >
        {/* <EditorProvider extensions={extensions.current} content={initialContentHtml} editable={editable !== false}>
      </EditorProvider> */}
        {props.showLineNumbers && props.json && (
          <div
            id="line-numbers"
            ref={lineNumberRef}
            className={
              "absolute top-0 left-0 text-gray-300 dark:text-content-300 -mt-0.5 leading-[21px] bg-base-100 z-[1] min-w-[20px] text-right pr-2 dark:bg-[var(--input-background)]"
            }
          ></div>
        )}
        <EditorContent
          editor={editor}
          className={twMerge(
            "tiptap-wrapper [&>div]:h-full [&>div]:px-2.5 [&>div]:py-2 pretty-scroll pretty-scroll-hoverable",
            props.floatingTrigger && "[&>div]:pr-10",
            props.json &&
              props.showLineNumbers &&
              "pl-3 [&>div]:pt-0 overflow-x-auto overflow-y-visible leading-[21px] [&_p]:leading-[21px] -mt-0.5"
          )}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  )
}

export default InputVariableText
