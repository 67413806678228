import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IReportRating {
    id?: string
    operatorId: string
    operatorName: string

    // Time
    ratingAverage: number
    ratingsCount: number
}

export const reportRating: IGenericModel<IReportRating> = {
    ...generic(endpoints.REPORT_RATING),
}
