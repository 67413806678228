import CustomFieldInput from "../CustomFieldInput"
import VariablesDrop from "./VariablesDrop"
import { Button, Dropdown } from "antd"
import VariableDropTrigger from "./VariableDropTrigger"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { detectVariableFromString, mountVariableString } from "./variables.helper"
import { InputVariableProps } from "."
import { useRecoilState } from "recoil"
import VariableTag from "./VariableExtension/VariableTag"
import { VariableEnum } from "pages/settings/workflows/workflow.enums"
import FallbackDrop from "./VariableExtension/FallbackDrop"
import { debounce } from "lodash"
import { workflowElementsObj } from "pages/settings/workflows/EditWorkflow"
import { variableAtom } from "atoms/app.atom"
// import useDebouncedMemo from "@sevenoutman/use-debounced-memo"

function InputVariableCustomField(props: InputVariableProps) {
  const [visible, setVisible] = useState(false)
  const [fallbackVisible, setFallbackVisible] = useState(false)
  // const [usingVariable, setUsingVariable] = useState(true)
  const [dynamicVariablesDef] = useRecoilState(variableAtom)
  const varRef = useRef<any>(true)

  // const debouncedVariable = useDebouncedMemo(
  //   () => {
  //     const result = usingVariable ? detectVariableFromString(props.value, { variablesRecord: dynamicVariablesDef.variablesRecord }) : null
  //     return result
  //   },
  //   [props.value],
  //   100
  // )

  const valueParsed = useMemo(() => {
    if (varRef.current) {
      const result = detectVariableFromString(props.value, { variablesRecord: dynamicVariablesDef.variablesRecord })
      if (result?.variableRecord) {
        return result
      }
      // setUsingVariable(false)
    }

    return props.value

    // const result = usingVariable
    //   ? detectVariableFromString(props.value, { variablesRecord: dynamicVariablesDef.variablesRecord })
    //   : props.value
    // return result
  }, [props.value, dynamicVariablesDef.variablesRecord])

  useEffect(() => {
    if (varRef.current && !(valueParsed as any)?.variableRecord) {
      varRef.current = false
    }
  }, [valueParsed])

  useEffect(() => {
    varRef.current = true
  }, [dynamicVariablesDef.variablesRecord])

  const updateAttributes = useCallback(
    (attr: Record<string, any>) => {
      const result = valueParsed as any
      props.onChange(
        mountVariableString({
          ...result?.variable,
          ...attr,
        })
      )
    },
    [props, valueParsed]
  )

  const updateFallbackDebounced = useMemo(() => {
    return debounce(
      (value) => {
        updateAttributes({
          fallback: typeof value === "string" ? value.replace(/[{}|]/g, "")?.trim?.() : value,
        })
      },
      100,
      {
        maxWait: 2000,
      }
    )
  }, [updateAttributes])

  const updateJsonPathDebounced = useMemo(() => {
    return debounce(
      (value) => {
        if (!value || value?.startsWith?.("$.") || value?.startsWith?.("$[")) {
          updateAttributes({
            jsonPath: value.replace(/[{}|]/g, "")?.trim?.(),
          })
        }
      },
      100,
      {
        maxWait: 2000,
      }
    )
  }, [updateAttributes])

  // useEffect(() => {
  //   if (varRef.current && !debouncedVariable?.variableRecord) {
  //     setUsingVariable(false)
  //   } else if (!usingVariable && debouncedVariable?.variableRecord) {
  //     setUsingVariable(true)
  //   }
  // }, [props.value])

  if (varRef.current && (valueParsed as any)?.variableRecord!) {
    const result = valueParsed as any

    const deletedVariable = result.reference && !workflowElementsObj[result.reference]
    return (
      <div className="flex justify-between border-lg bg-[var(--input-background)] border border-solid border-[var(--input-border-color)] rounded-lg relative py-2 px-2.5">
        <FallbackDrop
          onChange={updateFallbackDebounced}
          onChangeJsonPath={updateJsonPathDebounced}
          fallback={result?.variable.fallback}
          jsonPath={result?.variable.jsonPath}
          setVisible={setFallbackVisible}
          type={result?.variable.type}
          reference={result?.variable.reference}
          field={props.field}
        >
          <a>
            <VariableTag
              type={result?.variable.type as VariableEnum}
              fallback={result?.variable.fallback}
              jsonPath={result?.variable.jsonPath}
              reference={result?.variable.reference}
              showCategory
              labelClassName="max-w-[100px]"
              selected={fallbackVisible}
              deletedVariable={deletedVariable}
            />
          </a>
        </FallbackDrop>
        <Button
          onClick={() => {
            varRef.current = false
            props.onChange("")
          }}
          size="small"
          type="text"
          icon={<i className="far fa-times" />}
          className="!rounded-lg absolute top-[7px] right-1.5"
        />
      </div>
    )
  }

  return (
    <div className="relative">
      <CustomFieldInput {...props} value={props.value} />
      {/* <VariableDropTrigger command={(dto: any) => {}} /> */}
      <Dropdown
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}
        overlay={
          <VariablesDrop
            command={(dto: { id: string; label: string; reference: string; jsonPath: string; fallback: string }) => {
              // setUsingVariable(true)
              varRef.current = true
              updateAttributes({
                type: dto.id,
                jsonPath: dto.jsonPath,
                reference: dto.reference,
                fallback: dto.fallback,
              })
              // props.onChange(
              //   mountVariableString({
              //     type: dto.id,
              //     jsonPath: dto.jsonPath,
              //     reference: dto.reference,
              //     fallback: dto.fallback,
              //   })
              // )
            }}
            hide={function (): void {
              setVisible(false)
            }}
          />
        }
        trigger={["click"]}
      >
        {}
        <VariableDropTrigger
          onClick={() => {
            /* Não tirar o onclick */
            return
          }}
        />
      </Dropdown>
    </div>
  )
}

export default memo(InputVariableCustomField)
