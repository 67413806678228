import { Form, InputNumber } from "antd"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import CustomSelect from "components-old/forms/CustomSelect"
import ExpandableSwitchInput from "components-old/shared/ExpandableSwitchInput"
import BusinessHoursSelect from "components-old/ui/BusinessHoursSelect"
import { Clock01Icon } from "components-old/icons"
import { useEffect } from "react"

interface DelayActionProps extends BaseEditActionChildrenProps {}

const DelayAction = (props: DelayActionProps) => {
  const fieldName = [...props.actionFieldName, "delay"]

  useEffect(() => {
    if (!props.form.getFieldValue(fieldName)?.unit) {
      setTimeout(() => {
        props.form.setFields([{ name: fieldName, value: { duration: 1, unit: "minutes" } }])
        props.syncElements()
      }, 100)
    }
  }, [])

  return (
    <div>
      <div className="flex items-center gap-2">
        <Form.Item label="Duração" name={[...fieldName, "duration"]} required>
          <InputNumber className="w-full flex-1" />
        </Form.Item>
        <Form.Item label="Unidade" name={[...fieldName, "unit"]} required className="w-full flex-1">
          <CustomSelect
            className="w-full flex-1"
            options={[
              { label: "Segundos", value: "seconds" },
              { label: "Minutos", value: "minutes" },
              { label: "Horas", value: "hours" },
              { label: "Dias", value: "days" },
            ]}
          />
        </Form.Item>
      </div>
      <ExpandableSwitchInput
        name={[...fieldName, "useBusinessHours"]}
        label="Considerar horários"
        help="Se ao final do delay não for dentro dos horários selecionado, irá agendar para a próxima data dentro do horário selecionado."
        icon={Clock01Icon}
      >
        <div className="px-2 py-2">
          <BusinessHoursSelect allowClear={false} name={[...fieldName, "businessHoursId"]} />
        </div>
      </ExpandableSwitchInput>
    </div>
  )
}

export default DelayAction
