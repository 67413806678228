import { Privacy } from "lib/constants"
import toast from "lib/toast"
import { GenericResource, PrivateGenericResource } from "models/generic"
import { IUser } from "models/users"
import { SegmentDropdownProps } from "./SegmentDropdown"
import { exportArray } from "lib/helper"

export enum DefaultTableSegment {
  ALL = "",
  PRIVACY_MINE = "privacy:mine",
}

export const DefaultTableSegmentOptions = {
  PRIVACY_MINE: {
    value: DefaultTableSegment.PRIVACY_MINE,
    label: "Visível somente para mim",
    defaultFilteredFn: (
      items: PrivateGenericResource[],
      context: {
        user: IUser
        workspace: any
      }
    ) => {
      return items.filter((item) => {
        const isPrivate = (item as unknown as PrivateGenericResource).privacy?.type === Privacy.PRIVATE
        if (!isPrivate) {
          return false
        }
        const authorId = (item as unknown as PrivateGenericResource).authorId

        return authorId ? authorId == context?.user?.id : context?.workspace.isOwner
      })
    },
  },
}

// export const defaultTableSegmentOptions = [
//   {
//     value: DefaultTableSegment.ALL,
//     label: "Tudo",
//   },
//   {
//     value: DefaultTableSegment.PRIVACY_MINE,
//     label: "Visível somente para mim",
//   },
// ]

export const tableFilterLocalFn = <T>(
  dto: { filters: Record<string, any>; page: T[]; filterFn?: (item: T, filters: Record<string, any>) => boolean },
  context: {
    user: IUser
    workspace: any
    segments?: SegmentDropdownProps<T>["segments"]
  }
) => {
  let result = dto.page
  if (dto.filters.segment) {
    const segment = context.segments?.find((s) => s.value === dto.filters.segment)

    if (segment?.filtered) {
      result = segment?.filtered
    } else {
      switch (dto.filters.segment) {
        case "privacy:mine":
          result = result.filter((item) => {
            const isPrivate = (item as unknown as PrivateGenericResource).privacy?.type === Privacy.PRIVATE
            if (!isPrivate) {
              return false
            }
            const authorId = (item as unknown as PrivateGenericResource).authorId

            return authorId ? authorId == context?.user?.id : context?.workspace.isOwner
          })
          break
      }
    }
  }

  if (dto.filterFn) {
    result = result.filter((item) => {
      try {
        return dto.filterFn?.(item, dto.filters)
      } catch (e) {
        return false
      }
    })
  } else {
    if (dto.filters.search) {
      result = dto.page.filter((item) => ((item as any).name || "")?.toLowerCase?.().includes(dto.filters.search.toLowerCase()))
    }
  }

  return result
}

export const exportTableData = async <T extends GenericResource>(
  data: T[],
  getExportData: (data: T[]) => Promise<{ exportableData: Record<string, string>[]; filename: string }>,
  opts?: { xls?: boolean }
) => {
  const hideToast = toast.loading(`Exportando... 0%`, 0, {
    key: "exporting",
  })

  const { exportableData, filename } = await getExportData(data)

  exportArray(exportableData, `relatorio_${filename}`, { xlsx: opts?.xls })
  hideToast?.()
}
