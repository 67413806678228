import React from "react"
import { Row, Select, SelectProps } from "antd"
import { useStoreState } from "hooks"
import botImg from "assets/images/bot-eye.svg"
import Avatar from "../Avatar"
import { avatarColors } from "lib/constants"
import CustomSelect from "components-old/forms/CustomSelect"

interface BotSelectProps extends SelectProps<any> {}

function BotSelect(props: BotSelectProps) {
  const { all: bots } = useStoreState((state) => state.bots)

  return (
    <CustomSelect
      allowClear
      showSearch
    //   optionFilterProp="children"
    //   filterOption={(input, option: any) => {
    //     return option?.children?.props.children?.[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   }}
      {...props}
    >
      {bots.map((bot: any) => (
        <Select.Option value={bot.id} key={bot.id} data-search={bot.name}>
          <Row align="middle" style={{ flexFlow: "nowrap" }}>
            <Avatar
              name={""}
              index={bot.id}
              fontSize={12}
              size={16}
              imgSrc={botImg}
              imageStyle={{
                backgroundColor: avatarColors[parseInt(bot.id, 10) % avatarColors.length],
              }}
              // notRounded
              style={{ marginRight: 8 }}
            />
            {bot.name}
          </Row>
        </Select.Option>
      ))}
    </CustomSelect>
  )
}

export default BotSelect
