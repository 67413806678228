import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { HubspotContactPropertyShort } from "pages/settings/integrations/hubspot/EditHubspotSettings"

export type IIntegrationType =
  | "pipedrive"
  | "rdstation"
  | "exactspotter"
  | "tinyerp"
  | "smarters"
  | "followize"
  | "nectarcrm"
  | "consulta_fgts"
  | "bitrix24"
  | "gestor_premium"
  | "mola"
  | "rdstation-marketing"
  | "banco-inter"
  | "hubspot"
  | "dom360"
  | "sensedata"
  | "zoho"

export interface IIntegrationPipedrive extends IIntegration {
  data: {
    apiKey?: string
    tokens?: {
      [token: string]: string
    }
    configuration: {
      person: any
      organization: any
      deal: any
      activity: any
    }
  }
}

// interface DomUserRelation {
//     [kinbox_user_id: string]: {
//         userid: string
//         name: string
//     }
// }

// export interface IIntegrationDom360 extends IIntegration {
//   data: {
//     token?: string
//     subdomain?: string
//     users_relation?: {
//       [kinbox_user_id: string]: {
//         userid: string
//         name: string
//       }
//     }
//   }
// }

// export interface IIntegrationFollowize extends IIntegration {
//   data: {
//     token_account?: string
//     tokens_team?: {
//       name: string
//       value: string
//     }[]
//     users_relation?: {
//       user_id: string
//       attendantId: string
//     }[]
//   }
// }

export interface IIntegrationNectarCRM extends IIntegration {
  data: {
    tokens?: {
      [token: string]: string
    }
    pipeline?: string
  }
}

export interface IIntegrationRDStation extends IIntegration {
  data: {
    token?: string
    stageId?: string
  }
}

export interface IIntegrationHubspot extends IIntegration {
  data: {
    token?: string
    tokens?: string[]
    portalId?: string
    contactFields: HubspotContactPropertyShort[]
    dealFields: HubspotContactPropertyShort[]
    syncField: string
    syncFieldPlaceholder: string
    requireds: { stageId: string; dealFieldIds: string[] }[]
    properties_map: Record<string, string>
  }
}

export interface IIntegrationZoho extends IIntegration {
  data: {
    token?: string
    syncField: string
    syncFieldPlaceholder: string
    org_id: string
    client_id: string
    client_secret: string
    generatedCode: string
    // leadFields: any[]
    dealLayout: string
    dealFields: any[]
    leadLayout: string
    tokenResult: {
      access_token: "{access_token}"
      refresh_token: "{refresh_token}"
      api_domain: "https://www.zohoapis.com"
      token_type: "Bearer"
      expires_in: 3600
    }
    // portalId?: string
    // contactFields: HubspotContactPropertyShort[]
    // dealFields: HubspotContactPropertyShort[]
    // requireds: { stageId: string; dealFieldIds: string[] }[]
    properties_map: Record<string, string>
  }
}

export interface IIntegrationRDStationMarketing extends IIntegration {
  data: {
    clientId?: string
    clientSecret?: string
    code?: string
    accessToken?: string
    refreshToken?: string
    expiresIn?: string
    sendAsConversion?: boolean
  }
}

export interface IIntegrationBancoInter extends IIntegration {
  data: {
    client_id?: string
    client_secret?: string
    cert?: string
    key?: string
    token?: string
  }
}

// export interface IIntegrationExactSpotter extends IIntegration {
//   data: {
//     token?: string
//   }
// }

export interface IIntegrationTinyERP extends IIntegration {
  data: {
    token?: string
  }
}

export interface IIntegrationSensedata extends IIntegration {
  data: {
    token?: string
  }
}

export interface IIntegrationBitrix24 extends IIntegration {
  data: {
    token_url?: string
    token_url_users?: string
    webhookUrl?: string
    properties_map?: Record<string, string>
  }
}

export interface IIntegrationConsultaFGTS extends IIntegration {
  data: {
    token?: string
  }
}

export interface IIntegrationSmarters extends IIntegration {
  data: {
    publicToken?: string
    privateToken?: string
  }
}

export interface IIntegrationMola extends IIntegration {
  data: {
    token?: string
    email?: string
    password?: string
    tokenMode?: boolean
    cnpj?: string
  }
}

export interface IIntegrationGestorPremium extends IIntegration {
  data: {
    token?: string
    url?: string
  }
}

export interface IIntegrationRow {
  id: IIntegrationType
  name: string
  description: string
  shortDescription: string
  avatar: string
  color: string
  isPlugin?: boolean
  disabled?: boolean
}

export interface IIntegration {
  id: string
  isEnabled: boolean
  type: IIntegrationType
  isInvisible?: boolean
  data: any
}

export const integrations: IGenericModel<IIntegration> = {
  ...generic(endpoints.INTEGRATIONS),
}
