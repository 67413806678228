import React, { useMemo } from "react"
import { Form, Row, Col, FormInstance } from "antd"
import { getValueInput, validateValue } from "lib/helper"
import { InputType } from "lib/constants"
import { ICustomField, ICustomFieldEntity } from "models/customFields"
import { useRecoilValue } from "recoil"
import { themeAtom } from "atoms/app.atom"
import { useStoreState } from "hooks"

interface EditEntityCustomFieldsProps {
  form: FormInstance
  item?: any
  entity: ICustomFieldEntity
  customFilter?: (customField: ICustomField) => boolean
  allRequired?: boolean
  variables?: boolean
  fieldName?: string[]
  columns?: 1 | 2
  notShowJson?: boolean
}

function EditEntityCustomFields(props: EditEntityCustomFieldsProps) {
  const theme = useRecoilValue(themeAtom)
  const { all: customFields } = useStoreState((state) => state.customFields)

  const entityCustomFields = useMemo(() => {
    if (props.customFilter) return customFields.filter(props.customFilter)
    return customFields.filter((customField) => {
      if (!customField.entity && props.entity == "contact") return true
      return customField?.entity == props.entity
    })
  }, [customFields, props.entity])

  return (
    <div>
      <Row gutter={12}>
        {entityCustomFields
          ?.filter?.((customField) => customField.type != InputType.Textarea && customField.type != InputType.JSON)
          .map((customField, i) => (
            <Col span={props.columns === 1 ? 24 : entityCustomFields?.length == 1 ? 24 : 12} key={customField.id}>
              <Form.Item
                label={customField.name}
                name={[...(props.fieldName || []), "customFields", customField.placeholder, "value"]}
                // valuePropName={customField.type == InputType?.Boolean ? "checked" : undefined}
                rules={[
                  ...(customField?.validate
                    ? [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve()
                            }
                            if (customField?.validate) {
                              const isValid = validateValue(value, customField.validation_type, customField?.validation_mask)
                              if (!isValid) {
                                return Promise.reject(new Error("Valor inválido"))
                              }
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]
                    : []),
                  ...(props.allRequired ? [{ required: true, message: "Campo obrigatório" }] : []),
                ]}
              >
                {getValueInput(
                  {
                    type: customField.type,
                    options: customField.options,
                  },
                  undefined,
                  {
                    variables: props.variables,
                  }
                )}
              </Form.Item>
            </Col>
          ))}
      </Row>
      {entityCustomFields
        ?.filter?.((customField) => {
          if (props.notShowJson) return customField.type == InputType.Textarea
          return customField.type == InputType.Textarea || customField.type == InputType.JSON
        })
        .map((customField, i) => (
          <Form.Item
            label={customField.name}
            key={"a" + customField.id}
            // name={["customFields", customField.placeholder, "value"]}
            name={[...(props.fieldName || []), "customFields", customField.placeholder, "value"]}
            rules={[
              ...(customField?.validate
                ? [
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve()
                        }
                        if (customField?.validate) {
                          const isValid = validateValue(value, customField.validation_type, customField?.validation_mask)
                          if (!isValid) {
                            return Promise.reject(new Error("Valor inválido"))
                          }
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]
                : []),
              ...(props.allRequired ? [{ required: true, message: "Campo obrigatório" }] : []),
            ]}
          >
            {getValueInput(
              {
                type: customField.type,
                options: customField.options,
              },
              {
                json: {
                  form: props.form,
                  theme: theme,
                  name: customField.placeholder,
                  value: props.item?.customFields?.[customField.placeholder]?.value,
                  style: {
                    height: 200,
                    width: "100%",
                  },
                },
              }
            )}
          </Form.Item>
        ))}
    </div>
  )
}

export default EditEntityCustomFields
