import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum WalletTransactionStatus {
  WAITING_PAYMENT = "waiting_payment",
  PAID = "paid",
  PENDING_REFUNDED = "pending_refuded",
  REFUNDED = "refunded",
  WITH_ERROR = "with_error",
  FAILED = "failed",
  SUCCESS = "success",
}

export interface IWalletTransaction {
  id: string
  _id: string
  workspaceId: number
  operatorId: string
  status: WalletTransactionStatus
  amount: number
  pix_qr_code: string
  externalTransactionId: string
  updatedAt: string
  createdAt: string
  description?: string
  assistLogId?: string
}

export const wallet: IGenericModel<IWalletTransaction> = {
  ...generic(endpoints.WALLET_TRANSACTIONS),
}
