import React from "react"

import styles from "./style.module.css"
import { Radio } from "antd"
import { twMerge } from "tailwind-merge"
import Tooltip from "components-old/Tooltip"

interface IRadioboxProps {
  children: React.ReactNode
  icon?: React.ReactNode
  selected?: boolean
  value?: string
  tip?: string
  disabled?: boolean
  dashed?: boolean
  className?: string
}

function Radiobox(props: IRadioboxProps) {
  return (
    <Tooltip title={props.tip}>
      <label
        className={twMerge([
          "flex-center group relative h-[7rem] w-full cursor-pointer flex-col gap-2 rounded-lg text-sm text-center px-2",
          props.selected && !props.dashed && "outline-solid outline-2 outline outline-primary",
          props.disabled && "opacity-50 pointer-events-none",
          !props.dashed && "bg-gray-100 hover:bg-gray-200 dark:bg-neutral-hover dark:hover:bg-neutral-focus",
          props.dashed && "border-2 border-dashed border-divider-100 hover:border-divider-200 ",
          props.selected && props.dashed && "border-primary hover:border-primary bg-sky-50 dark:bg-base-200",
          props.className,
        ])}
        // classList={{
        //     "bg-gray-200": checked(),
        //     "border border-primary": checked(),
        // }}
      >
        <i className="text-xl flex-1 flex items-end">{props.icon}</i>
        <Radio
          value={props.value}
          type="radio"
          className={twMerge(["radio radio-sm absolute top-1 right-1 checked:bg-primary group-hover:visible", !props.selected && "invisible"])}
          disabled={props.disabled}
          // classList={{
          //     invisible: !checked(),
          //     visible: checked(),
          // }}
          // {...inputProps}
          // checked={checked()}
        />
        <span className="flex-1">{props.children}</span>
      </label>
    </Tooltip>
  )
}

export default Radiobox
