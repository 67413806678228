import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { NodeField } from "../ConversationNodeCore"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import BaseExpanderFields from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import botImg from "assets/images/bot-eye.svg"
import { useStoreState } from "hooks"
import { Bot02Icon } from "components-old/icons"
import Avatar from "components-old/ui/Avatar"
import { avatarColors } from "lib/constants"

interface RunBotNodeProps extends BaseNodeProps {}

function RunBotNode(props: RunBotNodeProps) {
  const { all: bots } = useStoreState((state) => state.bots)

  const { fields } = useMemo(() => {
    let fields: NodeField[] = []
    const runBot = props.data?.action?.runBot

    const bot = bots.find((b) => +b.id == props.data?.action?.runBot?.botId)

    if (runBot?.conversationReference) {
      fields.push({
        icon: WorkflowDefs.CategoryRecord.conversation.icon,
        value: runBot?.conversationReference ? (
          <span>
            Conversa <span className="text-sky-600">#{runBot?.conversationReference}</span>
          </span>
        ) : (
          ""
        ),
        label: "Conversa",
      })
    }

    if (bot) {
      fields.push({
        icon: Bot02Icon,
        value: bot ? (
          <div className="flex items-center gap-2 truncate shadow dark:bg-base-200 px-2 rounded-full">
            <Avatar
              name={""}
              index={bot?.id}
              fontSize={12}
              size={16}
              imgSrc={botImg}
              imageStyle={{
                backgroundColor: avatarColors[parseInt(bot?.id, 10) % avatarColors.length],
              }}
            />
            <span className="truncate">{bot?.name}</span>
          </div>
        ) : (
          ""
        ),
        label: "Bot",
      })
    }

    return {
      fields,
    }
  }, [bots, props.data?.action?.runBot])

  return (
    <BaseNode {...props} title="Executar bot">
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONVERSATION} />
    </BaseNode>
  )
}

export default RunBotNode
