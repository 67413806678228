import React from "react"

function CustomSvg() {
    return (
        <svg
            version="1.1"
            id="svg2"
            // x="0px"
            // y="0px"
            // width="1200px"
            // height="1200px"
            viewBox="0 0 1200 1200"
            enableBackground="new 0 0 1200 1200"
        >
            <path
                id="path4485"
                // fill="#8e44ad"
                d="M600,0C268.629,0,0,268.629,0,600s268.629,600,600,600s600-268.629,600-600
	S931.371,0,600,0z M542.871,234.375h114.258l11.06,110.303c22.729,6.054,44.277,14.988,64.16,26.514l85.84-70.166l80.786,80.786
	l-70.166,85.84c11.525,19.883,20.46,41.432,26.514,64.16l110.303,11.06v114.258l-110.303,11.133
	c-6.054,22.709-14.997,44.22-26.514,64.087l70.166,85.84l-80.786,80.786l-85.913-70.166c-19.874,11.516-41.371,20.464-64.087,26.514
	l-11.06,110.303H542.871l-11.133-110.303c-22.696-6.05-44.155-15.007-64.014-26.514l-85.913,70.166l-80.786-80.786l70.166-85.913
	c-11.507-19.858-20.463-41.317-26.514-64.014l-110.303-11.133V542.871l110.303-11.06c6.05-22.716,14.998-44.213,26.514-64.087
	l-70.166-85.913l80.786-80.786l85.84,70.166c19.867-11.517,41.378-20.46,64.087-26.514L542.871,234.375z M600,494.312
	c-58.374,0-105.688,47.314-105.688,105.688S541.626,705.688,600,705.688S705.688,658.374,705.688,600S658.374,494.312,600,494.312z"
            />
        </svg>

        // <svg
        //     id="Capa_1"
        //     enableBackground="new 0 0 512 512"
        //     viewBox="0 0 512 512"
        //     xmlns="http://www.w3.org/2000/svg"
        // >
        //     <g>
        //         <path
        //             d="m226 512 15-34.947v-171.474l-15-19.579-19.421-75h-174.789l-31.79 75c0 124.072 101.928 226 226 226z"
        //             fill="#ff6b4d"
        //         />
        //         <path
        //             d="m226 286v52.5c-33.137 0-60 26.863-60 60s26.863 60 60 60v53.5c124.072 0 225-101.928 225-226l-27.947-15h-157.263z"
        //             fill="#e1e6f0"
        //         />
        //         <path
        //             d="m451 121v-60h-60c0-33.091-26.909-61-60-61s-60 27.909-60 61h-45l-15 25.737v171.473l15 27.79h52.5c0 33.137 26.863 60 60 60s60-26.863 60-60h52.5v-45c33.091 0 61-26.909 61-60s-27.909-60-61-60z"
        //             fill="#ffbe40"
        //         />
        //         <path
        //             d="m226 113.5v-52.5c-124.072 0-226 100.928-226 225h53.5c0-33.137 26.863-60 60-60s60 26.863 60 60h52.5v-52.5c33.137 0 60-26.863 60-60s-26.863-60-60-60z"
        //             fill="#59abff"
        //         />
        //     </g>
        // </svg>
    )
}

export default CustomSvg
