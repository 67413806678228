import { Input } from "antd"
import { InputProps } from "antd/lib/input"
import React, { useEffect, useRef } from "react"
import cx from "classnames"
import styles from "./style.module.css"

interface ISearchInputProps extends InputProps {
  // ref?: any
  gray?: boolean
  icon?: boolean
  autoFocus?: boolean
}

function SearchInput(props: ISearchInputProps) {
  const { gray, icon, ...rest } = props
  const searchRef = useRef<any>()

  // useEffect(() => {
  //     if (props.autoFocus) {
  //         setTimeout(() => {
  //             ``searchRef?.current?.focus()
  //         }, 500)
  //     }
  // }, [searchRef, props.autoFocus])

  return (
    <Input
      autoComplete="off"
      key={"search-input"}
      // prefix={<i className="far fa-search" />}
      placeholder="Pesquisar"
      {...rest}
      className={cx(
        styles.search,
        "g_input_search",
        {
          [styles.gray]: gray,
          [styles.small]: props.size == "small",
        },
        props.className
      )}
      id="search-input"
      // allowClear={{ clearIcon: <i className="fal fa-times" /> } as any}
      allowClear
      autoFocus={props.autoFocus}
      ref={searchRef}
      prefix={icon ? <i className="far fa-search" style={{ marginRight: 3, marginLeft: 3 }} /> : undefined}
    />
  )
}

export default SearchInput
