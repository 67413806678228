import { useSortable } from "@dnd-kit/sortable"
import { Row, flexRender } from "@tanstack/react-table"
import { GenericResource } from "models/generic"
import { CSSProperties } from "react"
import { CSS } from "@dnd-kit/utilities"
import { twMerge } from "tailwind-merge"
import { useRecoilState } from "recoil"
import { isMobileAtom } from "atoms/app.atom"

interface DraggableRowProps<T extends GenericResource> {
  row: Row<T>
  onClickRow?: (row: T) => void
  id: string
  bodyRowClassname?: string
  bodyRowCellClassname?: string
}

// Row Component
export function DraggableRow<T extends GenericResource>(props: DraggableRowProps<T>) {
  const [isMobile] = useRecoilState(isMobileAtom)
  const isPin = props.row?.getIsPinned()

  const { transform, transition, setNodeRef, isDragging, attributes, listeners } = useSortable({
    id: props.id,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
    display: "flex",
    width: "100%",
    borderTop: "1px solid var(--divider)",
  }

  return (
    // connect row ref to dnd-kit, apply important styles
    <tr
      ref={setNodeRef}
      style={style}
      key={props.row.id}
      className={props.bodyRowClassname}
      onClick={props.onClickRow ? () => props.onClickRow?.(props.row.original) : undefined}
    >
      {props.row.getVisibleCells().map((cell, i) => {
        const isDraggableButton = !isMobile && i === 0

        return (
          <td
            key={cell.id}
            style={{ width: cell.column.getSize() }}
            {...(isDraggableButton ? { ...attributes, ...listeners } : {})}
            className={twMerge(
              props.bodyRowCellClassname,
              isPin && "flex-1",
              isDraggableButton && "cursor-grab group/row active:cursor-grabbing"
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}
