import { Form, Radio } from "antd"
import React from "react"
import Radiobox from "./Radiobox"
import { twMerge } from "tailwind-merge"

interface IRadioboxFieldProps {
    name: string
    label?: string | React.ReactNode
    className?: string
    itemClassName?: string
    items: {
        value: string
        icon: React.ReactNode
        label: string | React.ReactNode
        tip?: string
        disabled?: boolean
    }[]
    dashed?: boolean
}

function RadioboxField(props: IRadioboxFieldProps) {
    return (
        <Form.Item
            noStyle
            shouldUpdate={(changedValues) => {
                return !!changedValues[props.name]
            }}
        >
            {(form) => (
                <Form.Item label={props.label} name={props.name}>
                    <Radio.Group
                        className={twMerge(
                            "grid grid-cols-4 justify-items-center gap-1.5",
                            props.className
                        )}
                    >
                        {props.items.map((item) => (
                            <Radiobox
                                key={item.value + item.label}
                                icon={item.icon}
                                value={item.value}
                                selected={form.getFieldValue(props.name) === item.value}
                                tip={item.tip}
                                disabled={item.disabled}
                                dashed={props.dashed}
                                className={props.itemClassName}
                            >
                                {item.label}
                            </Radiobox>
                        ))}
                    </Radio.Group>
                </Form.Item>
            )}
        </Form.Item>
    )
}

export default RadioboxField
