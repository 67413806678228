import { IAsset } from "components-old/ui/FileCard"
import { endpoints } from "config/endpoints.config"
// import { InputType } from "lib/constants"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface IQuickPhrase extends GenericResource {
  id: string
  createdAt: string
  lastUpdateAt: string
  phrase: string
  privacy: "public" | "private"
  hotkey: null
  operatorId: string
  tag: string
  workspaceId: string
  voice: IAsset
  type: "text" | "voice" | "image"
  attachments: IAsset[]
  groupId?: string
}

export const quickPhrases: IGenericModel<IQuickPhrase> = {
  ...generic(endpoints.QUICK_PHRASES),
}
