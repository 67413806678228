import React, { useState } from "react"
import config from "config"
import ModalHeader from "components-old/ModalHeader"
import ModalBody from "components-old/ModalBody"
import ModalFooter from "components-old/ModalFooter"
import { Button, Form, Select } from "antd"
import request from "lib/request"
import { inbox, useStore } from "store"
import toast from "lib/toast"

function SyncZapHistoryModal(props: any) {
    const inboxStore = useStore(inbox)
    const [loading, setLoading] = useState(false)

    async function onSubmit(model: any) {
        setLoading(true)
        return request
            .post(config.endpoints.WHATSAPP_SYNC, { ...model, id: props.item.id })
            .then((x: any) => {
                setLoading(false)
                const hide = toast.loading(`Sincronizando conversas... 0%`, 60, {
                    key: "sync-conversations",
                })
                inboxStore?.setSync({
                    hide,
                })
                props.onBackModal()
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    return (
        <Form
            layout="vertical"
            onFinish={onSubmit}
            hideRequiredMark
            initialValues={{
                messagesLimit: 20,
                chatsLimit: 500,
            }}
        >
            <div>
                <ModalHeader title="Sincronizar histórico" />
                <ModalBody>
                    Sincroniza seu histórico de conversas do Whatsapp com o Kinbox. Esse processo
                    pode levar vários minutos.
                    <br />
                    <br />
                    <Form.Item
                        label="Quantidade de conversas"
                        name="chatsLimit"
                        help="Mais recentes"
                    >
                        <Select>
                            <Select.Option value={10}>10</Select.Option>
                            <Select.Option value={200}>200</Select.Option>
                            <Select.Option value={500}>500</Select.Option>
                            <Select.Option value={1000}>1000</Select.Option>
                            <Select.Option value={2000}>2000</Select.Option>
                            <Select.Option value={5000}>5000</Select.Option>
                            <Select.Option value={10000}>10000</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Quantidade de mensagens por conversa"
                        name="messagesLimit"
                        help="Mais recentes"
                    >
                        <Select>
                            <Select.Option value={20}>20</Select.Option>
                            <Select.Option value={40}>40</Select.Option>
                        </Select>
                    </Form.Item>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="text"
                        style={{ marginRight: 10, height: "auto" }}
                        onClick={() => props.onBackModal()}
                    >
                        Cancelar
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Sincronizar
                    </Button>
                </ModalFooter>
            </div>
        </Form>
    )
}

export default SyncZapHistoryModal
