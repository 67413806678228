import React from "react"
import cx from "classnames"
import BaseNode, { BaseNodeProps } from "../BaseNode"
import { WorkflowActionsItem, WorkflowDefs } from "../../workflow.defs"
import { Button } from "antd"
import AddTriggerBtn from "./AddTriggerBtn"
import { colors } from "lib/colors"

interface TriggerNodeProps extends BaseNodeProps {}

function TriggerNode(props: TriggerNodeProps) {
  const actionProps = WorkflowDefs.ActionRecord[props.type] as WorkflowActionsItem
  const category = WorkflowDefs.CategoryRecord[actionProps?.variableCategory]
  const Icon = actionProps?.icon

  const triggers = Object.keys(props.data?.action?.triggers || {})?.map((triggerKey) => {
    return {
      type: triggerKey,
      data: { active: props.data?.action?.triggers?.[triggerKey].active },
    }
  })

  return (
    <BaseNode
      {...props}
      customHeader={
        <div className={cx("w-full flex items-center justify-center text-base h-10 px-4 pt-1")}>
          <div className="flex justify-start items-center w-full gap-3 font-medium">
            <Icon
              style={{
                color: colors[category?.palette][600],
              }}
            />
            Quando...
            {(props.data?.action as any)?.text}
          </div>
        </div>
      }
      // defaultHandleLabel="Então..."
      hideActions
      notTarget
      className="w-64"
    >
      <div className="flex flex-col gap-2 w-full">
        {triggers?.map((trigger, index) => {
          const def = WorkflowDefs.trigger.TriggerRecord[trigger.type]
          const category = WorkflowDefs.trigger.CategoryRecord[def.category]
          const Icon = def.icon

          return (
            <Button
              key={trigger.type}
              className={cx(
                "h-12 px-2.5 bg-base-200 dark:bg-hover-overlay flex items-center gap-2.5 rounded-lg justify-start text-left w-full mb-1",
                "!outline-2 !outline-primary focus:!outline hover:!outline active:!outline"
              )}
              onClick={() => {}}
              type="text"
            >
              <Icon className="text-green-600 !w-5 !h-5" />
              <div className="leading-[18px] font-normal truncate">
                <div className="font-normal text-[13px] truncate">{def?.name}</div>
                <div className="text-3xs font-normal text-content-300">{category.label}</div>
                {/* <div className="text-3xs font-normal text-content-300">#{def.type}</div> */}
              </div>
            </Button>
          )
        })}

        <AddTriggerBtn id={props.id} style={{ marginTop: triggers?.length ? "5px" : 0, fontSize: 12 }} />
        {/* <Button
          icon={<PlusSignIcon className="!w-4 !h-4" />}
          className="w-full py-5 border-2 border-dashed border-divider-100 !text-xs text-primary hover:border-primary active:bg-none focus:!bg-transparent hover:bg-sky-100 hover:text-primary"
          style={triggers?.length ? { marginTop: "5px" } : {}}
          type="text"
          onClick={(e) => {
            e?.stopPropagation()
            setModal(
              <SelectTriggerModal
                onSelect={(trigger) => {
                  updateElementAction(
                    props.id,
                    (oldAction) => ({
                      ...oldAction!,
                      triggers: {
                        ...oldAction?.triggers!,
                        [trigger]: {
                          active: true,
                        },
                      },
                    }),
                    { setElements }
                  )
                  setModal(null)
                }}
                onCancel={() => {
                  setModal(null)
                }}
              />
            )
          }}
        >
          Novo gatilho
        </Button> */}
      </div>
    </BaseNode>
  )
}

export default TriggerNode
