import { themeAtom } from "atoms/app.atom"
import { ColorPalette, ColorSwatch, getDarkTagColors, getLightTagColors } from "lib/colors"
import React, { memo } from "react"
import { useRecoilState } from "recoil"
import { twMerge } from "tailwind-merge"

interface TagColorProps {
  // icon: React.ElementType
  children: React.ReactNode
  className?: string
  // iconClassName?: string
  // size?: "sm" | "md" | "lg"
  palette: ColorPalette
  animated?: boolean
  lightTone?: {
    bg?: keyof ColorSwatch
    text?: keyof ColorSwatch
    border?: keyof ColorSwatch
  }
}

function TagColor(props: TagColorProps) {
  const [theme] = useRecoilState(themeAtom)
  const colors = theme === "dark" ? getDarkTagColors(props.palette) : getLightTagColors(props.palette, { tone: props.lightTone })

  return (
    <span
      className={twMerge(
        "rounded flex-center px-1.5 gap-1.5",
        props.animated && "animate-jump animate-duration-100 animate-ease-out",
        // (props.size === "md" || !props.size) && "!w-7 !h-7 p-[5px]",
        // props.size === "sm" && "!w-5 !h-5 p-1.5",
        // props.size === "lg" && "!w-7 !h-7 p-1",
        props.className
      )}
      style={{
        backgroundColor: colors.bg,
        color: colors.text,
        borderColor: colors.border,
      }}
    >
      {props.children}
      {/* <props.icon className={twMerge("w-full h-full", props.iconClassName)} /> */}
    </span>
  )
}

export default memo(TagColor)
