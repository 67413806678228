import { Button, Form } from "antd"
import { isMobileAtom } from "atoms/app.atom"
import EditableDropField from "components-old/common/EditableDropField"
import { Analytics01Icon, Edit02Icon } from "components-old/icons"
import { useStoreState } from "hooks"
import { InputType } from "lib/constants"
import { Link, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import Tooltip from "components-old/Tooltip"
import ActiveWorkflowButton from "./ActiveWorkflowButton"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import styles from "./style.module.css"
import WorkflowHeaderSearch from "./WorkflowHeaderSearch"
import { useState } from "react"
import { Workflow } from "models/workflows"
import AnalyticsDrawer from "./analytics/AnalyticsDrawer"

interface WorkflowHeaderProps {
  changed: boolean
  fullscreen: boolean
  setChanged: (changed: boolean) => void
  workflow?: Workflow
}

function WorkflowHeader(props: WorkflowHeaderProps) {
  const { loading } = useStoreState((state) => state.workflows)
  const [isMobile] = useRecoilState(isMobileAtom)
  const { id } = useParams<any>()
  const [showAnalytics, setShowAnalytics] = useState(false)

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue, setFieldsValue }) => {
        return (
          <>
            <div
              className={twMerge(
                "w-full h-[60px] flex items-center gap-2 justify-between select-none",
                props.fullscreen && "fixed top-2 right-0 z-10 px-4 pointer-events-none"
              )}
            >
              <div className={cx("flex items-center gap-2", props.fullscreen && styles.panel)}>
                <Link to="/settings/workflows">
                  <Button icon={<i className="far fa-angle-left" />} className="rounded-full" />
                </Link>
                <div className="truncate">
                  <EditableDropField
                    format={(name) => (
                      <div className="flex items-center">
                        <h2 className="font-medium !text-lg !mb-0 truncate block h-full !leading-9">{name}</h2>
                        <Button icon={<Edit02Icon className="!w-4 !h-4" />} type="text" className="rounded-full" />
                      </div>
                    )}
                    value={getFieldValue("name")}
                    type={InputType.Text}
                    label={"Nome do cenário"}
                    hideLabel
                    placeholder="name"
                    notCopyable
                    onSubmit={async (model) => {
                      props.setChanged(true)
                      return setFieldsValue({ name: model.name })
                    }}
                    hideSuccessMessage
                  />
                </div>
                <div className="shrink-0 w-px h-6 mt-[3px] bg-divider-100 mx-3" />
                <ActiveWorkflowButton getFieldValue={getFieldValue} />
              </div>
              <div className="flex justify-center">{/* <ActiveWorkflowButton getFieldValue={getFieldValue} /> */}</div>
              {!isMobile && (
                <div className={cx("flex items-center gap-2", props.fullscreen && styles.panel)}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading.saving}
                    disabled={!props.changed}
                    className={cx("rounded-xl", !props.changed && "opacity-50")}
                  >
                    Publicar
                  </Button>
                  {/* <Tooltip title="Simular" placement="bottom" mouseEnterDelay={0}>
                    <Button
                      type="text"
                      icon={
                        <i className="flex items-center">
                          <PlayIcon />
                        </i>
                      }
                      onClick={() => {}}
                      disabled
                    />
                  </Tooltip> */}
                  <Tooltip title="Analítico" placement="bottom" mouseEnterDelay={0}>
                    <Button
                      type="text"
                      icon={
                        <i className="flex items-center">
                          <Analytics01Icon />
                        </i>
                      }
                      onClick={() => {
                        // setModal(
                        //   <AnalyticsModal
                        //     workflowId={id}
                        //     onCancel={function (): void {
                        //       setModal(null)
                        //     }}
                        //   />
                        // )
                        setShowAnalytics(true)
                      }}
                      disabled={id === "new"}
                    />
                  </Tooltip>
                  <WorkflowHeaderSearch />
                  {/* <Tooltip title="Pesquisar" placement="bottom" mouseEnterDelay={0}>
                  <Button type="text" icon={<Search01Icon />} disabled onClick={() => {}} />
                </Tooltip> */}
                </div>
              )}
            </div>
            <AnalyticsDrawer
              workflow={props.workflow}
              visible={showAnalytics}
              onCancel={function (): void {
                setShowAnalytics(false)
              }}
            />
          </>
        )
      }}
    </Form.Item>
  )
}

export default WorkflowHeader
