import { endpoints } from "config/endpoints.config"
// import { InputType } from "lib/constants"
import { GenericResource, IGenericModel, generic } from "models/generic"

type IAssignmentRuleTo =
  | "agent"
  | "group"
  | "round_robin_agent"
  | "round_robin_agent_group"
  | "round_robin_agent_groups"
  | "weight_agent"
  | "weight_agent_group"
  | "weight_agent_online"
  | "last_assigned_agent"
  | "agent_group"
  | "round_robin_group"

export interface IAssignmentRule extends GenericResource {
  id: string
  updatedAt: string
  createdAt: string
  name: string
  assignTo: IAssignmentRuleTo
  isActive: boolean
  rule: "all" | "new" | "existing"
  groupId: string
  agentId: string
  groups?: string[]
}

export const assignmentRules: IGenericModel<IAssignmentRule> = {
  ...generic(endpoints.ASSIGNMENT_RULES),
}
