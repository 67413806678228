import React, { useMemo } from "react"
import { Button } from "antd"
import { useStoreState } from "hooks"
import TagDrop from "components-old/ui/TagDrop"
import { ITag } from "models/tags"
import { twMerge } from "tailwind-merge"
import { convertColorToTagColor, tagColors } from "pages/settings/tags/EditTag"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"
import _ from "lodash"
import { verifyPermission } from "lib/helper"
import toast from "lib/toast"
import { animated, useTransition } from "react-spring"

interface TagInputProps {
  selectedIds?: number[]
  onAdd?: (tag: ITag) => void
  onRemove?: (tag: ITag) => void
  className?: string
  tagItemProps?: TagItemProps
  customAdd?: React.ReactNode
  customTagDrop?: React.ReactNode
}

interface TagItemProps {
  children?: React.ReactNode | string
  size?: "sm" | "md" | "lg"
  colors?: { bg: string; text: string; border?: string; 500: string }
  className?: string
  bordered?: boolean
  alwaysShowRemove?: boolean
  onRemove?: () => void
  animated?: boolean
  capitalize?: boolean
}

export const TagItem = (props: TagItemProps) => {
  const [theme] = useRecoilState(themeAtom)
  const colors = props.colors || tagColors.gray
  const isDefaultColor = colors.bg === tagColors.gray.bg

  return (
    <div
      className={twMerge(
        "tag-box-item px-1.5 py-0.5 rounded-lg text-xs font-medium truncate leading-4 group",
        props.size === "lg" ? "px-1.5 py-0.5 text-[13px] font-semibold !shadow-none" : "",
        props.size === "sm" ? "py-0" : "",
        props.bordered && "border border-solid border-divider-100 !bg-base-100",
        props.animated && "animate-jump-in animate-duration-200 animate-ease-out",
        props.className
      )}
      style={{
        backgroundColor: theme === "dark" ? colors[600] || colors[500] : colors.bg,
        // color: colors.text,
        color: theme === "dark" ? (props.bordered ? "var(--content-primary)" : isDefaultColor ? "#e0e1e5" : "white") : colors.text,
        // boxShadow: `${colors.border} 0px 0px 0px 1px inset`,
        width: "fit-content",
      }}
      title={typeof props.children === "string" ? props.children : undefined}
    >
      {props.children}
      {props.onRemove && (
        <i
          className={twMerge(
            "far fa-times ml-1.5 text-xs cursor-pointer hidden group-hover:inline group-hover:font-bold",
            props.alwaysShowRemove && "inline"
          )}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onRemove?.()
          }}
        />
      )}
    </div>
  )
}

function TagInput(props: TagInputProps) {
  const { all: tags } = useStoreState((state) => state.tags)

  const selectedTags: ITag[] = useMemo(() => {
    return _.uniq(props.selectedIds)
      ?.map((tagId: number) => tags.find((x) => Number(x.id) == tagId))
      .filter((x) => x) as ITag[]
  }, [props.selectedIds, tags])

  // const maxWidthStyle = maxWidth / (selectedTags.length + (props.group ? 1 : 0)) - 5

  const transitions = useTransition(selectedTags, {
    keys: (tag) => tag?.id!,
    from: { opacity: 0.5, transform: "scale(0.5)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0.5)" },
    expires: 150,
    config: { tension: 280, friction: 60, duration: 150 },
  })

  return (
    <div className={twMerge("flex gap-0.5 flex-wrap", props.className)}>
      {/* {selectedTags?.map((tag) => ( */}
      {transitions((style, tag, t, index) => {
        return (
          <animated.div style={style}>
            <TagItem
              key={tag.id}
              colors={convertColorToTagColor(tag.color)}
              onRemove={() => {
                props.onRemove?.(tag)
              }}
              size="lg"
              alwaysShowRemove
              {...props.tagItemProps}
            >
              {tag.name}
            </TagItem>
          </animated.div>
        )
      })}
      {/* ))} */}
      {props.customTagDrop || (
        <TagDrop onSubmit={props.onAdd} selectedIds={props.selectedIds}>
          {props.customAdd || (
            <Button
              type="text"
              className="rounded-lg"
              icon={<i className="fas fa-plus" style={{ fontSize: 10, marginTop: 2, marginRight: -2 }} />}
              size="small"
              style={{
                fontSize: 13,
                height: 22,
                marginTop: 2,
                marginBottom: 2,
                color: "var(--content-secondary)",
              }}
            >
              Add
            </Button>
          )}
        </TagDrop>
      )}
    </div>
  )
}

export default TagInput
