import {
  Bot02Icon,
  BotIcon,
  CustomerService02Icon,
  KeyframesDoubleAddIcon,
  Message01Icon,
  MessageAdd01Icon,
  MessageDone01Icon,
  MessageEdit01Icon,
  MessageSearch01Icon,
  Queue01Icon,
} from "components-old/icons"
import { VariableCategory, VariableItem, WorkflowActionsItem, WorkflowEntityDef } from "../../workflow.defs"
import CreateConversationNode from "./create-conversation/CreateConversation.node"
import CreateConversationAction from "./create-conversation/CreateConversation.action"
import { CategoryEnum, VariableEnum, ActionEnum } from "../../workflow.enums"
import RunBotNode from "./run-bot/RunBot.node"
import RunBotAction from "./run-bot/RunBot.action"
import ArchiveConversationNode from "./archive-conversation/ArchiveConversation.node"
import ArchiveConversationAction from "./archive-conversation/ArchiveConversation.action"
import { getDescriptionFormatted } from "../../workflow.helper"
import FindConversationNode from "./find-conversation/FindConversation.node"
import FindConversationAction from "./find-conversation/FindConversation.action"
import { InputType } from "lib/constants"
import QueueConversationNode from "./queue-conversation/QueueConversation.node"
import QueueConversationAction from "./queue-conversation/QueueConversation.action"
import AssignConversationNode from "./assign-conversation/AssignConversation.node"
import AssignConversationAction from "./assign-conversation/AssignConversation.action"

export const ConversationWorkflowDefs: WorkflowEntityDef = {
  /*********************************
   *  Category
   **********************************/
  category: {
    type: CategoryEnum.CONVERSATION,
    label: "Conversa",
    icon: Message01Icon,
    palette: "sky",
  },
  /*********************************
   *  Fields
   **********************************/
  fields: [
    {
      label: "ID",
      icon: Message01Icon,
      type: InputType.Text,
      name: "id",
      selectable: false,
    },
  ],
  /*********************************
   *  Actions
   **********************************/
  actions: [
    {
      type: ActionEnum.CONVERSATION_FIND,
      name: "Buscar conversa",
      icon: MessageSearch01Icon,
      node: FindConversationNode,
      edit: FindConversationAction,
      isReferenceable: true,
      variableCategory: CategoryEnum.CONVERSATION,
      getDescription(dto) {
        const conversation = dto.actionLog?.context?.conversation?.id

        if (!conversation) return "Conversa não encontrada."

        return getDescriptionFormatted({
          preffix: "A conversa ",
          suffix: "foi encontrada.",
          avatar: dto.actionLog?.context?.conversation?.avatar || "",
          name: dto.actionLog?.context?.conversation?.name,
          palette: dto.category.palette,
        })
      },
    },
    {
      type: ActionEnum.CONVERSATION_CREATE,
      name: "Criar conversa",
      icon: MessageAdd01Icon,
      node: CreateConversationNode,
      isReferenceable: true,
      edit: CreateConversationAction,
      variableCategory: CategoryEnum.CONVERSATION,
      getDescription: (dto) => {
        const conversation = dto.actionLog?.context?.conversation?.id

        if (!conversation) return "Conversa não encontrada."

        const wasCreated = new Date(dto.actionLog?.context?.conversation?.createdAt) > new Date(dto.executionLog.startedAt)
        return getDescriptionFormatted({
          preffix: "A conversa de",
          suffix: wasCreated ? "foi criada." : "foi encontrada.",
          // icon: dto.category?.icon,
          avatar: dto.actionLog?.context?.conversation?.contact?.avatar || "",
          name: dto.actionLog?.context?.conversation?.contact?.name,
          palette: dto.category.palette,
        })
        // return (
        //   <span className="flex items-center gap-1">
        //     Conversa
        //     <div className="shadow-button bg-base-100 px-2 rounded-xl flex items-center gap-1 text-content-100 truncate">
        //       <Avatar
        //         imgSrc={dto.actionLog?.context?.conversation?.avatar}
        //         size={14}
        //         name={dto.actionLog?.context?.conversation?.contact.name}
        //       />
        //       {dto.actionLog?.context?.conversation?.contact.name}
        //     </div>
        //     foi {wasCreated ? "criada" : "encontrada"}.
        //   </span>
        // )
      },
    },
    // {
    //   type: ActionEnum.CONVERSATION_UPDATE,
    //   name: "Atualizar conversa",
    //   icon: MessageEdit01Icon,
    //   node: UpdateConversationNode,
    //   edit: UpdateConversationAction,
    //   variableCategory: CategoryEnum.CONVERSATION,
    // },
    {
      type: ActionEnum.CONVERSATION_ARCHIVE,
      name: "Resolver conversa",
      icon: MessageDone01Icon,
      node: ArchiveConversationNode,
      edit: ArchiveConversationAction,
      variableCategory: CategoryEnum.CONVERSATION,
      getDescription: (dto) => {
        return getDescriptionFormatted({
          preffix: "Conversa de ",
          avatar: dto.actionLog?.context?.conversation?.contact?.avatar || "",
          name: dto.actionLog?.context?.conversation?.contact?.name,
          palette: dto.category.palette,
          suffix: "foi resolvida.",
        })
      },
    },
    {
      type: ActionEnum.CONVERSATION_RUN_BOT,
      name: "Executar bot",
      icon: Bot02Icon,
      node: RunBotNode,
      edit: RunBotAction,
      variableCategory: CategoryEnum.CONVERSATION,
      getDescription(dto) {
        return getDescriptionFormatted({
          preffix: "Foi iniciado o bot na conversa de ",
          avatar: dto.actionLog?.context?.conversation?.contact?.avatar || "",
          name: dto.actionLog?.context?.conversation?.contact?.name,
          palette: dto.category.palette,
        })
      },
    },
    // {
    //   type: ActionEnum.CONVERSATION_ADD_TO_QUEUE,
    //   name: "Adicionar na fila",
    //   // icon: Queue01Icon,
    //   icon: KeyframesDoubleAddIcon,
    //   node: QueueConversationNode,
    //   edit: QueueConversationAction,
    //   variableCategory: CategoryEnum.CONVERSATION,
    //   getDescription: (dto) => {
    //     return getDescriptionFormatted({
    //       preffix: "Conversa de ",
    //       avatar: dto.actionLog?.context?.conversation?.contact?.avatar || "",
    //       name: dto.actionLog?.context?.conversation?.contact?.name,
    //       palette: dto.category.palette,
    //       suffix: "foi adicionada na fila.",
    //     })
    //   },
    // },
    {
      type: ActionEnum.CONVERSATION_ASSIGN,
      name: "Atribuir para agente",
      icon: CustomerService02Icon,
      node: AssignConversationNode,
      edit: AssignConversationAction,
      variableCategory: CategoryEnum.CONVERSATION,
      getDescription: (dto) => {
        const error = dto.actionLog?.context?.error

        if (error) return error

        return getDescriptionFormatted({
          preffix: "Conversa de ",
          avatar: dto.actionLog?.context?.conversation?.contact?.avatar || "",
          name: dto.actionLog?.context?.conversation?.contact?.name,
          palette: dto.category.palette,
          suffix: "foi atribuída.",
        })
      },
    },
  ],
  /*********************************
   *  Variables
   **********************************/
  variables: [
    {
      type: VariableEnum.CONVERSATION_ID,
      category: CategoryEnum.CONVERSATION,
      label: "ID",
      primitiveType: "string",
      description: "Identificador único da conversa.",
    },
    {
      type: VariableEnum.CONVERSATION_CREATED_AT,
      category: CategoryEnum.CONVERSATION,
      label: "Criado em",
      primitiveType: "string",
      description: "Data de criação da conversa.",
    },
    {
      type: VariableEnum.CONVERSATION_JSON,
      category: CategoryEnum.CONVERSATION,
      label: "$json",
      primitiveType: "json",
      description: "Conversa em formato JSON.",
      json: {
        identifier: "string",
        createdAt: "Fri May 31 2024 23:46:13 GMT+0000 (Coordinated Universal Time)",
        email: "string",
        workspacePlatformId: "string",
        isBlocked: false,
        finishedAt: "string",
        botId: "string",
        lastMessageAt: "Fri May 31 2024 23:46:16 GMT+0000 (Coordinated Universal Time)",
        contact: {
          id: "string",
          avatar: "string",
          email: "string",
          location: "string",
          name: "string",
          phone: "string",
          newContact: false,
        },
        hasNewMessages: false,
        lastCallMessageId: "string",
        showPending: false,
        referral: "string",
        location: "string",
        pendingAt: "string",
        isReopened: false,
        isFinished: false,
        operatorLastMessageAt: "string",
        customerLastMessageAt: "string",
        lastSeen: "string",
        isCreatedByActiveChat: false,
        isGroup: false,
        groupId: "string",
        name: "string",
        protocol: "string",
        operatorId: "string",
        isNew: false,
        isFromIntegration: false,
        avatar: "string",
        isChatBotActive: false,
        platformId: "string",
        newContact: false,
        phone: "string",
        id: "string",
        neverAnsweredByOperator: true,
        newCustomer: true,
        callId: "string",
        customerId: "string",
        expirationDate: "string",
        tags: ["string"],
        isPrivate: false,
        isDbMigrated: false,
        isHidden: false,
        isWithoutOperatorAnswer: false,
        lastMessage: {
          _id: "string",
          idMessage: 9464,
          workspaceId: 1,
          customerPlatformId: 162,
          callId: 2260,
          originalChannelId: "string",
          Call: {
            id: "string",
            operatorId: "string",
            customerPlatformId: "string",
            workspacePlatformId: "string",
            campaignId: "string",
            lastCallMessageId: "string",
            workspaceId: "string",
            groupId: "string",
            labelId: "string",
            chatBotId: "string",
            protocol: "string",
            isChatBotActive: false,
            isFinished: false,
            isWithoutOperatorAnswer: true,
            hasScheduledAutomation: false,
            isWithoutClientAnswer: false,
            finishedAt: "2024-05-31T23:46:16.052Z",
            firstOperatorMessageAt: "2024-05-31T23:46:16.052Z",
            lastOperatorMessageAt: "2024-05-31T23:46:16.052Z",
            firstCustomerMessageAt: "2024-05-31T23:46:16.052Z",
            lastCustomerMessageAt: "2024-05-31T23:46:16.052Z",
            isNew: false,
            isReopened: false,
            isImported: false,
            isHidden: false,
            isRatingActive: false,
            isFromIntegration: false,
            isCreatedByActiveChat: false,
            isCreatedByContact: false,
            answeredAt: "2024-05-31T23:46:16.052Z",
            referral: "json",
            rating: null,
            schedules: null,
            botLoopCount: 1,
            callExpiresAt: "2024-05-31T23:46:16.052Z",
            uniqueIdentifier: "string",
            createdAt: "2024-05-31T23:46:16.052Z",
            CustomerPlatform: null,
          },
          operatorId: "string",
          operatorName: "string",
          operatorAvatar: "string",
          contactName: "string",
          content: '[{"insert":"string"}]',
          extraDataMessage: {},
          replyTo: {},
          replyToMeta: {},
          externalId: "string",
          status: 1,
          tempId: "string",
          fileName: "string",
          size: 1,
          isDeleted: false,
          type: 1,
          attempts: 1,
          resendPosition: 1,
          identifier: "string",
          customerIdExternalId: "string",
          isSent: false,
          isError: false,
          errorDescription: "string",
          isMedia: false,
          isPinned: false,
          isScheduled: false,
          isForwarding: false,
          extra: { workflowId: "string" },
          viewedAt: "2024-05-31T23:46:16.073Z",
          createdAt: "2024-05-31T23:46:16.073Z",
        },
        customFields: {},
      },
      // json: {
      //   call: {
      //     id: 1,
      //     answeredAt: "2024-05-28T15:23:08.290Z",
      //     createdAt: "2024-05-28T15:23:08.290Z",
      //     customerPlatformId: 1,
      //     isFinished: true,
      //     finishedAt: "2024-05-28T15:23:08.290Z",
      //     groupId: 1,
      //     isFromIntegration: true,
      //     isHidden: true,
      //     isImported: true,
      //     new: true,
      //     isReopened: true,
      //     isWithoutOperatorAnswer: true,
      //     isCreatedByActiveChat: true,
      //     operatorId: 1,
      //     workspacePlatformId: 1,
      //     lastCallMessageId: 1,
      //     workspaceId: 1,
      //     chatBotId: 1,
      //     protocol: "string",
      //     extraData: "json",
      //     isChatBotActive: true,
      //     hasScheduledAutomation: true,
      //     isWithoutClientAnswer: true,
      //     isRatingActive: true,
      //     firstOperatorMessageAt: "2024-05-28T15:23:08.290Z",
      //     lastOperatorMessageAt: "2024-05-28T15:23:08.290Z",
      //     firstCustomerMessageAt: "2024-05-28T15:23:08.290Z",
      //     lastCustomerMessageAt: "2024-05-28T15:23:08.290Z",
      //     callExpiresAt: "2024-05-28T15:23:08.290Z",
      //     referral: {
      //       utm_campaign: "string",
      //       utm_source: "string",
      //       utm_medium: "string",
      //       utm_term: "string",
      //       utm_content: "string",
      //       origin: "string",
      //       headline: "string",
      //       ctwa_clid: "string",
      //       image_url: "string",
      //       video_url: "string",
      //       source_id: "string",
      //       media_type: "string",
      //       source_url: "string",
      //       thumbnail_url: "string",
      //       source_type: "string",
      //       body: "string",
      //     },
      //     botLoopCount: 1,
      //     uniqueIdentifier: "string",
      //     customFields: {},
      //   },
      //   assignedOperatorMessagesCount: 1,
      //   botMessagesCount: 1,
      //   contact: {
      //     id: 1,
      //     name: "string",
      //     avatar: "string",
      //     email: "string",
      //     phone: "string",
      //     notes: "string",
      //     newContact: true,
      //     createdAt: "2024-05-28T15:23:08.290Z",
      //     customFields: {},
      //   },
      //   contactMessagesCount: 1,
      //   conversation: {
      //     lastSeen: "2024-05-28T15:23:08.290Z",
      //     createdAt: "2024-05-28T15:23:08.290Z",
      //     lastMessageAt: "2024-05-28T15:23:08.290Z",
      //     customerLastSeen: "2024-05-28T15:23:08.290Z",
      //     newCustomer: true,
      //     pendingAt: "2024-05-28T15:23:08.290Z",
      //     hasNewMessages: true,
      //     identifier: "string",
      //     showPending: true,
      //     importationTimestamp: 1,
      //     wasImported: true,
      //     isDbMigrated: true,
      //     isBlocked: true,
      //     isGroup: true,
      //     origin: "string",
      //     customerLastMessageAt: "2024-05-28T15:23:08.290Z",
      //     operatorLastMessageAt: "2024-05-28T15:23:08.290Z",
      //     uniqueIdentifier: "string",
      //     expirationDate: "2024-05-28T15:23:08.290Z",
      //     id: 1,
      //     platformId: 1,
      //   },
      //   createdAt: "2024-05-28T15:23:08.290Z",
      //   followers: [],
      //   operatorMessagesCount: 1,
      //   scheduledMessages: [],
      //   tags: [1],
      //   totalMessagesCount: 1,
      //   trails: [
      //     {
      //       id: 1,
      //       type: "string",
      //       date: "2023-12-05",
      //     },
      //   ],
      //   updatedAt: "2024-05-28T15:23:08.290Z",
      //   workspaceId: 1,
      //   lastMessage: {
      //     idMessage: 1,
      //     _id: "string",
      //     operatorId: 1,
      //     content: '[{"insert":"string"}]',
      //     createdAt: "2024-05-28T15:23:08.290Z",
      //     type: 1,
      //     id: "string",
      //   },
      //   tma: 1,
      //   tme: 1,
      //   tmp: 1,
      // },
    },
    {
      type: VariableEnum.CONVERSATION_SESSION_ID,
      category: CategoryEnum.CONVERSATION,
      label: "ID da sessão",
      primitiveType: "number",
      description: "Identificador único da sessão atual.",
    },
    {
      type: VariableEnum.CONVERSATION_PROTOCOL,
      category: CategoryEnum.CONVERSATION,
      label: "Protocolo",
      primitiveType: "string",
      description: "Protocolo da sessão atual.",
    },
    {
      type: VariableEnum.CONVERSATION_ASSIGNED,
      category: CategoryEnum.CONVERSATION,
      label: "Atribuído",
      primitiveType: "string",
      description: "ID do agente atribuído da sessão atual.",
    },
    {
      type: VariableEnum.CONVERSATION_GROUP,
      category: CategoryEnum.CONVERSATION,
      label: "Grupo",
      primitiveType: "string",
      description: "ID do Grupo da sessão atual.",
    },
    {
      type: VariableEnum.CONVERSATION_CHANNEL,
      category: CategoryEnum.CONVERSATION,
      label: "Canal",
      primitiveType: "string",
      description: "ID do Canal da sessão atual.",
    },
  ],
}
