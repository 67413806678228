import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { Form } from "antd"
import SelectReference from "pages/settings/workflows/actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import InputVariableFormItem from "components-old/forms/InputVariable/InputVariableFormItem"
import BaseUpdateAction from "pages/settings/workflows/actions/BaseUpdateAction"
import { useRecoilState } from "recoil"
import { entityFieldsAtom } from "atoms/workflow.atom"

interface FindDealPropsAction extends BaseEditActionChildrenProps {}

const FindDealAction = (props: FindDealPropsAction) => {
  const fieldName = [...props.actionFieldName, "deal"]
  const [dynamicFields] = useRecoilState(entityFieldsAtom)

  return (
    <div>
      <Form.Item label="Contato" name={[...fieldName, "contactReference"]}>
        <SelectReference showSearch allowClear category={WorkflowDefs.CategoryRecord.contact} />
      </Form.Item>
      <Form.Item label="Conversa" name={[...fieldName, "conversationReference"]}>
        <SelectReference showSearch allowClear category={WorkflowDefs.CategoryRecord.conversation} />
      </Form.Item>
      {/* <Form.Item label="Tarefa" name={[...fieldName, "taskReference"]}>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.conversation} />
      </Form.Item> */}
      <InputVariableFormItem field={WorkflowDefs.Fields.deal.record.id} name={[...fieldName, "id"]} />
      <BaseUpdateAction form={props.form} fieldName={fieldName} fields={dynamicFields.deal} />
    </div>
  )
}

export default FindDealAction
