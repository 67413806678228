import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum NewsTag {
    Improvement = "improvement",
    Feature = "feature",
    Bug = "bug",
}

export const getNewTagInfo = (tag: NewsTag) => {
    switch (tag) {
        case NewsTag.Improvement:
            return {
                color: "#e2f0fa",
                textColor: "#20459c",
                label: "Melhoria",
            }
        case NewsTag.Feature:
            return {
                color: "#EBFAE2",
                textColor: "#4F9C20",
                label: "Novidade",
            }
        case NewsTag.Bug:
            return {
                color: "#fae2e2",
                textColor: "#9c2020",
                label: "Bug",
            }
        default:
            return {
                color: "#9E9E9E",
                label: "Outro",
            }
    }
}

export interface INews {
    id: string
    title: string
    text: string
    tags?: NewsTag[]
    image?: string
    video?: string
    updatedAt: string
    createdAt: string
    reactions?: Record<number, string>
    feedbackCount: number
}

export interface NewsFeedback {
    _id: string
    id: string
    workspaceId: string
    operatorId: number
    operatorName: string
    operatorAvatar: string
    workspaceName: string
    text: string
    createdAt: string
    type?: string
    newsId?: string
}

export const news: IGenericModel<INews> = {
    ...generic(endpoints.NEWS),
}
