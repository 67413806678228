import React, { useMemo, useState } from "react"
import { Button, Modal } from "antd"
import { twMerge } from "tailwind-merge"
import SearchInput from "components-old/common/SettingsPage/SearchInput"
import { throttle } from "lodash"
import { WorkflowDefs } from "../../workflow.defs"
import { WorkflowTriggerCategoryTypeEnum, WorkflowTriggerTypeEnum } from "../workflow.triggers.defs"

interface SelectTriggerModalProps {
  onSelect: (trigger: WorkflowTriggerTypeEnum) => void
  onCancel: () => void
}

function SelectTriggerModal(props: SelectTriggerModalProps) {
  const [selectedCategory, setSelectedCategory] = useState<WorkflowTriggerCategoryTypeEnum | null>(WorkflowDefs.trigger.Category.OTHER)
  const [search, setSearch] = useState("")

  const setSearchDebounced = useMemo(
    () =>
      throttle((value) => {
        setSearch(value)
      }, 100),
    []
  )

  return (
    <Modal
      title={
        <div className="flex items-center justify-between w-full pt-2 pr-6">
          <div className="flex-1 text-lg">Começar o cenário quando...</div>
          <div>
            <SearchInput
              autoFocus
              gray
              icon
              onChange={(e) => {
                setSearchDebounced(e.target.value)
              }}
            />
          </div>
        </div>
      }
      className="g_trigger_modal"
      visible
      footer={null}
      closable={false}
      closeIcon={<div />}
      width={762}
      bodyStyle={{
        minHeight: 400,
      }}
      maskClosable
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onCancel={props.onCancel}
    >
      <div className="flex gap-6">
        {/* {!search && (
          <div className="flex flex-col">
            {WorkflowDefs.trigger.categories.map((category) => {
              const Icon = category.icon
              return (
                <a
                  key={category.type}
                  className={twMerge(
                    "h-9 pl-3 pr-5 hover:bg-base-300 flex items-center gap-2 rounded-lg",
                    selectedCategory === category.type && "font-medium bg-base-300"
                  )}
                  onClick={() => setSelectedCategory(category.type)}
                >
                  <Icon />
                  {category.label}
                </a>
              )
            })}
          </div>
        )} */}
        <div className="flex-1 overflow-y-auto overflow-x-visible px-2">
          {/* {!!search ? (
            <div className="text-md">Resultado da pesquisa:</div>
          ) : (
            <div className="">
              <div className="font-medium text-md">{WorkflowDefs.trigger.CategoryRecord[selectedCategory!].label}</div>
              <div className="text-xs text-content-200">{WorkflowDefs.trigger.CategoryRecord[selectedCategory!].description}</div>
            </div>
          )} */}
          <div className="py-4">
            {WorkflowDefs.trigger.triggers
              // .filter((trigger) => (search ? true : !selectedCategory || trigger.category === selectedCategory))
              .filter((trigger) => (search ? trigger.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true))
              .map((trigger) => {
                const Icon = trigger.icon
                return (
                  <Button
                    key={trigger.type}
                    className="h-16 px-5 flex bg-base-200 items-center gap-4 rounded-lg justify-start text-left w-full hover:bg-base-300 hover:shadow-none mb-3"
                    // style={{
                    //   border: "1px solid var(--divider)",
                    // }}
                    onClick={() => props.onSelect(trigger.type)}
                    type="text"
                  >
                    <Icon className="text-green-600 !w-6 !h-6" />
                    <div className="leading-5 -mt-1">
                      <div className="text-content-200 text-xs font-normal">
                        {WorkflowDefs.trigger.CategoryRecord[trigger.category!].label}
                      </div>
                      <div className="font-medium text-md">{trigger.name}</div>
                    </div>
                  </Button>
                )
              })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SelectTriggerModal
