import React, { useMemo } from "react"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { CheckmarkCircle02Icon, ContactIcon, Mail02Icon, SmartPhone01Icon, StickyNote02Icon, UserSearch01Icon } from "components-old/icons"
import { useRecoilState } from "recoil"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import BaseExpanderFields from "../../../actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { DealWorkflowDefs } from "../deal.workflow.def"
import { DealStatusLabel } from "models/deals"
import { variableAtom } from "atoms/app.atom"

interface UpdateDealNodeProps extends BaseNodeProps {}

function UpdateDealNode(props: UpdateDealNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const deal = props.data?.action?.deal
    if (deal?.dealReference) {
      fields.push({
        icon: UserSearch01Icon,
        value: (
          <span>
            Negócio <span className="text-pink-600">#{deal?.dealReference}</span>
          </span>
        ),
        label: "Negócio",
      })
    }
    if (deal?.status) {
      fields.push({
        icon: CheckmarkCircle02Icon,
        value: DealStatusLabel[deal.status as any],
        label: "Status",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.deal])

  return (
    <BaseNode {...props} title="Atualizar negócio">
      <BaseExpanderFields fields={fields} category={CategoryEnum.DEAL} />
    </BaseNode>
  )
}

export default UpdateDealNode
