import React, { useMemo } from "react"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { Id01Icon, SmartPhone01Icon } from "components-old/icons"
import { useRecoilState } from "recoil"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import BaseExpanderFields from "../../../actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { Handle, Position } from "react-flow-renderer"
import styles from "../../../actions/style.module.css"
import cx from "classnames"
import { variableAtom } from "atoms/app.atom"

interface FindContactNodeProps extends BaseNodeProps {}

function FindContactNode(props: FindContactNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const contact = props.data?.action?.contact || {}
    if (contact.id) {
      fields.push({
        icon: Id01Icon,
        value: transformStringToJSX(contact.id ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "ID",
      })
    }
    if (contact.phone) {
      fields.push({
        icon: SmartPhone01Icon,
        value: transformStringToJSX(contact.phone, { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Telefone",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.contact])

  return (
    <BaseNode
      {...props}
      title="Buscar contato"
      handlesAfter={
        <div className="flex justify-end items-center h-6 mb-2 -mt-4">
          <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">Não encontrado</span>
          <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
            <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
          </Handle>
        </div>
      }
      // defaultHandleLabel="Encontrado"
    >
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONTACT} />
    </BaseNode>
  )
}

export default FindContactNode
