import { Form } from "antd"
import React, { memo } from "react"
import InputVariable from "."
import { InputField } from "pages/settings/workflows/workflow.defs"
import { InputType, InputTypeIconNew } from "lib/constants"
import IconTag from "components-old/shared/IconTag"
import { ColorPalette } from "lib/colors"
import { LabelHelp } from "../LabelHelp"

interface InputVariableFormItemProps {
  field: InputField
  name: string | string[]
  showIcon?: boolean
  palette?: ColorPalette
}

function InputVariableFormItem(props: InputVariableFormItemProps) {
  const Icon = props.field.icon || InputTypeIconNew?.[props.field.type]
  return (
    <Form.Item
      label={
        <LabelHelp help={props.field?.description}>
          <div className="flex items-center gap-1.5">
            {Icon && props.palette && (
              <IconTag
                icon={Icon}
                palette={props.palette}
                lightTone={{
                  text: 600,
                  bg: 50,
                }}
              />
            )}
            {props.field.label}
          </div>
        </LabelHelp>
      }
      name={props.name}
      // valuePropName={props.field.type === InputType.Boolean ? "checked" : undefined}
    >
      <InputVariable field={props.field} />
    </Form.Item>
  )
}

export default memo(InputVariableFormItem)
