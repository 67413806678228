import React, { useMemo, useState } from "react"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import { Button, Col, Form, Input, Row, Switch } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStore, useStoreActions, useStoreState } from "hooks"
import InstagramSvg from "../svgs/InstagramSvg"
import { IChannelInstagram } from "models/channels"
import { ChannelStatus } from ".."
import { general } from "store"
import toast from "lib/toast"
import { endpoints } from "config/endpoints.config"
import request from "lib/request"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"
import HorizontalSwitch from "components-old/ui/HorizontalSwitch"
import GroupsSelect from "components-old/ui/GroupsSelect"
// import Tooltip from "components-old/Tooltip"

interface IEditInstagramProps extends RouteComponentProps<{ id: string }> {
  onChange: Function
}

function EditInstagram(props: IEditInstagramProps) {
  const [form] = Form.useForm()
  const { all: channels } = useStoreState((state) => state.channels)
  const { patchElementOnAll } = useStoreActions((state) => state.channelsInstagram)
  const { id } = useParams<any>()
  const { _domain } = useStore(general)
  const [reconnecting, setReconnecting] = useState(false)
  const [item, setItem] = useState<IChannelInstagram>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const channel = useMemo(() => {
    return channels.find((c) => c.id == id)
  }, [channels, id])

  return (
    <SettingsPageEdit<IChannelInstagram>
      {...props}
      pageComponent="modal"
      onChangeItem={(item) => {
        setItem(item)
      }}
      routeName="channels"
      width={500}
      title="Canal de Instagram"
      onSubmitSuccess={() => {
        props.onChange()
      }}
      noPrompt
      okProps={{
        loading,
        // style: {
        //     display: "none",
        // },
      }}
      onSubmitBefore={(values) => {
        setLoading(true)
        return request
          .patch<IChannelInstagram>(`${endpoints.INSTAGRAM}/${id}`, {
            ignore_story_mention: values.ignore_story_mention,
            ignore_story_reply: values.ignore_story_reply,
            customFields: values.customFields,
            isPrivate: values.isPrivate,
            groupIds: values.groupIds,
          })
          .then((res) => {
            toast.success("Canal atualizado.")
            patchElementOnAll(res.data)
            setLoading(false)
            history.push(`/settings/channels`)
          })
      }}
      // actions={
      //     <FacebookProvider appId={((envKeys as any)[_domain.key] || {}).facebook_app_id || envKeys.staging.facebook_app_id}>
      //         <Login
      //             scope="instagram_basic,instagram_manage_messages, pages_manage_metadata"
      //             onCompleted={(data: any) => {
      //                 setReconnecting(true)
      //                 const accessToken = data?.tokenDetail?.accessToken
      //                 axios({
      //                     method: "get",
      //                     url: endpoints.INSTAGRAM_GRAPH_API + accessToken,
      //                 })
      //                     .then((response) => {
      //                         console.log("response", response, channel, item)
      //                         const page = response.data?.data?.find((page: any) => page.id == item?.pageId)
      //                         request
      //                             .save(endpoints.INSTAGRAM, {
      //                                 avatar: page?.picture?.data?.url,
      //                                 pageInstagramId: page?.instagram_business_account?.id,
      //                                 pageId: page?.id,
      //                                 name: page.name,
      //                                 accessToken: page.access_token,
      //                             })
      //                             .then((response) => {
      //                                 toast.success(
      //                                     <span>
      //                                         Conectado ao canal de Instagram <strong>{response?.data.name}</strong>
      //                                     </span>
      //                                 )
      //                                 history.push("/settings/channels")
      //                             })
      //                             .finally(() => {
      //                                 setReconnecting(false)
      //                             })
      //                     })
      //                     .catch((e) => {
      //                         console.log(e)
      //                         setReconnecting(false)
      //                     })
      //             }}
      //             onError={(err: any) => toast.error(err.message)}
      //         >
      //             {({ loading, handleClick, error, data }: any) => (
      //                 <Button
      //                     icon={
      //                         <i
      //                             className="fab fa-instagram"
      //                             // style={{ color: "var(--primary)" }}
      //                             style={{ color: "#fff" }}
      //                         />
      //                     }
      //                     loading={reconnecting || loading}
      //                     onClick={handleClick}
      //                     type="primary"
      //                 >
      //                     Reconectar ao Instagram
      //                 </Button>
      //             )}
      //         </Login>
      //     </FacebookProvider>
      // }
      titleOverwrite={
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <Row wrap={false}>
              <Col>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <InstagramSvg />
                </div>
              </Col>
              <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                {channel?.description}
              </Col>
            </Row>
          </Col>
        </Row>
      }
      storePath={(store) => store.channelsInstagram}
      form={form}
      hideFooterWhenSaved
    >
      <Form.Item label="Status">
        <ChannelStatus status={channel?.status} />
      </Form.Item>
      <Form.Item label="Página de Facebook associada" name="description">
        <Input disabled />
      </Form.Item>
      <Row align="middle" style={{ marginBottom: 10 }}>
        <Col flex={1}>
          <div style={{ lineHeight: "" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>Ignorar menções</div>
            <div style={{ fontSize: 13, color: "var(--secondary)" }}>Não cria conversa ao ser mencionado em um story</div>
          </div>
        </Col>
        <Col>
          <Form.Item name="ignore_story_mention" noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row align="middle" style={{ marginBottom: 10 }}>
        <Col flex={1}>
          <div style={{ lineHeight: "" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>Ignorar resposta de story</div>
            <div style={{ fontSize: 13, color: "var(--secondary)" }}>Não cria conversa se responderem ao seu story</div>
          </div>
        </Col>
        <Col>
          <Form.Item name="ignore_story_reply" noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <HorizontalSwitch
          title="Restringir visibilidade"
          description="Selecione os grupos que tem acesso a visualizar esse canal"
          name="isPrivate"
        >
          <Form.Item name="groupIds">
            <GroupsSelect mode="multiple" />
          </Form.Item>
        </HorizontalSwitch>
      </Form.Item>
      <EditEntityCustomFields form={form} item={item} entity="channel" />

      {/* <Row align="middle" style={{ marginBottom: 10 }}>
                <Col flex={1}>
                    <div style={{ lineHeight: "" }}>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>Bot ignora resposta de story</div>
                        <div style={{ fontSize: 13, color: "var(--secondary)" }}>
                            Bot não irá responder resposta de story
                        </div>
                    </div>
                </Col>
                <Col>
                    <Form.Item name="bot_ignore_story_reply" noStyle valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row> */}
    </SettingsPageEdit>
  )
}

export default EditInstagram
