import { Form, Select } from "antd"
import { Privacy } from "lib/constants"
import GroupsSelect from "../GroupsSelect"
import { useStore, useStoreState } from "hooks"
import { session } from "store"
import CustomSelect from "components-old/forms/CustomSelect"
import { UserGroupIcon, ViewIcon, ViewOffIcon } from "components-old/icons"

export interface PrivacyType {
  type: Privacy
  groupIds?: string[]
}

interface PrivacyFieldProps {
  isEdit?: boolean
}

function PrivacyField(props: PrivacyFieldProps) {
  const { user } = useStore(session)
  const { all: members } = useStoreState((state) => state.members)

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
      }}
    >
      <Form.Item
        shouldUpdate={(prevValues: any, nextValues: any) => {
          return prevValues?.privacy?.type !== nextValues?.privacy?.type
        }}
        noStyle
      >
        {({ getFieldValue }) => {
          const authorId = getFieldValue("authorId")
          let isOtherAuthor

          let author

          if (props.isEdit) {
            isOtherAuthor = authorId != user?.id
            if (isOtherAuthor) {
              author = members.find((m) => m.id == authorId) || members?.find((m) => m.isOwner)
            }
          } else {
            author = members.find((m) => m.id == user?.id)
          }

          return (
            <>
              <Form.Item
                label="Visível para"
                name={["privacy", "type"]}
                style={{
                  flex: 1,
                }}
              >
                <CustomSelect showSearch>
                  <Select.Option value={Privacy.PUBLIC} data-search="Todos os membros">
                    <div className="flex items-center">
                      <ViewIcon className="mr-2 w-[18px] h-[18px] text-primary mt-0.5" />
                      Todos
                    </div>
                  </Select.Option>
                  <Select.Option value={Privacy.GROUP} data-search="Grupo">
                    <div className="flex items-center">
                      <UserGroupIcon className="mr-2 w-[18px] h-[18px] text-primary" />
                      Grupos
                    </div>
                  </Select.Option>
                  <Select.Option value={Privacy.PRIVATE} data-search={"Apenas para mim" + (author?.name || "")}>
                    <div className="flex items-center">
                      <ViewOffIcon className="mr-2 w-[18px] h-[18px] text-primary mt-1" />
                      {isOtherAuthor && author?.id ? (
                        <span className="flex gap-1 items-center">
                          Apenas para <b>{author?.name}</b>
                        </span>
                      ) : (
                        "Apenas para mim"
                      )}
                    </div>
                  </Select.Option>
                </CustomSelect>
              </Form.Item>
              <Form.Item
                shouldUpdate={(changedValues, allValues) => {
                  return changedValues?.privacy?.type !== allValues?.privacy?.type
                }}
                noStyle
              >
                {({ getFieldValue }) => {
                  if (getFieldValue(["privacy", "type"]) === "group") {
                    return (
                      <Form.Item
                        label="Grupos"
                        name={["privacy", "groupIds"]}
                        style={{
                          flex: 1,
                        }}
                      >
                        <GroupsSelect mode="multiple" />
                      </Form.Item>
                    )
                  }
                }}
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
    </div>
  )
}

export default PrivacyField
