import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { NodeField } from "../ConversationNodeCore"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import BaseExpanderFields from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"

interface ArchiveConversationNodeProps extends BaseNodeProps {}

function ArchiveConversationNode(props: ArchiveConversationNodeProps) {
  const { fields } = useMemo(() => {
    let fields: NodeField[] = []
    const conversation = props.data?.action?.conversation

    if (conversation?.conversationReference) {
      fields.push({
        icon: WorkflowDefs.CategoryRecord.conversation.icon,
        value: conversation?.conversationReference ? (
          <span>
            Conversa <span className="text-sky-600">#{conversation?.conversationReference}</span>
          </span>
        ) : (
          ""
        ),
        label: "Conversa",
      })
    }

    return {
      fields,
    }
  }, [props.data?.action?.conversation])

  return (
    <BaseNode {...props} title="Resolver conversa">
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONVERSATION} />
    </BaseNode>
  )
}

export default ArchiveConversationNode
