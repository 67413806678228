import { Button } from "antd"
import React from "react"
import { ArrowHeadType, getBezierPath, getEdgeCenter, getMarkerEnd, removeElements } from "react-flow-renderer"
import styles from "./style.module.css"
import cx from "classnames"
import { useRecoilState, useSetRecoilState } from "recoil"
import { workflowActiveRunEdgeAtom, workflowElementsAtom } from "atoms/workflow.atom"

const foreignObjectSize = 40

export default function RemovableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  sourceHandleId,
}: any) {
  const [currentEdgeId, setCurrentEdgeId] = useRecoilState(workflowActiveRunEdgeAtom)
  const setElements = useSetRecoilState(workflowElementsAtom)

  const onEdgeClick = (evt: any, id: string, data: any) => {
    evt.stopPropagation()
    setElements((els) => removeElements([{ id } as any], els))
  }

  const edgePath = getBezierPath({
    sourceX: sourceX - 10,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  // const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })
  
  const color = sourceHandleId == "not_found" ? "#ef4444" : "var(--edge)"
  const markerEnd = sourceHandleId == "not_found" ? "url(#arrowhead-not-found)" : "url(#arrowhead)"

  return (
    <>
      <path style={{ ...style, stroke: color }} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd}  />
      <path
        id={id}
        style={{
          ...style,
          stroke: sourceHandleId == "not_found" ? color: "#0078d4",
          strokeWidth: 20,
          strokeOpacity: 0.15,
          strokeDasharray: 0,
          transition: "opacity .2s ease-in-out",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          opacity: 0,
        }}
        className={cx("react-flow__edge-path edge-show-hover", {
          [styles.edge_active]: currentEdgeId == id,
        })}
        d={edgePath}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className={cx(styles.edge_foreign_object, "edge-show-hover")}
        requiredExtensions="http://www.w3.org/1999/xhtml"
        style={{ opacity: 0 }}
      >
        <Button
          icon={<i className="far fa-times flex-center" style={{ width: "100%", height: "100%", fontSize: 12, marginTop: 2 }} />}
          onClick={(event: any) => onEdgeClick(event, id, data)}
          title="Excluir caminho"
          style={{
            padding: 4,
            width: 22,
            height: 22,
            background: "#0078d4",
            color: "white",
            marginLeft: 5,
            marginTop: 8,
            borderRadius: "50%",
          }}
          className={cx(styles.remove_button, "flex-center shrink-0")}
        ></Button>
      </foreignObject>
    </>
  )
}
