import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IMessageSchedule {
  id?: string
  date: string
  time: string
  createdAt: string
  message: string
  customerPlatformId: string
  attempts?: number
  isDeleted?: boolean
  isSent?: boolean
  isError?: boolean
  everyMonth?: boolean
  operatorId?: string
  contactName: string
  // channelType: keyof typeof channelsTypes
  phone?: string
  workspacePlatformId: string
  template?: any
}

export const messageSchedules: IGenericModel<IMessageSchedule> = {
  ...generic(endpoints.MESSAGE_SCHEDULES),
}
