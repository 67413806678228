import { endpoints } from "config/endpoints.config"
import { thunk, Thunk } from "easy-peasy"
import request from "lib/request"
import { IGenericModel, generic } from "models/generic"

export interface ISegmentFilters {
  assigned?: boolean
  notAssigned?: boolean
  isAllTags?: boolean
  newCustomer?: boolean
  newContact?: boolean
  isFromIntegration?: boolean
  my?: boolean
  open?: boolean
  notOpen?: boolean
  // lastMessageFromClient?: boolean
  showPending?: boolean
  unread?: boolean
  starred?: boolean
  shared?: boolean
  noTags?: boolean
  neverAnsweredByOperator?: boolean
  noGroup?: boolean
  minutesSincePending?: number
  minutesSinceLastMessage?: number
  query?: string

  channels?: {
    id: string
  }[]
  agents?: {
    id: string
  }[]
  groups?: {
    id: string
  }[]
  tags?: {
    id: string
  }[]
  noTagsIds?: {
    id: string
  }[]
  followers?: {
    id: string
  }[]
  filters?: string
}

export interface ISegment {
  id: string
  name: string
  description: string
  count: number
  icon: string
  isPublic: boolean
  isCustom: boolean
  subscribed: boolean
  operatorId: string
  workspaceId: string
  restrictionType: "public" | "user" | "group"
  restrictionGroupIds: string[]
  filters: ISegmentFilters
  isFolder?: boolean
  parentId?: string | null
}

export interface ISegmentsModel extends IGenericModel<ISegment> {
  toggleSubscription: Thunk<ISegmentsModel, { id: string; subscribed: boolean }>
}

export const segments: ISegmentsModel = {
  toggleSubscription: thunk(async (actions, model) => {
    actions.patchElementOnAll({ ...model, subscribed: !model.subscribed })
    const endpoint = model.subscribed ? "/unsubscribe/" : "/subscribe/"
    return request.post(endpoints.INBOX_SEGMENTS + endpoint + model.id).then((response) => {
      return response.data
    })
  }),
  ...generic(endpoints.INBOX_SEGMENTS),
}
