import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { useSetRecoilState } from "recoil"
import MakeRequestModal from "./MakeRequestModal"
import { editWorkflowNodeIdAtom } from "atoms/workflow.atom"
import React from "react"

interface MakeRequestActionProps extends BaseEditActionChildrenProps {
  onCancel: () => void
}

const MakeRequestAction = (props: MakeRequestActionProps) => {
  const fieldName = [...props.actionFieldName, "request"]
  const setEditNodeId = useSetRecoilState(editWorkflowNodeIdAtom)
  // const setModal = useSetRecoilState(modalState)
  return (
    <MakeRequestModal
      {...props}
      onClose={() => {
        setEditNodeId(undefined)
        props.onCancel?.()
      }}
    />
  )

  // const [dynamicVariablesDef] = useRecoilState(variableAtom)

  // const { fields } = useMemo(() => {
  //   const url = transformStringToJSX(props.editNode?.data?.action?.request?.url ?? "", {
  //     variablesRecord: dynamicVariablesDef.variablesRecord,
  //   })

  //   return {
  //     fields: {
  //       url,
  //       method: props.editNode?.data?.action?.request?.method,
  //     },
  //   }
  // }, [dynamicVariablesDef.variablesRecord, props.editNode?.data?.action?.request?.method, props.editNode?.data?.action?.request?.url])

  // return (
  //   <div>
  //     {/* <Form.Item label="Endereço" name={[...fieldName, "url"]} required>
  //       <FakeInput disabled>
  //         {transformStringToJSX(props.editNode?.data?.action?.request?.url ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord })}
  //       </FakeInput>
  //     </Form.Item> */}
  //     <div className="bordered p-3">
  //       <div className="flex items-center gap-3 truncate">
  //         <div className="text-primary font-bold text-xs">{fields.method}</div>
  //         <div className="truncate">{fields.url}</div>
  //       </div>
  //       <div
  //         className="mt-2 pt-2"
  //         style={{
  //           borderTop: "2px dashed var(--divider)",
  //         }}
  //       >
  //         saddsa
  //       </div>
  //     </div>
  //     <Button
  //       type="primary"
  //       icon={<PencilEdit02Icon />}
  //       className="!bg-[rgb(55,65,81)]"
  //       onClick={() => {
  //         setModal(
  //           <MakeRequestModal
  //             {...props}
  //             onClose={() => {
  //               setModal(null)
  //             }}
  //           />
  //         )
  //       }}
  //     >
  //       Editar
  //     </Button>
  //   </div>
  // )
}

export default MakeRequestAction
