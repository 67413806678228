import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactFlow, {
  Elements,
  FlowElement,
  OnLoadParams,
  addEdge,
  removeElements,
  ConnectionMode,
  ConnectionLineType,
  useZoomPanHelper,
  useStore,
  useStoreActions,
  OnConnectStartParams,
} from "react-flow-renderer"

import styles from "./style.module.css"
import { FormInstance } from "antd"
import { useRecoilState, useSetRecoilState } from "recoil"
import { quickViewWorkflowNodeIdAtom, searchVisibleAtom, workflowConnectingAtom, workflowSelectedElementsAtom } from "atoms/workflow.atom"
import toast from "lib/toast"
import cx from "classnames"
import { Workflow } from "models/workflows"
import { WorkflowDefs, WorkflowNodeData } from "./workflow.defs"
import { isMobileAtom } from "atoms/app.atom"
import AddActionDrop from "./AddActionDrop"
import RemovableEdge from "./edges/RemovableEdge"
import ConnectionLine from "./edges/ConnectionLine"
import { enableWorkflowAnimation, generateNewNodeId } from "./workflow.helper"
import { ActionEnum } from "./workflow.enums"
import DummyNode from "./actions/Dummy.node"

interface WorkflowCanvaProps {
  elements: Elements
  setElements: any
  setChanged: any
  form: FormInstance
  editNode?: FlowElement<WorkflowNodeData>
  setEditNodeId: Function
  setUnblocked: Function
  unblocked: boolean
  item?: Workflow
  goToPosition: any
  goToPositionInstant: any
  setGoToPosition: Function
  syncElements: any
}

// const nodeTypes = {
//   message: MessageNode,
// }

// const nodeTypes = Object.values(WorkflowActions).reduce((acc, action) => {
//   acc[action.type] = action.node
//   return acc
// }, {})

const edgeTypes = {
  removable_edge: RemovableEdge,
}

const isMacOS = navigator.userAgent.indexOf("Mac OS X") != -1
const multiSelectionKeyCode = isMacOS ? 91 /* cmd */ : 17 /* ctrl */
export let draggingNodeId: any = undefined
let quickViewPreviousPosition: any = undefined
let reactFlowInstance: OnLoadParams<any> | undefined
export let saveQuickViewPreviousPosition = () => {
  const instance = reactFlowInstance?.toObject()
  quickViewPreviousPosition = {
    x: instance?.position[0] || 0,
    y: instance?.position[1] || 0,
    zoom: instance?.zoom || 1,
  }
}

let mousePos: any
// let autoConnectToNode: OnConnectStartParams | null
let connectingParams: any

function WorkflowCanva(props: WorkflowCanvaProps) {
  const reactFlowWrapper = useRef<any>(null)
  // const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams<any>>()
  const { transform } = useZoomPanHelper()
  const [selectedElements, setSelectedElements] = useRecoilState(workflowSelectedElementsAtom)
  // const [connecting, setConnecting] = useState<false | string>(false)
  const [zoom, setZoom] = useState(1)
  const [isMobile] = useRecoilState(isMobileAtom)
  const [connecting, setConnecting] = useRecoilState(workflowConnectingAtom)
  const [connectingTemp, setConnectingTemp] = useState<OnConnectStartParams | null>(null)
  const setSelectedElementsFlow = useStoreActions((actions) => actions.setSelectedElements)
  const [quickViewNodeId, setQuickViewNodeId] = useRecoilState(quickViewWorkflowNodeIdAtom)
  // const updateNodeInternals = useUpdateNodeInternals()
  const [visible, setVisible] = useState(false)
  // const [keepConnectEdge, setKeepConnectEdge] = useState(false)
  const [visibleOnEdge, setVisibleOnEdge] = useState(false)
  const setSearchVisible = useSetRecoilState(searchVisibleAtom)
  const autoConnectToNodeRef = useRef<OnConnectStartParams | null>(null)

  const dropdownRef = useRef<any>(null)
  const nodeTypes = useMemo(
    () =>
      WorkflowDefs.actions.reduce((acc, action) => {
        acc[action.type] = action.node
        return acc
      }, {}),
    []
  )

  // const [addDropPosition, setAddDropPosition] = useState<any>()

  useEffect(() => {
    if (quickViewNodeId) {
      // const node = workflowElementsObj[quickViewNodeId]
      const node: any = props.elements.find((el) => el.id == quickViewNodeId)
      if (node) {
        transform(
          {
            x: node?.position?.x * -1 + window.innerWidth / 2 - 360,
            y: node?.position?.y * -1 + window.innerHeight / 2 - 160,
            zoom: 1,
          },
          300
        )
      }
    } else {
      transform(quickViewPreviousPosition || { x: 0, y: 0, zoom: 1 }, 300)
    }
  }, [quickViewNodeId])

  useEffect(() => {
    return () => {
      setSelectedElementsFlow([])
      setQuickViewNodeId(undefined)
      setSelectedElements([])
      setConnecting(false)
    }
  }, [])

  const centerPosition = useCallback(
    (dto: { nodeId?: string; position?: { x: number; y: number }; overwriteX?: number; overwriteY?: number }) => {
      let position = dto.position

      if (!position) {
        const node = props.elements.find((el) => el.id == dto.nodeId) as any

        if (!node) return

        position = node?.position
      }
      const { x: domX, y: domY } = reactFlowWrapper.current?.getBoundingClientRect?.() || { x: 0, y: 0 }
      const nodeWidth = 240
      const nodeDefaultHeight = 100

      const correctedPosition = {
        x: ((position?.x || 0) - window.innerWidth / 2) * -1 - domX - nodeWidth / 2,
        y: ((position?.y || 0) - window.innerHeight / 2) * -1 - domY - nodeDefaultHeight / 2,
      }

      transform({ x: dto.overwriteX || correctedPosition.x, y: dto.overwriteY || correctedPosition?.y, zoom: 1 }, 500)

      // const state = reactFlowInstance?.toObject()

      // console.log({
      //   elements: props.elements,
      //   currentFlowPosX: state?.position?.[0],
      //   newFlowPosX: correctedPosition.x,
      //   // nodePositionX: node?.position?.x,
      //   // projectedX: reactFlowInstance?.project(node?.position!)?.x,
      //   rect: reactFlowWrapper.current.getBoundingClientRect(),
      // })
    },
    [props.elements, transform]
  )

  useEffect(() => {
    if (typeof props.goToPosition == "object") {
      transform(props.goToPosition, 500)
      props.setGoToPosition(true)
    }
  }, [props.goToPosition])

  useEffect(() => {
    if (typeof props.goToPositionInstant == "object") {
      transform(props.goToPositionInstant, 0)
    }
  }, [props.goToPositionInstant])

  const onConnect = useCallback(
    (params) => {
      const sourceUsed: any = props.elements.find((elem: any) => elem.source == params.source && elem.sourceHandle == params.sourceHandle)

      if (!sourceUsed) {
        if (params.source == params.target) {
          return
        }
        // console.log("params", params)
        return props.setElements((els: Elements) =>
          addEdge(
            {
              ...params,
              // animated: true,
              style: { stroke: "var(--content-primary)" },
              type: "removable_edge",
              arrowHeadType: "arrow",
            },
            els
          )
        )
      } else {
        toast.warning("Só pode haver um caminho saindo de cada nó.")
      }
    },
    [props.elements]
  )

  const onAddAction = useCallback(
    (e: any, type: string) => {
      let _autoConnectToNode = autoConnectToNodeRef.current
      // autoConnectToNode = null
      setVisible(false)
      setVisibleOnEdge(false)
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect()
      // const position = reactFlowInstance?.project({
      //   x: reactFlowBounds.left,
      //   y: reactFlowBounds.top,
      // })
      const screenCenter = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      }

      const centerPositionObj = {
        x: screenCenter.x - reactFlowBounds.left,
        y: screenCenter.y - reactFlowBounds.top,
      }

      // let mousePosX = e?.domEvent.clientX - reactFlowBounds.left

      const finalPosition = mousePos?.x
        ? {
            x: mousePos?.x - reactFlowBounds.left,
            y: mousePos?.y - reactFlowBounds.top,
          }
        : centerPositionObj

      const position = reactFlowInstance?.project(finalPosition)

      // const id = shortid.generate()
      // const intArray = props.elements
      //   .map((x) => x.id)
      //   .map((num) => {
      //     try {
      //       const integer = parseInt(num, 10)
      //       return integer
      //     } catch (e) {
      //       return 0
      //     }
      //   })
      // const id = (_.max(intArray) || 0) + 1 + ""
      const id = generateNewNodeId(props.form)
      const action = WorkflowDefs.ActionRecord[type]
      const newNode = {
        id,
        type,
        position: position as any,
        data: {
          // label: `${type} node`,
          backgroundColor: action?.color,
          action: {
            // message: "",
          },
        },
      }

      enableWorkflowAnimation(newNode.id)

      // props.setElements((es: Elements) => es.concat(newNode))
      props.setElements((es: Elements) => [...es, newNode])

      setSelectedElementsFlow([{ id, type }])

      props.setEditNodeId(id)

      if (mousePos.x > window.innerWidth - 510) {
        setTimeout(() => {
          // setCenterPositionId(id)
          // const state = reactFlowInstance?.toObject()
          centerPosition({ position: newNode.position })

          if (_autoConnectToNode) {
            onConnect({
              source: _autoConnectToNode.nodeId,
              sourceHandle: _autoConnectToNode?.handleId,
              target: id,
              targetHandle: null,
            })
            _autoConnectToNode = null
          }
        }, 100)
      } else {
        setTimeout(() => {
          if (_autoConnectToNode) {
            onConnect({
              source: _autoConnectToNode.nodeId,
              // sourceHandle: null,
              sourceHandle: _autoConnectToNode?.handleId,
              target: id,
              targetHandle: null,
            })
            _autoConnectToNode = null
          }
        }, 100)
      }
      mousePos = undefined
    },
    [props, setSelectedElementsFlow, autoConnectToNodeRef.current]
  )

  // const onAddDummy = useCallback(
  //   (e: any) => {
  //     const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect()
  //     const screenCenter = {
  //       x: window.innerWidth / 2,
  //       y: window.innerHeight / 2,
  //     }

  //     const centerPositionObj = {
  //       x: screenCenter.x - reactFlowBounds.left,
  //       y: screenCenter.y - reactFlowBounds.top,
  //     }
  //     const finalPosition = mousePos?.x
  //       ? {
  //           x: mousePos?.x - reactFlowBounds.left,
  //           y: mousePos?.y - reactFlowBounds.top,
  //         }
  //       : centerPositionObj

  //     const position = reactFlowInstance?.project(finalPosition)

  //     const newNode = {
  //       id: "dummy",
  //       type: "dummy",
  //       position: position as any,
  //       data: {},
  //     }
  //     props.setElements((es: Elements) => [...es, newNode])
  //   },
  //   [props]
  // )

  const flowStore = useStore()

  return (
    <>
      <div
        className={cx(styles.canvas, "relative overflow-hidden", {
          g_multiselected: selectedElements?.length > 1,
          g_zoomedOut: zoom < 0.5,
          g_connecting: connecting,
        })}
        ref={reactFlowWrapper}
      >
        <ReactFlow
          elements={props.elements}
          snapGrid={[20, 20]}
          snapToGrid
          onClick={(e: any) => {
            if (visible) {
              setVisible(false)
            }
            setSearchVisible(false)
            // if (visibleOnEdge) {
            //   setVisibleOnEdge(false)
            // }
            if (!!props.editNode && e.target?.classList?.[0] === "react-flow__pane") {
              props.setEditNodeId(undefined)
            }
          }}
          onLoad={(_reactFlowInstance) => {
            reactFlowInstance = _reactFlowInstance
            // setReactFlowInstance(_reactFlowInstance)
          }}
          // onDrop={(event) => {
          //   event.preventDefault()
          //   const type: ActionEnum = event.dataTransfer.getData("node") as ActionEnum

          //   if (type) {
          //     const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect()
          //     const position = reactFlowInstance?.project({
          //       x: event.clientX - reactFlowBounds.left,
          //       y: event.clientY - reactFlowBounds.top,
          //     })
          //     const id = shortid.generate()
          //     // const typeProps = WorkflowActions[type]
          //     const action = WorkflowDefs.ActionRecord[type]
          //     const newNode = {
          //       id,
          //       type,
          //       position: position as any,
          //       data: {
          //         label: `${type} node`,
          //         backgroundColor: colors[action?.palette]?.[600],
          //         action: {
          //           message: "",
          //         },
          //       },
          //     }
          //     props.setElements((es: Elements) => es.concat(newNode))
          //   }
          // }}
          // onDragOver={(event) => {
          //   event.preventDefault()
          //   event.dataTransfer.dropEffect = "move"
          // }}
          onlyRenderVisibleElements
          defaultZoom={1}
          nodeTypes={nodeTypes}
          //@ts-ignore
          edgeTypes={edgeTypes}
          onConnect={onConnect}
          onElementsRemove={(elementsToRemove) => {
            props.setElements((els) => {
              return removeElements(
                elementsToRemove?.filter((el) => el.type !== ActionEnum.TRIGGER),
                els
              )
            })
          }}
          connectionLineComponent={ConnectionLine}
          connectionMode={ConnectionMode.Strict}
          connectionLineType={ConnectionLineType.SmoothStep}
          deleteKeyCode={46}
          multiSelectionKeyCode={multiSelectionKeyCode}
          onConnectStart={(event, element) => {
            setConnecting(element.nodeId!)
            setConnectingTemp(element)
          }}
          onConnectEnd={(event: any) => {
            setConnecting(false)

            if (event.target?.classList?.[0] === "react-flow__pane") {
              // Defina a posição do dropdown com base na posição do mouse
              if (dropdownRef.current) {
                setVisibleOnEdge(true)
                mousePos = {
                  x: event.clientX,
                  y: event.clientY,
                }
                dropdownRef.current.style.left = `${event.x}px`
                dropdownRef.current.style.top = `${event.y}px`
                dropdownRef.current.style.display = "block"

                // onAddAction(event, ActionEnum.DELAY)
                // console.log(connectingParams)
                // onAddDummy(event)
                // onConnect({
                //   source: connectingParams.nodeId,
                //   sourceHandle: connectingParams.handleId,
                //   target: "dummy",
                //   targetHandle: null,
                // })
              }
            }
          }}
          onDoubleClickCapture={(e: any) => {
            // if not node or edge
            if (e.target?.classList?.[0] === "react-flow__pane") {
              // Defina a posição do dropdown com base na posição do mouse
              if (dropdownRef.current) {
                autoConnectToNodeRef.current = null
                mousePos = {
                  x: e.clientX,
                  y: e.clientY,
                }
                setVisibleOnEdge(true)
                dropdownRef.current.style.left = `${e.clientX}px`
                dropdownRef.current.style.top = `${e.clientY}px`
                dropdownRef.current.style.display = "block"
              }
            }
          }}
          zoomOnDoubleClick={false}
          // onNodeDoubleClick={(e, node) => {
          //   props.setEditNodeId(node.id)
          // }}
          onMoveEnd={(flowTransform) => {
            setZoom(flowTransform?.zoom || 1)
          }}
          onNodeDragStart={(event, element) => {
            draggingNodeId = element.id
          }}
          onNodeDragStop={(event, element) => {
            draggingNodeId = undefined
            props.setElements((elements: Elements) => {
              const newElements = [...elements]
              const selectedElems = flowStore.getState().selectedElements
              const selectedElements = flowStore.getState().nodes.filter((n) => selectedElems?.findIndex((se) => se.id == n.id) != -1)
              selectedElements.forEach((element) => {
                const index = newElements.findIndex((el) => el.id == element.id)
                if (index > -1) {
                  newElements[index] = {
                    ...newElements[index],
                    position: element.__rf?.position || element?.position,
                  }
                }
              })
              return newElements
            })
          }}
          onMoveStart={(event) => {
            if (visible) {
              setVisible(false)
            }
            if (visibleOnEdge) {
              setVisibleOnEdge(false)
            }
          }}
          minZoom={0.05}
          onSelectionChange={(elements) => {
            setSelectedElements(elements || [])
          }}
        />
        {/* {props.editNode?.type == "message" && (
          <EditNodeMessageDrawer
            visible={props.editNode?.type == "message"}
            onBack={() => props.setEditNodeId(undefined)}
            node={props.editNode}
            elements={props.elements}
            setElements={props.setElements}
          />
        )} */}
        {/* <div
        className={styles.grid}
        style={{
          backgroundImage: `url(${getGridBase64(parseInt(zoom * 100 + "", 10))})`,
        }}
      /> */}
      </div>
      {!isMobile && (
        <>
          <div className="absolute top-1/2 left-8 transform -translate-y-1/2 z-10">
            <AddActionDrop
              visible={visible}
              setVisible={setVisible}
              // addDropPosition={addDropPosition}
              // setAddDropPosition={setAddDropPosition}
              onAddAction={(e, t) => {
                autoConnectToNodeRef.current = null
                mousePos = {
                  x: e.clientX,
                  y: e.clientY,
                }
                onAddAction(e, t)
              }}
            />
          </div>
          <div ref={dropdownRef} className="fixed w-0 h-0">
            <AddActionDrop
              visible={!!visibleOnEdge}
              setVisible={setVisibleOnEdge as any}
              onAddAction={(e, t) => {
                autoConnectToNodeRef.current = connectingTemp
                setConnectingTemp(null)
                onAddAction(e, t)
              }}
              isOnEdgeEnd
              getPopupContainer={() => {
                return dropdownRef
              }}
            />
          </div>
        </>
      )}
      <div className="">
        <svg width="10px" height="10px" viewBox="-100 -100 200 200">
          <defs>
            <marker
              id="arrowhead"
              viewBox="0 0 10 10"
              refX="7"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="4"
              markerHeight="3"
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" stroke="none" fill="var(--edge)" />
            </marker>
          </defs>

          <path d="M -50,50 Q -50,-50 50,20" stroke="var(--edge)" fill="none" markerEnd="url(#arrowhead)" />
          <path d="M 50,-50 Q 80,0 40,80" stroke="var(--edge)" strokeWidth="3" fill="none" markerEnd="url(#arrowhead)" />
        </svg>
        <svg width="10px" height="10px" viewBox="-100 -100 200 200">
          <defs>
            <marker
              id="arrowhead-not-found"
              viewBox="0 0 10 10"
              refX="7"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="4"
              markerHeight="3"
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" stroke="none" fill="#ef4444" />
            </marker>
          </defs>

          <path d="M -50,50 Q -50,-50 50,20" stroke="#ef4444" fill="none" markerEnd="url(#arrowhead-not-found)" />
          <path d="M 50,-50 Q 80,0 40,80" stroke="#ef4444" strokeWidth="3" fill="none" markerEnd="url(#arrowhead-not-found)" />
        </svg>
        {/* <svg width="80%" height="80%" viewBox="-100 -100 200 200">
          <defs>
            <marker
              id="red-arrowhead"
              viewBox="0 0 10 10"
              refX="7"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="4"
              markerHeight="3"
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" stroke="none" fill="red" />
            </marker>
          </defs>

          <path d="M -50,50 Q -50,-50 50,20" stroke="red" fill="none" marker-end="url(#red-arrowhead)" />
          <path d="M 50,-50 Q 80,0 40,80" stroke="red" stroke-width="3" fill="none" marker-end="url(#red-arrowhead)" />
        </svg> */}
      </div>
    </>
  )
}

export default WorkflowCanva
