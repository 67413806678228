import FormModal from "components-old/forms/FormModal"
import { Form } from "antd"
import { useEffect, useRef, useState } from "react"
import IconTag from "components-old/shared/IconTag"
import { WorkflowDefs } from "../../../workflow.defs"
import { LabelHelp } from "components-old/forms/LabelHelp"
import CopyableInput from "components-old/forms/CopyableInput"
import { v2Socket } from "hooks/useSocketV2"
import { useRecoilState } from "recoil"
import { receivedWebhookMonitorAtom } from "atoms/workflow.atom"
import { SpinnerListeningIcon } from "components-old/icons"
import CollapseCards from "pages/crm/deals/ViewDeal/CollapseCards"
import AnalyticsJSONViewer from "pages/settings/workflows/analytics/AnalyticsJSONViewer"

interface DetermineStructureWebhookModalProps {
  onBack: () => void
  onSubmit: (schema: any) => void
  url?: string
  existingStructure?: any
}

function DetermineStructureWebhookModal(props: DetermineStructureWebhookModalProps) {
  const [form] = Form.useForm()
  const preRef = useRef<any>(null)
  const [received, setReceived] = useRecoilState(receivedWebhookMonitorAtom)
  const [structure, setStructure] = useState<any>()
  const [refresher, setRefresher] = useState(0)

  async function onSubmit(model: any) {
    props.onSubmit(structure)
  }

  useEffect(() => {
    v2Socket?.emit("workflow:webhook-inbound:monitor", { webhookId: props.url?.split("/")?.pop() })
  }, [])

  useEffect(() => {
    if (received?.body || received?.query) {
      setRefresher((prev) => prev + 1)
      setStructure(received)
    }
  }, [received])

  // useEffect(() => {
  //   if (structure) {
  //     const source = document.querySelector("#json-renderer")
  //     const dest = document.querySelectorAll(".json-picker-input")

  //     setTimeout(() => {
  //       ;(window as any).JPPicker.render(source, structure?.body || structure?.query || {}, dest)
  //     }, 100)
  //   }
  // }, [structure])

  return (
    <FormModal
      form={form}
      onSubmit={onSubmit}
      onBack={props.onBack}
      type="modal"
      saved={false}
      formProps={{
        initialValues: {
          url: props.url,
        },
        onValuesChange(changedValues, values) {},
      }}
      titleOverwrite={
        <div className="flex items-center gap-2">
          <div>
            <IconTag size="lg" icon={WorkflowDefs.trigger.TriggerRecord.webhook_inbound.icon} palette={"green"}></IconTag>
          </div>
          Webhook de Recebimento
        </div>
      }
      hideSuccessMessage
      width={700}
      okText="Salvar"
      okDisabled={!structure}
    >
      <CopyableInput
        inputProps={{
          disabled: true,
        }}
        copyableValue={props.url || ""}
        name={"url"}
        className="mb-1"
        label="Endereço Url"
      />
      <div className="bg-teal-100 dark:bg-base-200 rounded-xl p-4 my-4">
        {structure && <SpinnerListeningIcon className="text-teal-600 -mb-1 mr-2 h-5 w-5" />}
        Kinbox agora está ouvindo os dados e determinará automaticamente a estrutura de dados a partir dos dados recebidos. Para iniciar
        isso, envie sua amostra de dados para o endereço do webhook exibido acima.
      </div>
      <Form.Item
        label={
          <LabelHelp
            help={
              <div className="space-y-2">
                {structure ? (
                  <p>Essa é a estrutura JSON detectada.</p>
                ) : (
                  <p>Envia uma requisição para o endereço acima para que a estrutura apareça abaixo</p>
                )}
              </div>
            }
          >
            Estrutura
          </LabelHelp>
        }
      >
        {!structure && (
          <div className="flex-center w-full h-52 gap-3 bordered">
            <SpinnerListeningIcon className="text-teal-600" />
            Aguardando recebimento de dados...
          </div>
        )}
        {/* <div
          className={twMerge(
            "rounded-lg bg-base-200 p-3 overflow-auto max-h-[500px] pretty-scroll pretty-scroll-hoverable determine-structure",
            !structure && "hidden"
          )}
        >
          <pre id="json-renderer" className="mb-0" ref={preRef} key={refresher}></pre>
        </div>
        <input className="json-picker-input hidden" /> */}
        {structure && (
          <CollapseCards
            defaultActiveKey={["body", "query"]}
            bordered
            destroyInactivePanel={false}
            panels={[
              ...(Object.keys(structure.body || {})?.length
                ? [
                    {
                      key: "body",
                      title: (
                        <LabelHelp className="text-sm flex items-center gap-3" help="Dados do body recebidos.">
                          Body
                        </LabelHelp>
                      ),
                      content: <AnalyticsJSONViewer json={structure?.body} id="body" />,
                    },
                  ]
                : []),
              ...(Object.keys(structure.query || {})?.length
                ? [
                    {
                      key: "query",
                      title: (
                        <LabelHelp className="text-sm flex items-center gap-3" help="Dados de url query recebidos.">
                          Query
                        </LabelHelp>
                      ),
                      content: <AnalyticsJSONViewer json={structure?.query} id="query" />,
                    },
                  ]
                : []),
            ]}
          />
        )}
      </Form.Item>
    </FormModal>
  )
}

export default DetermineStructureWebhookModal
