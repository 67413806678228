import SearchDrop from "components-old/ui/SearchDrop"
import React, { useMemo } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  editWorkflowNodeIdAtom,
  quickViewWorkflowNodeIdAtom,
  searchVisibleAtom,
  workflowElementsAtom,
  workflowSelectedElementsAtom,
} from "atoms/workflow.atom"
import { Button, Menu } from "antd"
import { VariableCategory, WorkflowActionsItem, WorkflowDefs } from "./workflow.defs"
import { useStoreActions, useZoomPanHelper } from "react-flow-renderer"
import MenuItemInnerSelectable from "components-old/ui/SearchDrop/MenuItemInnerSelectable"
import Tooltip from "components-old/Tooltip"
import { colors } from "lib/colors"
import _ from "lodash"
import { saveQuickViewPreviousPosition } from "./WorkflowCanva"

interface WorkflowHeaderSearchProps {}

function WorkflowHeaderSearch(props: WorkflowHeaderSearchProps) {
  const [elements] = useRecoilState(workflowElementsAtom)
  const setQuickViewNodeId = useSetRecoilState(quickViewWorkflowNodeIdAtom)
  const [selectedElements, setSelectedElements] = useRecoilState(workflowSelectedElementsAtom)
  const [searchVisible, setSearchVisible] = useRecoilState(searchVisibleAtom)
  const setEditNodeId = useSetRecoilState(editWorkflowNodeIdAtom)
  const setSelectedElementsFlow = useStoreActions((actions) => actions.setSelectedElements)

  const nodes = useMemo(() => elements?.filter((el) => el.type != "removable_edge"), [elements])

  // const { transform } = useZoomPanHelper()

  const selectedElementsIds = selectedElements?.map((el) => el.id)

  return (
    <SearchDrop
      title="Pesquisar ação"
      width={320}
      persistent
      visible={searchVisible}
      setVisible={setSearchVisible}
      options={[
        ...nodes?.map((el) => {
          //   const actionInfo = getLabelAndIconByActionType(el?.type!)
          const actionInfo = WorkflowDefs.ActionRecord[el?.type!] as WorkflowActionsItem
          const category = WorkflowDefs.CategoryRecord[actionInfo?.variableCategory] as VariableCategory
          //   const id = el?.id != "1" && el?.id != "end" ? " - #" + el?.id : ""
          //   const text = el?.data?.action?.text ? ` - ${el?.data?.action?.text}` : ""
          const searchName = `${actionInfo?.name} #${el?.id}`
          const selected = selectedElementsIds.includes(el?.id!)

          const Icon = actionInfo?.icon
          return {
            searchValue: searchName,
            node: (
              <Menu.Item
                key={el?.id}
                eventKey={el?.id}
                onMouseEnter={() => {
                  if (el?.id) {
                    setQuickViewNodeId(el?.id)
                  }
                }}
                onMouseLeave={() => {
                  if (el?.id) {
                    setQuickViewNodeId(undefined)
                  }
                }}
                onClick={() => {
                  setSearchVisible(false)
                  saveQuickViewPreviousPosition()
                  setQuickViewNodeId(undefined)
                  setSelectedElementsFlow([el])
                  setEditNodeId(el.id)
                  //   setTimeout(() => {
                  //     transform(
                  //       {
                  //         x: (el as any)?.position?.x * -1 + window.innerWidth / 2 - 120,
                  //         y: (el as any)?.position?.y * -1 + window.innerHeight / 2,
                  //         zoom: 1,
                  //       },
                  //       300
                  //     )
                  //     setSelectedElements([el])
                  //   }, 100)
                }}
                icon={
                  <Icon
                    className="w-5 h-5"
                    style={{
                      color: actionInfo?.colors?.bg || colors[category?.palette]?.[600],
                    }}
                  />
                  //   <i
                  //     className={selected ? actionInfo?.icon?.replace("far", "fas") : actionInfo?.icon}
                  //     style={{
                  //       color: selected ? "var(--primary)" : undefined,
                  //     }}
                  //   />
                }
              >
                <MenuItemInnerSelectable title={searchName} selected={selected}>
                  <div className="text-2sm font-normal w-full flex items-center gap-2">
                    <span className="truncate flex-1">{actionInfo?.name}</span>
                    <span className="opacity-40">#{el?.id}</span>
                  </div>
                </MenuItemInnerSelectable>
              </Menu.Item>
            ),
          }
        }),
      ]}
    >
      <Tooltip title="Pesquisar ação" placement="bottom">
        <Button
          type="text"
          icon={<i className="far fa-search" style={{ fontSize: 19 }} />}
          style={{
            height: 38,
            borderRadius: 10,
          }}
          onClick={() => {
            saveQuickViewPreviousPosition()
          }}
        ></Button>
      </Tooltip>
    </SearchDrop>
  )
}

export default WorkflowHeaderSearch
