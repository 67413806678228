import React from "react"
import { Form, Row, Select } from "antd"
import { useGenericStore } from "hooks"
import { IBusinessHours } from "models/businessHours"
import CustomSelect from "components-old/forms/CustomSelect"
import { Clock01Icon } from "components-old/icons"

function BusinessHoursSelect(props: any) {
  const { all: businessHours } = useGenericStore((state) => state.businessHours)

  return (
    <Form.Item name={props.name || "businessHours"} label={props.label || "Horários"} style={{ width: "100%" }}>
      <CustomSelect
        mode={props.mode}
        allowClear
        showSearch={false}
        optionFilterProp="children"
        filterOption={(input, option: any) => {
          return option?.children?.props.children?.[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        disabled={props.disabled}
      >
        {businessHours.map((hour: IBusinessHours) => (
          <Select.Option value={hour.id} key={hour.id}>
            {/* <Row align="middle" style={{ flexFlow: "nowrap", maxWidth: 300 }}>
              🕐 {hour.name}
            </Row> */}
            <div className="flex items-center gap-2">
                {/* <Clock01Icon className="w-5 h-5 text-primary" /> */}
                <div>{hour.name}</div>
            </div>
          </Select.Option>
        ))}
      </CustomSelect>
    </Form.Item>
  )
}

export default BusinessHoursSelect
