import { Button, Form } from "antd"
import Tooltip from "components-old/Tooltip"
import { Delete03Icon } from "components-old/icons"
import React, { memo } from "react"
import { twMerge } from "tailwind-merge"
import { InputField } from "../workflow.defs"
import InputVariable from "components-old/forms/InputVariable"
import { InputType } from "lib/constants"

interface BaseUpdateActionItemProps {
  fieldName: string | string[]
  propertiesMap: Record<string, any>
  fieldKey: string
  formInstance: any
  name: string | string[]
  optionRecord: InputField
}

function BaseUpdateActionItem(props: BaseUpdateActionItemProps) {
  return (
    <div className={twMerge("flex flex-col gap-2 mb-4")}>
      <div className="flex justify-between gap-2">
        <div className="flex items-center gap-2 w-52 truncate">
          {/* <Icon className="text-primary" /> */}
          <Tooltip title="Remover" mouseEnterDelay={0}>
            <Button
              icon={
                <i>
                  <Delete03Icon className="w-[18px] h-[18px] text-red-500" />
                </i>
              }
              size="small"
              // shape="circle"
              // type="text"
              className="!rounded-lg !bg-red-50 hover:!bg-red-100 focus:!bg-red-100 dark:!bg-transparent dark:hover:!bg-base-200 dark:focus:!bg-base-200"
              onClick={() => {
                const newPropertiesMap = JSON.parse(JSON.stringify({ ...props.propertiesMap }))

                if (props.optionRecord?.isCustomField) {
                  delete newPropertiesMap?.customFields?.[props.fieldKey]
                  props.formInstance.setFields([
                    {
                      name: [...props.fieldName, "customFields"],
                      value: newPropertiesMap?.customFields,
                    },
                  ])
                } else {
                  delete newPropertiesMap?.[props.fieldKey]
                  props.formInstance.setFields([
                    {
                      name: props.fieldName,
                      value: newPropertiesMap,
                    },
                  ])
                }
              }}
            />
          </Tooltip>
          <div className="flex-1 truncate font-medium">{props.optionRecord.label}</div>
        </div>
      </div>
      <Form.Item
        name={props.name}
        rules={
          props.optionRecord?.type !== InputType.CustomNode
            ? [
                {
                  required: true,
                  message: "Obrigatório.",
                },
              ]
            : []
        }
        noStyle
        className="flex-1 w-full"
      >
        <Form.Item name={props.name} noStyle>
          <InputVariable field={props.optionRecord} className="flex-1 w-full" />
        </Form.Item>
      </Form.Item>
    </div>
  )
}

export default memo(BaseUpdateActionItem)
