import { Button } from "antd"
import { themeAtom } from "atoms/app.atom"
import { CircleArrowDown01Icon } from "components-old/icons"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { twMerge } from "tailwind-merge"

interface ExpanderProps {
  children: React.ReactNode
  maxHeight: number
  iconClassName?: string
  iconStyle?: React.CSSProperties
}

function Expander(props: ExpanderProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [theme] = useRecoilState(themeAtom)
  const contentRef = useRef<any>(null)

  useEffect(() => {
    if ((contentRef.current?.scrollHeight || 0) > props.maxHeight) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [props.maxHeight])

  const handleToggle = (e) => {
    e?.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  //   const style =
  //     theme === "dark"
  //       ? {
  //           background: "linear-gradient( to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100% )",
  //         }
  //       : {
  //           background: "linear-gradient( to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100% )",
  //         }

  return (
    <div className="relative">
      <div
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : `${props.maxHeight}px`,
          overflow: "hidden",
          transition: "max-height 0.3s ease",
          position: "relative",
        }}
      >
        {props.children}
        {!isExpanded && showButton && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50px", // Ajuste a altura conforme necessário
              //   ...style,
              background: "linear-gradient(to bottom, transparent 0%, rgb(255, 255, 255, 0.5) 50%, white)",
              borderBottom: "1px solid var(--divider)",
            }}
          />
        )}
      </div>
      {showButton && (
        <div className="flex justify-center">
          <Button className="mt-1 w-full text-2sm" onClick={handleToggle} type="text">
            {/* {isExpanded ? "Mostrar menos" : "Mostrar mais"} */}
            <CircleArrowDown01Icon
              className={twMerge("w-5 h-5", props.iconClassName)}
              style={isExpanded ? { ...props.iconStyle, transform: "rotate(180deg)" } : props.iconStyle}
            />
            {isExpanded ? "Mostrar menos" : "Mostrar mais"}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Expander
