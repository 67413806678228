import React from "react"
import { IMember } from "models/members"
import Avatar from "./ui/Avatar"

interface IAgentAvatarProps {
    agent?: IMember
    size: number
    style?: React.CSSProperties
    fontSize?: number
}

function AgentAvatar(props: IAgentAvatarProps) {
    return (
        <Avatar
            index={props.agent?.id}
            size={props.size}
            fontSize={props.fontSize}
            style={props.style}
            imgSrc={props.agent?.avatar}
            name={props.agent?.name}
        />
    )
}

export default AgentAvatar
