import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { Button, Col, Row } from "antd"
// import { Link } from "react-router-dom"
import { IColumnProps } from "."
import { CSS } from "@dnd-kit/utilities"

// import styles from "./style.module.css"
import ListPageItem from "./ListPageItem"

interface IListPageDraggableItemProps<T> {
    item: T
    path: string
    columns: IColumnProps<T>[]
    draggable?: boolean
    style?: React.CSSProperties
}

function ListPageDraggableItem(props: IListPageDraggableItemProps<any>) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props.item.id,
        disabled: !props.draggable,
    })

    const style: any = {
        transform: CSS.Transform.toString(transform),
        transition,
        // zIndex: isDragging ? 1 : undefined,
    }

    return (
        <div ref={setNodeRef} {...attributes} style={style} key={props.item?.id}>
            <ListPageItem
                listeners={listeners}
                item={props.item}
                path={props.path}
                columns={props.columns}
                style={props.style}
                draggable={props.draggable}
                dragging={isDragging}
            />
        </div>
    )
}

export default ListPageDraggableItem
