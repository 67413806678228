import { Button } from "antd"
import Icon from "components-old/common/Icon"
import { twMerge } from "tailwind-merge"

interface AddListProps {
  children?: React.ReactNode
  className?: string | false
  onClick?: () => void
  disabled?: boolean
  icon?: React.ReactNode
}

export const AddListButton = (props: AddListProps) => {
  return (
    <Button
      type="dashed"
      style={{
        textAlign: "left",
        height: 45,
        borderWidth: 2,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
      className={twMerge("border-divider-100 !hover:border-primary rounded-lg mt-1", props.className)}
    >
      {props.icon || <Icon name="fas fa-plus-circle text-primary" style={{ marginRight: 5 }} />}
      <span style={{ color: "var(--content-primary)" }}>{props?.children || "Add"}</span>
    </Button>
  )
}
