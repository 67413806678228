import { default as MentionExtension } from "@tiptap/extension-mention"
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react"
import { VariableNode } from "./VariableNode"
import { mountVariableString } from "../variables.helper"

// {contact:$json.$.customFields.idade.value|fallback}

export const VariablesExtension = MentionExtension.extend({
  // draggable: true,
  addNodeView() {
    return ReactNodeViewRenderer(VariableNode)
  },
  parseHTML() {
    return [
      {
        tag: "variable-component",
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ["variable-component", mergeAttributes(HTMLAttributes)]
  },
  deleteTriggerWithBackspace: true,
  renderText({ node }) {
    return mountVariableString({
      type: node.attrs.id,
      jsonPath: node.attrs.jsonPath,
      reference: node.attrs.reference,
      fallback: node.attrs.fallback,
    })
    // return `{${node.attrs.id}|${node.attrs.jsonPath || ""}|${node.attrs.reference || ""}|${node.attrs.fallback || ""}}`
    // return `{${node.attrs.id}${node.attrs.jsonPath ? `.${node.attrs.jsonPath}` : ""}${
    //   node.attrs.fallback ? `|${node.attrs.fallback}` : ""
    // }}`
  },
  addAttributes() {
    return {
      id: {
        default: "",
      },
      label: {
        default: "",
      },
      fallback: {
        default: "",
      },
      jsonPath: {
        default: "",
      },
      reference: {
        default: "",
      },
    }
  },
})
