import React from "react"
import { Row, Select, SelectProps } from "antd"
import { useGenericStore } from "hooks"
import CustomSelect from "components-old/forms/CustomSelect"

interface PipelinesSelectProps extends SelectProps<any> {}

function PipelinesSelect(props: PipelinesSelectProps) {
  const { all: pipelines } = useGenericStore((state) => state.pipelines)

  return (
    <CustomSelect allowClear showSearch {...props}>
      {pipelines.map((pipeline: any) => (
        <Select.Option value={pipeline.id} key={pipeline.id} data-search={pipeline.name}>
          <Row align="middle" style={{ flexFlow: "nowrap" }}>
            {pipeline.name}
          </Row>
        </Select.Option>
      ))}
    </CustomSelect>
  )
}

export default PipelinesSelect
