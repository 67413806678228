import React from "react"

export default function ModalFooter(props) {
    const { children, style } = props
    if (!children) return null
    return (
        <div className="modal-footer" style={style}>
            {children}
        </div>
    )
}
