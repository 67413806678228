import cx from "classnames"
import React from "react"
import styles from "./style.module.css"
import Avatar, { IAvatarProps } from "components-old/ui/Avatar"
import Uploader from "../Uploader"
import { getAsset } from "lib/helper"
import { Form, Row, Col, Button } from "antd"
import toast from "lib/toast"

interface IImageUploader {
  label?: string
  description?: string | React.ReactNode
  bottomDescription?: string | React.ReactNode
  name?: string | string[]
  avatarPlaceholderIcon?: string
  avatarPlaceholder?: string
  avatarProps?: IAvatarProps
  className?: string
  extra?: React.ReactNode
  removable?: boolean
  leftRemovable?: boolean
  setFiles?: (files: any) => void
  size?: number
  onRemove?: any
  disabled?: boolean
}

function ImageUploader(props: IImageUploader) {
  const name = props.name || "avatar"

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue, setFields }) => {
        let image = getFieldValue(name)
        const id = getFieldValue("id")
        return (
          <div>
            <Row
              style={{ marginBottom: props.leftRemovable ? 0 : 10 }}
              className={cx("image_uploader_wrapper", props.className)}
              justify="center"
              align={props.leftRemovable ? "middle" : "top"}
            >
              {props.leftRemovable && (
                <Col style={{ marginRight: 15 }}>
                  <div>
                    {props.leftRemovable && image && (
                      <Button
                        type="text"
                        size="small"
                        onClick={() => {
                          setFields([
                            {
                              name: name,
                              value: null,
                            },
                          ])
                          // setFieldsValue({ [name]: null })
                          props.onRemove?.()
                        }}
                      >
                        Remover
                      </Button>
                    )}
                  </div>
                </Col>
              )}
              <Col>
                <Form.Item name={name} noStyle>
                  <Uploader
                    className={cx(styles.avatar_uploader)}
                    dropOptions={{
                      accept: "image/*",
                      multiple: false,
                      disabled: props.disabled,
                      maxSize: 2097152,
                      onDropRejected: () => {
                        toast.error("Erro ao fazer upload. O tamanho máximo deve ser 2MB.")
                      },
                    }}
                    style={{
                      width: props.size || 80,
                      height: props.size || 80,
                    }}
                  >
                    <Avatar
                      imgSrc={image?.[0]?.preview || getAsset(image)}
                      size={props.size || 60}
                      index={id}
                      name={getFieldValue("name")}
                      fontSize={20}
                      avatarPlaceholderIcon={props.avatarPlaceholderIcon}
                      avatarPlaceholder={props.avatarPlaceholder}
                      {...props.avatarProps}
                    />
                  </Uploader>
                </Form.Item>
              </Col>
              {(props.label || props.description) && (
                <Col>
                  <div className={cx(styles.avatar_info, "avatar_info")}>
                    {props.label && <p>{props.label}</p>}
                    {props.description && <span>{props.description}</span>}
                    <div>
                      {props.removable && !props.disabled && image && (
                        <Button
                          size="small"
                          onClick={() => {
                            // setFieldsValue({ [name]: null })
                            setFields([
                              {
                                name: name,
                                value: null,
                              },
                            ])
                          }}
                        >
                          Remover
                        </Button>
                      )}
                    </div>
                    {props.extra}
                  </div>
                </Col>
              )}
            </Row>
            {(props.removable || props.bottomDescription) && !props.disabled && (
              <Row justify="center">
                <Col>
                  <div>
                    {props.removable && image && (
                      <Button
                        size="small"
                        onClick={() => {
                          // setFieldsValue({ [name]: null })
                          setFields([
                            {
                              name: name,
                              value: null,
                            },
                          ])
                        }}
                        style={{ marginBottom: 5 }}
                      >
                        Remover
                      </Button>
                    )}
                  </div>
                  {props.bottomDescription}
                </Col>
              </Row>
            )}
          </div>
        )
      }}
    </Form.Item>
  )
}

export default ImageUploader
