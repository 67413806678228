import { OnChangeFn, SortingState, TableOptions, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { useCallback, useEffect, useState } from "react"
import { GenericResource } from "models/generic"
import { VirtualInfiniteTableProps } from "./virtual/VirtualInfiniteTable"
import cx from "classnames"
import { useDebouncedCallback } from "use-debounce"

interface UseTableProps<T extends GenericResource> extends VirtualInfiniteTableProps<T> {
  tableContainerRef: React.RefObject<HTMLDivElement>
  scrollToTop?: () => void
  reactTableOptions?: Partial<TableOptions<T>>
}

export const useTable = <T extends GenericResource>(props: UseTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([])

  //flatten the array of arrays from the useInfiniteQuery hook
  const totalDBRowCount = props.totalCount ?? 0
  const totalFetched = props.data.length

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(() => {
    if (!props.isFetching && totalFetched < totalDBRowCount) {
      props.fetchNextPage()
    }
  }, [props, totalFetched, totalDBRowCount])
  // const fetchMoreOnBottomReached = useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement) {
  //       const { scrollHeight, scrollTop, clientHeight } = containerRefElement
  //       console.log("fetchMoreOnBottomReached", scrollHeight, scrollTop, clientHeight, scrollHeight - scrollTop - clientHeight < 100)
  //       //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
  //       if (scrollHeight - scrollTop - clientHeight < 100 && !props.isFetching && totalFetched < totalDBRowCount) {
  //         props.fetchNextPage()
  //       }
  //     }
  //   },
  //   [props, totalFetched, totalDBRowCount]
  // )

  const debouncedFetchMoreOnBottomReached = useDebouncedCallback((containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement
      //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
      if (scrollHeight - scrollTop - clientHeight < 500 && !props.isFetching && totalFetched < totalDBRowCount) {
        props.fetchNextPage()
      }
    }
  }, 50)

  // const debouncedFetchMoreOnBottomReached = useCallback(
  //   debounce(
  //     () =>
  //       fetchMoreOnBottomReached({
  //         isFetching: props.isFetching,
  //         fetchNextPage: props.fetchNextPage,
  //         totalFetched,
  //         totalDBRowCount,
  //         containerRefElement: props.tableContainerRef.current,
  //       }),
  //     200
  //   ),
  //   []
  // )

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    // If local data, we don't need to fetch more data because we already have all data
    if (!props.local) {
      debouncedFetchMoreOnBottomReached(props.tableContainerRef.current)
    }
  }, [debouncedFetchMoreOnBottomReached, props.local, props.tableContainerRef])

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    state: {
      sorting: props.sorting ?? sorting,
      columnPinning: {
        // left: ['expand-column'],
        right: ["actions-column"],
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: false,
    ...props.reactTableOptions,

  })

  //scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    if (props.onSortChange) {
      props.onSortChange?.(updater)
      setTimeout(() => {
        if (!!table.getRowModel().rows.length) {
          props.scrollToTop?.()
        }
      }, 50)
    } else {
      setSorting(updater)
      if (!!table.getRowModel().rows.length) {
        props.scrollToTop?.()
      }
    }
  }

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  //   TODO: Ver se realmente precisa disso ou posso colocar direto no useractable hook
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))

  const { rows } = table.getRowModel()

  return {
    totalDBRowCount,
    totalFetched,
    rows,
    fetchMoreOnBottomReached: debouncedFetchMoreOnBottomReached,
    table,
    bodyRowClassname: cx(
      "first:!border-transparent",
      props.onClickRow &&
        "peer cursor-pointer hover:!border-transparent hover:bg-base-300 hover:rounded-xl focus-within:bg-base-300 focus-within:rounded-xl focus-within:!border-transparent"
    ),
    bodyRowCellClassname: "h-14 flex items-center px-2.5 truncate",
  }
}
