import BaseUpdateAction from "pages/settings/workflows/actions/BaseUpdateAction"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { useRecoilState, useSetRecoilState } from "recoil"
import { entityFieldsAtom } from "atoms/workflow.atom"
import { Button, Form, Input, Select } from "antd"
import SelectReference from "pages/settings/workflows/actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import { BookmarkAdd02Icon, BookmarkMinus02Icon, FilterIcon, NoteAddIcon, PackageIcon, TextSquareIcon } from "components-old/icons"
import { InputType } from "lib/constants"
import TagsSelect from "components-old/ui/TagsSelect"
import FormItem from "antd/lib/form/FormItem"
import CustomSelect from "components-old/forms/CustomSelect"
import { useGenericStore } from "hooks"
import usePrivacy, { ItemWithPrivacy } from "hooks/usePrivacy"
import { Pipeline } from "models/pipelines"
import { TagItem } from "components-old/chat/TagInput"
import { convertColorToTagColor } from "pages/settings/tags/EditTag"
import { modalLevel2Atom } from "atoms/app.atom"
import ChooseProductsModal from "pages/crm/deals/ViewDeal/ChooseProductsModal"

interface UpdateDealActionProps extends BaseEditActionChildrenProps {}

const UpdateDealAction = (props: UpdateDealActionProps) => {
  const fieldName = [...props.actionFieldName, "deal"]
  const [dynamicFields] = useRecoilState(entityFieldsAtom)
  const { all: pipelinesAll } = useGenericStore((state) => state.pipelines)
  const setModal2 = useSetRecoilState(modalLevel2Atom)

  const { allowedItems: pipelines } = usePrivacy<Pipeline>({
    items: pipelinesAll as ItemWithPrivacy[],
  })

  return (
    <div>
      <Form.Item label="Negócio" name={[...fieldName, "dealReference"]} required>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.deal} excludeId={props.editNode.id} />
      </Form.Item>
      {/* <Form.Item shouldUpdate noStyle>
        {(form) => {
          console.log(form.getFieldValue(fieldName))
        }}
      </Form.Item> */}
      <BaseUpdateAction
        form={props.form}
        fieldName={fieldName}
        fields={dynamicFields.deal}
        extraFields={[
          {
            name: "addTags",
            label: "Adicionar tags",
            icon: BookmarkAdd02Icon,
            type: InputType.CustomNode,
            custom: (
              <FormItem name={[...fieldName, "addTags"]} noStyle>
                <TagsSelect mode="multiple" allowClear />
              </FormItem>
            ),
          },
          {
            name: "removeTags",
            label: "Remover tags",
            icon: BookmarkMinus02Icon,
            type: InputType.CustomNode,
            custom: (
              <FormItem name={[...fieldName, "removeTags"]} noStyle>
                <TagsSelect mode="multiple" allowClear />
              </FormItem>
            ),
          },
          {
            name: "name",
            label: "Nome",
            icon: TextSquareIcon,
            type: InputType.CustomNode,
            custom: (
              <FormItem name={[...fieldName, "name"]} noStyle>
                <Input />
              </FormItem>
            ),
          },
          {
            name: "stageId",
            label: "Mudar etapa",
            icon: FilterIcon,
            type: InputType.CustomNode,
            custom: (
              <div className="bordered">
                <Form.Item label="Funil" name={[...fieldName, "pipelineId"]}>
                  <CustomSelect
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={() => {
                      props.form.setFields([
                        {
                          name: [...fieldName, "stageId"],
                          value: undefined,
                        },
                      ])
                    }}
                  >
                    {pipelines?.map((pipeline) => (
                      <Select.Option key={pipeline._id} value={pipeline._id}>
                        {pipeline.name}
                      </Select.Option>
                    ))}
                  </CustomSelect>
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {(form) => {
                    const pipelineStages = pipelines?.find(
                      (pipeline) => pipeline._id === form.getFieldValue([...fieldName, "pipelineId"])
                    )?.stages

                    return (
                      <Form.Item label="Etapa" name={[...fieldName, "stageId"]}>
                        <CustomSelect allowClear showSearch optionFilterProp="label">
                          {pipelineStages?.map((stage) => (
                            <Select.Option key={stage.id} value={stage.id} label={stage.name}>
                              <div className="flex items-center h-full">
                                <TagItem colors={convertColorToTagColor(stage.color)}>{stage.name}</TagItem>
                              </div>
                            </Select.Option>
                          ))}
                        </CustomSelect>
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </div>
            ),
          },
          // {
          //   name: "addComment",
          //   label: "Adicionar comentário",
          //   icon: NoteAddIcon,
          //   type: InputType.CustomNode,
          //   custom: (
          //     <FormItem name={[...fieldName, "addComment"]} noStyle>
          //       <TextArea />
          //     </FormItem>
          //   ),
          // },
          {
            name: "addProducts",
            label: "Adicionar produto",
            icon: PackageIcon,
            type: InputType.CustomNode,
            custom: (
              <div className="bordered">
                <Form.Item shouldUpdate noStyle>
                  {(form) => {
                    const products = form.getFieldValue([...fieldName, "addProducts"]) || []
                    const hasProducts = products?.length > 0
                    return (
                      <Button
                        type="text"
                        icon={!hasProducts && <i className="fas fa-plus-circle" />}
                        className="text-primary hover:text-primary focus:text-primary hover:bg-blue-50 dark-theme:hover:bg-base-100-hover focus:bg-transparent"
                        onClick={() => {
                          setModal2(
                            <ChooseProductsModal
                              deal={{ products } as any}
                              onSubmit={async (model) => {
                                // setFieldsValue({ [addPrefix("products")]: model.products, [addPrefix("value")]: calculateProductsTotal(model.products) })
                                form.setFields([
                                  {
                                    name: [...fieldName, "addProducts"],
                                    value: model.products,
                                  },
                                ])
                                setModal2(null)
                                props.syncElements()
                              }}
                              onBack={() => {
                                setModal2(null)
                              }}
                            />
                          )
                        }}
                      >
                        {hasProducts ? products?.length + " produto" + (products?.length > 1 ? "s" : "") : "Produto"}
                      </Button>
                    )
                  }}
                </Form.Item>
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}

export default UpdateDealAction
