import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import FallbackDrop from "./FallbackDrop"
import { useMemo, useState } from "react"
import { debounce } from "lodash"
import VariableTag from "./VariableTag"
import { workflowElementsObj } from "pages/settings/workflows/EditWorkflow"

export function VariableNode(props: NodeViewProps) {
  const [visible, setVisible] = useState(false)
  // const { parseVariables, values } = useVariablesContext()

  const updateFallbackDebounced = useMemo(() => {
    return debounce(
      (value) => {
        props.updateAttributes({
          fallback: typeof value === "string" ? value?.replace?.(/[{}|]/g, "")?.trim?.() : value,
        })
      },
      100,
      {
        maxWait: 2000,
      }
    )
  }, [])

  const updateJsonPathDebounced = useMemo(() => {
    return debounce(
      (value) => {
        if (!value || value?.startsWith?.("$.") || value?.startsWith?.("$[")) {
          props.updateAttributes({
            jsonPath: value.replace(/[{}|]/g, "")?.trim?.(),
          })
        }
      },
      100,
      {
        maxWait: 2000,
      }
    )
  }, [])

  // const [type, reference] = props.node.attrs.id?.split?.("#") || []
  // console.log("props.node.attrs.id", props.node.attrs)
  const deletedVariable = props.node.attrs.reference && !workflowElementsObj[props.node.attrs.reference]

  return (
    <NodeViewWrapper className="inline w-fit cursor-pointer">
      <FallbackDrop
        onChange={updateFallbackDebounced}
        onChangeJsonPath={updateJsonPathDebounced}
        fallback={props.node?.attrs.fallback}
        jsonPath={props.node?.attrs.jsonPath}
        setVisible={setVisible}
        type={props.node.attrs.id}
        reference={props.node.attrs.reference}
      >
        <span>
          <VariableTag
            type={props.node.attrs.id}
            reference={props.node.attrs.reference}
            fallback={props.node.attrs.fallback}
            jsonPath={props.node.attrs.jsonPath}
            showCategory
            selected={visible}
            deletedVariable={deletedVariable}
          />
        </span>
        {/* <Tooltip
          title={
            <div className="p-1 text-black">
              <div className="flex items-center mb-2 text-xs gap-1">
                <span className="font-bold">{variable?.type}</span>
                <span className="text-xs">{`<${variable?.primitiveType}>`}</span>
              </div>
              <div>{variable?.description}</div>
            </div>
          }
          color={colors.pink[300]}
        >
          <span
            className={twMerge(
              "tiptap-variable variable-selectable rounded-[5px] px-1 py-[1px] !no-underline whitespace-nowrap inline-flex items-center gap-0.5 text-2sm font-medium"
            )}
            style={{
              ...(visible
                ? {
                    boxShadow: "0 0 0 2px #fde68a",
                  }
                : {}),
              backgroundColor: colors.pink[100],
              border: "1px solid " + colors.pink[200],
              // backgroundColor: getLightTagColors(category.palette)?.bg,
            }}
          >
            <span className="text-pink-900">{category?.label}</span>
            <ArrowRight02Icon className="w-4 h-4 text-pink-800" />
            {props.node.attrs.label || props.node.attrs.id}
          </span>
        </Tooltip> */}
      </FallbackDrop>
    </NodeViewWrapper>
  )
}
