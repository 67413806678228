import { Editor } from "@tiptap/react"
import { useEffect, useMemo } from "react"
import { debounce } from "lodash"
import React from "react"
import { InputVariableTextProps } from "./InputTextVariable"
import VariableDropTrigger from "./VariableDropTrigger"
import Tooltip from "components-old/Tooltip"
import { Button } from "antd"
import { AiBeautifyIcon } from "components-old/icons"

interface InputVariableBarProps extends InputVariableTextProps {
  editor: Editor | null
  beautifyJson?: Function
}

const InputVariableBar = (props: InputVariableBarProps) => {
  // const { editor } = useCurrentEditor()
  const { editor } = props

  const onChangeDebounce = useMemo(
    () =>
      debounce(
        (editor: Editor | null) => {
          const text = editor?.getText()?.trim()

          // console.log("salvando texto", text)
          // console.log("salvando html", editor?.getHTML())
          // console.log("salvando json", editor?.getJSON())
          props.onChange?.(text ?? "")
        },
        300,
        {
          maxWait: 2000,
        }
      ),
    [props.onChange]
  )

  useEffect(() => {
    editor?.on("update", () => {
      onChangeDebounce?.(editor)
    })
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <>
      <VariableDropTrigger
        floatingTrigger={props.floatingTrigger}
        editor={editor}
        command={(dto: any) => {
          editor.chain().focus().run()
          editor.commands.insertContent(
            `<variable-component id="${dto.id || ""}" fallback="${dto.fallback || ""}" jsonPath="${dto.jsonPath || ""}" reference="${
              dto.reference || ""
            }"></variable-component>`
          )
        }}
      />
      {/* TODO: BOTAO DE FORMATAR */}
      {/* {props.json && (
        <div className="absolute -top-9 right-12 z-[1]">
          <Tooltip mouseEnterDelay={0} title="Formatar">
            <div>
              <Button
                onClick={() => props.beautifyJson?.()}
                className="w-8 h-8"
                icon={<AiBeautifyIcon className="w-[18px] h-[18px] text-content-100" />}
                type="text"
              />
            </div>
          </Tooltip>
        </div>
      )} */}
    </>
    // <div className="absolute -top-9 right-[3px]">
    //   <Tooltip mouseEnterDelay={0} title="Usar variável">
    //     <button
    //       onClick={() => {
    //         getVariablesDrop({
    //           editor,
    //           command: (dto: any) => {
    //             editor.chain().focus().run()
    //             editor.commands.insertContent(
    //               `<variable-component id="${dto.id || ""}" fallback="${dto.fallback || ""}" jsonPath="${dto.jsonPath || ""}" reference="${
    //                 dto.reference || ""
    //               }"></variable-component>`
    //             )
    //           },
    //           clientRect: () => buttonRef.current?.getBoundingClientRect(),
    //         } as any)
    //       }}
    //       className={cx(buttonClass)}
    //       type="button"
    //       ref={buttonRef}
    //     >
    //       <CodeIcon className="w-[18px] h-[18px] text-content-100" />
    //     </button>
    //   </Tooltip>
    // </div>
  )
}

export default InputVariableBar
