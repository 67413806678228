import React, { memo, useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { SimpleImg } from "react-simple-img"

import defaultAvatarSrc from "assets/images/no-avatar.svg"
import styles from "./style.module.css"
import Icon from "components-old/common/Icon"
import { LoadingOutlined } from "@ant-design/icons"
import { getAsset, getColorPaletteByIndex, seedStringToNumber } from "lib/helper"
import { useRecoilState } from "recoil"
import { themeAvatarAtom } from "atoms/app.atom"
import { darkVividPalette, defaultPalette, lightPalette } from "lib/constants"
import { convertColorToTagColor } from "pages/settings/tags/EditTag"

export const colorsDarker = [
  "#a39f95",
  "#6e7dc2",
  "#959b9e",
  "#645cc4",
  "#b57364",
  "#2f8a78",
  "#d6815a",
  "#b59759",
  "#cf6a87",
  "#bf7891",
  "#3c868f",
  "#608049",
  "#bfa05e",
  "#cf6a87",
  "#e15f41",
  "#4081ad",
  "#87a13f",
  "#b08966",
]

// export const colors = [
//     "#d1ccc0",
//     "#abb7f1",
//     "rgb(173, 56, 137)",
//     "#dfe6e9",
//     "#a29bfe",
//     "#fab1a0",
//     "#4fd6bb",
//     "#f3a683",
//     "#f7d794",
//     "#cf6a87",
//     "#f8a5c2",
//     "#63cdda",
//     "#b8e994",
//     "#f5cd79",
//     "#cf6a87",
//     "#e15f41",
//     "#5cb8f6",
//     "#badc58",
//     "#f8c291",
// ]
export const colors = darkVividPalette

export type IAvatarProps = {
  imgSrc?: string
  name?: string
  size?: number
  index?: string
  avatarPlaceholderIcon?: string
  fontSize?: number
  lazy?: boolean
  round?: boolean
  loading?: boolean
  showOverlay?: boolean
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  iconStyle?: React.CSSProperties
  placeholderStyle?: React.CSSProperties
  className?: string
  overlayIcon?: React.ReactNode
  color?: string
  shadow?: boolean
  avatarPlaceholder?: string
  notRounded?: boolean
  unRounded?: boolean
  oneLetter?: boolean
  forceInitials?: boolean
  icon?: React.ReactNode
  themeAvatar?: string
  palette?: string[]
}

function Avatar({
  name = "",
  size = 32,
  fontSize,
  index,
  lazy = false,
  round,
  loading,
  avatarPlaceholderIcon,
  iconStyle,
  placeholderStyle,
  ...props
}: IAvatarProps) {
  const [imageError, setImageError] = useState(false)
  const [themeAvatar, setThemeAvatar] = useRecoilState(themeAvatarAtom)
  // const avatarFunny = `https://avatars.dicebear.com/api/${props?.themeAvatar || themeAvatar}/${index || name}.svg?fontSize=40`
  const avatarFunny = `https://api.dicebear.com/6.x/${props?.themeAvatar || themeAvatar}/svg?fontSize=${size}&seed=${name || index}`
  const isThemeInitials = (props?.themeAvatar || themeAvatar) == "initials"
  const imgSrc = props.imgSrc || (isThemeInitials || props.forceInitials ? undefined : avatarFunny)

  useEffect(() => {
    setImageError(false)
  }, [imgSrc])

  // const parsedIndex = useMemo(() => parseInt((String(index) || "").slice(-2)), [index])
  const color = useMemo(() => {
    return getColorPaletteByIndex(index || name, props.palette || defaultPalette)
  }, [index, name, props.palette])

  const style = useMemo(
    () =>
      size
        ? {
            width: size,
            height: size,
            fontSize: fontSize || size / 2,
          }
        : {},
    [fontSize, size]
  )

  const [firstLetter, lastLetter] = useMemo(() => {
    const splitName = (name || "").split(" ")
    const first = splitName[0][0] || ""
    const last = splitName[1] ? splitName[1][0] : ""
    return props.oneLetter ? [first, ""] : [first, last]
  }, [name, props.oneLetter])

  const placeholder = useMemo(() => {
    const colors = convertColorToTagColor(props.color || color)

    return (
      <div
        className={cx(styles.placeholder, "placeholder")}
        style={{
          backgroundColor: colors.bg || props.color || color,
          color: colors.text,
          // boxShadow: `${colors.border} 0px 0px 0px 1px inset`,
          ...style,
          ...placeholderStyle,
        }}
      >
        {name ? (
          <>
            {firstLetter}
            {lastLetter}
          </>
        ) : (
          props.icon || (
            <Icon
              name={avatarPlaceholderIcon || "fa fa-user"}
              style={{
                fontSize: fontSize,
                // color: "rgba(255,255,255, 1)",
                color: colors.text,
                ...iconStyle,
              }}
            />
          )
        )}
      </div>
    )
  }, [avatarPlaceholderIcon, color, firstLetter, fontSize, iconStyle, lastLetter, name, placeholderStyle, props.color, props.icon, style])

  const imgProps = {
    height: size,
    width: size,
    src: imageError ? avatarFunny : getAsset(imgSrc!) || props.avatarPlaceholder,
    style: { ...props.imageStyle, width: size, height: size },
    onError: () => {
      setImageError(true)
    },
  }

  return (
    <figure
      className={cx(styles.avatar, props.className, {
        round,
        [styles.shadow]: props.shadow,
        [styles.notRounded]: props.notRounded,
      })}
      style={props.style}
    >
      {(imgSrc || props.avatarPlaceholder) && (!imageError || !isThemeInitials) ? (
        lazy ? (
          <SimpleImg placeholder={props.avatarPlaceholder || defaultAvatarSrc} {...imgProps} />
        ) : (
          <img {...imgProps} alt="avatar" />
        )
      ) : (
        placeholder
      )}
      {loading && (
        <div className={styles.loading}>
          <div>
            <LoadingOutlined />
          </div>
        </div>
      )}
      {props.showOverlay && (
        <div className={styles.overlay}>
          {props.overlayIcon}
        </div>
      )}
    </figure>
  )
}

export default memo(Avatar)
