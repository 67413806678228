import React, { useEffect } from "react"
import Form, { FormInstance } from "antd/es/form"
import ChannelsSelect from "components-old/ui/ChannelsSelect"

interface EditNewSessionProps {
  formInstance: FormInstance
  actionFieldName: string[]
  syncElements: Function
}

function EditNewSession(props: EditNewSessionProps) {
  const channelFormName = [...props.actionFieldName, "channelId"]
  const channelIds = props.formInstance.getFieldValue(channelFormName)

  useEffect(() => {
    props.formInstance.setFields([
      {
        name: [...props.actionFieldName, "triggerFilterValue"],
        value: JSON.stringify(channelIds || []),
      },
    ])
    setTimeout(() => {
      props.formInstance.setFields([
        {
          name: [...props.actionFieldName, "triggerFilterValue"],
          value: JSON.stringify(channelIds || []),
        },
      ])
      props.syncElements()
    }, 500)
  }, [channelIds])

  return (
    <div>
      <ChannelsSelect name={channelFormName} mode="multiple" />
    </div>
  )
}

export default EditNewSession
