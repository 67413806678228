import React, { lazy, Suspense, useEffect, useMemo, useState } from "react"
import { IEnv, initRequest } from "lib/request"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import { general, session, useStore } from "store"
import config from "config"
import { getHost, isMolaSkin, isPortSkin } from "lib/helper"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { appLoaderAtom, isMobileAtom, randomKeyAtom, themeAtom } from "atoms/app.atom"
import RequestInspector from "lib/nanui/RequestInspector"
import Favicon from "react-favicon"
import faviconDevAsset from "assets/images/logos/favicon-dev.png"
import faviconMolaAsset from "assets/images/logos/favicon-mola.png"
import faviconNextAsset from "assets/images/logos/favicon-next.png"
import faviconInspectionAsset from "assets/images/logos/favicon-inspection.png"
import AppLoader from "components-old/ui/AppLoader"
import RedirectLogin from "RedirectLogin"
import ReloadPrompt from "components/feedback/ReloadPrompt"
import { ErrorBoundary } from "react-error-boundary"

const AuthLayout = lazy(() => import("./layouts/AuthLayout"))
const BasicLayoutAuth = lazy(() => import("./layouts/BasicLayoutAuth"))
const Reset = lazy(() => import("./pages/auth/Reset"))
const Forgot = lazy(() => import("./pages/auth/Forgot"))
const JoinRegister = lazy(() => import("./pages/auth/JoinRegister"))
const JoinWorkspace = lazy(() => import("./pages/auth/JoinWorkspace"))
const InvoicePrint = lazy(() => import("./pages/settings/billing/InvoicePrint"))
const EmailConfirm = lazy(() => import("./pages/auth/EmailConfirm"))
const AdminViewWorkspace = lazy(() => import("./pages/auth/AdminViewWorkspace"))
const NextViewWorkspace = lazy(() => import("./pages/auth/InsiderViewWorkspace"))

const _isProduction = import.meta.env?.PROD && import.meta.env.VITE_ENV !== "staging"

function AppRouter() {
  const generalStore = useStore(general)
  const sessionStore = useStore(session)
  const setRandomKey = useSetRecoilState(randomKeyAtom)
  const theme = useRecoilValue(themeAtom)
  const appLoader = useRecoilValue(appLoaderAtom)
  const [isInspectionMode, setIsInspectionMode] = useState(false)
  // const { subdomain } = getHost()
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)

  const faviconAsset = useMemo(() => {
    if (isMolaSkin()) {
      return faviconMolaAsset
    }

    if (isInspectionMode) {
      return faviconInspectionAsset
    }

    // if (getHost()?.subdomain === "next") {
    //   return faviconNextAsset
    // }

    if (!_isProduction) {
      return faviconDevAsset
    }
  }, [isMolaSkin(), isInspectionMode])

  useEffect(() => {
    try {
      switch (theme) {
        case "light":
          // ;(window as any).themeColor.content = "#2f3136"
          ;(window as any).themeColor.content = "#fff"
          break
        case "blue":
          // ;(window as any).themeColor.content = "#054475"
          // ;(window as any).themeColor.content = "#0060aa"
          ;(window as any).themeColor.content = "#0078d4"
          break
        case "purple":
          // ;(window as any).themeColor.content = "#054475"
          // ;(window as any).themeColor.content = "#0060aa"
          ;(window as any).themeColor.content = "#444791"
          break
        case "port":
          // ;(window as any).themeColor.content = "#054475"
          // ;(window as any).themeColor.content = "#0060aa"
          ;(window as any).themeColor.content = "#405584"
          break

        case "noir":
          ;(window as any).themeColor.content = "#3d3d3d"
          break

        case "dark":
          ;(window as any).themeColor.content = "#2f3136"
          break
      }
    } catch (e) {
      console.log(e)
    }
  }, [theme])

  const history = useHistory()

  useEffect(() => {
    initRequest({
      history,
      defaultEnv: _isProduction ? config.envs[1] : config.envs[0],
    })
    generalStore?.recoverDomain()
    sessionStore?.recoverSession()

    const { subdomain } = getHost()
    if (subdomain == "view" || subdomain == "partner-inspect") {
      setIsInspectionMode(true)
    }
    if (subdomain == "mobile" || window.innerWidth < 768) {
      document.body.classList.add("mobile")
      setIsMobile(true)
    }

    if (isMolaSkin()) {
      document.body.classList.add("mola")
    }

    if (isPortSkin()) {
      document.body.classList.add("port")
    }

    // Tocar som apenas em uma aba aberta
    var randomKey = Math.random() * 10000 + ""
    setRandomKey(randomKey)
    localStorage.setItem("tabKey", randomKey)

    //when main tab closes, remove item from localStorage
    window.addEventListener("unload", function () {
      if (localStorage.getItem("tabKey") == randomKey) {
        localStorage.removeItem("tabKey")
      }
    })

    if (isMobile) {
      document.ontouchmove = function (e) {
        e.preventDefault()
      }
    }

    //when non-main tabs receive the "close" event,
    //the first one will set the `tabKey` to its `randomKey`
    window.addEventListener("storage", function (evt) {
      if (evt.key == "tabKey" && evt.newValue == null) {
        if (localStorage.getItem("tabKey") == null || localStorage.getItem("tabKey") == undefined) {
          localStorage.setItem("tabKey", randomKey)
        }
      }
    })

    try {
      document.getElementById("initial-loading-wrapper")!.style.opacity = "0"
      document.getElementById("initial-loading-wrapper")!.style.zIndex = "-1"

      setTimeout(() => {
        const element = document.getElementById("initial-loading-wrapper")
        if (element) {
          element.remove()
        }
      }, 1000)
    } catch (e) {}
  }, [])

  return (
    <ErrorBoundary
      onError={(error, info) => {
        console.log(error, info)
      }}
      fallback={<></>}
    >
      <Suspense fallback={<AppLoader />}>
        {appLoader && <AppLoader />}
        {/* <Helmet>
                {theme == "light" && <meta name="theme-color" content="#fff" />}
                {theme == "blue" && <meta name="theme-color" content="rgb(0, 120, 212)" />}
                {theme == "noir" && <meta name="theme-color" content="rgb(61, 61, 61)" />}
                {theme == "dark" && <meta name="theme-color" content="#2f3136" />}
            </Helmet> */}
        <Switch>
          <Route path="/view-workspace/:token">
            <AdminViewWorkspace />
          </Route>
          <Route path="/next-workspace/:token">
            <NextViewWorkspace />
          </Route>
          <Route path="/auth/email-confirm">
            <EmailConfirm />
          </Route>
          <Route path="/invoices/:id/:transactionId">
            <InvoicePrint />
          </Route>
          <Route path="/auth/join-workspace">
            <JoinWorkspace />
          </Route>
          <Route path="/auth/join/register">
            <JoinRegister />
          </Route>
          <Route path="/auth/forgot">
            <Forgot />
          </Route>
          <Route path="/auth/reset">
            <Reset />
          </Route>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          {sessionStore?.user.email ? (
            <Route path="/">
              <BasicLayoutAuth />
            </Route>
          ) : (
            !sessionStore?.user.isRecovering && <RedirectLogin />
          )}
        </Switch>
        {!_isProduction && <RequestInspector envs={config.envs} left onChange={(env: IEnv) => generalStore?.setDomain(env)} />}
        {faviconAsset && <Favicon url={faviconAsset} />}
        <ReloadPrompt />
      </Suspense>
    </ErrorBoundary>
  )
}

export default AppRouter
