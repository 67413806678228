import React from "react"
import SearchDrop from "components-old/ui/SearchDrop"
import { Menu } from "antd"
import { PlusSignIcon } from "components-old/icons"
import styles from "./style.module.css"
import cx from "classnames"
import { WorkflowDefs } from "./workflow.defs"
import IconTag from "components-old/shared/IconTag"
import { ActionEnum } from "./workflow.enums"

interface AddActionDropProps {
  onAddAction: (e: any, type: string) => void
  visible?: boolean
  setVisible?: (visible: boolean) => void
  addDropPosition?: {
    x: number
    y: number
  }
  setAddDropPosition?: any
  isOnEdgeEnd?: boolean
  getPopupContainer?: any
}

function AddActionDrop(props: AddActionDropProps) {
  // const setVisible = useCallback(
  //   (visible: boolean) => {
  //     if (!visible) {
  //       props.setAddDropPosition?.(null)
  //     }
  //     props.setVisible?.(visible)
  //   },
  //   [props]
  // )

  return (
    <div className={styles.add_action_drop}>
      <SearchDrop
        virtual={false}
        visible={props.visible}
        setVisible={props.setVisible}
        persistent
        width={350}
        getPopupContainer={props.getPopupContainer}
        title={
          <div className="flex-center gap-1.5">
            {/* <EnergyIcon className="text-amber-500" /> */}
            Nova ação
          </div>
        }
        // overlayClassname="!left-[200px] transform -translate-y-1/2"
        // overlayStyle={
        //   props.addDropPosition
        //     ? {
        //         left: props.addDropPosition?.x,
        //         top: props.addDropPosition?.y,
        //       }
        //     : {}
        // }
        menuStyle={props.isOnEdgeEnd ? {} : { marginTop: -70, marginLeft: 80 }}
        options={WorkflowDefs.actions
          ?.filter((x) => x.type !== ActionEnum.TRIGGER)
          .map((action) => {
            const category = WorkflowDefs.CategoryRecord[action.variableCategory]
            return {
              key: action.type,
              searchValue: action?.name,
              node: (
                <Menu.Item
                  key={action.type}
                  eventKey={action.type}
                  onClick={(e) => {
                    e.domEvent.stopPropagation()
                    props.onAddAction(e, action.type)
                  }}
                  style={{
                    width: "100%",
                  }}
                  icon={
                    <div>
                      <IconTag
                        icon={action.icon}
                        palette={category.palette}
                        className="!mr-0 [&>*]:!stroke-[2] border border-solid !w-8 !h-8 p-1"
                        size="lg"
                        lightTone={{
                          bg: 50,
                          text: 600,
                          // border: 0,
                        }}
                        // darkTone={{
                        //   bg: 0,
                        //   text: 600,
                        //   border: 0,
                        // }}
                        customColors={{
                          bg: action.colors?.bg,
                          text: action.colors?.text,
                        }}
                      />
                    </div>
                    // <Icon
                    //   className="w-6 h-6"
                    //   style={{
                    //     color: action.color,
                    //   }}
                    // />
                    // <div
                    //   className="w-8 h-8 rounded-full flex-center"
                    //   style={{
                    //     backgroundColor: action.color,
                    //   }}
                    // >
                    //   <Icon className="text-white w-5 h-5" />
                    // </div>
                  }
                >
                  <div className="py-1.5 ml-1">{action.name}</div>
                </Menu.Item>
              ),
            }
          })}
      >
        {props.isOnEdgeEnd ? (
          <div />
        ) : (
          <div
            className={cx(styles.animated_border, " w-16 h-16 rounded-full bg-primary flex-center shadow hover:shadow-lg cursor-pointer")}
          >
            <PlusSignIcon className="w-8 h-8 text-white" />
          </div>
        )}
      </SearchDrop>
    </div>
  )
}

export default AddActionDrop
