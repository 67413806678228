import { Form } from "antd"
import { ICustomFieldEntity } from "models/customFields"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"

interface BaseCustomFieldInputsProps {
  fieldName: string[]
  entity: ICustomFieldEntity
}

function BaseCustomFieldInputs(props: BaseCustomFieldInputsProps) {
  return (
    <Form.Item shouldUpdate={() => false} noStyle>
      {(formInstance) => {
        return (
          <EditEntityCustomFields
            form={formInstance as any}
            fieldName={props.fieldName}
            entity={props.entity}
            columns={1}
            variables
            notShowJson
          />
        )
      }}
    </Form.Item>
  )
}

export default BaseCustomFieldInputs
