import BaseUpdateAction from "pages/settings/workflows/actions/BaseUpdateAction"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import { useRecoilState } from "recoil"
import { entityFieldsAtom } from "atoms/workflow.atom"
import { Form } from "antd"
import SelectReference from "pages/settings/workflows/actions/SelectReference"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface UpdateContactActionProps extends BaseEditActionChildrenProps {}

const UpdateContactAction = (props: UpdateContactActionProps) => {
  const fieldName = [...props.actionFieldName, "contact"]
  const [dynamicFields] = useRecoilState(entityFieldsAtom)

  return (
    <div>
      <Form.Item label="Contato" name={[...fieldName, "contactReference"]} required>
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.contact} excludeId={props.editNode.id} />
      </Form.Item>
      <BaseUpdateAction form={props.form} fieldName={fieldName} fields={dynamicFields.contact} />
    </div>
  )
}

export default UpdateContactAction
