import React from "react"
import { Tooltip as TooltipAnt } from "antd"
import { TooltipProps } from "antd/lib/tooltip"

interface ITooltipProps {
    disabled?: boolean
}

function Tooltip(props: ITooltipProps & TooltipProps) {

    if (props.disabled) {
        return <>{props.children}</>
    }

    return (
        <TooltipAnt
            // placement="top"
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
            destroyTooltipOnHide
            autoAdjustOverflow
            zIndex={9999}
            {...props}
        >
            {props.children}
        </TooltipAnt>
    )
}

export default Tooltip