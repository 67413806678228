import { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "hooks"
import { useParams, useLocation } from "react-router-dom"
import { Actions } from "easy-peasy"
import queryString from "query-string"
import { IStoreModel } from "models"

export type IStorePath = (actions: Actions<IStoreModel>) => any

type IUseFetchOneFromParamId = <Result>(
  mapActions: (actions: Actions<IStoreModel>) => Result,
  config?: {
    existingItem?: any
    idName?: string
    notFetchOne?: boolean
    updateCounter?: number
  }
) => any

const useFetchOneFromParamId: IUseFetchOneFromParamId = (storePath, config) => {
  const idString = config?.idName || "id"
  //@ts-ignore
  const { all } = useStoreState(storePath as any)
  const [item, setItem] = useState()
  let params = useParams<any>()
  const id = params[idString]
  const location = useLocation()
  const { fetchOne, setModel }: any = useStoreActions(storePath)

  const changeItem = (value: any) => {
    setItem(value)
    setModel(value)
  }

  const setItemClone = (data: any) => {
    const query = queryString.parse(location.search)
    if (query.cloneFromId) {
      const keyName: any = (query.keyName as string) || "name"
      const { id, ...rest } = data
      changeItem({ ...rest, [keyName]: "Cópia de " + rest[keyName] })
    } else {
      changeItem(data)
    }
  }

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (config?.existingItem) {
      changeItem(config?.existingItem)
    } else {
      if (!config?.notFetchOne) {
        if (id != "new" || query.cloneFromId) {
          const _id = id != "new" ? id : query.cloneFromId
          const itemInAll = all.find((x: any) => x.id == _id)
          if (itemInAll) {
            setItemClone(itemInAll)
          } else {
            // console.log("_id", _id)
            fetchOne({ id: _id }).then((data: any) => {
              setItemClone(data)
            })
          }
        }
      }
    }
  }, [id, config?.updateCounter])

  return item
}

export { useFetchOneFromParamId }
