import { Button, Drawer } from "antd"
import React, { useState } from "react"
import SegmentDropdown from "components-old/shared/table/SegmentDropdown"
import { twMerge } from "tailwind-merge"
import { Workflow } from "models/workflows"
import AnalyticsCore from "./AnalyticsCore"
import { RefreshIcon } from "components-old/icons"
import Tooltip from "components-old/Tooltip"

export interface AnalyticsDrawerProps {
  onCancel: () => void
  workflow?: Workflow
  visible: boolean
  adminMode?: boolean
}

function AnalyticsDrawer(props: AnalyticsDrawerProps) {
  const [maxDays, setMaxDays] = useState(30)
  const [status, setStatus] = useState("")
  const [refreshing, setRefreshing] = useState(false)

  return (
    <Drawer
      title={
        <div className="flex items-center justify-between w-full">
          <div className="flex-1 text-lg">Analítico</div>
          <div className="flex gap-2">
            <Tooltip title="Atualizar">
              <Button
                className="rounded-xl"
                icon={<RefreshIcon />}
                loading={refreshing}
                onClick={() => {
                  setRefreshing(true)
                }}
              />
            </Tooltip>
            <SegmentDropdown<any>
              selected={maxDays as any}
              setSelected={(value) => {
                setMaxDays(value as any)
              }}
              segments={
                [
                  {
                    value: 1,
                    label: "Hoje",
                  },
                  {
                    value: 7,
                    label: "Últimos 7 dias",
                  },
                  {
                    value: 15,
                    label: "Últimos 15 dias",
                  },
                  {
                    value: 30,
                    label: "Últimos 30 dias",
                  },
                ] as any
              }
            />
            <SegmentDropdown<any>
              selected={status}
              setSelected={(value) => {
                setStatus(value as any)
              }}
              segments={[
                {
                  value: "",
                  label: "Tudo",
                },
                {
                  value: "pending",
                  label: "Pendente",
                },
                {
                  value: "awaiting",
                  label: "Aguardando",
                },
                {
                  value: "running",
                  label: "Executando",
                },
                {
                  value: "failed",
                  label: "Falhou",
                },
                {
                  value: "done",
                  label: "Concluído",
                },
              ]}
            />
          </div>
        </div>
      }
      className="g_trigger_modal no-mask"
      visible={props.visible}
      footer={null}
      // closable={false}
      closeIcon={<i className={twMerge("far fa-times")} />}
      width={Math.min(window.innerWidth - 100, 1024)}
      bodyStyle={{
        minHeight: 400,
        overflow: "hidden",
        paddingRight: 0,
        paddingLeft: 0,
      }}
      maskClosable
      maskStyle={{
        transition: "none",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      // onCancel={props.onCancel}
      onClose={props.onCancel}
      zIndex={30}
    >
      {props.visible && (
        <AnalyticsCore {...props} status={status} maxDays={maxDays} refreshing={refreshing} setRefreshing={setRefreshing} />
      )}
    </Drawer>
  )
}

export default AnalyticsDrawer
