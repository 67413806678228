import { useEffect } from "react"
import { useStore, general } from "store"
import { createStore, createTypedHooks } from "easy-peasy"
import useScrollTop from "./useScrollTop"
import models, { IStoreModel } from "models"
export * from "./useGenericStore"
export * from "./useFetchOneFromParamId"

// Store
const { useStoreActions, useStoreState, useStoreDispatch, ...rest } = createTypedHooks<IStoreModel>()

export const store = createStore(models)

function useFetch(storeName: any, { filters, query, ...config }: any = {}) {
  const store = useStore(storeName)

  useEffect(() => {
    ;(async function () {
      await store.clear()
      await store.get({ params: query, ...config })
    })()
  }, [filters])

  return store
}

function useBreadcrumb(
  newBreadcrumb: {
    title?: string | React.ReactNode
    subtitle?: string | React.ReactNode
    tabTitle?: string
    backTo?: string
  },
  { revertBreadcrumbOnBack }: any = {}
) {
  const { setPageTitle, setPageSubtitle } = useStoreActions((state) => state.general)
  const { changeBreadcrumb, breadcrumb } = useStore(general)

  useEffect(() => {
    changeBreadcrumb(newBreadcrumb)
    setPageTitle(newBreadcrumb.title)
    setPageSubtitle(newBreadcrumb.subtitle || "")
    document.title = (newBreadcrumb.tabTitle || newBreadcrumb.title) + " - Kinbox"
    if (revertBreadcrumbOnBack) {
      return () => {
        changeBreadcrumb(breadcrumb)
        document.title = (breadcrumb.tabTitle || breadcrumb.title) + " - Kinbox"
      }
    }
  }, [])
}

function useModalClick(onClick: any, hasModal: any) {
  useEffect(() => {
    if (hasModal) {
      const element = document.getElementById("modal-overlay-clickable")
      element?.addEventListener("click", onClick, { passive: false })
      return () => {
        element?.removeEventListener("click", onClick)
      }
    }
  }, [hasModal])
}

function useKeyPress(handler: any, { condition = false } = {}, listeners = []) {
  useEffect(() => {
    window.removeEventListener("keydown", handler)
    if (condition) {
      window.addEventListener("keydown", handler, { passive: true })
      return () => {
        window.removeEventListener("keydown", handler)
      }
    }
  }, [...listeners])
}

export { useBreadcrumb, useFetch, useModalClick, useKeyPress, useScrollTop, useStoreActions, useStoreState, useStore }
