import React, { useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Alert, Button, Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStoreState } from "hooks"
import { IChannel } from "models/channels"
import { ChannelStatus } from ".."
import WhatsappSvg from "../svgs/WhatsappSvg"
import TextArea from "antd/lib/input/TextArea"
import { WHATSAPP_CLOUD_API_WEBHOOK } from "lib/constants"
import copy from "copy-to-clipboard"
import toast from "lib/toast"
import HorizontalSwitch from "components-old/ui/HorizontalSwitch"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"
import GroupsSelect from "components-old/ui/GroupsSelect"

interface IEditEmailProps extends RouteComponentProps<{ id: string }> {
  onChange: Function
}

function EditWhatsappCloud(props: IEditEmailProps) {
  const [form] = Form.useForm()
  const { all: channels } = useStoreState((state) => state.channels)
  const { id } = useParams<any>()
  const [item, setItem] = useState<IChannel>()

  const channel = useMemo(() => {
    return channels.find((c) => c.id == id)
  }, [channels, id])

  return (
    <SettingsPageEdit<IChannel>
      {...props}
      pageComponent="page"
      routeName="channels"
      title="Canal de Whatsapp Cloud API"
      onChangeItem={(item) => setItem(item)}
      onSubmitSuccess={() => {
        props.onChange()
      }}
      width={500}
      titleOverwrite={
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <Row wrap={false}>
              <Col>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <WhatsappSvg />
                </div>
              </Col>
              <Col
                className="truncate"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {channel?.description}
              </Col>
            </Row>
          </Col>
        </Row>
      }
      storePath={(store) => store.channelsWhatsappCloudApi}
      form={form}
      hideFooterWhenSaved
      // defaultValues={{
      //     identifier: shortid.generate() + "kinbox@mail.kinbox.com.br",
      // }}
      formProps={{
        onValuesChange(changedValues, values) {
          if (changedValues?.isPrivate && !form.getFieldValue("groupIds")) {
            form.setFieldsValue({
              groupIds: [],
            })
          }
        },
      }}
    >
      {item?.extraData?.alertMesaage && (
        <Alert
          showIcon
          message={item?.extraData?.alertMesaage}
          description="Em caso de dúvida entre em contato com o nosso suporte"
          type="warning"
        />
      )}
      <Form.Item label="Status">
        <ChannelStatus status={channel?.status} />
      </Form.Item>
      <Form.Item
        label="Descrição"
        name="description"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
      >
        <Input placeholder="Canal de suporte" />
      </Form.Item>
      <Form.Item label="Número" name="phone">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="ID da conta comercial do WhatsApp"
        name="waba_id"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Identificação do número de telefone"
        name="identifier"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Token"
        name="hashIdentifier"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
      >
        <TextArea rows={5} style={{ resize: "none" }} />
      </Form.Item>
      <Form.Item
        label="Webhook"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
      >
        <Input
          value={WHATSAPP_CLOUD_API_WEBHOOK}
          disabled
          suffix={
            <Button
              icon={<i className="fal fa-copy" />}
              type="text"
              onClick={() => {
                copy(WHATSAPP_CLOUD_API_WEBHOOK)
                toast.success("Copiado.")
              }}
            />
          }
        />
      </Form.Item>
      <HorizontalSwitch
        title="Apenas Receptivo"
        description="Com essa opção marcada, o canal não pode enviar mensagem ativa e nem ser usado em campanhas."
        name="disable_active_chat"
      />
      <HorizontalSwitch
        title="Restringir visibilidade"
        description="Selecione os grupos que tem acesso a visualizar esse canal"
        name="isPrivate"
      >
        <Form.Item name="groupIds">
          <GroupsSelect mode="multiple" />
        </Form.Item>
      </HorizontalSwitch>
      <Alert
        message={
          <div
            style={{
              fontSize: 16,
              color: "var(--content-secondary)",
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <p>A API Oficial do WhatsApp agora está disponível para qualquer empresa de qualquer tamanho, grande e pequena.</p>
            <br />
            <p>
              <a
                href="https://kinbox.notion.site/Instala-o-996c0c3974134465ac1a7f239c8f8c0b"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "var(--primary)",
                }}
              >
                <i className="fal fa-lightbulb" style={{ marginRight: 5 }} />
                Clique aqui
              </a>
              {` `}para ver a documentação de como conectar.
            </p>
          </div>
        }
        type="info"
      />
      <EditEntityCustomFields form={form} item={item} entity="channel" />
    </SettingsPageEdit>
  )
}

export default EditWhatsappCloud
