import Tooltip from "components-old/Tooltip"
import React, { memo } from "react"
import { colors } from "lib/colors"
import { twMerge } from "tailwind-merge"
import { detectVariableFromString, splitVariableString } from "../variables.helper"
import { ArrowDataTransferHorizontalIcon } from "components-old/icons"
import { useRecoilState } from "recoil"
import { themeAtom, variableAtom } from "atoms/app.atom"
import { CategoryEnum, VariableEnum } from "pages/settings/workflows/workflow.enums"
import { VariableItem, WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface VariableTagProps {
  type: VariableEnum
  reference?: string
  fallback?: string
  jsonPath?: string
  style?: React.CSSProperties
  showCategory?: boolean
  className?: string
  selected?: boolean
  deletedVariable?: boolean
  selectable?: boolean
  labelClassName?: string
}

function splitString(input) {
  const regex = /(\{[^}]+\})/g
  return input.split(regex).filter(Boolean)
}

export const transformStringToJSX = (input, { variablesRecord }) => {
  if (!input) return null

  // const regex = /{(.*?)}/g
  // // const regex = /{([^}]+)}/g
  // const parts = input.split(regex)
  const parts = splitString(input)

  return (
    <span className="truncate max-w-[190px]">
      {parts?.map?.((part, index) => {
        const result = detectVariableFromString(part, {
          variablesRecord,
        })

        if (!result?.variable) return part
        // if (part?.startsWith("{") && part?.endsWith("}")) {
        // const withoutBrackets = part.slice(1, -1)
        // const { type, reference, fallback, jsonPath } = splitVariableString(withoutBrackets)
        // const variable = variableRecord[type]

        return (
          <VariableTag
            key={index}
            // type={`${type}#${reference}` as any}
            type={result?.variable.type as VariableEnum}
            fallback={result?.variable.fallback}
            jsonPath={result?.variable.jsonPath}
            reference={result?.variable.reference}
            showCategory
            selectable={false}
            labelClassName="max-w-[100px]"
          />
        )

        // return <span className="font-bold">{part}</span>
        // } else {
        //   return part
        // }
      })}
    </span>
  )
}

function VariableTag(props: VariableTagProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)
  const [theme] = useRecoilState(themeAtom)

  // const [type, reference] = props.type.split("#")

  // const variable = WorkflowDefs.VariableRecord[props.type] as VariableItem
  const variable = dynamicVariablesDef.variablesRecord[props.type] as VariableItem

  if (!variable) return <div></div>

  const category = WorkflowDefs.CategoryRecord[variable?.category]
  const isJson = variable.primitiveType === "json"
  const palette = category.palette
  const showCategory = props.showCategory && category.type !== CategoryEnum.UTILS
  // const tone = variable?.customFieldKey ? "sky" : isJson ? "amber" : "pink"

  return (
    <Tooltip
      title={
        <div className="p-1 text-black space-y-2">
          <div className="flex items-center text-xs gap-1 truncate">
            <span className="font-bold truncate">{variable?.type}</span>
            <span className="text-xs">{`<${variable?.primitiveType}>`}</span>
          </div>
          <div>{variable?.description}</div>
          {props.deletedVariable && (
            <div className="bg-red-600 font-medium rounded-lg text-white p-1.5 px-3 text-xs flex items-center gap-2">
              <i className="fas fa-exclamation-triangle text-white"></i>A referência dessa variável foi excluída.
            </div>
          )}
          {!!props.fallback && (
            <div
              className="flex items-center gap-2 text-2xs"
              style={{
                color: colors[palette][700],
              }}
            >
              <ArrowDataTransferHorizontalIcon className="w-4 h-4" />"{props.fallback}"
            </div>
          )}
        </div>
      }
      color={colors[palette][200]}
    >
      <span
        className={twMerge(
          "tiptap-variable truncate shadow-[0_0_0_0_var(--variable-tag-hover-shadow)] transition-shadow duration-[0.15s] ease-[ease-in-out] rounded-[5px] px-1.5 !no-underline whitespace-nowrap inline-flex items-center gap-0.5 text-xs font-medium",
          props.selectable !== false && "hover:shadow-[0px_0px_0px_2px_var(--variable-tag-hover-shadow)]",
          props.className
        )}
        style={
          {
            "--variable-tag-hover-shadow": colors[palette][300],
            backgroundColor: colors[palette][variable?.customFieldKey ? 200 : 100],
            border: "1px solid " + colors[palette][200],
            color: colors[palette][900],
            // backgroundColor: getLightTagColors(category.palette)?.bg,
            ...(props.selected
              ? {
                  boxShadow: "0 0 0 2px " + colors[palette][300],
                }
              : {}),
            ...props.style,
            ...(theme === "dark"
              ? {
                  backgroundColor: "var(--base-200)",
                  border: "1px solid var(--base-200)",
                  color: colors[palette][400],
                }
              : {}),
          } as React.CSSProperties
        }
      >
        {props.deletedVariable && <i className="fas fa-exclamation-triangle text-red-600"></i>}
        {/* {props.showCategory && (
          <>
            <span className="text-pink-900">
              {category?.label} {reference && <span className="text-2xs opacity-60">#{reference}</span>}
            </span>
            <ArrowRight02Icon className="w-4 h-4 text-pink-800" />
          </>
        )}
        {variable.label || variable.type} */}
        <span className={twMerge("truncate max-w-[180px]", props.labelClassName)}>
          {isJson && showCategory ? props?.jsonPath || "$json" : variable.label || variable.type}
        </span>
        {showCategory && (
          <span className={isJson ? "opacity-60" : ""}>
            do {category?.label} {props.reference && <span className="text-2xs opacity-80">#{props.reference}</span>}
          </span>
        )}
      </span>
    </Tooltip>
  )
}

export default memo(VariableTag)
