import React from "react"
import cx from "classnames"
import { Link } from "react-router-dom"
import { general, useStore } from "store"

const getBtnContent = ({ children, icon, iconRight, iconStyle, loading, textStyle }) => {
    return (
        <>
            {loading && <i className="far fa-spinner-third loading-icon" />}
            <span className="btn-text" style={textStyle}>
                {icon && <i className={icon} style={iconStyle} />}
                {children}
                {iconRight && <i className={`${iconRight} btn-icon-right`} style={iconStyle} />}
            </span>
        </>
    )
}

export default function Button(props) {
    const {
        to,
        light,
        type,
        primary,
        success,
        danger,
        cancel,
        file,
        onHandleFile,
        className,
        loader,
        isLoading,
        iconRight,
        small,
        big,
        invisible,
        action,
        listItem,
        round,
        simple,
        full,
        gray,
        ios,
        iconStyle,
        textStyle,
        ...otherProps
    } = props
    const generalStore = useStore(general)
    const loading = isLoading || generalStore.loaders.includes(loader) ? "loading" : ""
    const classname = cx("btn", className, {
        light,
        primary,
        cancel,
        loading,
        danger,
        success,
        small,
        big,
        action,
        invisible,
        listItem,
        simple,
        round,
        full,
        gray,
        ios,
        "only-icon": otherProps.icon && !otherProps.children
    })

    if (to) {
        return (
            <Link {...otherProps} className={classname} to={to}>
                {getBtnContent({ ...otherProps, iconRight, iconStyle, textStyle })}
            </Link>
        )
    }

    return (
        <button {...otherProps} type={type || "button"} className={classname}>
            {getBtnContent({ ...otherProps, iconRight, loading, iconStyle, textStyle })}
            {file && (
                <input
                    type="file"
                    className={"file-input-menu"}
                    accept="**/*"
                    onChange={e => onHandleFile(e.target.files)}
                />
            )}
        </button>
    )
}
