// import mock from "lib/mock"
import { stores } from "store"

export async function getFromMock(method, url) {
    return new Promise(function (resolve, reject) {
        resolve({
            data: {},
            status: 200,
        })
        // const data = mock.data[0].routes.find(
        //     e => "/" + e.endpoint == url && e.method == method
        // )
        // if (data) {
        //     setTimeout(function() {
        //         resolve({
        //             data: JSON.parse(data.body),
        //             status: data.statusCode
        //         })
        //     }, (data && data.latency) || 0)
        // }
    })
}

export function writeSpell(spellMethod, { method, url, data }) {
    stores.general._changeReqModel({
        method: spellMethod,
        data: {
            endpoint: url,
            body: data,
            method,
        },
    })
}

export function printRequest({ method, url, data, response }) {
    console.group(`Rota [${method.toUpperCase()}]`)
    console.log("%cEndpoint: " + url, "color: #f1c40f; background-color: black")
    console.log("Enviado: ", data)
    console.log("Recebido: ", response ? response.data : "Erro de conexão. Sem contato com servidor")
    console.groupEnd()
}
