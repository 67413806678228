import React from "react"
import Expander from "components-old/shared/Expander"
import Tooltip from "components-old/Tooltip"
import { CategoryEnum } from "../workflow.enums"
import { WorkflowDefs } from "../workflow.defs"
import { colors } from "lib/colors"

interface BaseExpanderFieldsProps {
  fields?: {
    icon?: React.ElementType
    value: React.ReactNode
    label?: string
  }[]
  category: CategoryEnum
  color?: string
}

export interface NodeField {
  icon?: React.ElementType
  value: React.ReactNode
  label?: string
}

function BaseExpanderFields(props: BaseExpanderFieldsProps) {
  const palette = WorkflowDefs.CategoryRecord[props.category!].palette

  return (
    <Expander
      maxHeight={200}
      iconStyle={{
        color: props.color || colors[palette]?.[600],
      }}
    >
      <div className="truncate max-w-[250px]">
        {props.fields?.map((field, index) => (
          <Tooltip title={field.label} key={field.label}>
            <div key={index} className="flex rounded-lg px-4 py-2 gap-2">
              {field.icon && (
                <field.icon
                  className="shrink-0"
                  style={{
                    color: props.color || colors[palette]?.[600],
                  }}
                />
              )}
              {field.value}
            </div>
          </Tooltip>
        ))}
      </div>
    </Expander>
  )
}

export default BaseExpanderFields
