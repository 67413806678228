import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export type ICampaignStatus =
  | "executing"
  | "finished_success"
  | "paused"
  | "canceled"
  | "finished_fail"
  | "waiting_processing"
  | "waiting_file_processing"
export type ICampaignMessageDistributionType = "fixed" | "random"

export interface ICampaign {
  id: string
  workspacePlatformId: string
  createdAt: string
  lastUpdateAt: string
  description: string
  executionDate: string
  file: string
  distributionFile?: string
  message: string
  name: string
  isActive: boolean
  status: ICampaignStatus
  chunks: number
  type?: "sms"
  finalTimeBetweenMessages: number
  initialTimeBetweenMessages: number
  timeBetweenChunks: number
  totalNumbers: number
  template?: any
  totalNumbersExecuted: number
  messageDistributionType: ICampaignMessageDistributionType
  isWaitingWorkingHour?: boolean
  WorkspacePlatform: {
    platformId: string
    workspaceId: string
  }
}

export const campaigns: IGenericModel<ICampaign> = {
  ...generic(endpoints.CAMPAIGN),
}
