import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import BaseExpanderFields, { NodeField } from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { Link01Icon } from "components-old/icons"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { useRecoilState } from "recoil"
import IconTag from "components-old/shared/IconTag"
import TagColor from "components-old/shared/TagColor"
import { twMerge } from "tailwind-merge"
import RequestMethodColor from "components-old/RequestMethodTag"
import { variableAtom } from "atoms/app.atom"

interface MakeRequestNodeProps extends BaseNodeProps {}

function MakeRequestNode(props: MakeRequestNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    const request = props.data?.action?.request

    let fields: NodeField[] = []
    if (request?.url) {
      fields.push({
        icon: Link01Icon,
        value: (
          <span className="flex gap-1 items-center truncate">
            {/* <TagColor
              palette={"teal"}
              animated
              lightTone={{
                text: 700,
              }}
              className={twMerge("rounded-[5px] !bg-green-400/20", request?.method === "POST" && "bg-blue-400")}
            >
              <span className="font-medium text-[9px]">{request?.method}</span>
            </TagColor> */}
            <RequestMethodColor method={request?.method} />
            {transformStringToJSX(request?.url, { variablesRecord: dynamicVariablesDef.variablesRecord })}
          </span>
        ),
        label: "Endereço URL da requisição",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.request])

  return (
    <BaseNode {...props} title="Requisição HTTP">
      <BaseExpanderFields fields={fields} category={CategoryEnum.UTILS} />
    </BaseNode>
  )
}

export default MakeRequestNode
