import React from "react"
import { getBezierPath } from "react-flow-renderer"

export default function ConnectionLine({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, sourceHandle }: any) {
  const color = sourceHandle.id == "not_found" ? "#ef4444" : "var(--edge)"
  const markerEnd = sourceHandle.id == "not_found" ? "url(#arrowhead-not-found)" : "url(#arrowhead)"

  const edgePath = getBezierPath({
    sourceX: sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <g>
      <path
        fill="none"
        stroke={color}
        strokeWidth={2.5}
        // className="animated"
        // d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* <circle cx={targetX} cy={targetY} fill="var(--edge)" r={5} stroke="var(--edge)" strokeWidth={5} /> */}
    </g>
  )
}
