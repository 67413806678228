import React, { useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStoreState } from "hooks"
import EmailSvg from "../svgs/EmailSvg"
import { IChannel } from "models/channels"
import { ChannelStatus } from ".."
import shortid from "shortid"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"

interface IEditEmailProps extends RouteComponentProps<{ id: string }> {
    onChange: Function
}

function EditEmail(props: IEditEmailProps) {
    const [form] = Form.useForm()
    const { all: channels } = useStoreState((state) => state.channels)
    const { id } = useParams<any>()
    const [item, setItem] = useState<IChannel>()

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    return (
        <SettingsPageEdit<IChannel>
            {...props}
            pageComponent="page"
            routeName="channels"
            onChangeItem={(item) => setItem(item)}
            title="Canal de E-mail"
            onSubmitSuccess={() => {
                props.onChange()
            }}
            width={500}
            titleOverwrite={
                <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                        <Row wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        width: 24,
                                        height: 24,
                                        display: "flex",
                                        marginRight: 10,
                                    }}
                                >
                                    <EmailSvg />
                                </div>
                            </Col>
                            <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                                Canal de E-mail
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            storePath={(store) => store.channelsEmail}
            form={form}
            hideFooterWhenSaved
            defaultValues={{
                identifier: shortid.generate() + "kinbox@mail.kinbox.com.br",
            }}
        >
            <div style={{ color: "var(--content-secondary)" }}>
                <p>
                    Você pode ler e responder mensagens que seus clientes enviam para o e-mail existente do seu time (e.g: contato@suaempresa.com) diretamente
                    no Kinbox.
                </p>
                <br />
                <p>O processo é simples, apenas habilite "forwarding" nas configurações do seu provedor de e-mail e redirecione para o endereço abaixo.</p>
            </div>
            <br />
            <Form.Item label="Status">
                <ChannelStatus status={channel?.status} channel={channel} />
            </Form.Item>
            <Form.Item label="Descrição" name="description">
                <Input placeholder="contato@suaempresa.com" />
            </Form.Item>
            <Form.Item
                label="Configurações de Encaminhamento"
                name="identifier"
                help="Para receber as mensagens que seus clientes enviam para seu e-mail existente, vá para as configurações do seu provedor de email e habilite encaminhamento (forwarding) de todas as mensagens para o endereço especial acima."
            >
                <Input disabled />
            </Form.Item>
            <EditEntityCustomFields form={form} item={item} entity="channel" />
        </SettingsPageEdit>
    )
}

export default EditEmail
