import React, { memo } from "react"
import { Button, Col, Row } from "antd"
import { Link } from "react-router-dom"
import { IColumnProps } from "."
import cx from "classnames"

import styles from "./style.module.scss"

interface IListPageItemProps<T> {
    item: T
    path?: string
    columns: IColumnProps<T>[]
    draggable?: boolean
    style?: React.CSSProperties
    listeners?: any
    dragOverlay?: boolean
    dragging?: boolean
}

function ListPageItem(props: IListPageItemProps<any>) {
    // const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    //     id: props.item.id,
    //     disabled: !props.draggable,
    // })

    // const style: any = {
    //     transform: CSS.Transform.toString(transform),
    //     transition,
    //     zIndex: isDragging ? 1 : undefined,
    // }

    if (props.item?.to || props.path) {
        return (
            <Link
                key={props.item?.id}
                to={props.item.to || `${props.path}/${props.item?.id}`}
                className={cx(styles.item, {
                    [styles.dragOverlay]: props.dragOverlay,
                    [styles.dragging]: props.dragging,
                })}
                style={props.style}
                // ref={setNodeRef}
                // {...attributes}
            >
                <Row
                    justify="space-between"
                    align="middle"
                    gutter={12}
                    style={{ width: "100%", height: "100%" }}
                    wrap={false}
                >
                    {props.columns.map((column, index) => (
                        <Col
                            key={(column.key as any) || column.dataIndex!}
                            span={column.span || props.columns.length}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            className={styles.column}
                        >
                            {props.draggable && index == 0 && (
                                <Button
                                    type="text"
                                    htmlType="button"
                                    className={styles.drag_handle}
                                    icon={
                                        <i>
                                            <svg viewBox="0 0 20 20" width="12">
                                                <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                                            </svg>
                                        </i>
                                    }
                                    {...props.listeners}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                />
                            )}
                            {column?.render?.(
                                props.item?.[(column as any)?.dataIndex!],
                                props.item,
                                index
                            ) || props.item?.[(column as any)?.dataIndex!]}
                        </Col>
                    ))}
                </Row>
            </Link>
        )
    }

    return (
        <a
            key={props.item?.id}
            className={cx(styles.item, {
                [styles.dragOverlay]: props.dragOverlay,
                [styles.dragging]: props.dragging,
            })}
            style={{ ...props.style, cursor: "default" }}
            // ref={setNodeRef}
            // {...attributes}
        >
            <Row
                justify="space-between"
                align="middle"
                gutter={12}
                style={{ width: "100%", height: "100%" }}
                wrap={false}
            >
                {props.columns.map((column, index) => (
                    <Col
                        key={(column.key as any) || column.dataIndex!}
                        span={column.span || props.columns.length}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        className={styles.column}
                    >
                        {props.draggable && index == 0 && (
                            <Button
                                type="text"
                                htmlType="button"
                                className={styles.drag_handle}
                                icon={
                                    <i>
                                        <svg viewBox="0 0 20 20" width="12">
                                            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                                        </svg>
                                    </i>
                                }
                                {...props.listeners}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}
                            />
                        )}
                        {column?.render?.(
                            props.item?.[(column as any)?.dataIndex!],
                            props.item,
                            index
                        ) || props.item?.[(column as any)?.dataIndex!]}
                    </Col>
                ))}
            </Row>
        </a>
    )
}

export default memo(ListPageItem)
