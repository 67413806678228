import React, { useEffect, useMemo } from "react"
import { Button, Col, Menu, Row, Typography } from "antd"
import { InputType } from "lib/constants"
import EditInputModal from "components-old/chat/side/EditInputModal"
import { useSetRecoilState } from "recoil"
import { modalState } from "atoms/app.atom"
import toast from "lib/toast"
import cx from "classnames"

import styles from "./style.module.css"
import _, { divide } from "lodash"
import { formatCustomInputValue } from "lib/formatInputValue"
import SearchDrop from "components-old/ui/SearchDrop"
import MenuItemInnerSelectable from "components-old/ui/SearchDrop/MenuItemInnerSelectable"
import Tooltip from "components-old/Tooltip"
import { twMerge } from "tailwind-merge"

const { Text } = Typography

interface IEditableDropFieldProps {
  label?: string | React.ReactNode
  value?: string | React.ReactNode
  onClick?: Function
  onSubmit?: (model: Record<string, any>) => Promise<any>
  type?: InputType
  placeholder?: string
  copyableValue?: string
  notCopyable?: boolean
  multiline?: boolean
  notSelectable?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  valueColStyle?: React.CSSProperties
  hiddenIfEmpty?: boolean
  labelClass?: string
  pulse?: boolean
  onlyIcon?: boolean
  options?: {
    value: string
    label: string
    selected?: boolean
    display?: React.ReactNode
  }[]
  format?: (value: any) => React.ReactNode | string
  tooltip?: string
  hideLabel?: boolean
  hideSuccessMessage?: boolean
  valueClass?: string
  className?: string
  rowClass?: string
}

function EditableDropField(props: IEditableDropFieldProps) {
  const setModal = useSetRecoilState(modalState)
  const ref = React.useRef<any>(null)

  useEffect(() => {
    if (props.pulse) {
      setTimeout(() => {
        // scroll into view
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }, 500)
    }
  }, [props.pulse])

  const valueFormatted = formatCustomInputValue(props.type || InputType.Text, props.value)

  const { valueNode } = useMemo(() => {
    let trigger = (
      <a
        className={cx(
          styles.value,
          "px-2 hover:bg-[var(--btn-hover-fill)] rounded-lg flex-1 truncate h-full",
          {
            [styles.not_selectable]: props.notSelectable,
            [styles.disabled]: props.disabled,
            "hover:!bg-transparent": props.disabled,
            "!cursor-default": props.notSelectable,
          },
          props.valueClass
        )}
        style={props.valueColStyle}
        onClick={() => {
          if (!props.notSelectable && props.type !== InputType.MultiSelect && props.type !== InputType.Select) {
            if (props.onClick) {
              props.onClick()
            } else {
              setModal(
                <EditInputModal
                  type={props.type!}
                  label={props.label!}
                  value={props.value as string}
                  placeholder={props.placeholder!}
                  onSubmit={props.onSubmit}
                  options={props.options}
                  hideSuccessMessage={props.hideSuccessMessage}
                />
              )
            }
          }
        }}
      >
        {/* <Text
          style={{ width: "100%" }}
          ellipsis={
            !props.format
              ? {
                  tooltip: _.truncate(typeof valueFormatted == "string" ? valueFormatted : "", { length: 200 }),
                }
              : undefined
          }
          className="truncate"
        > */}
        {props.format?.(props.value) || valueFormatted}
        {/* </Text> */}
      </a>
    )

    if (props.tooltip) {
      trigger = (
        <Tooltip title={props.tooltip} placement="topLeft">
          {trigger}
        </Tooltip>
      )
    }

    switch (props.type) {
      case InputType.Select:
      case InputType.MultiSelect:
        return {
          valueNode: (
            <SearchDrop
              title={props.label}
              options={
                props.options?.map((opt) => ({
                  searchValue: opt.label as string,
                  node: (
                    <Menu.Item
                      key={opt.value + opt.label}
                      onClick={(e) => {
                        props.onSubmit?.({
                          operatorId: +opt.value,
                        })
                      }}
                      style={{
                        width: "100%",
                      }}
                      className="global_full_width_menu_item"
                    >
                      <MenuItemInnerSelectable selected={opt.selected} title={opt.label as string}>
                        {opt.display || opt.label}
                      </MenuItemInnerSelectable>
                    </Menu.Item>
                  ),
                })) || []
              }
            >
              {trigger}
            </SearchDrop>
          ),
        }

      default:
        return { valueNode: trigger }
    }
  }, [props, setModal, valueFormatted])

  if (props.hiddenIfEmpty && !props.value) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cx(
        styles.editable_field,
        {
          [styles.multiline]: props.multiline,
          [styles.pulse]: props.pulse,
        },
        props.className
      )}
    >
      <Row wrap={false} style={{ width: "100%", height: "100%" }} align={props.multiline ? "top" : "middle"} className={props.rowClass}>
        {!props.hideLabel && (
          <Col
            span={props.onlyIcon ? 4 : valueFormatted ? 12 : 24}
            className={twMerge(
              styles.label,
              props.labelClass,
              "g_label, max-w-[150px]",
              !props.onlyIcon ? "max-w-[150px]" : "max-w-[28px]"
            )}
          >
            <div className="flex justify-center items-center flex-nowrap">
              {!!props.icon && (
                <Tooltip title={props.label as string} placement="topLeft">
                  <span className="text-lg w-6 flex items-center justify-center">{props.icon}</span>
                </Tooltip>
              )}
              {!props.onlyIcon && (
                <Text
                  style={{
                    width: props.icon ? "calc(100% - 35px)" : "calc(100% - 15px)",
                    color: "var(--content-secondary)",
                  }}
                  ellipsis={typeof props.label == "string" ? { tooltip: props.label } : false}
                >
                  {props.label}
                </Text>
              )}
            </div>
          </Col>
        )}
        {props.multiline ? (
          <Col span={props.onlyIcon ? 20 : valueFormatted ? 12 : 0} className={twMerge(styles.value, "!leading-[25px] break-words whitespace-break-spaces")}>
            {props.value}
          </Col>
        ) : (
          valueNode
        )}
        {!props.notCopyable && (
          <div className={styles.copy}>
            <Button
              type="text"
              // icon={<i className="fal fa-copy" style={{ fontSize: 15 }} />}
              icon={
                <div className="text-md text-secondary flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                      <path d="M8 4v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7.242a2 2 0 0 0-.602-1.43L16.083 2.57A2 2 0 0 0 14.685 2H10a2 2 0 0 0-2 2"></path>
                      <path d="M16 18v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h2"></path>
                    </g>
                  </svg>
                  {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.1em"
                                        height="1.1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                                            <path
                                                fill="currentColor"
                                                d="M9 2a2 2 0 0 0-2 2v1a1 1 0 0 0 2 0V4h1a1 1 0 1 0 0-2zm5 0a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zm5 0a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V4a2 2 0 0 0-2-2zm3 7a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0zm0 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1a2 2 0 0 0 2-2zM4 7a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zm0 2h11v11H4z"
                                            ></path>
                                        </g>
                                    </svg> */}
                </div>
              }
              onClick={(e) => {
                e?.preventDefault?.()
                e?.stopPropagation?.()
                navigator.clipboard.writeText(props.copyableValue || (props.value as string))
                toast.info("Copiado para o clipboard")
              }}
            />
          </div>
        )}
      </Row>
    </div>
  )
}

export default EditableDropField
