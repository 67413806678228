import React, { useMemo } from "react"
import { getProps } from "pages/settings/channels"
import { useStore, useStoreState } from "hooks"
import { session } from "store"
import Avatar from "components-old/ui/Avatar"
import { getChannelType } from "lib/helper"
import { ContactIcon, Id01Icon, InboxIcon } from "components-old/icons"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { Handle, Position } from "react-flow-renderer"
import styles from "../../../actions/style.module.css"
import cx from "classnames"
import BaseExpanderFields from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { useRecoilState } from "recoil"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import { variableAtom } from "atoms/app.atom"
interface FindDealNodeProps extends BaseNodeProps {}

function FindDealNode(props: FindDealNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  // const { all: channels } = useStoreState((state) => state.channels)
  const { workspace } = useStore(session)

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const deal = props.data?.action?.deal || {}

    if ((deal as any).id) {
      fields.push({
        icon: Id01Icon,
        value: transformStringToJSX((deal as any).id ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "ID",
      })
    }

    if ((deal as any)?.contactReference) {
      fields.push({
        icon: ContactIcon,
        // value: "Contato #" + conversation?.contactReference,
        value: (
          <span>
            Contato <span className="text-sky-600">#{(deal as any)?.contactReference}</span>
          </span>
        ),
        label: "Contato",
      })
    }

    if ((deal as any)?.conversationReference) {
      fields.push({
        icon: WorkflowDefs.CategoryRecord.conversation.icon,
        value: (deal as any)?.conversationReference ? (
          <span>
            Conversa <span className="text-sky-600">#{(deal as any)?.conversationReference}</span>
          </span>
        ) : (
          ""
        ),
        label: "Conversa",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.deal, workspace])

  return (
    <BaseNode
      {...props}
      title="Buscar negócio"
      handlesAfter={
        <div className="flex justify-end items-center h-6 mb-2 -mt-4">
          <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">
            Não encontrado
          </span>
          <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
            <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
          </Handle>
        </div>
      }
    >
      <BaseExpanderFields fields={fields} category={CategoryEnum.DEAL} />
    </BaseNode>
  )
}

export default FindDealNode
