import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export enum PartnerType {
  DEFAULT = "default", // only invite and commission not inspection
  RESELLER = "reseller", // control payment and inspection
  MULTILEVEL = "multilevel", // 2%
}

export interface IWorkspace {
  id: string
  workspaceId?: string
  createdAt: string
  lastUpdateAt: string
  onlineAgents?: number
  onlineAgentsToday?: number
  activeSubscribers?: number
  activeTrials?: number
  activeOperatorsCount: number
  name?: string
  workspace?: string
  isActive: boolean
  avatar: string
  notes?: string
  wallet?: {
    balance: number
  }
  whatsappActiveChannelsCount: number
  instagramActiveChannelsCount: number
  partnerId?: number
  onboarding_color?: string
  token?: string
  onboarding_status?: string
  sms_credits?: number
  settings?: {
    about: {
      origin: string
      company_type: string
      job_role: string
      company_size: string
      objectives: string[]
    }
  }
  owner: {
    id: string
    avatar: string
    email: string
    name: string
    status: boolean
  }
  subscription: {
    id: string
    createdAt: string
    lastUpdateAt: string
    expiresAt: string
    autorenewal: boolean
    agents: number
    instagramUnits: number
    months: number
    numbers: number
    isActive: boolean
    license: string
    order: string
    status: string
    total: string
    whatsapp: boolean
    workspaceId: string
    discount: number
  }

  // Se é parceiro
  isPartner?: boolean
  partnerData?: {
    maxDiscount: number // Máximo desconto que pode dar para clientes
    maxCommission: number // Máxima comissão que o parceiro recebe
    type: PartnerType // Tipo de parceiro
  }

  // Se é cliente do parceiro
  customerPartnerData?: {
    commission?: number // % comissão que o parceiro recebe do cliente
    partnerId: string // Id do ambiente parceiro
    type?: PartnerType // Tipo de parceiro
  }[]
}

export const workspaces: IGenericModel<IWorkspace> = {
  ...generic(endpoints.ADMIN_WORKSPACES),
}
