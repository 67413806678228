import React, { useEffect, useState } from "react"
import { Form, Row, Col, Button, Menu, Dropdown } from "antd"
import styles from "./style.module.css"
import { IContact } from "models/contacts"
import { FormInstance } from "antd/lib/form"
import cx from "classnames"

export interface IAdvancedFilter {
  icon?: string
  name: string
  Component: React.ReactNode
}

interface IFiltersPanelProps {
  contact?: IContact
  onBack?: Function
  setSearchString?: any
  children: React.ReactNode
  onSubmit: Function
  advancedFilters?: IAdvancedFilter[]
  placeholder?: string
  noDebounce?: boolean
  gray?: boolean
  setFiltersForm?: (form: FormInstance) => void
  defaultFiltersValues?: {
    [a: string]: any
  }
  setClearFiltersComponent?: Function
  autoSearchSubmit?: boolean
  onChangeFilters?: Function
  filtering?: boolean
  setFiltering?: Function
  onClearFilters?: Function
  dropdownWidth?: number
}

function FiltersPanel(props: IFiltersPanelProps) {
  const [unsaved, setUnsaved] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    props.setFiltersForm?.(form)
  }, [])

  const submit = (model: any) => {
    Object.keys(model).forEach((key) => {
      if (model[key] && key != "from" && key != "search") {
        props.setClearFiltersComponent?.(
          <Button
            icon={<i className="far fa-times" />}
            style={{
              // color: "var(--content-secondary)",
              // fontWeight: 400,
              marginLeft: 7,
            }}
            htmlType="button"
            onClick={() => {
              form.resetFields()
              form.submit()
              props.setClearFiltersComponent?.(null)
              props?.onChangeFilters?.({}, {})
            }}
          >
            Limpar filtros
          </Button>
        )
      }
    })
    setUnsaved(false)
    setVisible(false)
    props.onSubmit(model)
  }

  useEffect(() => {
    // Se tem defaultFiltersValue já chama submit
    if (props.defaultFiltersValues) {
      form.submit()
    }
  }, [])

  return (
    <Form
      onFinish={submit}
      form={form}
      hideRequiredMark
      layout="vertical"
      className={styles.panel_search}
      onValuesChange={(changedValues, allValues) => {
        props.onChangeFilters?.(changedValues, allValues)
        if (props.autoSearchSubmit) {
          form.submit()
        }
        setUnsaved(true)
      }}
      initialValues={props.defaultFiltersValues}
    >
      <Row align="middle" gutter={12}>
        <Col>{props.children}</Col>
        {props.advancedFilters && (
          <Col style={{ marginRight: 12 }}>
            {props.filtering ? (
              <Button
                icon={<i className={"far fa-times"} />}
                style={{
                  color: "var(--content-primary)",
                  display: "flex",
                  alignItems: "center",
                  marginRight: -11,
                }}
                onClick={() => {
                  form.resetFields()
                  form.submit()
                  props.setClearFiltersComponent?.(null)
                  props?.onChangeFilters?.({}, {})
                  props.setFiltering?.(false)
                  props.onClearFilters?.()
                }}
              >
                <span style={{ lineHeight: "15px" }}>Limpar filtros</span>
              </Button>
            ) : (
              <Dropdown
                onVisibleChange={(visible) => {
                  setVisible(visible)
                }}
                visible={visible}
                trigger={["click"]}
                className={cx("common_dropdown")}
                overlay={
                  <Menu style={{ padding: 18, width: props.dropdownWidth || 300 }}>
                    <div>
                      <Form
                        onFinish={submit}
                        form={form}
                        hideRequiredMark
                        layout="vertical"
                        className={styles.panel_search}
                        onValuesChange={(changedValues, allValues) => {
                          setUnsaved(true)
                        }}
                        initialValues={props.defaultFiltersValues}
                      >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {props.advancedFilters?.map((advanced, index) => (
                            <div key={"advanced" + advanced.name + index} style={{ minWidth: 200 }}>
                              {advanced.Component}
                            </div>
                          ))}
                        </div>
                        <Row justify="end">
                          <Col>
                            <Button type="primary" htmlType="submit">
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Menu>
                }
              >
                <Button
                  // type="text"
                  // icon={<i className={props.filtering ? "far fa-times" : "far fa-sliders"} />}
                  icon={
                    props.filtering ? (
                      <i className="far fa-times" />
                    ) : (
                      <i className="fal fa fill-content-100">
                        <svg viewBox="0 0 14 9" style={{ width: 15 }}>
                          <path d="M6.17392 8.99625H7.64416C8.04847 8.99625 8.37928 8.65889 8.37928 8.24656C8.37928 7.83423 8.04847 7.49687 7.64416 7.49687H6.17392C5.76961 7.49687 5.4388 7.83423 5.4388 8.24656C5.4388 8.65889 5.76961 8.99625 6.17392 8.99625ZM0.292969 0.749687C0.292969 1.16202 0.623772 1.49937 1.02809 1.49937H12.79C13.1943 1.49937 13.5251 1.16202 13.5251 0.749687C13.5251 0.337359 13.1943 0 12.79 0H1.02809C0.623772 0 0.292969 0.337359 0.292969 0.749687ZM3.23344 5.24781H10.5846C10.989 5.24781 11.3198 4.91045 11.3198 4.49812C11.3198 4.08579 10.989 3.74844 10.5846 3.74844H3.23344C2.82913 3.74844 2.49833 4.08579 2.49833 4.49812C2.49833 4.91045 2.82913 5.24781 3.23344 5.24781Z"></path>
                        </svg>
                      </i>
                    )
                  }
                  // icon={
                  //     <i
                  //         style={{
                  //             display: "flex",
                  //             alignItems: "center",
                  //             marginRight: -3,
                  //         }}
                  //     >
                  //         <svg
                  //             width="24"
                  //             height="24"
                  //             fill="none"
                  //             xmlns="http://www.w3.org/2000/svg"
                  //             role="presentation"
                  //         >
                  //             <path
                  //                 fillRule="evenodd"
                  //                 clipRule="evenodd"
                  //                 d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-1 0a8 8 0 11-16 0 8 8 0 0116 0zM7.5 9a.5.5 0 000 1h9a.5.5 0 000-1h-9zM9 12a.5.5 0 000 1h6a.5.5 0 100-1H9zm1 3.5a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5z"
                  //                 fill="var(--content-secondary)"
                  //             ></path>
                  //         </svg>
                  //     </i>
                  // }
                  style={{
                    color: "var(--content-primary)",
                    display: "flex",
                    alignItems: "center",
                    marginRight: -11,
                  }}
                >
                  <span style={{ lineHeight: "15px" }}>{props.filtering ? "Limpar filtros" : "Filtros"}</span>
                </Button>
              </Dropdown>
            )}
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default FiltersPanel
