import { ITab } from "components-old/common/SettingsPage/SettingsPageTabs"
import { Action, action, Computed, computed, thunk, Thunk } from "easy-peasy"
import React from "react"

export interface IGeneralModel {
    // state
    sideOpen: boolean
    teamChatKey: string
    lastPathToSettings: string
    topBanner?: {
        style?: React.CSSProperties
        text: React.ReactNode
    } | null
    tabs?: ITab[]
    pageTitle: string | React.ReactNode
    isNoteMode: boolean
    pageSubtitle: string | React.ReactNode
    teamChatMutate: any
    teamChatHasNewMessages: boolean
    teamChatVisible: boolean
    teamChatLastMessageCreatedAt: string
    imagesViewer?: { src: string; alt: string; downloadUrl: string }[]

    // actions
    setPageTitle: Action<IGeneralModel, string | React.ReactNode>
    setLastPathToSettings: Action<IGeneralModel, string>
    setPageSubtitle: Action<IGeneralModel, string | React.ReactNode>
    setTopBanner: Action<
        IGeneralModel,
        {
            style?: React.CSSProperties
            text: React.ReactNode
        } | null
    >
    setTeamChatKey: Action<IGeneralModel, string>
    setTeamChatMutate: Action<IGeneralModel, any>
    setTeamChatHasNewMessages: Action<IGeneralModel, boolean>
    setTeamChatLastMessageCreatedAt: Action<IGeneralModel, string>
    setTeamChatVisible: Action<IGeneralModel, boolean>
    setSideOpen: Action<IGeneralModel, boolean>
    setNoteMode: Action<IGeneralModel, boolean>
    setTabs: Action<IGeneralModel, ITab[]>
    toggleSideOpen: Action<IGeneralModel>
}

export const general: IGeneralModel = {
    teamChatMutate: null,
    isNoteMode: false,
    topBanner: null,
    lastPathToSettings: "/",
    pageTitle: "",
    pageSubtitle: "",
    tabs: [],
    teamChatKey: "",
    sideOpen: true,
    teamChatHasNewMessages: false,
    teamChatVisible: false,
    teamChatLastMessageCreatedAt: "",
    setTeamChatKey: action((state, key) => {
        state.teamChatKey = key
    }),
    setLastPathToSettings: action((state, path) => {
        state.lastPathToSettings = path
    }),
    setPageTitle: action((state, title) => {
        state.pageTitle = title
    }),
    setTabs: action((state, tabs) => {
        state.tabs = tabs
    }),
    setTopBanner: action((state, topBanner) => {
        state.topBanner = topBanner
    }),
    setPageSubtitle: action((state, subtitle) => {
        state.pageSubtitle = subtitle
    }),
    setTeamChatHasNewMessages: action((state, hasMessages) => {
        state.teamChatHasNewMessages = hasMessages
    }),
    setTeamChatVisible: action((state, visible) => {
        state.teamChatVisible = visible
    }),
    setSideOpen: action((state, open) => {
        state.sideOpen = open
    }),
    setNoteMode: action((state, isNote) => {
        state.isNoteMode = isNote
    }),
    toggleSideOpen: action((state, open) => {
        state.sideOpen = !state.sideOpen
    }),
    setTeamChatLastMessageCreatedAt: action((state, date) => {
        state.teamChatLastMessageCreatedAt = date
    }),
    setTeamChatMutate: action((state, mutate) => {
        state.teamChatMutate = mutate
    }),
}
