/**********************************
 * ACTIONS
 **********************************/
export enum ActionEnum {
  TRIGGER = "trigger",

  // Utils
  DELAY = "delay",
  MAKE_REQUEST = "make_request",

  // Contact
  CONTACT_CREATE = "contact:create",
  CONTACT_UPDATE = "contact:update",
  CONTACT_FIND = "contact:find",

  // Deal
  DEAL_CREATE = "deal:create",
  DEAL_UPDATE = "deal:update",
  DEAL_FIND = "deal:find",

  // Conversation
  CONVERSATION_CREATE = "conversation:create",
  CONVERSATION_UPDATE = "conversation:update",
  CONVERSATION_FIND = "conversation:find",
  CONVERSATION_RUN_BOT = "conversation:run-bot",
  CONVERSATION_ARCHIVE = "conversation:archive",
  CONVERSATION_ADD_TO_QUEUE = "conversation:add-to-queue",
  CONVERSATION_ASSIGN = "conversation:assign",
}

/**********************************
 * VARIABLES
 **********************************/
export enum VariableEnum {
  // UTILS
  TIMESTAMP = "utils:timestamp",
  NOW = "utils:now",
  UUID = "utils:uuid",
  RANDOM = "utils:random",

  // Deleted
  DELETED = "deleted",
  GENERIC_CUSTOM_FIELD = "generic:custom_field",

  // Workflow
  WORKFLOW_ID = "workflow:id",
  WORKFLOW_NAME = "workflow:name",

  // Workflow Run
  WORKFLOW_RUN_ID = "workflow:run_id",
  WORKFLOW_RUN_STARTED_AT = "workflow:run_started_at",
  WORKFLOW_RUN_OPERATIONS_CONSUMED = "workflow:run_operations_consumed",

  // Trigger
  WEBHOOK_URL = "webhook:url",
  WEBHOOK_QUERY_PARAMS = "webhook:query_params",
  WEBHOOK_BODY = "webhook:body",
  WEBHOOK_ORIGIN = "webhook:origin",
  WEBHOOK_REFERER = "webhook:referer",
  CAMPAIGN_MESSAGE_SENT_NUMBER = "campaign:message_sent:number",
  CAMPAIGN_MESSAGE_SENT_CAMPAIGN_ID = "campaign:message_sent:campaign_id",
  CAMPAIGN_MESSAGE_SENT_TEXT = "campaign:message_sent:text",

  // Workspace
  WORKSPACE_ID = "workspace:id",
  WORKSPACE_NAME = "workspace:name",

  // Contact
  CONTACT_ID = "contact:id",
  CONTACT_CREATED_AT = "contact:created_at",
  CONTACT_JSON = "contact:json",
  CONTACT_NAME = "contact:name",
  CONTACT_PHONE = "contact:phone",
  CONTACT_EMAIL = "contact:email",
  CONTACT_NOTES = "contact:notes",
  CONTACT_CUSTOM_FIELD = "contact:custom_field",
  CONTACT_EXTERNAL_ID = "contact:external_id",
  CONTACT_IS_IMPORTED = "contact:is_imported",
  CONTACT_ORIGIN = "contact:origin",
  CONTACT_HAS_CONVERSATION = "contact:has_conversation",
  CONTACT_IS_NEW = "contact:is_new",
  CONTACT_IS_PRIVATE = "contact:is_private",
  CONTACT_UTM_CAMPAIGNS = "contact:utm_campaigns",

  // Conversation
  CONVERSATION_ID = "conversation:id",
  CONVERSATION_CREATED_AT = "conversation:created_at",
  CONVERSATION_JSON = "conversation:json",
  CONVERSATION_SESSION_ID = "conversation:session_id",
  CONVERSATION_PROTOCOL = "conversation:protocol",
  CONVERSATION_ASSIGNED = "conversation:assigned",
  CONVERSATION_GROUP = "conversation:group",
  CONVERSATION_CHANNEL = "conversation:channel",

  // Deal
  DEAL_ID = "deal:id",
  DEAL_CREATED_AT = "deal:created_at",
  DEAL_JSON = "deal:json",
  DEAL_STATUS = "deal:status",
  DEAL_IS_WON = "deal:is_won",
  DEAL_IS_LOST = "deal:is_lost",
  DEAL_IS_OPEN = "deal:is_open",
  DEAL_VALUE = "deal:value",
  DEAL_STAGE = "deal:stage",
  DEAL_PIPELINE = "deal:pipeline",
  DEAL_CUSTOM_FIELD = "deal:custom_field",
}

/**********************************
 * CATEGORIES
 **********************************/
export enum CategoryEnum {
  DELETED = "deleted",
  UTILS = "utils",
  OTHER = "other",
  CONTEXT = "context",
  WORKFLOW = "workflow",
  WORKSPACE = "workspace",

  // TRIGGERS ONLY
  WEBHOOK = "webhook",
  CAMPAIGN = "campaign",
  CAMPAIGN_MESSAGE_SENT = "campaign:message_sent",

  // WITH REFERENCE ONLY
  CONTACT = "contact",
  CONVERSATION = "conversation",
  DEAL = "deal",
}
