import { useState } from "react"
import req from "lib/req"
import _ from "lodash"
import arrayMove from "array-move"
import { toast } from "lib/toast"
import request from "lib/request"
import { message } from "antd"

function baseStore(baseEndpoint, hoc) {
    return () => {
        const [endpoint] = useState(baseEndpoint)
        const [all, setAll] = useState([])
        const [one, setOne] = useState()
        const [loading, setLoading] = useState({ get: false })
        const [loaders, setLoaders] = useState([])
        const [matchCount, setMatchCount] = useState(0)
        const [totalCount, setTotalCount] = useState(-1)

        function setAllUniq(callback) {
            setAll((allLocal) => _.uniqBy(callback(allLocal), "id"))
        }

        /*
         * Get
         */
        async function get(payload = {}) {
            const { isNextPage, ...config } = payload
            setLoading({ ...loading, get: true })
            // const response = await req(endpoint, config)
            try {
                const response = await request.get(endpoint, config)
                const dataArray = response.data.data || []
                if (isNextPage) {
                    setAllUniq((all) => [...all, ...dataArray])
                } else {
                    setAll(dataArray)
                    setMatchCount(response.data.matchCount)
                    setTotalCount(response.data.totalCount)
                }
                return response.data
            } catch (error) {
                return error
            } finally {
                setLoading({ ...loading, get: false })
            }
        }

        /*
         * Get One
         */
        async function getOne(id) {
            // const response = await req(endpoint + "/" + id)
            // const errors = response.error || response.errors
            // console.log("errors", errors)
            // if (!errors) {
            //     setOne(response)
            // }
        }

        /*
         * Save
         */
        async function save(payload, { customEndpoint, callback } = {}) {
            const { data, toastMessage, ...configs } = payload
            try {
                const response = await request.save(customEndpoint || endpoint, data, configs)

                if (callback) {
                    callback(response.data)
                } else {
                    saveItem(response.data)
                }

                if (toastMessage) {
                    toast.success(toastMessage)
                    // toast(toastMessage, "success")
                }

                return response.data
            } catch (e) {
                console.error(e)
                throw e
            }
        }

        /*
         * Remove
         */
        async function remove(payload, { fast } = {}) {
            const { data, toastMessage, ...configs } = payload
            try {
                const response = await request.delete(endpoint, data, configs)
                removeFromAll(response.data)
                setMatchCount((m) => m - 1)
                setTotalCount((t) => t - 1)

                if (toastMessage) {
                    toast(toastMessage, "warn", { icon: "delete" })
                }
            } catch (e) {
                console.error(e)
            }
        }

        /*
         * Remove
         */
        async function removeFromAll(item) {
            const existingItem = all.find((e) => e.id == item.id)
            if (existingItem) {
                setAll((all) => all.filter((e) => e.id != item.id))
                setMatchCount((m) => m - 1)
                setTotalCount((t) => t - 1)
            }
        }

        function clear() {
            setAll([])
        }

        function clearOne() {
            setOne(null)
        }

        function saveItem(
            item,
            { saveOnEnd = true, byIndex, dataArray = all, setDataArray = setAll, uniqById } = {}
        ) {
            // console.log("SAVE ITEM", dataArray, item)
            const index = byIndex || dataArray.findIndex((x) => x.id == item.id)
            if (index > -1) {
                setDataArray((dataArray) => {
                    const dataArrayUniq = _.uniqBy(dataArray, "id")
                    const indexInside = byIndex || dataArrayUniq.findIndex((x) => x.id == item.id)
                    dataArrayUniq[indexInside] = { ...dataArrayUniq[indexInside], ...item }
                    return [...dataArrayUniq]
                })
            } else {
                const isArray = Array.isArray(item)
                if (saveOnEnd) {
                    setDataArray((dataArray) => {
                        const dataArrayUniq = _.uniqBy(dataArray, "id")
                        return isArray ? [...dataArrayUniq, ...item] : [...dataArrayUniq, item]
                    })
                } else {
                    setDataArray((dataArray) => {
                        const dataArrayUniq = _.uniqBy(dataArray, "id")
                        return isArray ? [...item, ...dataArrayUniq] : [item, ...dataArrayUniq]
                    })
                }
                setMatchCount((m) => m + 1)
                setTotalCount((t) => t + 1)
            }
        }

        function sortToFirst(item) {
            setAll((all) => {
                const sorted = _.sortBy(all, function(x) {
                    return x.id == item.id ? 0 : 1
                })
                return [...sorted]
            })
        }

        function addLoader(loader) {
            setLoaders([...loaders, loader])
        }
        function removeLoader(loader) {
            setLoaders(loaders.filter((x) => x != loader))
        }

        async function moveItemSave({ oldIndex, newIndex }) {
            const newAll = moveItem({ oldIndex, newIndex })
            await req(endpoint + "/sort", {
                method: "patch",
                data: newAll.map((x) => x.id),
            })
        }

        async function moveItem({ oldIndex, newIndex, array = all }) {
            const newAll = arrayMove(array, oldIndex, newIndex)
            setAll(newAll)
            return newAll
        }

        function findById(id) {
            return all.find((x) => x.id == id)
        }

        function findManyById(ids) {
            return all.filter((x) => ids.includes(x.id))
        }

        const toReturn = {
            all,
            one,
            matchCount,
            totalCount,
            endpoint,
            loading,
            loaders,

            // Actions
            get,
            getOne,
            save,
            remove,
            clear,
            clearOne,
            setLoading,
            addLoader,
            removeLoader,
            sortToFirst,
            removeFromAll,
            findById,
            findManyById,

            // For HOC Actions
            setAll,
            saveItem,
            moveItem,
            moveItemSave,
            setOne,
            setMatchCount,
            setTotalCount,
        }

        return hoc ? hoc(toReturn) : toReturn
    }
}

export default baseStore
