import React from "react"

export default function ModalHeader({ title }) {
    if (!title) return null
    return (
        <div className="modal-header">
            <span className="modal-header-title">{title}</span>
        </div>
    )
}
