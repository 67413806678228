import { IContact } from "models/contacts"
import { IConversation, IConversationDetails } from "models/conversations"
import { atom, selector } from "recoil"

export interface IMessage {
  id: string
  tempId: string
  attempts: number
  Call?: any
  operatorName: string
  contactName?: string
  isDeleted: boolean
  isForwarding: boolean
  Operator: {
    avatar?: string
    id: string
    name: string
  }
  callId: string
  content: string
  createdAt: string
  isMedia: boolean
  isSent: boolean
  isPinned: boolean
  error?: string
  type: number
  externalId?: string
  fileName?: string
  size?: number
  operatorId?: string
  replyTo?: any
  replyToMeta: {
    id: string
    _id: string
    createdAt: string
  }
  extraDataMessage?: IMessageExtraData
  errorDescription?: string
  customerPlatformId?: string
  idMessage?: string
  extra?: {
    campaignId?: string
    campaignName?: string
    participantPhone?: string
    transcription?: string
    menu?: {
      contentText: string
      menu_options: {
        value: string
        name: string
      }[]
      type: "menu"
    }
  }
  status?: number
}

interface IMessageExtraData {
  name?: string
  logo?: string
  permalink?: string
  phone?: string
  email?: string
  embed_conversation_id?: string
  embed_channel_id?: string
  type: "channel_transfer"
  operator_id: string
  target_conversation_id: string
}

export interface ICall {
  id: string
  answeredAt: string
  callExpiresAt: string
  chatBotId: string
  createdAt: string
  customerPlatformId: string
  extraData: any
  finishedAt: string
  groupId: string
  isChatBotActive: boolean
  isFinished: boolean
  isFromIntegration: boolean
  isHidden: boolean
  isImported: boolean
  isNew: boolean
  isReopened: boolean
  isWithoutOperatorAnswer: boolean
  labelId: string
  lastCallMessageId: string
  operatorId: string
  protocol: string
  workspaceId: string
  workspacePlatformId: string
}

export interface IConversationExtended extends IConversation {
  messages?: IMessage[]
  details?: IConversationDetails
  customFields?: {
    [key: string]: {
      value: string
    }
  }
}

export const conversationsAtom = atom<IConversationExtended[]>({
  key: "conversationAtom",
  default: [],
})

const activeConversationAtom = atom<IConversationExtended | null>({
  key: "activeConversationAtom",
  default: null,
})

/**
 * Ao setar o active seta a lista de conversas
 */
export const activeConversationSelector = selector<IConversationExtended | null>({
  key: "activeConversationSelector",
  get: ({ get }) => {
    return get(activeConversationAtom)
  },
  set: ({ set, get }, newActiveConversationAtom) => {
    set(activeConversationAtom, newActiveConversationAtom)
    const conversations = get(conversationsAtom)
    const id = conversations.find((conversation) => conversation.id == (newActiveConversationAtom as IConversationExtended)?.id)?.id
    const newConversationsList = conversations.map((conversation) => {
      if (conversation.id == id) {
        return { ...conversation, ...newActiveConversationAtom }
      }
      return conversation
    })
    if (id) {
      set(conversationsAtom, newConversationsList)
    }
  },
})

export const inboxUnreadAtom = atom<number>({
  key: "inboxUnreadAtom",
  default: 0,
})

export const presenceAtom = atom<
  | {
      workspacePlatformId: any
      jid: any
      status: any
      customerPlatformId: any
      operatorId: any
    }
  | null
  | undefined
>({
  key: "presenceAtom",
  default: null,
})

export const conversationTaskUpdatedAtom = atom<number>({
  key: "conversationTaskUpdatedAtom",
  default: 0,
})
