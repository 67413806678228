import React, { useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import {
    Col,
    Form,
    Input,
    Row,
} from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStoreState } from "hooks"
import { IChannel } from "models/channels"
import { ChannelStatus } from ".."
import mlImg from "assets/images/channels/mercado-livre.png"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"

interface IEditMercadoLivreProps extends RouteComponentProps<{ id: string }> {
    onChange: Function
}

function EditMercadoLivre(props: IEditMercadoLivreProps) {
    const [form] = Form.useForm()
    const { all: channels } = useStoreState((state) => state.channels)
    const { id } = useParams<any>()
    const [item, setItem] = useState<IChannel>()

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    return (
        <SettingsPageEdit<IChannel>
            {...props}
            pageComponent="modal"
            routeName="channels"
            width={500}
            onChangeItem={(item) => setItem(item)}
            title="Canal de Mercado Livre"
            onSubmitSuccess={() => {
                props.onChange()
            }}
            titleOverwrite={
                <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                        <Row wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        width: 36,
                                        height: 36,
                                        display: "flex",
                                        marginRight: 10,
                                    }}
                                >
                                    <img src={mlImg} alt="" style={{ borderRadius: "50%" }} />
                                </div>
                            </Col>
                            <Col title="Canal de Mercado Livre">
                                <Row align="middle" style={{ height: "100%" }}>
                                    Canal de Mercado Livre
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            storePath={(store) => store.channelsML}
            form={form}
            hideFooterWhenSaved
        >
            <Form.Item label="Status">
                <ChannelStatus status={channel?.status} />
            </Form.Item>
            <Form.Item label="Descrição" name="description">
                <Input disabled />
            </Form.Item>
            <EditEntityCustomFields form={form} item={item} entity="channel" />
        </SettingsPageEdit>
    )
}

export default EditMercadoLivre
