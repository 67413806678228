import React from "react"
function SearchEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg version="1.1" id="svg2" width="2666.6667" height="2666.6667" viewBox="0 0 2666.6667 2666.6667" {...props}>
      <defs id="defs6" />
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,2666.6667)">
        <g id="g10" transform="scale(0.1)">
          <path
            d="m 17574.2,10271.4 c 0,-3879.8 -3145.2,-7025 -7025,-7025 -3879.82,0 -7025.01,3145.2 -7025.01,7025 0,3879.8 3145.19,7025 7025.01,7025 3879.8,0 7025,-3145.2 7025,-7025"
            style={{
              // fill: "#f2f2f2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path14"
            className="fill-[#f2f2f2] dark:fill-base-200"
          />
          <path
            d="m 12366.1,5179.3 c -78.9,-331.9 -329.2,-613.1 -680.3,-715.1 L 5760.78,2742.7 c -519.8,-151 -1063.5,148 -1214.5,667.7 l -2081.3,7163.8 c -151,519.7 147.9,1063.5 667.6,1214.5 l 3705.6,1076.6 2219.4,644.8 c 519.8,151 1063.52,-147.9 1214.52,-667.7 l 2081.3,-7163.7 c 49,-168.7 50.7,-339.9 12.7,-499.4"
            style={{
              // fill: "#e6e6e6",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path16"
            className="fill-[#e6e6e6] dark:fill-[#3f4249]"
          />
          <path
            d="m 12366.1,5179.3 -4970.72,285.1 c -540.4,31 -953.3,494.1 -922.3,1034.5 l 365.1,6366.4 2219.4,644.8 c 519.8,151 1063.52,-147.9 1214.52,-667.7 l 2081.3,-7163.7 c 49,-168.7 50.7,-339.9 12.7,-499.4"
            style={{
              // fill: "#b2b2b2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path18"
            className="fill-[#b2b2b2] dark:fill-base-200"
          />
          <path
            d="M 13624.2,5566.4 H 7454.19 c -541.24,0 -980,438.8 -980,980 v 7460 c 0,541.3 438.76,980 980,980 h 6170.01 c 541.2,0 980,-438.7 980,-980 v -7460 c 0,-541.2 -438.8,-980 -980,-980"
            style={{
              // fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path20"
            className="fill-[#ffffff] dark:fill-base-100"
          />
          <path
            d="m 12591.8,8339.7 60.9,-72.6 c -8,-8.2 -16.1,-16.4 -24.4,-24.6 -54.4,-54.4 -111.7,-106.9 -171.9,-157.3 -59.9,-50.1 -121.4,-97.6 -184.6,-142.3 -14.3,-9.8 -28.6,-19.7 -42.9,-29.4 -4.8,-3.2 -9.6,-6.5 -14.5,-9.6 l -58.6,69.9 -33.5,39.9 -33.4,39.8 c 14.3,9.7 28.6,19.6 42.9,29.4 63.2,44.7 124.7,92.2 184.6,142.3 60.2,50.4 117.5,102.9 171.9,157.3 12.5,12.3 24.6,24.7 36.5,37.2 l 33.7,-40.1 33.3,-39.9"
            style={{
              // fill: "#e6e6e6",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path22"
            className="fill-[#e6e6e6] dark:fill-base-200"
          />
          <path
            d="m 13732.1,6980.9 -69.3,-58.2 -366.7,-307.8 -225.8,268.8 -33.2,39.7 -33.3,39.8 -323.7,385.8 c 4.7,3.3 9.4,6.6 14.1,10 14.1,9.9 28.2,20.1 42.2,30.2 62.2,45.6 123.4,93.6 183.4,143.7 59.9,50.4 117.6,102.4 173.3,155.9 8.3,7.9 16.6,15.9 24.8,24.1 l 322.1,-383.9 33.1,-39.7 33.4,-39.5 167.5,-199.6 58.1,-69.3"
            style={{
              // fill: "#e6e6e6",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path24"
            className="fill-[#e6e6e6] dark:fill-base-200"
          />
          <path
            d="m 14893.5,4127.4 -2015.1,2401.5 c -71.1,84.7 -60,211 24.7,282.1 l 704.2,590.9 c 84.7,71 211,60 282,-24.7 l 2015.1,-2401.5 c 166.4,-198.3 140.5,-493.9 -57.8,-660.2 l -292.9,-245.8 c -198.2,-166.4 -493.8,-140.5 -660.2,57.7"
            style={{
              // fill: "#b2b2b2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path26"
            className="fill-[#b2b2b2] dark:fill-base-200"
          />
          <path
            d="m 13195.1,7620.8 c -8.1,-8.2 -16.4,-16.2 -24.7,-24.1 -55.8,-53.5 -113.4,-105.5 -173.4,-155.9 -60,-50.1 -121.2,-98.1 -183.3,-143.7 -14.1,-10.1 -28.2,-20.3 -42.3,-30.2 -4.7,-3.4 -9.4,-6.7 -14,-10 l -169.8,202.3 -33.4,39.8 -33.5,39.9 -306.3,365 c 4.9,3.1 9.7,6.4 14.5,9.6 14.3,9.7 28.6,19.6 42.9,29.4 63.2,44.7 124.7,92.2 184.6,142.3 60.2,50.4 117.5,102.9 171.9,157.3 8.3,8.2 16.4,16.4 24.4,24.6 l 304.2,-362.3 33.3,-39.8 33.6,-39.9 171.3,-204.3"
            style={{
              // fill: "#b2b2b2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path28"
            className="fill-[#b2b2b2] dark:fill-base-200"
          />
          <path
            d="m 8835.32,12373.7 c 1143.94,959.8 2855.18,810 3814.88,-333.8 886.5,-1056.4 826.2,-2597.3 -89.5,-3582.5 -11.8,-12.6 -23.7,-25.3 -35.9,-37.7 -11.9,-12.5 -24,-24.9 -36.5,-37.2 -54.4,-54.4 -111.7,-106.9 -171.9,-157.3 -59.9,-50.2 -121.4,-97.6 -184.6,-142.3 -14.3,-9.8 -28.6,-19.7 -42.9,-29.4 -14.4,-9.7 -28.9,-19.3 -43.4,-28.8 -503.7,-326.8 -1097.9,-475.9 -1705.5,-422.8 -720.57,63.2 -1373.38,403 -1838.32,957.2 -959.71,1143.5 -810.11,2854.9 333.64,3814.6 z m 4366.98,129.5 C 11985.1,13953.9 9822.69,14143 8372.04,12925.8 6921.57,11708.7 6732.23,9546.2 7949.58,8095.6 9093.7,6732.1 11073.2,6483 12511.6,7469.3 c 14.3,9.7 28.5,19.6 42.6,29.7 14.1,9.9 28.2,20.1 42.2,30.2 62.2,45.6 123.4,93.6 183.4,143.7 59.9,50.4 117.6,102.4 173.3,155.9 12.4,11.9 24.9,23.8 37.1,36.2 12.5,12.1 24.6,24.4 36.7,36.8 1221,1245.1 1319.7,3237.9 175.4,4601.4"
            style={{
              // fill: "#e6e6e6",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path30"
            className="fill-[#e6e6e6] dark:fill-base-200"
          />
          <path
            d="m 12560.7,8457.4 c -11.8,-12.6 -23.7,-25.3 -35.9,-37.7 -11.9,-12.5 -24,-24.9 -36.5,-37.2 -54.4,-54.4 -111.7,-106.9 -171.9,-157.3 -59.9,-50.2 -121.4,-97.6 -184.6,-142.3 -14.3,-9.8 -28.6,-19.7 -42.9,-29.4 -14.4,-9.7 -28.9,-19.3 -43.4,-28.8 -503.7,-326.8 -1097.9,-475.9 -1705.5,-422.8 -720.57,63.2 -1373.38,403 -1838.32,957.2 -959.71,1143.5 -810.11,2854.9 333.64,3814.6 1143.94,959.8 2855.18,810 3814.88,-333.8 886.5,-1056.4 826.2,-2597.3 -89.5,-3582.5 z m 129.1,3615.9 c -979.7,1167.3 -2720.07,1319.7 -3887.62,340 -1167.56,-979.5 -1319.72,-2720.2 -340.23,-3887.6 906.42,-1080.4 2464.35,-1291.3 3616.95,-540.9 14.6,9.5 28.9,19.2 43.4,28.9 14.3,9.7 28.5,19.4 42.6,29.7 63,44.3 124.6,91.7 184.8,142.1 60.1,50.3 117.4,102.9 172.1,157.1 12.3,12.3 24.6,24.7 36.7,37 12,12.6 23.9,25.1 35.9,37.7 939.2,1004.8 1001.8,2575.6 95.4,3656"
            style={{
              // fill: "#e6e6e6",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path32"
            className="fill-[#e6e6e6] dark:fill-base-200"
          />
          <path
            d="m 12560.7,8457.4 c -11.8,-12.6 -23.7,-25.3 -35.9,-37.7 -11.9,-12.5 -24,-24.9 -36.5,-37.2 -54.4,-54.4 -111.7,-106.9 -171.9,-157.3 -59.9,-50.2 -121.4,-97.6 -184.6,-142.3 -14.3,-9.8 -28.6,-19.7 -42.9,-29.4 -14.4,-9.7 -28.9,-19.3 -43.4,-28.8 -503.7,-326.8 -1097.9,-475.9 -1705.5,-422.8 -720.57,63.2 -1373.38,403 -1838.32,957.2 -959.71,1143.5 -810.11,2854.9 333.64,3814.6 1143.94,959.8 2855.18,810 3814.88,-333.8 886.5,-1056.4 826.2,-2597.3 -89.5,-3582.5"
            style={{
              // fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path34"
            className="fill-[#ffffff] dark:fill-base-100"
          />
          <path
            d="m 15373.4,4994.7 c -25.4,0 -50.6,10.6 -68.5,31.3 l -1641.3,1895.6 c -32.7,37.8 -28.6,95 9.2,127.7 37.8,32.8 95,28.7 127.8,-9.2 l 1641.2,-1895.5 c 32.8,-37.8 28.6,-95 -9.2,-127.8 -17.1,-14.8 -38.2,-22.1 -59.2,-22.1"
            style={{
              // fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path36"
            className="fill-[#ffffff] dark:fill-base-100"
          />
          <path
            d="m 8093,12179.9 c -14.28,0 -28.66,4.6 -40.77,14.1 -28.78,22.5 -33.84,64.1 -11.3,92.9 212.51,271.3 466.51,505.7 754.92,696.7 284.28,188.2 595.1,329.7 923.86,420.7 683.59,189.2 1393.79,149.5 2054.09,-114.7 33.9,-13.6 50.4,-52.1 36.8,-86 -13.6,-34 -52.1,-50.5 -86,-36.9 -1267,507 -2738.86,111.7 -3579.45,-961.4 -13.06,-16.7 -32.51,-25.4 -52.15,-25.4"
            style={{
              // fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path38"
            className="fill-[#ffffff] dark:fill-base-100"
          />
          <path
            d="m 7647.35,11404.9 c -8.19,0 -16.51,1.5 -24.58,4.8 -33.93,13.6 -50.44,52.1 -36.86,86 68.89,172.1 152.7,338.8 249.1,495.4 19.17,31.1 59.94,40.8 91.07,21.7 31.14,-19.2 40.83,-60 21.67,-91.1 -92.45,-150.2 -172.84,-310 -238.92,-475.2 -10.36,-25.8 -35.21,-41.6 -61.48,-41.6"
            style={{
              // fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path40"
            className="fill-[#ffffff] dark:fill-base-100"
          />
          <path
            d="m 11493.6,8907 c -77.4,-53.3 -163,-71.8 -256.9,-55.7 -93.8,16.2 -167.5,62.9 -220.7,140.3 -55,79.7 -73.4,166.4 -55.1,259.9 18.3,93.5 66.1,167 143.4,220.2 79.8,55 166.7,74.4 260.6,58.3 93.9,-16.2 168.3,-64.2 223.3,-144 53.3,-77.3 70.8,-162.8 52.5,-256.2 -18.3,-93.5 -67.4,-167.8 -147.1,-222.8 z m -550.6,806.7 c -107.2,-73.9 -217.2,-44.9 -330.2,86.9 l -254.4,321.1 c -53.2,66.3 -76.2,130.5 -69.2,192.4 7.1,61.8 38.2,124.7 93.5,188.5 90.8,103.1 145.1,205.7 162.8,308.1 17.8,102.3 -7.7,203.4 -76.5,303.1 -75.6,109.7 -164.7,176.2 -267,199.4 -102.5,23.2 -207.33,-2.2 -314.52,-76.1 -134.64,-92.8 -197.92,-211.8 -189.77,-357 0.74,-102.5 -10.7,-176.6 -34.36,-222.3 -23.63,-45.7 -70.11,-85.1 -139.3,-118 -78.42,-35.7 -151.6,-43 -219.54,-21.7 -67.96,21.2 -108.94,76.6 -122.91,166.3 -26.26,176.8 -1.85,348.1 73.32,514 75.15,165.7 197.44,307.1 367.04,424 154.57,106.5 311.32,171.3 470.14,194.4 158.8,23 310.2,2.2 453.9,-62.3 143.9,-64.6 269,-174.2 375.6,-328.7 122,-177.1 166.6,-357.9 133.8,-542.3 -32.9,-184.5 -123.6,-361 -272.2,-529.6 l 188.7,-305.8 c 85.5,-150.8 75.9,-262.2 -28.9,-334.4"
            style={{
              // fill: "#f2f2f2",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path42"
            className="fill-[#f2f2f2] dark:fill-base-200"
          />
        </g>
      </g>
    </svg>
  )
}

export default SearchEmpty
