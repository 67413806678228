import React from "react"
import Card, { CardProps } from "../Card"
import { twMerge } from "tailwind-merge"

interface CardLayoutProps extends CardProps {
  children: React.ReactNode
  className?: string
  headerClassName?: string
}

function CardLayout(props: CardLayoutProps) {
  const { children, className, headerClassName, ...rest } = props

  return (
    <Card
      className={twMerge(
        "max-w-3xl w-full mx-5 mt-5 mb-20 pb-4 mobile:shadow-none mobile:max-w-full mobile:p-0 mobile:m-0",
        props.className
      )}
      headerClassName={twMerge("mobile:hidden", props.headerClassName)}
      {...rest}
    >
      {props.children}
    </Card>
  )
}

export default CardLayout
