import React, { useMemo } from "react"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { ContactIcon, Mail02Icon, SmartPhone01Icon, StickyNote02Icon, UserSearch01Icon } from "components-old/icons"
import { useRecoilState } from "recoil"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import BaseExpanderFields from "../../../actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { variableAtom } from "atoms/app.atom"

interface UpdateContactNodeProps extends BaseNodeProps {}

function UpdateContactNode(props: UpdateContactNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const contact = props.data?.action?.contact || {}
    if (contact?.contactReference) {
      fields.push({
        icon: UserSearch01Icon,
        value: (
          <span>
            Contato <span className="text-pink-600">#{contact?.contactReference}</span>
          </span>
        ),
        label: "Contato",
      })
    }
    if (contact.name) {
      fields.push({
        icon: ContactIcon,
        value: transformStringToJSX(contact.name ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Nome",
      })
    }
    if (contact.phone) {
      fields.push({
        icon: SmartPhone01Icon,
        value: transformStringToJSX(contact.phone, { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Telefone",
      })
    }
    if (contact.email) {
      fields.push({
        icon: Mail02Icon,
        value: transformStringToJSX(contact.email, { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "E-mail",
      })
    }
    if (contact.notes) {
      fields.push({
        icon: StickyNote02Icon,
        value: transformStringToJSX(contact.notes, { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "Notas",
      })
    }

    return {
      fields,
    }
  }, [dynamicVariablesDef.variablesRecord, props.data?.action?.contact])

  return (
    <BaseNode {...props} title="Atualizar contato">
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONTACT} />
    </BaseNode>
  )
}

export default UpdateContactNode
