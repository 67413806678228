import React from "react"
import { Col, Row, Card } from "antd"
import styles from "./style.module.css"
import { Link, RouteComponentProps, useHistory } from "react-router-dom"
import SettingsPageSimple from "components-old/common/SettingsPage/SettingsPageSimple"
import WhatsappSvg from "./svgs/WhatsappSvg"
import InstagramSvg from "./svgs/InstagramSvg"
import TelegramSvg from "./svgs/TelegramSvg2"
import FacebookSvg from "./svgs/FacebookSvg"
import EmailSvg from "./svgs/EmailSvg"
import CustomSvg from "./svgs/CustomSvg"
import smsImg from "assets/images/channels/sms.png"

import MercadoLivreImage from "assets/images/channels/mercado-livre.png"
import cx from "classnames"
import { useStoreState } from "hooks"
import { CHANNEL_TYPES } from "lib/constants"
// import { session, useStore } from "store"

interface IChannelType {
    ComponentSVG?: any
    name?: string
    to?: string
    bg?: string
    logoType?: string
    imgStyle?: any
    disabled?: boolean
}

function AddChannel(props: RouteComponentProps) {
    const history = useHistory()
    const channelStore = useStoreState((state) => state.channels)

    const channelsTypes: IChannelType[] = [
        {
            ComponentSVG: WhatsappSvg,
            name: "WhatsApp",
            to: "/settings/channels/new/whatsapp/type",
            bg: "#3c9040",
        },
        {
            ComponentSVG: FacebookSvg,
            name: "Facebook",
            to: "/settings/channels/new/facebook",
            bg: "#4e71ba",
        },
        {
            ComponentSVG: TelegramSvg,
            name: "Telegram",
            to: "/settings/channels/new/telegram",
            bg: "#058dd0",
        },
        {
            ComponentSVG: EmailSvg,
            name: "E-mail",
            to: "/settings/channels/new/email",
            bg: "#eabc0c",
        },
        {
            ComponentSVG: InstagramSvg,
            name: "Instagram",
            to: "/settings/channels/new/instagram",
            bg: "#ffc3d6",
        },
        {
            ComponentSVG: MercadoLivreImage,
            name: "Mercado livre",
            to: "/settings/channels/new/mercado-livre",
            logoType: "image",
            imgStyle: { borderRadius: "50%" },
            bg: "#ffe503",
        },
        {
            ComponentSVG: smsImg,
            logoType: "image",
            name: "SMS",
            to: "/settings/channels/new/sms",
            bg: "#262525",
            disabled: !!channelStore.all.find((x) => x.channelId == CHANNEL_TYPES.SMS),
        },
        {
            ComponentSVG: CustomSvg,
            name: "Customizado",
            to: "/settings/channels/new/custom",
            bg: "#262525",
        },
    ]

    return (
        <SettingsPageSimple
            {...props}
            title="Conectar novo canal"
            onBack={() => history.push("/settings/channels")}
            // centerTitle="Conectar novo canal"
            // description="Escolha um canal de comunicação para integrar ao Kinbox"
            backTo="/settings/channels"
            helpText={<span>Conecte-se aos aplicativos de mensagens mais usados pelos seus clientes e responda todos em sua caixa de entrada.</span>}
            style={{
                maxWidth: 642,
            }}
            // center
        >
            <div
                style={{
                    fontSize: 16,
                    color: "var(--content-secondary)",
                    marginLeft: 5,
                    marginBottom: 20,
                }}
            >
                Escolha um canal de comunicação para integrar ao Kinbox
            </div>
            <div>
                {channelsTypes.map((channelType) => (
                    // <Col style={{ marginBottom: 15 }} lg={6} md={8} sm={24} key={channelType.name}>
                    <Link
                        key={channelType.name}
                        to={channelType.to || ""}
                        style={{ margin: 5, width: 180, height: 125 }}
                        className={cx({ [styles.disabled_card]: channelType.disabled })}
                    >
                        <Card className={styles.channel_card}>
                            {channelType.logoType == "image" ? (
                                <img src={channelType.ComponentSVG} alt="" style={channelType.imgStyle} />
                            ) : (
                                <channelType.ComponentSVG />
                            )}
                            <Row
                                justify="center"
                                align="middle"
                                style={{
                                    // height: 70,
                                    fontSize: 16,
                                    fontWeight: 400,
                                }}
                            >
                                {channelType.name}
                            </Row>
                        </Card>
                    </Link>
                    // </Col>
                ))}
            </div>
        </SettingsPageSimple>
    )
}

export default AddChannel
