import numeral from "numeral"

numeral.register("locale", "pt-br", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "kk",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return "º"
  },
  currency: {
    symbol: "R$",
  },
})

numeral.locale('pt-br');

const cpf = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.substring(0, 11)
    v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") // Coloca um hífen entre o terceiro e o quarto dígitos
  }
  return v
}

const cnpj = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.substring(0, 14)
    v = v.replace(/(\d{2})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1/$2") // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{4})(\d{1,2})$/, "$1-$2") // Coloca um hífen entre o terceiro e o quarto dígitos
  }
  return v
}

const cell = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    if (v.length > 11) {
      return cellCountry(v)
    }
    // v = v.substring(0, 11)
    v = v.replace(/^(\d\d)(\d)/g, "($1) $2") // Coloca parênteses em volta dos dois primeiros dígitos
    if (v.length <= 13) {
      v = v.replace(/(\d{4})(\d)/, "$1-$2") // Coloca hífen entre o quarto e o quinto dígitos
    } else {
      v = v.replace(/(\d{5})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
    }
  }
  return v
}

const cellCountry = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    if (v.length > 13) {
      return v
    }
    // v = v.substring(0, 13)
    if (v.length > 12) {
      v = v.replace(/^(\d\d)(\d\d)(\d)/g, "+$1 ($2) $3") // Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d{5})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
    } else {
      v = v.replace(/^(\d\d)(\d\d)(\d)/g, "+$1 ($2) $3") // Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2") // Adicionando possibilidade do nono dígito.
    }
  }
  return v
}

const cep = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
    v = v.substring(0, 8)
    v = v.replace(/(\d{5})(\d)/, "$1-$2")
  }
  return v
}

const number = (v) => {
  if (v) {
    v = v.replace(/\D/g, "") // Remove tudo o que não é dígito
  }
  return v
}

const numberNotRepeated55 = (v) => {
  if (v.substr(0, 4) == "5555" && v.length >= 14) {
    v = v.replace(/^55/, "") // Tira os primeiros 2 digitos 5
  }
  return v
}

const propertyCode = (v) => {
  if (v.length == 1) {
    if (v == "{" || v == "}") {
      v = ""
    }
  }
  if (v.length > 3 && v.substr(v.length - 1) == "}" && v.substr(v.length - 2) != "}") {
    v = v.substring(0, v.length - 2)
    if (v.length < 3) {
      v = ""
    }
  }
  if (v) {
    v = v.replace(/{/g, "")
    v = v.replace(/ /g, "_")
    // v = v.replace(/{/g, "")
    // v = v.replace(/}/g, "")
    v = v.replace(/}/g, "")
    v = `{{${v}}}`
  }
  return v
}

const beginWithSlash = (v) => {
  // if (v.length == 1) {
  //     if(v == '/') {
  //         v = ''
  //     }
  // }
  // if (v.length > 3 && v.substr(v.length - 1) == '}' && v.substr(v.length - 2) != '}') {
  //     v = v.substring(0, v.length - 2)
  //     if (v.length < 3) {
  //         v = ''
  //     }
  // }
  if (v) {
    v = v.replace(/\//g, "")
    v = v.replace(/ /g, "_")
    // v = v.replace(/{/g, "")
    // v = v.replace(/}/g, "")
    // v = v.replace(/}/g, "")
    v = `/${v}`
  }
  return v
}

const currency = (v) => {
  return `R$ ${numeral(v).format("0.00")}`
}

const currencySmall = (v) => {
  return `R$ ${numeral(v).format("0.00[000]")}`
}

const currencyDecimal = (v) => {
  return `R$ ${numeral(v).format("0.00")}`
}

export const parseDecimal = (value) => {
  let numbers = value?.match(/\d/g)?.join("") || "0"
  const numbersInt = parseInt(numbers, 10)
  let result = (numbersInt / 100).toFixed(2)
  return result
}

export const formatterCurrency = (value) => {
  // return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // return `R$ ${(value + "")?.replace(".", ",")}`
  const number = parseFloat(value + "")
  return "R$ " + numeral(number).format("0,0.00")
}

export const formatterCurrencyNew = (value) => {
  const number = parseFloat(value + "")
  return "R$ " + numeral(number).format("0,0.00")
}

export const formatterCurrencyNewWithoutDollarSign = (value) => {
  const number = parseFloat(value + "")
  return numeral(number).format("0,0.00")
}

export const coolNumberOrDash = (value) => {
  if (value) return value.toLocaleString("pt-BR")
  // if (value) return new Intl.NumberFormat("en").format(value)
  // if (value) return numeral(value).format("0,0a")

  return "-"
}

export default {
  numberNotRepeated55,
  cpf,
  cell,
  cellCountry,
  cep,
  cnpj,
  propertyCode,
  number,
  currency,
  beginWithSlash,
  currencyDecimal,
  currencySmall,
  formatterCurrencyNewWithoutDollarSign,
}
