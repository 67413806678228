import React, { useMemo } from "react"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import BaseExpanderFields, { NodeField } from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { useStoreState } from "hooks"

interface DelayNodeProps extends BaseNodeProps {}

function DelayNode(props: DelayNodeProps) {
  const { all: businessHours } = useStoreState((state) => state.businessHours)

  const { fields } = useMemo(() => {
    let fields: NodeField[] = []
    const delay = props.data?.action?.delay

    if (delay?.duration && delay?.unit) {
      let unitLabel

      switch (delay?.unit) {
        case "seconds":
          unitLabel = "segundos"
          break
        case "minutes":
          unitLabel = "minutos"
          break
        case "hours":
          unitLabel = "horas"
          break
        case "days":
          unitLabel = "dias"
          break
      }

      fields.push({
        // icon: WorkflowDefs.ActionRecord.delay.icon,

        value: (
          <div className="flex gap-2 flex-wrap">
            Esperar
            <div className="flex items-center gap-2 truncate shadow-button dark:bg-base-200 px-2 rounded-full font-medium">
              <i className="fas fa-clock text-amber-400"></i>
              {delay?.duration + " " + unitLabel?.slice(0, delay?.duration === 1 ? -1 : unitLabel.length)}
            </div>
          </div>
        ),
        label: "Duração",
      })
    }

    if (delay?.useBusinessHours && delay?.businessHoursId) {
      const businessHour = businessHours.find((hour) => hour.id === delay.businessHoursId)

      fields.push({
        // icon: WorkflowDefs.ActionRecord.delay.icon,
        value: (
          <div className="flex gap-2 flex-wrap">
            Considerando horário
            <div className="flex items-center gap-2 truncate bg-hover-overlay dark:bg-base-200 px-2 rounded-full font-medium">
              {businessHour?.name}
            </div>
          </div>
        ),
        label: "",
      })
    }

    return {
      fields,
    }
  }, [businessHours, props.data?.action?.delay])

  return (
    <BaseNode {...props} title="Esperar um tempo">
      <BaseExpanderFields fields={fields} category={CategoryEnum.UTILS} color={WorkflowDefs.ActionRecord.delay.colors?.bg} />
    </BaseNode>
  )
}

export default DelayNode
