import React from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Col, Form, Input, Row, Select } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { ITag } from "models/tags"
import { getColorPaletteByIndex } from "lib/helper"
import { TagItem } from "components-old/chat/TagInput"
import PrivacyField from "components-old/ui/PrivacyField"
import { session, useStore } from "store"
import _ from "lodash"
import { Privacy } from "lib/constants"

interface IEditTagProps extends RouteComponentProps<{ id: string }> {
  children: React.ReactNode
}

export const tagColors = {
  light: {
    bg: "#f3f4f6",
    text: "#0f172a",
    border: "#f1f5f9",
    name: "light",
    500: "#64748c",
    600: "#475569",
  },
  slate: {
    bg: "#e2e8f0",
    // text: "#5C5E63",
    text: "#0f172a",
    border: "#f1f5f9",
    name: "slate",
    500: "#64748b",
    // 600: "#475569",
    600: "#475569",
  },
  gray: {
    bg: "#e2e8f0",
    // text: "#5C5E63",
    text: "var(--content-primary)",
    border: "#eeeff1",
    name: "gray",
    500: "#6b7280",
    // 600: "#475569",
    600: "rgba(0,0,0,0.1)",
  },
  zinc: {
    bg: "#e4e4e7",
    text: "#18181b",
    border: "#f4f4f5",
    name: "zinc",
    500: "#71717a",
    600: "#52525b",
  },
  stone: {
    bg: "#e7e5e4",
    text: "#1c1917",
    border: "#e7e5e4",
    name: "stone",
    500: "#78716c",
    600: "#57534e",
  },
  red: {
    bg: "#fecaca",
    text: "#772322",
    border: "#ffdcdb",
    name: "red",
    500: "#ef4444",
    600: "#dc2626",
  },
  orange: {
    bg: "#fed7aa",
    text: "#753501",
    border: "#fee0c8",
    name: "orange",
    500: "#f97316",
    600: "#ea580c",
  },
  amber: {
    bg: "#fde68a",
    text: "#594F03",
    border: "#fbf197",
    name: "amber",
    500: "#f59e0b",
    600: "#d97706",
  },
  yellow: {
    bg: "#fef08a",
    text: "#705500",
    border: "#ffebad",
    name: "yellow",
    500: "#eab308",
    600: "#ca8a04",
  },
  lime: {
    bg: "#d9f99d",
    text: "#505F07",
    border: "#e9f797",
    name: "lime",
    500: "#84cc16",
    600: "#65a30d",
  },
  green: {
    bg: "#bbf7d0",
    text: "#052e16",
    border: "#bbf7d0",
    name: "green",
    500: "#22c55e",
    600: "#16a34a",
  },
  emerald: {
    bg: "#86efac",
    text: "#075A39",
    border: "#c7f4d3",
    name: "emerald",
    500: "#10b981",
    600: "#059669",
  },
  teal: {
    bg: "#ccfbf1",
    text: "#134e4a",
    border: "#ccfbf1",
    name: "teal",
    500: "#14b8a6",
    600: "#0d9488",
  },
  cyan: {
    bg: "#cffafe",
    text: "#164e63",
    border: "#cffafe",
    name: "cyan",
    500: "#06b6d4",
    600: "#0891b2",
  },
  sky: {
    bg: "#e0f2fe",
    text: "#0c4a6e",
    border: "#e0f2fe",
    name: "sky",
    500: "#0ea5e9",
    600: "#0284c7",
  },
  blue: {
    bg: "#bfdbfe",
    text: "#0A5A70",
    border: "#c3edf9",
    name: "blue",
    500: "#3b82f6",
    600: "#2563eb",
  },
  indigo: {
    bg: "#c7d2fe",
    text: "#183C81",
    border: "#d6e5ff",
    name: "indigo",
    500: "#6366f1",
    600: "#4f46e5",
  },
  violet: {
    bg: "#ddd6fe",
    text: "#4711BB",
    border: "#e8ddfe",
    name: "violet",
    500: "#8b5cf6",
    600: "#7c3aed",
  },
  purple: {
    bg: "#e9d5ff",
    text: "#681B74",
    border: "#f2d6f5",
    name: "purple",
    500: "#a855f7",
    600: "#9333ea",
  },
  fuschia: {
    bg: "#fae8ff",
    text: "#831843",
    border: "#fae8ff",
    name: "fuschia",
    500: "#d946ef",
    600: "#c026d3",
  },
  pink: {
    bg: "#fbcfe8",
    text: "#6F065D",
    border: "#fddde7",
    name: "pink",
    500: "#ec4899",
    600: "#db2777",
  },
  rose: {
    bg: "#ffe4e6",
    text: "#881337",
    border: "#ffe4e6",
    name: "rose",
    500: "#f43f5e",
    600: "#e11d48",
  },
}

export const tagColorsArray = Object.values(tagColors)

export const convertColorToTagColor = (color: string) => {
  switch (color?.toLocaleLowerCase?.()) {
    case "#f4f5f6":
    case tagColors.gray.bg:
    case tagColors.gray[500]:
      return tagColors.gray
    case "#ffebeb":
    case "#e74c3c":
    case "#cf6a87":
    case "#c44569":
    case tagColors.red.bg:
    case tagColors.red[500]:
      return tagColors.red
    case "#feeee1":
    case "#e67e22":
    case "#e15f41":
    case tagColors.orange.bg:
    case tagColors.orange[500]:
      return tagColors.orange
    case "#fff3cc":
    case "#f1c40f":
    case "#feca57":
    case tagColors.yellow.bg:
    case tagColors.yellow[500]:
      return tagColors.yellow
    case "#f4fbcb":
    case "#2ecc71":
    case "#badc58":
    case "#6ab04c":
    case tagColors.lime.bg:
    case tagColors.lime[500]:
      return tagColors.lime
    case tagColors.emerald.bg:
    case tagColors.emerald[500]:
      return tagColors.emerald
    case tagColors.teal.bg:
    case tagColors.teal[500]:
      return tagColors.teal
    case tagColors.sky.bg:
    case tagColors.sky[500]:
      return tagColors.sky
    case tagColors.rose.bg:
    case tagColors.rose[500]:
      return tagColors.rose
    case tagColors.cyan.bg:
    case tagColors.cyan[500]:
      return tagColors.cyan
    case tagColors.slate.bg:
    case tagColors.slate[500]:
      return tagColors.slate
    case tagColors.fuschia.bg:
    case tagColors.fuschia[500]:
      return tagColors.fuschia
    case tagColors.zinc.bg:
    case tagColors.zinc[500]:
      return tagColors.zinc
    case tagColors.stone.bg:
    case tagColors.stone[500]:
      return tagColors.stone
    case "#ddf9e4":
    case "#1abc9c":
    case tagColors.green.bg:
    case tagColors.green[500]:
      return tagColors.green
    case "#daf4fc":
    case "#3498db":
    case "#34495e":
    case "#48dbfb":
    case tagColors.blue.bg:
    case tagColors.blue[500]:
      return tagColors.blue
    case "#e5eeff":
    case "#778beb":
    case tagColors.indigo.bg:
    case tagColors.indigo[500]:
      return tagColors.indigo
    case "#f5f0ff":
    case "#5f27cd":
    case "#30336b":
    case "#686de0":
    case "#786fa6":
    case tagColors.purple.bg:
    case tagColors.purple[500]:
      return tagColors.purple
    case "#f7e6f9":
    case "#9b59b6":
    case tagColors.violet.bg:
    case tagColors.violet[500]:
      return tagColors.violet
    case "#feecf1":
    case "#ff9ff3":
    case "#ffcccc":
    case tagColors.pink.bg:
    case tagColors.pink[500]:
      return tagColors.pink
    case "#fdf7c4":
    case tagColors.amber.bg:
    case tagColors.amber[500]:
      return tagColors.amber
    default:
      // Return random color from tagColors based on the string (same string same result)
      return getColorPaletteByIndex(color, tagColorsArray) as {
        bg: string
        text: string
        border: string
        500: string
      }
    // return {
    //     bg: "#000",
    //     text: "#fff",
    //     border:"#000",
    // }
  }
}

export const colorsArray: string[] = [
  "#95a5a6",
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#ff9ff3",
  "#feca57",
  "#48dbfb",
  "#5f27cd",
  "#badc58",
  "#6ab04c",
  "#30336b",
  "#686de0",
  "#000",
  "#ffcccc",
  "#cf6a87",
  "#c44569",
  "#778beb",
  "#786fa6",
  "#e15f41",
]

const colors = colorsArray.map((x) => ({ bg: x }))

function EditTag(props: IEditTagProps) {
  const [form] = Form.useForm()
  const { id } = useParams<any>()
  const { user } = useStore(session)

  return (
    <SettingsPageEdit<ITag>
      {...props}
      pageComponent="modal"
      routeName="tags"
      title="Tag"
      storePath={(store) => store.tags}
      form={form}
      metaConfigs={{
        saveWithPatch: true,
      }}
      defaultValues={{
        color: _.sample(tagColorsArray)?.bg,
        authorId: user?.id,
        privacy: {
          type: Privacy.PUBLIC,
        },
      }}
      formProps={{
        onValuesChange: (changed, values) => {
          if (changed.color) {
            form.setFieldsValue({
              textColor: "#fff",
            })
          }
        },
      }}
    >
      <Form.Item
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: "Por favor digite um nome.",
          },
        ]}
      >
        <Input autoFocus={id == "new"} />
      </Form.Item>
      <Form.Item label="Cor" name="color">
        <Select>
          {tagColorsArray.map((color) => (
            <Select.Option value={color.bg} key={color.name}>
              <div className="flex items-center h-full">
                <TagItem colors={color}>{color.name}</TagItem>
              </div>
              {/* <Row align="middle" gutter={12}>
                <Col>
                  <div
                    style={{
                      backgroundColor: color.bg,
                      borderRadius: "50%",
                      width: 19,
                      height: 19,
                      border: "4px solid " + color.bg,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></div>
                </Col>
                <Col>{color.bg}</Col>
              </Row> */}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="textColor" noStyle>
        <div />
      </Form.Item>
      <PrivacyField />
    </SettingsPageEdit>
  )
}

export default EditTag
