import React, { Component } from "react"
import { Link } from "react-router-dom"

class Dropdown extends Component {
    state = {
        style: { top: "-99999px", left: "-99999px" },
        isOpen: false
    }

    showMenu = () => {
        if (!this.props.down && !this.isHeightEnough()) {
            this.changeTop(-1 * this.calculateTopStyle() + 25)
        } else {
            this.changeTop(this.menu.clientHeight + 10)
        }
        document.addEventListener("click", this.closeMenu, { passive: true })
    }

    toggleMenu = event => {
        event.preventDefault()
        if (this.state.isOpen) {
            this.onCloseMenu(event)
        } else {
            this.showMenu(event)
        }
    }

    calculateTopStyle = () => this.menu.clientHeight + this.menuContent.clientHeight
    isHeightEnough = () =>
        this.menu.getBoundingClientRect().top + this.calculateTopStyle() + 10 < window.innerHeight

    changeTop = top => {
        const style = this.props.left ? { left: "auto" } : {}
        this.setState(state => ({
            style: { top, ...style },
            isOpen: true
        }))
    }

    closeMenu = event => {
        if (!this.props.notClose) {
            if (this.menuContent && !this.menuContent.contains(event.target)) {
                this.onCloseMenu()
            }
        }
    }

    onCloseMenu = () => {
        this.setState(
            { style: { top: "-99999px", left: "-99999px" }, isOpen: false },
            document.removeEventListener("click", this.closeMenu)
        )
    }

    render() {
        const { style } = this.state
        const { trigger, children, actions, ...props } = this.props
        return (
            <div
                className="menu-dropdown"
                ref={element => {
                    this.menu = element
                }}
            >
                <a onClick={(e) => {
                    this.toggleMenu(e)
                    e.stopPropagation()
                }}>{trigger}</a>
                <div
                    className="menu"
                    ref={element => {
                        this.menuContent = element
                    }}
                    style={{
                        ...style,
                        ...props.style,
                        ...(this.state.isOpen ? props.openStyle : {})
                    }}
                >
                    {children}
                </div>
            </div>
        )
    }
}

export default Dropdown
