import { Button, Col, Row } from "antd"
import React from "react"
import cx from "classnames"
import styles from "../style.module.css"
import { useZoomPanHelper, useStoreState } from "react-flow-renderer"
import Tooltip from "components-old/Tooltip"
import { ArrowExpand01Icon, ArrowShrink02Icon, CenterFocusIcon, MinusSignIcon, PlusSignIcon } from "components-old/icons"
import { useSetRecoilState } from "recoil"
import { editWorkflowNodeIdAtom } from "atoms/workflow.atom"

interface IBottomRightPanelProps {
  undoActions?: {
    canUndo?: boolean
    canRedo?: boolean
    undo: Function
    redo: Function
  }
  fullscreen: boolean
  setFullscreen: Function
}

function BottomRightPanel(props: IBottomRightPanelProps) {
  const { zoomIn, zoomOut, fitView } = useZoomPanHelper()
  const setEditNodeId = useSetRecoilState(editWorkflowNodeIdAtom)

  const [, , zoom] = useStoreState((state) => state.transform)
  return (
    <div
      className={styles.board_bottom_right}
      // style={{
      //   right: editNodeId ? 400 : 0
      // }}
    >
      <div className="flex gap-3">
        <div className={cx(styles.panel)}>
          <Row align="middle" style={{ height: "100%" }} gutter={6}>
            {props.fullscreen ? (
              <Col>
                <Tooltip title="Tela menor" placement="bottom" mouseEnterDelay={0}>
                  <Button type="text" icon={<ArrowShrink02Icon />} onClick={() => props.setFullscreen(false)} />
                </Tooltip>
              </Col>
            ) : (
              <Col>
                <Tooltip title="Tela cheia" placement="bottom" mouseEnterDelay={0}>
                  <Button type="text" icon={<ArrowExpand01Icon />} onClick={() => props.setFullscreen(true)} />
                </Tooltip>
              </Col>
            )}
            <Col>
              <Tooltip title="Centralizar" placement="bottom" mouseEnterDelay={0}>
                <Button type="text" icon={<CenterFocusIcon />} onClick={() => fitView()} />
              </Tooltip>
            </Col>
            {/* <Col>
              <Tooltip title="Centralizar" placement="bottom" mouseEnterDelay={0}>
                <Button type="text" icon={<i className="far fa-expand" />} onClick={() => fitView()} />
              </Tooltip>
            </Col> */}
            <Col>
              <Button type="text" icon={<MinusSignIcon />} onClick={() => zoomOut()} />
            </Col>
            <Col>
              <Button type="text" icon={<PlusSignIcon />} onClick={() => zoomIn()} />
            </Col>
            <Col>
              <strong style={{ width: 35, textAlign: "right" }}>{parseInt(zoom * 100 + "", 10)}%</strong>
            </Col>
          </Row>
        </div>
        <div className={cx(styles.panel)}>
          <div className="flex items-center h-full">
            <Tooltip title="Desfazer" placement="bottom" mouseEnterDelay={0}>
              <Button
                type="text"
                icon={<i className="far fa-rotate-left" />}
                disabled={!props.undoActions?.canUndo}
                onClick={() => {
                  setEditNodeId("")
                  props.undoActions?.undo()
                }}
              />
            </Tooltip>
            <Tooltip title="Refazer" placement="bottom">
              <Button
                type="text"
                icon={<i className="far fa-rotate-right" />}
                disabled={!props.undoActions?.canRedo}
                onClick={() => {
                  setEditNodeId("")
                  props.undoActions?.redo()
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomRightPanel
