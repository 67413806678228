import React from "react"
import { useWindowVirtualizer } from "@tanstack/react-virtual"
import { GenericResource } from "models/generic"
import { twMerge } from "tailwind-merge"
import { useTable } from "../useTable"
import { VirtualInfiniteTableProps } from "./VirtualInfiniteTable"
import VirtualInfiniteTableTable from "./VirtualInfiniteTableTable"

export function VirtualInfiniteTableDesktop<T extends GenericResource>(props: VirtualInfiniteTableProps<T>) {
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const { rows, fetchMoreOnBottomReached, table } = useTable<T>({
    ...props,
    tableContainerRef,
    scrollToTop: () => {
      rowVirtualizer.scrollToIndex?.(0)
    },
  })

  const rowVirtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 57, //estimate row height for accurate scrollbar dragging
    // getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    // scrollMargin: tableContainerRef.current?.offsetTop ?? 0,
    // scrollMargin: props.scrollMargin,
    overscan: props.overscan || 5,
  })

  return (
    <div
      className={twMerge("max-w-full overflow-x-auto", props.className, !props.local && "overflow-y-auto h-full")}
      // onScroll={(e) => {
      //   fetchMoreOnBottomReached(e.target as HTMLDivElement)
      // }}
      ref={tableContainerRef}
      style={{
        //   overflow: "auto", //our scrollable table container
        position: "relative", //needed for sticky header
        //   height: "600px", //should be a fixed height
      }}
    >
      {props.children}
      <VirtualInfiniteTableTable
        table={table}
        rowVirtualizer={rowVirtualizer}
        rows={rows}
        onClickRow={props.onClickRow}
        transparentHeader={props.transparentHeader}
        skeleton={(props?.isFetching && !props?.data?.length) || props.isFetchingNextPage}
      />
    </div>
  )
}
