import React from "react"
import { Col, Row, Select } from "antd"
import { SelectProps } from "antd/lib/select"
import { useStoreState } from "hooks"
import { TagItem } from "components-old/chat/TagInput"
import { convertColorToTagColor } from "pages/settings/tags/EditTag"
import CustomSelect from "components-old/forms/CustomSelect"
import { ITag } from "models/tags"

interface ITagsSelectProps extends SelectProps<any> {
  checkDisabled?: Function
}

function TagsSelect({ checkDisabled, ...props }: ITagsSelectProps) {
  const { all: tags } = useStoreState((state) => state.tags)

  return (
    <CustomSelect
      allowClear
      showSearch
      // optionFilterProp="children"
      // filterOption={(input, option: any) => {
      //   return option?.children?.props.children?.[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }}
      tagRender={({ label, closable, onClose, value }) => {
        const tag = tags.find((tag) => tag.id === value)
        return (
          <TagItem
            colors={tag?.color ? convertColorToTagColor(tag?.color) : undefined}
            onRemove={() => {
              onClose()
            }}
            alwaysShowRemove
            className="mr-1"
          >
            {tag?.name}
          </TagItem>
        )
      }}
      {...props}
    >
      {tags.map((tag: ITag) => (
        <Select.Option value={tag.id} key={tag.id} disabled={checkDisabled?.(tag)} data-search={tag.name}>
          <TagItem colors={convertColorToTagColor(tag.color)}>{tag.name}</TagItem>
          {/* {tag.name} */}
          {/* <div className="flex items-center gap-2">
            <span
              style={{
                color: tag.textColor,
                fontSize: 12,
                backgroundColor: tag.color,
                width: 20,
                height: 20,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i className="fas fa-tag"></i>
            </span>
            {tag.name}
          </div> */}
        </Select.Option>
      ))}
    </CustomSelect>
  )
}

export default TagsSelect
