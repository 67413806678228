import { endpoints } from "config/endpoints.config"
import { GenericResource, IGenericModel, generic } from "models/generic"
import { Notification } from "models/notifications"

export enum ActivityType {
  NOTE = "note",
  CHANGELOG = "changelog",
}

export interface ActivityComment {
  attachments?: {
    fileName: string
    name: string
    size: number
  }[]
  text?: string
  mentions?: Record<string, string>
  reactions?: Record<number, string>
  authorId: number
  id: string
  changelog?: Record<string, any>
  focused?: boolean
  meta?: {
    notification: {
      operatorIds: string[]
      payload: Partial<Notification>
    }
  }
}

export interface Activity extends GenericResource, ActivityComment {
  type: ActivityType
  operatorId: number
  dealId: string

  // COMMENTS
  comments?: ActivityComment[]
}

export const activities: IGenericModel<Activity> = {
  ...generic(endpoints.ACTIVITIES),
}
