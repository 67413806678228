import React, { useState, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
import io from "socket.io-client-legacy"
import config from "config"
import fromBase from "stores/baseStore"
import req from "lib/req"
import { stores } from "store"
import { messagesTypes } from "lib/constants"
import request from "lib/request"
import { verifyPermission, getAsset, parseMention, parseDeltaMessage, parseMentionToNotification, getUrlBase } from "lib/helper"
// import { mutate } from "swr"
import { store as easyStore, useStoreActions, useStoreState } from "hooks"
import { notification, message } from "antd"
import Avatar from "components-old/ui/Avatar"
import toast from "lib/toast"

const defaultSocket = { on: () => {} }
function InboxStore(store) {
    const [messages, setMessages] = useState({})
    const [connected, setConnected] = useState(true)
    const [reconnected, setReconnected] = useState(false)
    const [customers, setCustomers] = useState({})
    const [inboxCounts, setInboxCounts] = useState({})
    const [activeConversationId, setActiveConversationId] = useState()
    const [activeConversation, setActiveConversation] = useState({})
    const [socket, setSocket] = useState(defaultSocket)
    const [unread, setUnread] = useState(0)
    const [segmentId, setSegmentId] = useState()
    const [isTabOnFocus, setIsTabOnFocus] = useState(true)
    const [sync, setSync] = useState()
    const [presence, setPresence] = useState()
    // const channelState = useStoreState((state) => state.channels)
    // const channelActions = useStoreActions((actions) => actions.channels)

    const playSE = useCallback((type) => {
        if (stores.session.workspace.isAvailable) {
            document
                .getElementById(type)
                .play()
                .catch((e) => {
                    console.log("Erro ao tocar som", e)
                })
        }
    }, [])

    useEffect(() => {
        window.onfocus = () => stores.inbox.setIsTabOnFocus(true)
        window.onblur = () => stores.inbox.setIsTabOnFocus(false)
    }, [])

    /*
     * Get Messages
     */
    async function getMessages(payload) {
        const { isNextPage, ...configs } = payload
        stores.general.addLoader("get-message" + (payload.data.callId ? "-call" : ""))
        const response = await req(config.endpoints.MESSAGE, configs)
        stores.general.removeLoader("get-message" + (payload.data.callId ? "-call" : ""))
        const errors = response.error || response.errors
        const key = payload.data.callId ? "call-" + payload.data.callId : payload.data.id
        if (!errors) {
            if (isNextPage) {
                setMessages((messages) => ({
                    ...messages,
                    [key]: {
                        data: [...messages[key].data, ...response.data],
                        matchCount: response.matchCount,
                        totalCount: response.totalCount,
                    },
                }))
            } else {
                setMessages((messages) => ({
                    ...messages,
                    [key]: {
                        data: response.data || [],
                        matchCount: response.matchCount,
                        totalCount: response.totalCount,
                    },
                }))
            }
        }
        return response
    }

    function saveMessage(item, channelId, { notSetMessage } = {}) {
        // const newMessages = {
        //     ...messages,
        //     [channelId]: {
        //         ...messages[channelId],
        //         data: [item, ...messages[channelId].data],
        //     },
        // }

        console.log(notSetMessage)

        if (!notSetMessage) {
            setMessages((msgs) => ({
                ...msgs,
                [channelId]: {
                    ...msgs[channelId],
                    data: [item, ...((msgs[channelId] || {}).data || [])],
                },
            }))
        }
        store
            .save(
                { data: item },
                {
                    customEndpoint: config.endpoints.MESSAGE,
                    callback: (response) => {
                        // newMessages[channelId].data[
                        //     newMessages[channelId].data.length - length - 1
                        // ] = response
                        // newMessages[channelId].matchCount += 1
                        // newMessages[channelId].totalCount += 1
                        // setMessages({ ...newMessages })
                    },
                }
            )
            .catch((e) => {
                // setMessages((msgs) => ({
                //     ...msgs,
                //     [channelId]: {
                //         ...msgs[channelId],
                //         data: msgs[channelId].data.filter((msg) => msg.tempId != item.tempId),
                //     },
                // }))
                setMessages((msgs) => {
                    try {
                        const index = msgs[channelId]?.data.findIndex((x) => x.tempId == item.tempId)
                        if (index > -1) {
                            msgs[channelId].data[index].error = true
                        }
                    } catch (e) {
                        console.error(e)
                    }
                    return { ...msgs }
                })
            })
    }

    async function removeMessage(message, channelId) {
        const newMessages = { ...messages }

        console.log("newMessages", newMessages, message)

        const index = newMessages[channelId].data.findIndex((msg) => msg.id == message.id)
        newMessages[channelId].data[index].isDeleted = true

        setMessages(newMessages)

        try {
            return request.delete(config.endpoints.MESSAGE, { id: message.id })
        } catch (e) {
            console.error(e)
        }
    }

    /*
     * Schedule Message
     */
    async function saveScheduleMessage(model) {
        request.post(config.endpoints.SCHEDULE_MESSAGE, model)
    }

    /*
     * Get Customer Details
     */
    // async function getCustomerDetails(id) {
    //     stores.general.addLoader("get-customer")
    //     const response = await req(config.endpoints.CUSTOMER_DETAILS + "/" + id)
    //     stores.general.removeLoader("get-customer")
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setCustomers({
    //             // ...customers,
    //             [id]: response,
    //         })
    //     }
    // }

    /*
     * Get Customer Media
     */
    // async function getCustomerMedias(id) {
    //     stores.general.addLoader("get-customer-media")
    //     const response = await req(config.endpoints.CUSTOMER_MEDIAS + "?id=" + id)
    //     stores.general.removeLoader("get-customer-media")
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setCustomers({
    //             ...customers,
    //             [id]: response
    //         })
    //     }
    // }

    // /*
    //  * Get Quick Phrases
    //  */
    // async function getQuickPhrases(payload = {}) {
    //     const { isNextPage, ...configs } = payload
    //     stores.general.addLoader("get-quick-phrases")
    //     const response = await req(config.endpoints.QUICK_PHRASES, configs)
    //     stores.general.removeLoader("get-quick-phrases")
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setQuickPhrases(response.data || [])
    //     }
    // }

    // function saveQuickPhrase(model, cb) {
    //     store.save(
    //         { data: model },
    //         {
    //             customEndpoint: config.endpoints.QUICK_PHRASES,
    //             callback: response => {
    //                 store.saveItem(response, {
    //                     dataArray: quickPhrases,
    //                     setDataArray: setQuickPhrases
    //                 })
    //                 if (cb) cb()
    //             }
    //         }
    //     )
    // }

    // /*
    //  * Remove QuickPhrase
    //  */
    // async function removeQuickPhrase(payload, onBack) {
    //     const response = await req(config.endpoints.QUICK_PHRASES, {
    //         method: "delete",
    //         ...payload
    //     })
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setQuickPhrases(phrases => phrases.filter(e => e.id != response.id))
    //         onBack()
    //     }
    // }

    /*
     * Save property
     */
    // function saveProperty(id, model, configs) {
    //     store.save(
    //         { data: model, ...configs },
    //         {
    //             customEndpoint: config.endpoints.CUSTOMER_DETAILS + "/" + id + "/properties",
    //             callback: (response) => {
    //                 try {
    //                     store.saveItem(response, {
    //                         dataArray: customers[id].properties,
    //                         setDataArray: (saveFunc) => {
    //                             setCustomers((customers) => {
    //                                 customers[id].properties = saveFunc(customers[id].properties)
    //                                 return { ...customers }
    //                             })
    //                         },
    //                     })
    //                 } catch (e) {
    //                     console.log("erro ao salvar propriedade")
    //                 }
    //             },
    //         }
    //     )
    // }

    /*
     * Remove Property
     */
    // async function removeProperty(customerId, payload) {
    //     const response = await req(config.endpoints.CUSTOMER_DETAILS + "/" + customerId + "/properties", {
    //         method: "delete",
    //         ...payload,
    //     })
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setCustomers((customers) => {
    //             customers[customerId].properties = customers[customerId].properties.filter((e) => e.id != response.id)
    //             return { ...customers }
    //         })
    //     }
    //     return false
    // }

    /*
     * Save customer properties
     */
    // function updateCustomer(id, customerPlatformId, model, configs) {
    //     store.save(
    //         { data: { ...model, id }, ...configs },
    //         {
    //             customEndpoint: config.endpoints.CUSTOMER,
    //             callback: ({ id, ...response }) => {
    //                 store.saveItem({ id: customerPlatformId, ...response })
    //             },
    //         }
    //     )
    // }

    /*
     * Resolve Session
     */
    async function toggleResolved(customerPlatformId, endpoint, inboxItem) {
        const updatedLocal = { ...inboxItem, isFinished: !inboxItem.isFinished }
        store.saveItem(updatedLocal)
        if (store.activeConversation?.id == updatedLocal?.id) {
            store.setActiveConversation(updatedLocal)
        }
        stores.general.addLoader("resolving-" + customerPlatformId)
        const response = await req("/inbox/" + endpoint + "/" + customerPlatformId, {
            method: "post",
        })
        stores.general.removeLoader("resolving-" + customerPlatformId)
        // const errors = response.error || response.errors
        // if (!errors) {
        //     store.saveItem(response)
        // }
    }

    /*
     * Assign to agent
     */
    async function assignAgent(payload, inboxItem) {
        let { customerPlatformId, ...assigned } = payload.data
        store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.endpoints.TRANSFER_CALL_AGENT, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        const errors = response.error || response.errors
        if (errors) {
            store.saveItem(inboxItem)
        }

        return response
    }

    /*
     * Assign to group
     */
    async function assignGroup(payload, inboxItem) {
        let { customerPlatformId, ...assigned } = payload.data
        store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.endpoints.TRANSFER_CALL_GROUP, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        const errors = response.error || response.errors
        if (errors) {
            store.saveItem(inboxItem)
        }

        return response
    }

    /*
     * Assign to bot
     */
    async function assignBot(payload, inboxItem) {
        // let { customerPlatformId, ...assigned } = payload.data
        // store.saveItem({ ...inboxItem, ...assigned })

        stores.general.addLoader("transfer")
        const response = await req(config.endpoints.INIT_BOT, {
            method: "post",
            ...payload,
        })
        stores.general.removeLoader("transfer")

        // const errors = response.error || response.errors
        // if (errors) {
        //     store.saveItem(inboxItem)
        // }

        return response
    }

    /*
     * Get Inbox Counters
     */
    // async function getCounters(configs = {}) {
    //     const response = await req(config.endpoints.INBOX_COUNTERS, {
    //         method: "post",
    //         ...configs,
    //     })
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setInboxCounts(response || {})
    //     }
    // }

    async function saveTags(tags, inboxItem) {
        store.saveItem({ ...inboxItem, tags })
        await req(config.endpoints.SAVE_TAG, {
            method: "put",
            data: {
                tags,
                id: inboxItem.id,
                callId: inboxItem.callId,
            },
        })
    }

    /*
     * Toggle lido / não lido 👀
     */

    async function toggleReadNotRead(inboxItem) {
        request.post(config.endpoints.UNREAD + "/" + inboxItem.id, {
            hasNewMessages: !inboxItem.hasNewMessages,
        })
        store.saveItem({
            ...inboxItem,
            hasNewMessages: !inboxItem.hasNewMessages,
        })
    }

    /*
     * Toggle pendente ❗️❗️
     */

    async function togglePending(inboxItem) {
        const pending = inboxItem.showPending || ((inboxItem.showPending == null || inboxItem.showPending == undefined) && inboxItem.pendingAt)
        request.post(config.endpoints.PENDING + "/" + inboxItem.id, {
            showPending: !pending,
        })
        store.saveItem({ ...inboxItem, showPending: !pending })
    }

    /*
     * Favoritos ⭐
     */

    async function togglePin(inboxItem) {
        if (inboxItem.starred) {
            removeFavorite(inboxItem)
        } else {
            addFavorite(inboxItem)
        }
    }

    // async function getFavorites() {
    //     const response = await req(config.endpoints.FAVORITES)
    //     const errors = response.error || response.errors
    //     if (!errors) {
    //         setFavorites(response.data)
    //     }
    // }

    async function addFavorite(inboxItem) {
        store.saveItem({ ...inboxItem, starred: true })
        await req(config.endpoints.FAVORITES + "/" + inboxItem.id, {
            method: "post",
        })
    }

    async function removeFavorite(inboxItem) {
        store.saveItem({ ...inboxItem, starred: false })
        await req(config.endpoints.FAVORITES + "/" + inboxItem.id, {
            method: "delete",
        })
    }

    /*
     * Merge Users
     */
    // async function mergeUsers(stayId, leaveId) {
    //     stores.general.addLoader("merging")
    //     try {
    //         console.log("Prestes a chamar request", config.endpoints.MERGE, stayId, leaveId)
    //         const response = await request.put(config.endpoints.MERGE, {
    //             stayId,
    //             leaveId,
    //         })
    //         toast.success("Usuários mesclados")
    //         console.log(response.data)
    //         return response.data
    //     } catch (error) {
    //         console.log(error)
    //         return error
    //     } finally {
    //         stores.general.removeLoader("merging")
    //     }
    // }

    function filterBySegment(conversation, segmentId, selectedSubFilters) {
        const segment = easyStore.getState().segments.all.find((x) => x.id == segmentId) || {
            filters: {},
        }
        // const conversationGroup = groupsStore.all.find((x) => x.id == conversation.groupId)

        // Mine
        if ((segment.filters.my || selectedSubFilters?.my) && conversation.operatorId != stores.session.user.id) {
            return false
        }

        // Atribuido
        if (segment.filters.assigned && !conversation.operatorId) {
            return false
        }

        // Search Query
        if (
            selectedSubFilters?.query &&
            !conversation.name?.toLowerCase().includes(selectedSubFilters?.query?.toLowerCase()) &&
            !conversation.identifier?.toLowerCase().includes(selectedSubFilters?.query?.toLowerCase())
        ) {
            return false
        }

        // Novo usuario
        if (segment.filters.newCustomer && !conversation.newCustomer) {
            return false
        }

        // Pendente (Ultima mensagem do cliente)
        if (
            (segment.filters.showPending || selectedSubFilters?.showPending) &&
            !conversation.showPending
            // !(
            //     !conversation.lastMessage.operatorId &&
            //     conversation.lastMessage.type == messagesTypes.COMMON
            // )
        ) {
            return false
        }

        // Trás apenas mensagens com isFromIntegration (Ignora isHidden)
        if ((segment.filters.isFromIntegration || selectedSubFilters?.isFromIntegration) && !conversation.isFromIntegration) {
            return false
        }

        // Não mostra isHidden
        if (conversation.isHidden && !segment.filters.isFromIntegration) {
            return false
        }

        // Não Atribuido
        if ((segment.filters.assigned == false || selectedSubFilters?.assigned == false) && conversation.operatorId) {
            return false
        }
        // Abertos
        if (segment.filters.open && conversation.isFinished) {
            return false
        }
        // Fechados
        if (segment.filters.open == false && !conversation.isFinished) {
            return false
        }
        // Favoritos
        if ((segment.filters.starred || selectedSubFilters?.starred) && !conversation.starred) {
            return false
        }
        // Meus
        // if (segment.filters.my && conversation.operatorId != stores.session.user.id) {
        //     return false
        // }

        // console.log(conversation, segment.filters.channels, segment.filters.channels.every(x => x.id != conversation.platformId))
        // Canais específicos
        if (segment.filters.channels && segment.filters.channels.length && segment.filters.channels.every((x) => x.id != conversation.workspacePlatformId)) {
            return false
        }
        // Groups específicos
        if (segment.filters.groups && segment.filters.groups.length && segment.filters.groups.every((x) => x.id != conversation.groupId)) {
            return false
        }
        // Agents específicos
        if (segment.filters.agents && segment.filters.agents.length && segment.filters.agents.every((x) => x.id != conversation.operatorId)) {
            return false
        }
        // Tags específicos
        if (
            segment.filters.tags &&
            segment.filters.tags.length &&
            segment.filters.tags.every((x) => (conversation.tags || []).findIndex((y) => y == x.id) == -1)
        ) {
            return false
        }
        // Tags específicos AND
        if (
            segment.filters?.isAllTags &&
            segment.filters.tags &&
            segment.filters.tags.length &&
            !segment.filters.tags.map((x) => x.id).every((v) => conversation.tags.includes(v))
        ) {
            return false
        }

        // // Não é do grupo da conversa
        // if (
        //     workspace.accessLevel <= permissionsEnum.MASTER &&
        //     conversationGroup &&
        //     !conversationGroup.members.find((x) => x.id == user.id)
        // ) {
        //     return false
        // }

        return true
    }

    /*
     * Socket 🧲
     */
    function initSocket(socketUrl) {
        // console.log('oi', socketUrl)
        // clearSocket()
        let socketVar = defaultSocket
        if (socketUrl.search("localhost") == -1 && socketUrl.search("http") != -1) {
            socketVar = io(socketUrl)
            console.log("socketUrl", socketUrl, socketVar)
            setSocket(socketVar)
        }

        // Relativo somente a permissoes e não por segments
        function shouldSaveOnList(payload) {
            const userGroups = easyStore.getState().groups.all.filter((x) => x.members.find((memberId) => memberId == stores.session.user.id))

            const isOwner = stores.session.workspace.isOwner
            const isFollowing = payload.starred == true
            const isAllScope = verifyPermission("scope_all")
            // const isGroupScope = verifyPermission("scope_group")
            const isGroupScopeRestricted = verifyPermission("scope_group_restrict", {
                restrictive: true,
            })
            const isRestricted = verifyPermission("scope_user", {
                restrictive: true,
            })
            const isLimited = verifyPermission("scope_limited", {
                restrictive: true,
            })
            const noGroup = !payload.groupId
            // const noAgent = !payload.operatorId
            const isMine = payload.operatorId == stores.session.user.id

            const filterByGroup = (noGroup && !isRestricted && !isGroupScopeRestricted) || userGroups.findIndex((x) => x.id == payload.groupId) > -1

            const filterByOperator = isMine || (!isRestricted && !isLimited) || (isLimited && !payload.operatorId)
            // isGroupScope || (noAgent && !isRestricted)// || (filterByGroup && noAgent)

            return isOwner || isAllScope || isMine || isFollowing || (filterByGroup && filterByOperator)
        }

        socketVar.on("logout", (operator) => {
            console.log("ENTORU LOGOUT", operator)
            if (operator.id == stores.session.user.id) {
                toast.info("Você foi deslogado por um gestor...")
                setTimeout(() => {
                    stores.session.logout()
                }, 3000)
            }
        })

        socketVar.on("operator", (operator) => {
            try {
                easyStore.getActions().members.patchElementOnAll(operator)
                if (
                    operator.id == stores.session.user.id &&
                    operator.isAvailable != null &&
                    operator.isAvailable != undefined &&
                    stores.session.workspace.isAvailable != operator.isAvailable
                ) {
                    stores.session.patchWorkspace({
                        ...stores.session.workspace,
                        isAvailable: operator.isAvailable,
                    })
                }
            } catch (e) {
                console.error(e)
            }
        })
        socketVar.on("chat.presence", ({ workspacePlatformId, jid, status, customerPlatformId, operatorId }) => {
            console.log("presence", {
                workspacePlatformId,
                jid,
                status,
                customerPlatformId,
                operatorId,
            })
            stores.inbox.setPresence({
                workspacePlatformId,
                jid,
                status,
                customerPlatformId,
                operatorId,
            })
        })
        socketVar.on("channel.status", ({ id, status }) => {
            try {
                console.log("channel.status", id, status, typeof id, easyStore.getState().channels.all)
                const channel = easyStore.getState().channels.all.find((c) => c.id == id)
                if (channel) {
                    easyStore.getActions().channels.patchElementOnAll({ id, status })
                    stores.general.verifyAllChannelStatus()
                }
            } catch (e) {
                console.error(e)
            }
        })

        socketVar.on("channel.synced", (payload) => {
            if (stores?.inbox?.sync) {
                stores?.inbox?.sync?.hide?.()
            }
        })
        socketVar.on("chat.newCall", (payload) => {
            !import.meta.env?.PROD && console.log("chat.newCall", payload)

            if (payload?.syncData) {
                if (stores.inbox?.sync) {
                    stores.inbox?.sync?.hide?.()
                }
                const hide = toast.loading(`Sincronizando conversas... ${parseInt((payload?.syncData.count / payload?.syncData.total) * 100)}%`, 0)
                stores.inbox?.setSync({
                    hide,
                })
                if (payload?.syncData.count > 50) {
                    return
                }
            }

            if (shouldSaveOnList(payload) && filterBySegment(payload, stores.inbox.segmentId) && !payload?.syncData) {
                if (stores.session.user.notificationNewCall != false) {
                    playSE("pop_call")
                }
                if (
                    stores.session.user.webNotificationNewMessage != true &&
                    stores.session.user.webNotificationNewCall != false &&
                    stores.session.workspace.isAvailable
                ) {
                    if (Notification.permission == "granted") {
                        const notification = new Notification("Kinbox", {
                            body: `${payload?.name || "Visitante"}: ${parseDeltaMessage(payload?.lastMessage?.content)}`,
                            icon: "https://i.imgur.com/YqTEyNx.png",
                        })
                        notification.onclick = (e) => {
                            window.location.href = `${getUrlBase()}/inbox/all/` + payload?.customerPlatformId
                        }
                    }
                }
                // document
                //     .getElementById("pop_call")
                //     .play()
                //     .catch((e) => {
                //         console.log("Erro ao tocar som", e)
                //     })
            }
            // stores.general.setHasNewMessages(true)

            if (shouldSaveOnList(payload) && filterBySegment(payload, stores.inbox.segmentId)) {
                stores.inbox.saveItem({ ...payload, hasNewMessages: true }, { saveOnEnd: false })
                // stores.inbox.sortToFirst(payload)
            }
        })

        // Precisa mandar tudo senão da merda
        socketVar.on("chat.callStatus", (payload) => {
            !import.meta.env?.PROD && console.log("chat.callStatus", payload)

            const existingItem = stores.inbox.all.find((conversation) => conversation.id == payload.id)
            const item = {
                ...existingItem,
                ...payload,
            }

            // NOVIDADE PARA PROBLEMA DE ATUALIZAR CUSTOM FIELDS
            // if (stores.inbox.customers[payload.id]) {
            //     stores.inbox.setCustomers((values) => ({
            //         ...values,
            //         [payload.id]: {
            //             ...stores.inbox.customers[payload.id],
            //             contact: {
            //                 ...stores.inbox.customers[payload.id]?.contact,
            //                 ...payload.contact,
            //             },
            //         },
            //     }))
            // }

            if (stores.inbox.activeConversation.id == payload.id) {
                stores.inbox.setActiveConversation((conversation) => ({
                    ...conversation,
                    ...payload,
                }))
            }

            // Filtrar por grupo e operator
            if (shouldSaveOnList(item) && filterBySegment(item, stores.inbox.segmentId)) {
                stores.inbox.saveItem({
                    ...item,
                    // hasNewMessages: stores.inbox.activeConversationId != item.id,
                })
                stores.inbox.sortToFirst(item)
            } else {
                stores.inbox.removeFromAll(item)
            }

            if (payload.isDeleted) {
                stores.inbox.removeFromAll(item)
            }
        })
        socketVar.on("chat.newMessage", (payload) => {
            !import.meta.env?.PROD && console.log("chat.newMessage", payload)

            if (payload?.syncData) {
                if (stores.inbox?.sync) {
                    stores.inbox?.sync?.hide?.()
                }
                if (payload?.syncData.count < payload?.syncData.total) {
                    const hide = toast.loading(`Sincronizando mensagens... ${parseInt((payload?.syncData.count / payload?.syncData.total) * 100)}%`, 0)
                    stores.inbox?.setSync({
                        hide,
                    })
                }
            }

            // Atualizar na conversa
            const item =
                stores.inbox.activeConversation.id == payload.customerPlatformId
                    ? stores.inbox.activeConversation
                    : stores.inbox.all.find((x) => x.id == payload.customerPlatformId)
            if (item) {
                if (payload.newMessage.operatorId != stores.session.user.id && payload.newMessage.type != messagesTypes.SYSTEM) {
                    const isCustomerMsg = !payload.newMessage.operatorId && payload.newMessage.type == messagesTypes.COMMON
                    if (
                        !payload?.syncData &&
                        isCustomerMsg &&
                        payload?.newMessage?.Call?.lastCallMessageId &&
                        (stores.inbox.activeConversationId != payload.customerPlatformId || !stores.inbox.isTabOnFocus)
                    ) {
                        if (stores.session.user.notificationNewMessage) {
                            playSE("pop")
                        } else if (stores.session.user?.notificationNewMessageAssignedToMe) {
                            playSE("pop")
                        }
                        if (stores.session.user.webNotificationNewMessage && stores.session.workspace.isAvailable) {
                            if (Notification.permission == "granted") {
                                const notification = new Notification("Kinbox", {
                                    body: `Nova mensagem: ${parseDeltaMessage(payload?.newMessage?.content)}`,
                                    icon: "https://i.imgur.com/YqTEyNx.png",
                                })
                                notification.onclick = (e) => {
                                    window.location.href = `${getUrlBase()}/inbox/all/` + payload?.customerPlatformId
                                }
                            }
                        }
                    }
                    setUnread((un) => un + 1)
                }

                // Atualizar nas mensagens
                if (stores.inbox.messages[item.id]) {
                    // const index = stores.inbox.messages[item.id].data.findIndex(
                    //     (x) => x.content == payload.newMessage.content && !x.id
                    // )
                    const index = stores.inbox.messages[item.id].data.findIndex(
                        (x) => (x.tempId && x.tempId == payload.tempId) || (x.id && x.id == payload.newMessage.id)
                    )
                    if (index > -1) {
                        stores.inbox.setMessages((messages) => {
                            messages[item.id].data[index] = payload.newMessage
                            // messages[item.id].matchCount += 1
                            // messages[item.id].totalCount += 1
                            return { ...messages }
                        })
                    } else {
                        stores.inbox.setMessages((messages) => {
                            messages[item.id].data = [payload.newMessage, ...messages[item.id].data]
                            messages[item.id].matchCount += 1
                            messages[item.id].totalCount += 1
                            return { ...messages }
                        })
                    }
                }
            }
        })
        socketVar.on("chat.updateMessage", (payload) => {
            !import.meta.env?.PROD && console.log("chat.updateMessage", payload)

            // Atualizar na conversa
            const item =
                stores.inbox.activeConversation.id == payload.customerPlatformId
                    ? stores.inbox.activeConversation
                    : stores.inbox.all.find((x) => x.id == payload.customerPlatformId)
            if (item) {
                // Atualizar nas mensagens
                if (stores.inbox.messages[item.id]) {
                    // const index = stores.inbox.messages[item.id].data.findIndex(
                    //     (x) => x.content == payload.newMessage.content && !x.id
                    // )
                    const index = stores.inbox.messages[item.id].data.findIndex(
                        (x) => (x.tempId && x.tempId == payload.newMessage.tempId) || (x.id && x.id == payload.newMessage.id)
                    )
                    if (index > -1) {
                        console.log("1index", index, messages[item.id].data[index], {
                            ...messages[item.id].data[index],
                            ...payload.newMessage,
                        })
                        stores.inbox.setMessages((messages) => {
                            messages[item.id].data[index] = {
                                ...messages[item.id].data[index],
                                ...payload.newMessage,
                            }
                            return { ...messages }
                        })
                    }
                }
            }
        })
        socketVar.on("team-chat.message", (payload) => {
            !import.meta.env?.PROD && console.log("team-chat.message", payload)

            if (verifyPermission("team_chat")) {
                const mutate = easyStore.getState().general?.teamChatMutate?.mutate
                const addCount = easyStore.getState().general?.teamChatMutate?.addCount
                mutate?.((data) => {
                    const index = data?.[0].findIndex((message) => message.tempId == payload.tempId)
                    if (index > -1 && payload.tempId) {
                        data[0][index] = payload.message
                    } else {
                        data[0] = [payload.message, ...data[0]]
                    }
                    return data
                }, false)
                setTimeout(() => {
                    addCount?.()
                    if (stores.session.user.id != payload.message.Operator.id) {
                        if (!easyStore.getState().general?.teamChatVisible) {
                            notification.open({
                                key: payload.message.id,
                                message: payload.message.Operator.name,
                                // description: payload.message.text,
                                description: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parseMention(payload.message.text),
                                        }}
                                    />
                                ),

                                icon: (
                                    <Avatar
                                        name={payload.message.Operator.name}
                                        index={payload.message.Operator.id}
                                        fontSize={12}
                                        size={28}
                                        imgSrc={getAsset(payload.message.Operator.avatar)}
                                    />
                                ),
                                onClick: () => {
                                    easyStore.getActions().general?.setTeamChatVisible(true)
                                    notification.close(payload.message.id)
                                },
                                style: { cursor: "pointer" },
                            })
                        }
                        if (stores.session.user.notificationNewTeamMessage != false) {
                            playSE("pop_team_message")
                        }
                        if (stores.session.user.webNotificationNewTeamMessage) {
                            const notification = new Notification("Kinbox - Time", {
                                body: `${payload.message?.Operator?.name}: ${parseMentionToNotification(payload.message.text) || "Enviou mídia"}`,
                                icon: payload.message?.Operator?.avatar ? getAsset(payload.message?.Operator?.avatar) : "https://i.imgur.com/YqTEyNx.png",
                            })
                        }
                        // document
                        //     .getElementById("pop_team_message")
                        //     .play()
                        //     .catch((e) => {
                        //         console.log("Erro ao tocar som")
                        //     })
                    }
                }, 100)
            }

            // if (payload.message?.agent?.id != stores.session.user.id) {
            // mutate(generalState.teamChatKey, (data) => [payload.message, ...data], false)
            // }
        })
        socketVar.on("disconnect", (err) => {
            // if (err == "io server disconnect") {
            //     // the disconnection was initiated by the server, you need to reconnect manually
            //     stores.inbox.socket.connect()
            // }
            // toast("Desconectado do servidor.", "error")
            console.log("disconnect: DESCONECTOU DO SOCKET", err)
            setConnected(false)
        })
        socketVar.on("connect_error", (e) => {
            console.log("connect_error: Desconectado do servidor. Tentando reconectar...", "error")
            setConnected(false)
        })
        socketVar.on("connect_timeout", (e) => {
            console.log("connect_timeout: Desconectado do servidor. Tentando reconectar...", "error")
            setConnected(false)
        })
        socketVar.on("connect", (a) => {
            console.log("connect: CONECTOU DO SOCKET", a)
            setConnected(true)
        })
        socketVar.on("reconnect", () => {
            console.log("reconnect: Reconectado ao servidor.", "success")
            setConnected(true)
            setReconnected(true)
        })
        socketVar.on("WorkspaceActivation", ({ message }) => {
            toast.success(
                // "Seu ambiente agora está pronto para usar canais de WhatsApp",
                message
            )
        })
    }

    function clearSocket() {
        console.log("clear socker", stores.inbox.socket)
        if (stores.inbox.socket.disconnect) {
            console.log("DESCONECTANDO SOCKET", stores.inbox.socket, stores.inbox)
            stores.inbox.socket.disconnect()
            stores.inbox.setSocket(defaultSocket)
        }
    }

    return {
        ...store,
        messages,
        customers,
        inboxCounts,
        socket,
        initSocket,
        activeConversationId,
        activeConversation,
        saveScheduleMessage,
        connected,
        reconnected,
        segmentId,
        isTabOnFocus,
        sync,
        presence,

        // Actions
        getMessages,
        setSync,
        setPresence,
        setReconnected,
        setConnected,
        saveMessage,
        setMessages,
        removeMessage,
        // getCustomerDetails,
        // getQuickPhrases,
        // saveQuickPhrase,
        // removeQuickPhrase,
        // saveProperty,
        // updateCustomer,
        // removeProperty,
        assignAgent,
        assignGroup,
        assignBot,
        // getCounters,
        toggleResolved,
        saveTags,
        // favorites,
        // setFavorites,
        // getFavorites,
        addFavorite,
        removeFavorite,
        togglePin,
        setActiveConversationId,
        setActiveConversation,
        clearSocket,
        setSocket,
        unread,
        setUnread,
        toggleReadNotRead,
        togglePending,
        // filterBySegment,
        setSegmentId,
        setIsTabOnFocus,
        setCustomers,
    }
}

export default fromBase(config.endpoints.INBOX, InboxStore)
