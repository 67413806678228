import React, { useMemo } from "react"
import { getProps } from "pages/settings/channels"
import { useStore, useStoreState } from "hooks"
import { session } from "store"
import Avatar from "components-old/ui/Avatar"
import { getChannelType } from "lib/helper"
import { ContactIcon, Id01Icon, InboxIcon } from "components-old/icons"
import BaseNode, { BaseNodeProps } from "../../../actions/BaseNode"
import { Handle, Position } from "react-flow-renderer"
import styles from "../../../actions/style.module.css"
import cx from "classnames"
import BaseExpanderFields from "pages/settings/workflows/actions/BaseExpanderFields"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { useRecoilState } from "recoil"
import { transformStringToJSX } from "components-old/forms/InputVariable/VariableExtension/VariableTag"
import { variableAtom } from "atoms/app.atom"
interface FindConversationNodeProps extends BaseNodeProps {}

function FindConversationNode(props: FindConversationNodeProps) {
  const [dynamicVariablesDef] = useRecoilState(variableAtom)

  const { all: channels } = useStoreState((state) => state.channels)
  // const { all: members } = useStoreState((state) => state.members)
  // const { all: groups } = useStoreState((state) => state.groups)
  // const { all: tags } = useStoreState((state) => state.tags)
  const { workspace } = useStore(session)

  // const { fields } = useMemo(() => {
  //   // Channel
  //   let channelContent: any = null
  //   const channelId = props.data?.action?.conversation?.channelId

  //   if (channelId) {
  //     const channel = channels?.find((c) => c.id == channelId)

  //     if (channel) {
  //       const channelProps = getProps(channel, workspace)

  //       channelContent = (
  //         <div className="flex items-center gap-2 shadow px-2 rounded-full">
  //           <Avatar name={"WP"} index={"0"} fontSize={10} size={16} imgSrc={getChannelType(channel!.channelId).image} notRounded />
  //           <span className="truncate">{channelProps?.name}</span>
  //         </div>
  //       )
  //     }
  //   }

  //   // Member
  //   const member = members.find((m) => +m.id == props.data?.action?.conversation?.operatorId)

  //   // Group
  //   const group = groups.find((g) => +g.id == props.data?.action?.conversation?.groupId)

  //   let fields: {
  //     icon: React.ElementType
  //     value: React.ReactNode
  //     label?: string
  //   }[] = []
  //   const conversation = props.data?.action?.conversation
  //   if (conversation?.contactReference) {
  //     fields.push({
  //       icon: ContactIcon,
  //       // value: "Contato #" + conversation?.contactId,
  //       value: (
  //         <span>
  //           Contato <span className="text-sky-600">#{conversation?.contactReference}</span>
  //         </span>
  //       ),
  //       label: "Contato",
  //     })
  //   }
  //   if (conversation?.channelId) {
  //     fields.push({
  //       icon: InboxIcon,
  //       value: channelContent,
  //       label: "Canal",
  //     })
  //   }
  //   if (conversation?.operatorId) {
  //     fields.push({
  //       icon: UserIdVerificationIcon,
  //       value: (
  //         <div className="flex items-center gap-2 truncate shadow px-2 rounded-full">
  //           <AgentAvatar agent={member!} size={16} />
  //           <span className="truncate">{member?.name}</span>
  //         </div>
  //       ),
  //       label: "Agente atribuído",
  //     })
  //   }
  //   if (conversation?.groupId) {
  //     fields.push({
  //       icon: FolderShared01Icon,
  //       value: (
  //         <div className="flex items-center gap-2 truncate shadow px-2 rounded-full">
  //           <ColorEmojiIcon icon={group?.icon} size={16} />
  //           <span className="truncate">{group?.name}</span>
  //         </div>
  //       ),
  //       label: "Grupo",
  //     })
  //   }
  //   if (conversation?.tagIds) {
  //     fields.push({
  //       icon: Tag01Icon,
  //       value: (
  //         <div className="flex items-center gap-2">
  //           {conversation?.tagIds?.map((tagId: number) => {
  //             const tag = tags.find((g) => +g.id == tagId)
  //             if (!tag) return null
  //             return (
  //               <TagItem key={tagId} colors={convertColorToTagColor(tag?.color!)}>
  //                 {tag?.name}
  //               </TagItem>
  //             )
  //           })}
  //         </div>
  //       ),
  //       label: "Tags",
  //     })
  //   }

  //   return {
  //     fields,
  //   }
  // }, [channels, groups, members, props.data?.action?.conversation, tags, workspace])

  const { fields } = useMemo(() => {
    let fields: {
      icon: React.ElementType
      value: React.ReactNode
      label?: string
    }[] = []
    const conversation = props.data?.action?.conversation || {}

    // Channel
    let channelContent: any = null
    const channelId = props.data?.action?.conversation?.channelId

    if (channelId) {
      const channel = channels?.find((c) => c.id == channelId)

      if (channel) {
        const channelProps = getProps(channel, workspace)

        channelContent = (
          <div className="flex items-center gap-2 shadow px-2 rounded-full">
            <Avatar name={"WP"} index={"0"} fontSize={10} size={16} imgSrc={getChannelType(channel!.channelId).image} notRounded />
            <span className="truncate">{channelProps?.name}</span>
          </div>
        )
      }
    }

    if ((conversation as any).id) {
      fields.push({
        icon: Id01Icon,
        value: transformStringToJSX((conversation as any).id ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
        label: "ID",
      })
    }

    if ((conversation as any)?.contactReference) {
      fields.push({
        icon: ContactIcon,
        // value: "Contato #" + conversation?.contactReference,
        value: (
          <span>
            Contato <span className="text-sky-600">#{(conversation as any)?.contactReference}</span>
          </span>
        ),
        label: "Contato",
      })
    }
    // const contact = props.data?.action?.conversation?.contact?.id || {}
    // if ((contact as any).id) {
    //   fields.push({
    //     icon: Id01Icon,
    //     value: transformStringToJSX((contact as any).id ?? "", { variablesRecord: dynamicVariablesDef.variablesRecord }),
    //     label: "ID do contato",
    //   })
    // }
    // if ((contact as any).phone) {
    //   fields.push({
    //     icon: SmartPhone01Icon,
    //     value: transformStringToJSX((contact as any).phone, { variablesRecord: dynamicVariablesDef.variablesRecord }),
    //     label: "Telefone",
    //   })
    // }

    if ((conversation as any)?.channelId) {
      fields.push({
        icon: InboxIcon,
        value: channelContent,
        label: "Canal",
      })
    }

    return {
      fields,
    }
  }, [channels, dynamicVariablesDef.variablesRecord, props.data?.action?.conversation, workspace])

  return (
    <BaseNode
      {...props}
      title="Buscar conversa"
      handlesAfter={
        <div className="flex justify-end items-center h-6 mb-2 -mt-4">
          <span className="text-3xs -mr-2 text-content-200 whitespace-nowrap bg-red-50 dark:bg-[rgba(0,0,0,0.1)] text-red-600 px-2 rounded-full">
            Não encontrado
          </span>
          <Handle type="source" position={Position.Right} id="not_found" className={styles.node_handle_source_wrapper}>
            <div className={cx(styles.node_handle, "bg-red-500 shrink-0")} />
          </Handle>
        </div>
      }
    >
      <BaseExpanderFields fields={fields} category={CategoryEnum.CONVERSATION} />
    </BaseNode>
  )
}

export default FindConversationNode
