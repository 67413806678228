import { AssignIcon, CheckmarkCircle02Icon, GroupIcon, MoneyBag02Icon, SquareSingleFireIcon, Tag01Icon } from "components-old/icons"
import { WorkflowEntityDef } from "../../workflow.defs"
import { CategoryEnum, VariableEnum, ActionEnum } from "../../workflow.enums"
import { getDescriptionFormatted } from "../../workflow.helper"
import FindDealNode from "./find-deal/FindDeal.node"
import FindDealAction from "./find-deal/FindDeal.action"
import { InputType } from "lib/constants"
import { DealStatus } from "models/deals"
import UpdateDealNode from "./update-deal/UpdateDeal.node"
import UpdateDealAction from "./update-deal/UpdateDeal.action"
import CreateDealNode from "./create-deal/CreateDeal.node"
import CreateDealAction from "./create-deal/CreateDeal.action"

export const DealWorkflowDefs: WorkflowEntityDef = {
  /*********************************
   *  Category
   **********************************/
  category: {
    type: CategoryEnum.DEAL,
    label: "Negócio",
    icon: SquareSingleFireIcon,
    palette: "emerald",
  },
  /*********************************
   *  Fields
   **********************************/
  fields: [
    {
      label: "ID",
      icon: SquareSingleFireIcon,
      type: InputType.Text,
      name: "id",
      selectable: false,
    },
    {
      label: "Status",
      icon: CheckmarkCircle02Icon,
      type: InputType.Select,
      name: "status",
      options: [
        {
          label: "Aberto",
          value: DealStatus.OPEN,
        },
        {
          label: "Ganho",
          value: DealStatus.WON,
        },
        {
          label: "Perdido",
          value: DealStatus.LOST,
        },
      ],
    },
    {
      label: "Responsável",
      icon: AssignIcon,
      type: InputType.Assignee,
      name: "operatorId",
    },
    {
      label: "Grupo",
      icon: GroupIcon,
      type: InputType.Group,
      name: "groupId",
    },
    // {
    //   label: "Tags",
    //   icon: Tag01Icon,
    //   type: InputType.Tag,
    //   name: "tagIds",
    // },
    {
      label: "Valor",
      icon: MoneyBag02Icon,
      type: InputType.Decimal,
      name: "value",
    },
  ],
  /*********************************
   *  Actions
   **********************************/
  actions: [
    {
      type: ActionEnum.DEAL_FIND,
      name: "Buscar negócio",
      icon: SquareSingleFireIcon,
      node: FindDealNode,
      edit: FindDealAction,
      isReferenceable: true,
      variableCategory: CategoryEnum.DEAL,
      getDescription(dto) {
        const deal = dto.actionLog?.context?.deal?.id

        if (!deal) return "Negócio não encontrado."

        return getDescriptionFormatted({
          preffix: "O negócio ",
          suffix: "foi encontrado.",
          avatar: dto.actionLog?.context?.deal?.contact?.avatar || "",
          name: dto.actionLog?.context?.deal?.name,
          palette: dto.category.palette,
        })
      },
    },
    {
      type: ActionEnum.DEAL_CREATE,
      name: "Criar negócio",
      icon: SquareSingleFireIcon,
      variableCategory: CategoryEnum.DEAL,
      node: CreateDealNode,
      edit: CreateDealAction,
      isReferenceable: true,
      getDescription: (dto) => {
        return getDescriptionFormatted({
          preffix: "O negócio ",
          suffix: "foi criado.",
          avatar: dto.actionLog?.context?.deal?.contact?.avatar || "",
          name: dto.actionLog?.context?.deal?.name,
          palette: dto.category.palette,
        })
      },
    },
    {
      type: ActionEnum.DEAL_UPDATE,
      name: "Atualizar negócio",
      icon: SquareSingleFireIcon,
      variableCategory: CategoryEnum.DEAL,
      node: UpdateDealNode,
      edit: UpdateDealAction,
      getDescription: (dto) => {
        return getDescriptionFormatted({
          preffix: "O negócio ",
          suffix: "foi atualizado.",
          avatar: dto.actionLog?.context?.deal?.contact?.avatar || "",
          name: dto.actionLog?.context?.deal?.name,
          palette: dto.category.palette,
        })
      },
    },
  ],
  /*********************************
   *  Variables
   **********************************/
  variables: [
    {
      type: VariableEnum.DEAL_ID,
      category: CategoryEnum.DEAL,
      label: "ID",
      primitiveType: "string",
      description: "Identificador único do negócio.",
    },
    {
      type: VariableEnum.DEAL_CREATED_AT,
      category: CategoryEnum.DEAL,
      label: "Criado em",
      primitiveType: "string",
      description: "Data de criação do negócio.",
    },
    {
      type: VariableEnum.DEAL_JSON,
      category: CategoryEnum.DEAL,
      label: "$json",
      primitiveType: "json",
      description: "Negócio em formato JSON.",
      json: {
        name: "string",
        value: "number",
        lostReason: ["string"],
        lostReasonObs: "string",
        wonLostDate: "2024-10-01T19:39:19.098Z",
        lastStageChanged: "2024-10-01T19:39:19.098Z",
        contact: {
          id: 1,
          name: "string",
          avatar: "string",
          email: "string",
          phone: "string",
          createdAt: "2024-05-30T23:05:48.587Z",
        },
        conversation: {
          id: 1,
          platformId: 1,
          lastMessageAt: "2024-10-01T19:39:19.098Z",
          showPending: false,
          pendingAt: "string",
        },
        operatorId: 1,
        groupId: 1,
        pipelineId: "string",
        stageId: "string",
        authorId: 1,
        origin: "string",
        campaign: "string",
        position: 1,
        focusedCounts: 1,
        dueDate: "string",
        tagIds: [1, 2],
        status: "string",
        comments: 1,
        authorType: "string",
        timeInStage: {},
        products: [
          {
            id: "string",
            name: "string",
            price: 1,
            quantity: 1,
            discount: 1,
            discountType: "string",
            total: 1,
          },
        ],
        customFields: {
          cpf: {
            value: "string",
          },
        },
        tasks: {
          task_id: {
            cadenceId: "jf19tcmwO",
            done: true,
            required: true,
          },
        },
      },
    },
    {
      type: VariableEnum.DEAL_STATUS,
      category: CategoryEnum.DEAL,
      label: "Status do negócio",
      primitiveType: "string",
      description: "Status do negócio (won/lost/open)",
    },
    {
      type: VariableEnum.DEAL_IS_WON,
      category: CategoryEnum.DEAL,
      label: "Negócio ganho",
      primitiveType: "boolean",
      description: "Caso negócio esteja com status de ganho.",
    },
    {
      type: VariableEnum.DEAL_IS_LOST,
      category: CategoryEnum.DEAL,
      label: "Negócio perdido",
      primitiveType: "boolean",
      description: "Caso negócio esteja com status de perdido.",
    },
    {
      type: VariableEnum.DEAL_IS_OPEN,
      category: CategoryEnum.DEAL,
      label: "Negócio em andamento",
      primitiveType: "boolean",
      description: "Caso negócio esteja em andamento, ou seja nem ganho nem perdido ainda.",
    },
    {
      type: VariableEnum.DEAL_VALUE,
      category: CategoryEnum.DEAL,
      label: "Valor do negócio",
      primitiveType: "number",
      description: "Valor do negócio.",
    },
    {
      type: VariableEnum.DEAL_STAGE,
      category: CategoryEnum.DEAL,
      label: "Etapa do negócio",
      primitiveType: "string",
      description: "Qual etapa o negócio está no momento.",
    },
    {
      type: VariableEnum.DEAL_PIPELINE,
      category: CategoryEnum.DEAL,
      label: "Funil do negócio",
      primitiveType: "string",
      description: "Qual funil o negócio está.",
    },
  ],
}
