import { IMessage } from "atoms/conversation.atom"
import { messagesTypes } from "lib/constants"
import { isSameDay } from "lib/dates"
import { diffMinutes, verifyPermission } from "lib/helper"
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html"
import { IMessageProcessed } from "."
import { IConversation } from "models/conversations"
import toast from "lib/toast"
import { ICustomField } from "models/customFields"

export function getAuthor(msg: any, isGroup: boolean) {
  if (msg.type == messagesTypes.BOT) return "bot"
  if (msg.operatorId && (msg.type == messagesTypes.COMMON || msg.type == messagesTypes.PRIVATE)) return "agent"
  if (!msg.operatorId && (msg.type == messagesTypes.COMMON || msg.type == messagesTypes.EMAIL))
    return isGroup ? msg.contactName : "customer"
  return "system"
}

export const renderCustomWithCallback = function (customOp: any, contextOp: any, messageItem?: any) {
  let val = customOp.insert.value
  if (customOp.insert.type == "location") {
    const splitted = val.split("##")
    return `<a class="chat-location-item" href="${splitted[0]}" target="_blank">
            <img src="${splitted[1]}" />
            </a>`
  }
  if (customOp.insert.type == "contact") {
    return `<div class="chat-contact-item"><div><i>👤</i> <strong>${val.name}</strong></div><div><i>📞</i> ${val.phone}</div></div>`
  }
  if (customOp.insert.type == "audio") {
    return `<audio id="${val}" src="${val}" controls preload="metadata"> </audio>`
  }
  if (customOp.insert.type == "media_video") {
    return `<video id="${val}" width="300" height="168" controls><source src="${val}" type="video/mp4" /> </video>`
  }
  if (customOp.insert.type == "story") {
    return `<embed src="${val}" width="300" height="200" class="story-embed" />`
  }
  if (customOp.insert.type == "story_mention") {
    return `<div><a href="${val}" target="_blank"><button class="ant-btn ant-btn-primary"><span>Você foi mencionado em um Story</span></button></a></div>`
  }
  if (customOp.insert.type == "externalAdReply") {
    return `<div class="externalAdReply">
        <a href="${val.sourceUrl}" target="_blank">
            <div class="externalAdReply-title">${val.title}</div>
            <div class="externalAdReply-source">${val.sourceUrl}</div>
        </a>
        <a href="${val.sourceUrl}" target="_blank">
            <img src="${val.thumbnailUrl}" />
        </a>
        </div>`
  }
  if (customOp.insert.type == "empty_media") {
    return `<div> Midia vazia</div>`
  } else {
    const splitted = val.split("/")
    return `
        <a class="chat-file-item" href="${val}" target="_blank">
            <i class="fas fa-cloud-download"></i>
            <span>${messageItem?.fileName || splitted[splitted.length - 1]}</span>
            </a>`
  }
}

export const processMessageHtml = (message: IMessageProcessed) => {
  let html
  let isMediaWithText = false
  try {
    const parsedContent = JSON.parse(
      message.content.replaceAll("https://api.kinbox.com.br/api/assets/download/", "https://zapiko.s3-sa-east-1.amazonaws.com/")
    )
    // console.log("message", parsedContent)
    var converter = new QuillDeltaToHtmlConverter(parsedContent, {})

    if (message.isMedia && parsedContent?.length > 1) {
      isMediaWithText = true
    }

    converter.renderCustomWith((...args) => renderCustomWithCallback(...args, message))
    // converter.beforeRender((groupType, data) => {
    //     console.log(groupType, data)
    // })
    html = converter.convert()

    if (message.replyTo) {
      var converterReply = new QuillDeltaToHtmlConverter(JSON.parse((message.replyTo as any).content), {})
      converterReply.renderCustomWith((...args) => renderCustomWithCallback(...args, message))
      const htmlReplyTo = converterReply.convert()
      return {
        html,
        replyTo: message.replyTo,
        replyToHtml: htmlReplyTo,
        isMediaWithText,
      }
    }
  } catch (e) {
    html = message.content
    console.log(e)
  }

  return { html, isMediaWithText }
}

export const processMessages = (messages: IMessage[], isGroup: boolean) => {
  return (
    [...(messages || [])]
      .sort((a: any, b: any) => {
        try {
          return (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any)
        } catch (e) {
          console.log(e)
          return 0
        }
      })
      .map((x: any) => {
        if (x.replyTo && typeof x.replyTo == "string") {
          return { ...x, replyTo: { content: x.replyTo } }
        }
        return x
      })
      // Converter pra delta
      // .map((x: IMessage) => {
      //     let html
      //     let isMediaWithText = false
      //     try {
      //         const parsedContent = JSON.parse(
      //             x.content.replaceAll("https://api.kinbox.com.br/api/assets/download/", "https://zapiko.s3-sa-east-1.amazonaws.com/")
      //         )
      //         var converter = new QuillDeltaToHtmlConverter(parsedContent, {})

      //         if (x.isMedia && parsedContent?.length > 1) {
      //             isMediaWithText = true
      //         }

      //         converter.renderCustomWith((...args) => renderCustomWithCallback(...args, x))
      //         html = converter.convert()

      //         if (x.replyTo) {
      //             var converterReply = new QuillDeltaToHtmlConverter(JSON.parse((x.replyTo as any).content), {})
      //             converterReply.renderCustomWith((...args) => renderCustomWithCallback(...args, x))
      //             const htmlReplyTo = converterReply.convert()
      //             return {
      //                 ...x,
      //                 html,
      //                 replyTo: x.replyTo,
      //                 replyToHtml: htmlReplyTo,
      //                 isMediaWithText,
      //             }
      //         }
      //     } catch (e) {
      //         html = x.content
      //         console.log(e)
      //     }

      //     return { ...x, html, isMediaWithText }
      // })
      // Processar se mostra status ou se é continuação da mensagem do usuário
      .reduce((acc: any, msg: any) => {
        const prev = acc[acc.length - 1]
        let message = msg
        const prevAuthor = prev && getAuthor(prev, isGroup)
        const msgAuthor = getAuthor(msg, isGroup)

        const prevCreatedAt = prev && new Date(prev.createdAt)
        const createdAt = new Date(msg.createdAt)
        // console.log("prev", prev)
        if (
          prevAuthor == msgAuthor &&
          (prev || {}).operatorId == message.operatorId &&
          isSameDay(createdAt, prevCreatedAt) &&
          diffMinutes(createdAt, prevCreatedAt) < (message.operatorId ? 2 : 60)
        ) {
          acc[acc.length - 1] = { ...prev, hideStatus: true }
          return [...acc, { ...message, isSameUser: true }]
        }
        return [...acc, message]
      }, [])
      // Processar se é a última ou tem continuação de mensagem
      .map((msg: any, index: number, array: any) => {
        let message = msg
        let firstOfType = false
        const next = array[index + 1]
        const before = array[index - 1]
        const msgAuthor = getAuthor(msg, isGroup)
        if (before) {
          const previousAuthor = before && getAuthor(before, isGroup)
          if (!(previousAuthor == msgAuthor && before.operatorId == message.operatorId && message.type == before?.type)) {
            firstOfType = true
          }
        }
        if (!message.hideStatus) {
          return { ...msg, isLastOfType: true, firstOfType: true }
        }
        if (next) {
          const nextAuthor = next && getAuthor(next, isGroup)
          if (!(nextAuthor == msgAuthor && next.operatorId == message.operatorId && message.type == next?.type) || next.isMedia) {
            return { ...msg, isLastOfType: true, firstOfType }
          }
        } else {
          return { ...msg, isLastOfType: true, firstOfType }
        }
        return msg
      })
      // Colocar os divisores de dias e sessions
      .reduce((acc: any[], msg: IMessage) => {
        const prev = acc[acc.length - 1]
        const prevCreatedAt = prev && new Date(prev.createdAt)
        const createdAt = new Date(msg.createdAt)
        const shouldSeparateDay = !prevCreatedAt || !isSameDay(createdAt, prevCreatedAt)
        let newSession

        if (!!prev?.callId && !!msg?.callId && prev?.callId != msg?.callId) {
          newSession = {
            type: "NEW_SESSION",
            sessionId: msg?.callId,
            protocol: msg?.Call?.protocol,
            date: createdAt,
            idMessage: "sessionId_" + msg?.callId,
          } as any
        }

        if (shouldSeparateDay) {
          const today = isSameDay(createdAt, new Date())
          return [...acc, { type: "NEW_DAY", date: createdAt, today } as any, ...(newSession ? [newSession] : []), msg]
        }

        return [...acc, ...(newSession ? [newSession] : []), msg]
      }, [])
  )
}

export const verifyIfCanResolve = (dto: {
  conversation: IConversation
  sessionCustomFields: Record<string, { value: string }>
  contactCustomFields: Record<string, { value: string }>
  customFields: ICustomField[]
  userId: string
}) => {
  if (verifyPermission("chat_hide_messages_without_accept", { restrictive: true }) && dto.conversation?.operatorId != dto.userId) {
    throw new Error("Você só pode resolver essa conversa se for o atendente responsável.")
  }

  const contactFields = dto.contactCustomFields
  const sessionFields = dto.sessionCustomFields
  const customFields = dto.customFields
  const combinedFields = { ...contactFields, ...sessionFields }
  const sessionRequireds = customFields.filter(
    (cf) =>
      cf.entity == "session" && (cf.requiredToResolve || (cf.isDependent && cf.dependency && !!combinedFields?.[cf.dependency]?.value))
  )
  const contactRequireds = customFields.filter(
    (cf) =>
      cf.entity == "contact" && (cf.requiredToResolve || (cf.isDependent && cf.dependency && !!combinedFields?.[cf.dependency]?.value))
  )

  // Verifica se sessão tem campos obrigatórios
  if (sessionRequireds?.length && !dto.conversation?.isFinished) {
    sessionRequireds.forEach((cf) => {
      if (!sessionFields?.[cf.placeholder]?.value) {
        throw new Error(`Campo obrigatório ${cf.name} não foi setado.`)
      }
    })
  }

  // Verifica se contato tem campos obrigatórios
  if (contactRequireds?.length && !dto.conversation?.isFinished) {
    contactRequireds.forEach((cf) => {
      if (!contactFields?.[cf.placeholder]?.value) {
        throw new Error(`Campo obrigatório ${cf.name} não foi setado.`)
      }
    })
  }
}
