import { Button, Form, InputNumber, Radio, Select } from "antd"
import { FormInstance } from "antd/es/form"
import Tabs from "components-old/Tabs"
import Icon from "components-old/common/Icon"
import CustomSelect from "components-old/forms/CustomSelect"
import { LabelHelp } from "components-old/forms/LabelHelp"
import AgentsSelect from "components-old/ui/AgentsSelect"
import React from "react"
import { twMerge } from "tailwind-merge"
import RoundRobinGroupsList from "./RoundRobinGroupsList"

interface RoundRobinMembersListProps {
  fieldName: string[]
  formInstance: FormInstance<any>
}

function RoundRobinMembersList(props: RoundRobinMembersListProps) {
  const listClass = "flex gap-2 items-center flex-nowrap"
  const itemClass = "w-[90px] min-w-[90px] text-end truncate"
  const itemClassSmall = "w-[80px] min-w-[80px] text-end truncate"
  const selectBy = props.formInstance.getFieldValue([...props.fieldName, "selectBy"])

  return (
    <div className="p-3">
      <Form.Item label="Selecionar por" name={[...props.fieldName, "selectBy"]} required>
        <CustomSelect>
          <Select.Option value={"operator"}>Agente</Select.Option>
          <Select.Option value={"group"}>Grupo</Select.Option>
        </CustomSelect>
      </Form.Item>
      {/* <Form.Item name={[...props.fieldName, "groupedBy"]} required>
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="operator">Por agente</Radio.Button>
          <Radio.Button value="group">Por grupo</Radio.Button>
        </Radio.Group>
      </Form.Item> */}
      {!!selectBy && (
        <div className="shadow-lg p-2 rounded-xl">
          {selectBy === "group" ? (
            <RoundRobinGroupsList fieldName={props.fieldName} />
          ) : (
            <>
              <div className={twMerge(listClass, "mb-3 text-[13px]")}>
                <strong className="flex-1 w-full">Agentes participantes</strong>
                <strong className={itemClass}>
                  <LabelHelp
                    help={
                      <div className="space-y-2">
                        <p>Máximo de outras conversas abertas que o agente pode ter para ser elegível a receber essa sessão.</p>
                        {/* <p>Deixe 0 ou vazio para ilimitado.</p> */}
                        {/* <p className="text-yellow-400 text-xs">Somente considera conversas que foram atribuídas por essa ação.</p> */}
                        <p className="text-yellow-400 text-xs">Deixe 0 ou vazio para ilimitado.</p>
                      </div>
                    }
                  >
                    Capacidade
                  </LabelHelp>
                </strong>
                <strong className="w-6 min-w-[24px]"></strong>
              </div>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFields, setFieldsValue, getFieldsValue }) => {
                  return (
                    <Form.List name={[...props.fieldName, "operators"]}>
                      {(fields, { add, remove, move }) => {
                        return (
                          <div className="">
                            {fields?.map((field, i) => (
                              <div key={field.key} className={twMerge(listClass, "mb-1.5")}>
                                {/* AGENT */}
                                <div className="flex-1 w-full">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "id"]}
                                    fieldKey={(field as any).fieldKey}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Obrigatório",
                                      },
                                    ]}
                                    noStyle
                                    className="flex-1 w-full"
                                  >
                                    <AgentsSelect className="w-full" />
                                  </Form.Item>
                                </div>
                                {/* CAPACIDADE */}
                                <div className={itemClass}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "capacity"]}
                                    fieldKey={(field as any).fieldKey}
                                    noStyle
                                    className="flex-1"
                                  >
                                    <InputNumber controls={false} className="w-full input-end" step={1} min={0} />
                                  </Form.Item>
                                </div>
                                <div className="w-6 min-w-[24px]">
                                  <Button
                                    icon={<i className="far fa-trash-alt" />}
                                    size="small"
                                    shape="circle"
                                    style={{
                                      marginLeft: 0,
                                      padding: "0",
                                    }}
                                    type="text"
                                    onClick={() => {
                                      remove(field.name)
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                            <Button
                              type="dashed"
                              onClick={() => {
                                add({})
                              }}
                              // block
                              style={{
                                textAlign: "left",
                                height: 45,
                                borderWidth: 2,
                              }}
                              className="border-divider-100 !hover:border-primary rounded-lg mt-2"
                            >
                              <Icon name="fas fa-plus-circle text-primary" style={{ marginRight: 5 }} />
                              <span style={{ color: "var(--content-primary)" }}>Agente</span>
                            </Button>
                          </div>
                        )
                      }}
                    </Form.List>
                  )
                }}
              </Form.Item>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default RoundRobinMembersList
