import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"
import { ChannelType } from "./conversations"

export enum CampaignLeadType {
    CONTACT = "contact",
    FILE = "file",
}

export enum CampaignStatus {
    PROCESSING = "processing",
    SCHEDULED = "scheduled",
    EXECUTING = "executing",
    PAUSED = "paused",
    AWAITING_CHUNK = "awaiting-chunk",
    AWAITING_BUSINESS_HOURS = "awaiting-business-hours",
    AWAITING_OTHER_CAMPAIGN = "awaiting-other-campaign",
    CANCELED = "canceled",
    FAILED = "failed",
    DONE = "done",
    DONE_SENT = "done-sent",
}

export enum ChunkLeadStatus {
    READY = "ready",
    DUPLICATED = "duplicated",
    INCORRECT = "incorrect",
    AWAITING = "awaiting",
    SENT = "sent",
    RECEIVED = "received",
    READ = "read",
    FAILED = "failed",
    ANSWERED = "answered",
}

export const ChunkLeadStatusLabel = {
    [ChunkLeadStatus.READY]: "Pronto",
    [ChunkLeadStatus.DUPLICATED]: "Duplicado",
    [ChunkLeadStatus.INCORRECT]: "Formatação incorreta",
    [ChunkLeadStatus.AWAITING]: "Aguardando envio",
    [ChunkLeadStatus.SENT]: "Enviado",
    [ChunkLeadStatus.RECEIVED]: "Recebido",
    [ChunkLeadStatus.READ]: "Lido",
    [ChunkLeadStatus.FAILED]: "Falha",
    [ChunkLeadStatus.ANSWERED]: "Respondido",
}

// export enum ChunkStatus {
//     AWAITING = "awaiting",
//     EXECUTING = "executing",
//     FAILED = "failed",
//     DONE = "done",
// }

export interface Campaign {
    id: string
    createdAt: string
    name: string
    channelId?: string
    businessHoursId?: string
    channelType?: ChannelType
    leadType?: CampaignLeadType
    scheduledTo?: string
    originalScheduledTo?: string
    finishedAtPrediction?: string
    nextScheduledTo?: string
    leadsAssetUrl?: string
    assetsUrls?: string[]
    operatorId?: string
    operatorName?: string

    // @Prop({ type: Object })
    // @Allow()
    // conditions?: Conditions
    message?: string
    textRaw?: string
    active?: boolean
    status?: CampaignStatus
    chunksCount?: number
    leadsCount?: number
    secondsBetweenMessages?: number
    secondsBetweenChunks?: number
    chunkSize?: number
    template?: any
    progress?: number
    resultCounts?: {
        sent: number
        received: number
        read: number
        failed: number
        answered: number
        byChunkId: Record<
            string,
            {
                sent: number
                received: number
                read: number
                failed: number
            }
        >
    }
}

export interface ChunkLead {
    _id?: string
    id: string
    number: string
    placeholders: string[]
    status: ChunkLeadStatus
    sentAt: string
    answered: boolean
    callId?: string
}

// export interface Chunk {
//     leads: Record<string, ChunkLead>
//     resultCounts?: {
//         sent: number
//         failed: number
//         received: number
//         read: number
//     }
//     campaignId?: string
//     status?: ChunkStatus
//     order?: number
// }

export const campaignsv2: IGenericModel<Campaign> = {
    ...generic(endpoints.CAMPAIGN_V2),
}
