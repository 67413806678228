import { Button, Col, Row, Space } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import styles from "./style.module.css"

interface IMobileHeaderProps {
    backTo: string
    title: string | React.ReactNode
    className?: string
    actions?: React.ReactNode[]
}

function MobileHeader(props: IMobileHeaderProps) {
    return (
        <div className={cx(styles.mobile_header, "global-mobile-header", props.className)}>
            <Row justify="space-between">
                <Col>
                    <Space size="small">
                        <Link to={props.backTo}>
                            <Button
                                icon={<i className="far fa-arrow-left" />}
                                type="text"
                                style={{ marginLeft: -8 }}
                            ></Button>
                        </Link>
                        <span className={styles.mobile_header_title}>{props.title}</span>
                    </Space>
                </Col>
                <Col>{props.actions}</Col>
            </Row>
        </div>
    )
}

export default MobileHeader
