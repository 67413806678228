import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Prompt, useLocation } from "react-router-dom"
import { Form, Drawer, Button, Spin, Modal } from "antd"
import { FormInstance, FormProps } from "antd/lib/form"
import { AxiosResponse } from "axios"
import { useRecoilState, useSetRecoilState } from "recoil"
import { isMobileAtom, modalState } from "atoms/app.atom"
import DeleteDropdown from "components-old/DeleteDropdown"
import toast from "lib/toast"
import EditPage from "components-old/common/EditPage"
import styles from "./style.module.css"
import CustomDrawer from "components-old/ui/CustomDrawer"

type ISubmitBeforeConfig = {
  setSaving?: Function
  onClose?: Function
}

export interface IFormModal<T> {
  children: React.ReactNode | string
  onSubmit: (response: T) => any
  onDelete?: (id?: string) => Promise<any>
  onSubmitSuccess?: (response: T, onClose: Function) => void
  onSubmitBefore?: (model: T, onSubmit: Function, config?: ISubmitBeforeConfig) => void
  title?: string
  titleOverwrite?: string | boolean | React.ReactNode
  okText?: string | boolean
  okStyle?: React.CSSProperties
  successMessage?: string | boolean
  contentClassname?: string
  defaultValues?: Partial<T>
  form: FormInstance
  formProps?: FormProps
  type?: "drawer" | "modal" | "page"
  width?: number
  onBack?: Function
  hideFooterWhenSaved?: boolean
  hideFooter?: boolean
  okDisabled?: boolean
  item?: T & { id?: string }
  onReset?: Function
  saved?: boolean
  setSaved?: Function
  deleteButtonOnEditing?: boolean
  noPrompt?: boolean
  spinning?: boolean
  hideSuccessMessage?: boolean
  isEditing?: boolean
  okProps?: any
  drawerStyle?: React.CSSProperties
  requiredMark?: boolean
  extra?: React.ReactNode
  modalClassname?: string
  bodyStyle?: React.CSSProperties
  visible?: boolean
  setVisible?: (visible: boolean) => void
  drawerProps?: any
}

function FormModal<T extends Object>(props: IFormModal<T>) {
  const setModal = useSetRecoilState(modalState)
  const [_saved, _setSaved] = useState(true)
  const [saving, setSaving] = useState(false)
  const [excluding, setExcluding] = useState(false)
  const [_visible, _setVisible] = useState(true)
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [countLocation, setCountLocation] = useState(0)
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)
  const location = useLocation()

  const visible = useMemo(() => props.visible ?? _visible, [_visible, props.visible])
  const setVisible = useMemo(() => props.setVisible ?? _setVisible, [props.setVisible, _setVisible])

  const isEditing = props?.isEditing || !!props.item?.id

  const saved = useMemo(() => props.saved ?? _saved, [_saved, props.saved])
  const setSaved = useMemo(() => props.setSaved ?? _setSaved, [props.setSaved])

  useEffect(() => {
    if (props.item) {
      props.form.resetFields()
      props.onReset?.()
    }
  }, [props.item])

  const goBack = useCallback(() => {
    props.onBack ? props.onBack?.() : setModal(null)
  }, [props, setModal])

  const onClose = useCallback(
    (forcedBack?: boolean) => {
      if (props.type == "page" || props.type == "modal") {
        setVisible(false)
        goBack()
      } else if (drawerOpened) {
        if (!forcedBack && !saved) {
          goBack()
        } else {
          setModal(null)
          setVisible(false)
        }
      } else {
        goBack()
      }
    },
    [drawerOpened, goBack, props.type, saved, setModal]
  )

  useEffect(() => {
    if (props.type == "page") {
      if (countLocation > 1) {
        setModal(null)
      }
      setCountLocation((c) => c + 1)
    }
  }, [location, props.type])

  const onSubmit = async (model: any) => {
    setSaving(true)
    try {
      return props
        .onSubmit({ ...props.item, ...model })
        .then((response: AxiosResponse<T>) => {
          if (!props.hideSuccessMessage) {
            toast.success(props.successMessage || ((isEditing ? `${props.title} salvo.` : `${props.title} criado.`) as any))
          }
          setSaving(false)
          setSaved(true)

          if (!props.onSubmitSuccess) {
            onClose(true)
          } else {
            props.onSubmitSuccess?.(response as any, () => onClose(true))
          }
        })
        .catch((e: any) => {
          setSaving(false)
        })
    } catch (e) {
      console.log(11, e)
      setSaving(false)
    }
  }

  const content = (
    <>
      <Prompt
        when={!saved && !props.noPrompt && !isMobile && props.type != "page"}
        message="Você tem mudanças não salvas, tem certeza que deseja fechar?"
      />
      <div className={props.contentClassname}>
        <Form
          initialValues={props.item || props.defaultValues}
          layout="vertical"
          onFinish={(model) => {
            props.onSubmitBefore
              ? props.onSubmitBefore(model as T, onSubmit, {
                  setSaving,
                  onClose,
                })
              : onSubmit(model)
          }}
          requiredMark={props.requiredMark || false}
          form={props.form}
          {...props.formProps}
          onValuesChange={(changedValues, allValues) => {
            setSaved(false)
            props.formProps?.onValuesChange?.(changedValues, allValues)
          }}
        >
          {props.children}
        </Form>
      </div>
    </>
  )

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: props.type == "drawer" ? "row-reverse" : "row",
        justifyContent: "flex-end",
        padding: props.type == "modal" ? "2px 8px" : props.type == "drawer" ? "2px 12px" : "2px 0",
      }}
    >
      {props.deleteButtonOnEditing && props.onDelete && isEditing ? (
        <DeleteDropdown
          onDelete={() => {
            setExcluding(true)
            props
              .onDelete?.(props.item?.id)
              .then((e) => {
                toast.success("Excluído com sucesso.")
                onClose(true)
              })
              .finally(() => {
                setExcluding(false)
              })
          }}
          description="Você deleterá permanentemente. Não é possível desfazer. Tem certeza?"
        >
          <Button
            style={{ marginRight: 8, marginLeft: 15, minWidth: 90 }}
            className="normal"
            loading={excluding}
            icon={<i className={"fal fa-trash-alt"} />}
          >
            Excluir
          </Button>
        </DeleteDropdown>
      ) : (
        <div></div>
      )}
      <Button
        type="primary"
        htmlType="submit"
        onClick={props.form.submit}
        loading={saving}
        style={{ ...props.okStyle }}
        disabled={saved || props.okDisabled}
        {...props.okProps}
      >
        {props.okText || (!isEditing ? "Criar" : "Salvar")}
      </Button>
    </div>
  )

  // const footer = (
  //     <div
  //         style={{
  //             display: "flex",
  //             flexDirection: pageComponent == "drawer" ? "row-reverse" : "row",
  //             justifyContent: "space-between",
  //             padding:
  //                 pageComponent == "modal"
  //                     ? "2px 8px"
  //                     : pageComponent == "drawer"
  //                     ? "2px 12px"
  //                     : "2px 0",
  //         }}
  //     >
  //         <div>{props.actions}</div>
  //         <Button
  //             type="primary"
  //             htmlType="submit"
  //             onClick={props.form.submit}
  //             loading={saving}
  //             {...props.okProps}
  //         >
  //             {props.okText || "Salvar"}
  //         </Button>
  //     </div>
  // )

  if (props.type == "drawer" && !isMobile) {
    return (
      <CustomDrawer
        title={props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}
        width={props.width || 430}
        onClose={() => onClose()}
        open={visible}
        bodyStyle={{ paddingBottom: 80, ...(props.bodyStyle || {}) }}
        maskClosable
        closeIcon={<i className="fal fa-times" style={{ fontSize: 21 }} />}
        // getContainer={false}
        // contentWrapperStyle={{
        //   zIndex: 99,
        // }}
        style={props.drawerStyle}
        extra={props.extra}
        {...props.drawerProps}
      >
        <Spin spinning={!!props.spinning}>{content}</Spin>
        {!props.hideFooter && <div style={{ marginLeft: -12, marginTop: 35 }}>{(!props.hideFooterWhenSaved || !saved) && footer}</div>}
      </CustomDrawer>
    )
  }

  if (props.type == "modal" || isMobile) {
    return (
      <Modal
        title={props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}
        afterClose={() => goBack()}
        onCancel={() => onClose()}
        open={visible}
        maskClosable
        footer={footer}
        closeIcon={<i className="fal fa-times" style={{ fontSize: 18 }} />}
        zIndex={1001}
        width={props.width}
        className={props.modalClassname}
        bodyStyle={props.bodyStyle}
      >
        {content}
      </Modal>
    )
  }

  if (props.type == "page") {
    return (
      <EditPage
        title={props.titleOverwrite || `${!isEditing ? "Criar" : "Editar"} ${props.title?.toLocaleLowerCase()}`}
        footer={footer}
        onBack={() => goBack()}
        width={props.width || 600}
        // style={{
        //     paddingTop: "var(--header-height)",
        // }}
        className={styles.form_modal}
      >
        {content}
      </EditPage>
    )
  }

  return <div>{content}</div>
}

export default FormModal
