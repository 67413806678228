import React, { memo } from "react"
import { ExecutionLogItem } from "./AnalyticsExecutionModal"
import { twMerge } from "tailwind-merge"
import { colors } from "lib/colors"
import Tooltip from "components-old/Tooltip"
import IconTag from "components-old/shared/IconTag"
import { ActionEnum } from "../workflow.enums"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"

interface AnalyticsActionItemProps {
  item: ExecutionLogItem
  index: number
  setExecutionActionLogItem: (item: ExecutionLogItem) => void
}

// <CircleArrowUp02Icon className="text-divider-200 rounded-full w-8 h-8 p-1.5"
function AnalyticsActionItem(props: AnalyticsActionItemProps) {
  const [theme] = useRecoilState(themeAtom)

  return (
    <div
      className="flex-center flex-col w-full mt-1 gap-1 opacity-0"
      style={{
        animation: `slide-up-banner 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards`,
        animationDelay: `${50 * props.index}ms`,
      }}
    >
      {props.index > 0 && (
        <svg
          className="text-divider-200 rounded-full w-8 h-8 p-1.5"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d="M11 5.825L6.4 10.4L5 9l7-7l7 7l-1.4 1.425l-4.6-4.6V13h-2zM11 18v-3h2v3zm0 4v-2h2v2z"></path>
        </svg>
      )}
      <button
        className={twMerge(
          "flex flex-col w-full bordered dark:bg-[rgba(0,0,0,0.15)] dark:border-none hover:bg-base-200 dark:hover:bg-[rgba(0,0,0,0.25)] rounded-2xl py-4 px-4",
          props.item.id === "executing" && "cursor-default hover:bg-transparent"
        )}
        onClick={() => {
          if (props.item.id !== "executing") {
            props.setExecutionActionLogItem(props.item)
          }
        }}
        // style={
        //   {
        //     //   borderTop: "1px solid var(--divider)",
        //     //   boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        //   }
        // }
      >
        <div className="flex items-center gap-3">
          <div className={twMerge("flex", props.item.id === "executing" && "mt-0")}>
            <IconTag
              size="lg"
              icon={props.item.Icon}
              palette={props.item.category?.palette!}
              lightTone={{
                bg: props.item.actionId === ActionEnum.TRIGGER ? 0 : 200,
                text: props.item.actionId === ActionEnum.TRIGGER ? 600 : 700,
              }}
              customColors={{
                bg: props.item.actionRecord?.colors?.bg,
                text: props.item.actionRecord?.colors?.text,
              }}
            />
            {/* <span
            className="flex-center w-9 h-9 rounded-full text-md"
            style={
              props.item.id === "executing"
                ? { backgroundColor: "var(--base-100)" }
                : {
                    backgroundColor: colors[props.item.category?.palette]?.[600],
                  }
            }
          >
            <props.item.Icon
              style={{
                color: colors[props.item.category?.palette]?.[props.item.id === "executing" ? 600 : 50],
              }}
            />
          </span> */}
          </div>
          <div>
            <Tooltip title={props.item.timeFromNow}>
              <span
                style={{
                  color:
                    props.item.actionRecord?.colors?.text ||
                    colors[props.item.category?.palette][props.item.id === "executing" && theme === "dark" ? 400 : 700],
                }}
              >
                <b className="truncate text-[13px]">
                  {props.item?.label} {props.item.id !== "executing" && <span>#{props.item.actionId} -</span>}
                </b>{" "}
                <span className="text-secondary text-xs">{props.item.timeComplete}</span>
              </span>
            </Tooltip>
          </div>
        </div>
        {props.item.id !== "executing" && (
          <div
            className="mt-2 pt-3 w-full"
            style={{
              borderTop: "2px dashed var(--divider)",
            }}
          >
            <div className="break-words whitespace-pre-wrap">{props.item?.description}</div>
          </div>
        )}
      </button>
    </div>
  )
}

export default memo(AnalyticsActionItem)
