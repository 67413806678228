import { setSaveWorkflowHistory, workflowHistoryState, workflowNotSaveHistory } from "pages/settings/workflows/EditWorkflow"
import { InputField, WorkflowDefs } from "pages/settings/workflows/workflow.defs"
import { CategoryEnum } from "pages/settings/workflows/workflow.enums"
import { Elements, OnLoadParams } from "react-flow-renderer"
import { atom } from "recoil"

export const workflowActiveRunNodeAtom = atom<string>({
  key: "workflowActiveRunNodeAtom",
  default: "1",
})

export const workflowActiveRunEdgeAtom = atom<string>({
  key: "workflowActiveRunEdgeAtom",
  default: "",
})

export const workflowRunBotOnStepAtom = atom<boolean>({
  key: "workflowRunBotOnStepAtom",
  default: false,
})

export const workflowSelectedElementsAtom = atom<Elements | []>({
  key: "workflowSelectedElementsAtom",
  default: [],
})

export const workflowConnectingAtom = atom<string | undefined | boolean>({
  key: "workflowConnectingAtom",
  default: undefined,
})

export const workflowElementsAtom = atom<Elements | []>({
  key: "workflowElementsAtom",
  default: [],
  effects_UNSTABLE: [
    ({ setSelf, onSet }: any) => {
      onSet((newValue: any, _: any, isReset: any) => {
        if (!workflowNotSaveHistory) {
          workflowHistoryState?.push({ elements: newValue })
        }
        setSaveWorkflowHistory(false)
      })
    },
  ],
})

export const editWorkflowNodeIdAtom = atom<string | undefined>({
  key: "editWorkflowNodeIdAtom",
  default: "",
})

export const quickViewWorkflowNodeIdAtom = atom<string | undefined>({
  key: "quickViewWorkflowNodeIdAtom",
  default: "",
})

export const workflowVariablesAtom = atom<
  {
    nodeId: string
    nodeType: string
  }[]
>({
  key: "workflowVariablesAtom",
  default: [],
})

export interface ReceivedWebhookMonitor {
  query: any
  body: any
  origin: string
  referer: string
  webhookId: string
}

export const receivedWebhookMonitorAtom = atom<ReceivedWebhookMonitor | undefined>({
  key: "receivedWebhookMonitorAtom",
  default: undefined,
})

export const searchVisibleAtom = atom<boolean>({
  key: "searchVisibleAtom",
  default: false,
})

export const entityFieldsAtom = atom<
  Record<
    CategoryEnum,
    {
      record: Record<string, InputField>
      array: InputField[]
    }
  >
>({
  key: "entityFieldsAtom",
  default: WorkflowDefs.Fields,
})
