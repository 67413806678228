import React from "react"
import { twMerge } from "tailwind-merge"

interface RequestMethodColorProps {
  method: string
}

function RequestMethodColor(props: RequestMethodColorProps) {
  // const methodUppercase = props.method.toUpperCase()

  return (
    <span
      className={twMerge(
        "rounded-[5px] text-white font-medium text-[9px] px-1 bg-green-600",
        props.method === "POST" && "bg-orange-500 ",
        props.method === "PUT" && " bg-blue-500",
        props.method === "PATCH" && "bg-purple-500",
        props.method === "DELETE" && "bg-red-500"
      )}
    >
      {props?.method}
    </span>
  )
}

export default RequestMethodColor
