import { Col, Form, Row, Switch } from "antd"
import Tooltip from "components-old/Tooltip"
import React from "react"

interface IHorizontalSwitchProps {
    title: string
    description?: string
    name: string
    value?: string | React.ReactNode
    disabled?: boolean
    editable?: boolean
    onEdit?: any
    children?: React.ReactNode
}

function HorizontalSwitch(props: IHorizontalSwitchProps) {
    return (
        <Form.Item noStyle={!!props.children}>
            <Row align="middle" style={props.children ? { marginBottom: 10 } : {}}>
                <Col flex={1}>
                    <div>
                        <div style={{ fontSize: 14, fontWeight: 500 }}>
                            {props.title}
                            {props.description && (
                                <Tooltip title={props.description}>
                                    <i
                                        className="fal fa-question-circle"
                                        style={{
                                            fontSize: 14,
                                            marginLeft: 6,
                                            color: "var(--gray-40)",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </Col>
                <Col style={{ marginLeft: 20 }}>
                    <Form.Item name={props.name} noStyle valuePropName="checked">
                        <Switch disabled={props.disabled} />
                    </Form.Item>
                </Col>
            </Row>
            {props.children && (
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (getFieldValue(props.name) ? props.children : null)}
                </Form.Item>
            )}
        </Form.Item>
    )
}

export default HorizontalSwitch
