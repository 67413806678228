import React, { useCallback } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"

interface IUploadProps {
    children: React.ReactNode
    className?: string
    value?: any
    onChange?: Function
    dropOptions?: DropzoneOptions
    inline?: boolean
    style?: React.CSSProperties
}

function Uploader(props: IUploadProps) {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles?.length) {
            props.onChange?.(
                acceptedFiles.map((file: any) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        ...props.dropOptions,
    })

    return (
        <div
            {...getRootProps()}
            className={props.className}
            style={props.inline ? { display: "inline-block", ...props.style } : { ...props.style }}
        >
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : props.children}
        </div>
    )
}

export default Uploader
