import { Form } from "antd"
import AgentsSelect from "components-old/ui/AgentsSelect"
import GroupsSelect from "components-old/ui/GroupsSelect"
import TagsSelect from "components-old/ui/TagsSelect"
import BaseCustomFieldInputs from "../../actions/BaseCustomFieldInputs"

interface ConversationFormCoreProps {
  fieldName: string[]
}

function ConversationFormCore(props: ConversationFormCoreProps) {
  return (
    <>
      <Form.Item label="Atribuído" name={[...props.fieldName, "operatorId"]}>
        <AgentsSelect />
      </Form.Item>
      <Form.Item label="Grupo" name={[...props.fieldName, "groupId"]}>
        <GroupsSelect />
      </Form.Item>
      <Form.Item label="Tags" name={[...props.fieldName, "tagIds"]}>
        <TagsSelect mode="multiple" />
      </Form.Item>
      <BaseCustomFieldInputs fieldName={props.fieldName} entity={"session"} />
    </>
  )
}

export default ConversationFormCore
