import React from "react"
import InputVariableText, { InputVariableTextProps } from "./InputTextVariable"
import cx from "classnames"

interface InputJsonVariableProps extends InputVariableTextProps {}

function InputJsonVariable(props: InputJsonVariableProps) {
  return (
    <InputVariableText
      {...props}
      className={cx("font-mono text-2sm [&_.tiptap]:whitespace-pre", props.className)}
      json
      //   editorProps={{
      //     handleKeyDown: (view, event) => {
      //       if (event.key === "Tab") {
      //         event.preventDefault()
      //         view.dispatch(view.state.tr.insertText("\t"))
      //         return true
      //       }
      //       return false
      //     },
      //   }}
    />
  )
}

export default InputJsonVariable
