import { ReactRenderer } from "@tiptap/react"
import tippy from "tippy.js"

import VariablesDrop from "./VariablesDrop"
import { SuggestionOptions, SuggestionProps } from "@tiptap/suggestion"
import { VariableItem } from "pages/settings/workflows/workflow.defs"

export const getVariablesDrop = (props: SuggestionProps<any>) => {
  let popup
  let component = new ReactRenderer(VariablesDrop, {
    props: {
      ...props,
      hide: () => {
        popup[0].hide()
      },
    },
    editor: props.editor,
  })

  popup = (tippy as any)("body", {
    getReferenceClientRect: props.clientRect,
    appendTo: () => document.body,
    content: component.element,
    showOnCreate: true,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  })

  return {
    component,
    popup,
  }
}

const suggestions = (
  variables: VariableItem[],
  opts?: Omit<SuggestionOptions<any>, "editor">
): Omit<SuggestionOptions<any>, "editor"> | undefined => {
  return {
    items: ({ query }) => {
      // return variables.filter((item) => item?.label?.toLowerCase?.().startsWith(query.toLowerCase()))
      return variables
    },
    char: "{",
    allowedPrefixes: null,
    render: () => {
      let component
      let popup

      return {
        onStart: (props) => {
          // component = new ReactRenderer(VariablesDrop, {
          //   props: {
          //     ...props,
          //     hide: () => {
          //       popup[0].hide()
          //     },
          //   },
          //   editor: props.editor,
          // })

          // popup = (tippy as any)("body", {
          //   getReferenceClientRect: props.clientRect,
          //   appendTo: () => document.body,
          //   content: component.element,
          //   showOnCreate: true,
          //   interactive: true,
          //   trigger: "manual",
          //   placement: "bottom-start",
          // })
          const result = getVariablesDrop(props)
          component = result.component
          popup = result.popup

          if (!props.clientRect) {
            return
          }
        },

        onUpdate(props) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide()

            return true
          }

          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
    ...opts
  }
}

export default suggestions
