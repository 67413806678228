import { SortingState } from "@tanstack/react-table"
import request from "lib/request"
import { IPaginatedResponse } from "models/generic"
import { useMemo } from "react"
import {
  InfiniteData,
  InitialDataFunction,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutateFunction,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "react-query"

export interface UseInfiniteQueryGenericProps<T> {
  key: string | string[]
  endpoint?: string
  fetchData?: (options: { limit: number; offset: number; params: Record<string, any> }) => Promise<IPaginatedResponse<T>>
  params?: any
  paginated?: boolean
  enabled?: boolean
  staleTime?: number
  limit?: number
  initialData?: InfiniteData<any> | InitialDataFunction<InfiniteData<any>> | undefined
  onSuccess?: ((data: InfiniteData<any>) => void) | undefined
  options?: UseInfiniteQueryOptions<any, unknown, any, any, string | (string | string[])[]>
}

export type UseInfiniteQueryGenericReturn<T> = UseInfiniteQueryResult<
  {
    data: T[]
    nextPage: any
    total: number
  },
  unknown
> & {
  add: UseMutateFunction<
    {
      data: any
    },
    unknown,
    T,
    {
      previousResource: unknown
    }
  >
  flatData: T[]
  total: number
  remove: (resourceId: string) => Promise<void>
  update: UseMutateFunction<any, unknown, Partial<T> & { id: any }, any>
  addLocal: (newResource: T) => void
  removeLocal: (resourceId: string) => void
  updateLocal: (updatedResource: T) => void
  setAllLocal: (updatedResources: T[]) => void
  invalidate: () => Promise<any>
  queryKey: string | (string | string[])[]
}

function useInfiniteQueryGeneric<T extends { id: any }>(props: UseInfiniteQueryGenericProps<T>): UseInfiniteQueryGenericReturn<T> {
  const queryClient = useQueryClient() // Hook para interagir com o cache do React Query
  const key = useMemo(() => {
    if (props.paginated) {
      return [props.key, JSON.stringify(props.params)]
    }

    return props.key
  }, [props.key, props.paginated, props.params])

  const limit = props.limit || 20 // Defina o limite de itens por busca

  //  ██████╗ ██╗   ██╗███████╗██████╗ ██╗   ██╗
  // ██╔═══██╗██║   ██║██╔════╝██╔══██╗╚██╗ ██╔╝
  // ██║   ██║██║   ██║█████╗  ██████╔╝ ╚████╔╝
  // ██║▄▄ ██║██║   ██║██╔══╝  ██╔══██╗  ╚██╔╝
  // ╚██████╔╝╚██████╔╝███████╗██║  ██║   ██║
  //  ╚══▀▀═╝  ╚═════╝ ╚══════╝╚═╝  ╚═╝   ╚═╝
  //
  const queryResult = useInfiniteQuery(
    key,
    async ({ pageParam = 0 }) => {
      const offset = pageParam
      let data

      if (props.endpoint) {
        const response = await request.get<IPaginatedResponse<T>>(props.endpoint, {
          params: {
            limit,
            offset,
            ...props.params,
          },
        })
        data = response.data
      } else {
        data = await props.fetchData!({
          limit,
          offset,
          params: props.params,
        })
      }

      return {
        data: data.data,
        nextPage: data.data.length < limit ? null : offset + limit, // Atualiza o offset para a próxima busca
        total: data.totalCount,
      }
    },
    {
      getNextPageParam: (lastPage: any) => lastPage?.nextPage,
      enabled: props.enabled,
      // keepPreviousData: true,
      // staleTime: props.staleTime || 1000,
      initialData: props.initialData,
      initialDataUpdatedAt: Date.now() - 10000,
      onSuccess: props.onSuccess,
      ...props.options,
    }
  )

  const flatData = useMemo(() => {
    return queryResult.data?.pages?.flatMap?.((page) => page.data) || []
  }, [queryResult.data?.pages])

  // Add Local
  const addLocal = (newResource: T) => {
    queryClient.setQueryData(key, (old: any) => {
      old.pages[0].data.unshift(newResource) // Assume que você quer adicionar no início da lista
      return old
    })
  }

  // Remove Local
  const removeLocal = (resourceId: string) => {
    queryClient.setQueryData<IPaginatedResponse<T>>(key, (oldData: any) => {
      return {
        ...oldData,
        // Atualize o array de páginas removendo a atividade deletada
        pages: oldData?.pages?.map((page) => ({
          ...page,
          data: page.data.filter((resource) => resource.id !== resourceId),
        })),
      }
    })
  }

  // Update Local
  const updateLocal = (updatedResource: T) => {
    queryClient.setQueryData<IPaginatedResponse<T>>(key, (oldData: any) => {
      return {
        ...oldData,
        pages: oldData?.pages?.map((page) => ({
          ...page,
          data: page.data.map((resource) => (resource.id === updatedResource.id ? { ...resource, ...updatedResource } : resource)),
        })),
      }
    })
  }

  // Set All Local
  const setAllLocal = (updatedResources: T[]) => {
    queryClient.setQueryData<IPaginatedResponse<T>>(key, (oldData: any) => {
      return {
        ...oldData,
        pages: oldData?.pages?.map((page) => ({
          ...page,
          data: updatedResources,
        })),
      }
    })
  }

  //  █████╗ ██████╗ ██████╗
  // ██╔══██╗██╔══██╗██╔══██╗
  // ███████║██║  ██║██║  ██║
  // ██╔══██║██║  ██║██║  ██║
  // ██║  ██║██████╔╝██████╔╝
  // ╚═╝  ╚═╝╚═════╝ ╚═════╝
  //
  const addResourceMutation = useMutation(
    async (newResource: T) => {
      // Substitua com a sua função de adição real aqui
      return request.post(props.endpoint!, { ...newResource })
    },
    {
      // Quando a mutação começa, atualiza imediatamente a UI de forma otimista
      onMutate: async (newResource) => {
        await queryClient.cancelQueries(key)

        // Snapshot dos dados atuais para possível rollback
        const previousResource = JSON.parse(JSON.stringify(queryClient.getQueryData(key)))

        // Atualiza otimisticamente a UI com a nova atividade
        addLocal(newResource)
        // queryClient.setQueryData(key, (old: any) => {
        //   old.pages[0].data.unshift(newResource) // Assume que você quer adicionar no início da lista
        //   return old
        // })

        return { previousResource }
      },
      // Se a mutação falhar, usa o snapshot dos dados para rollback
      onError: (err, newResource, context: any) => {
        queryClient.setQueryData(key, context?.previousResource)
      },
      // Em caso de sucesso, invalida as queries para recarregar os dados atualizados do servidor
      onSuccess: () => {
        queryClient.invalidateQueries(key)
      },
    }
  )

  // ██████╗ ███████╗███╗   ███╗ ██████╗ ██╗   ██╗███████╗
  // ██╔══██╗██╔════╝████╗ ████║██╔═══██╗██║   ██║██╔════╝
  // ██████╔╝█████╗  ██╔████╔██║██║   ██║██║   ██║█████╗
  // ██╔══██╗██╔══╝  ██║╚██╔╝██║██║   ██║╚██╗ ██╔╝██╔══╝
  // ██║  ██║███████╗██║ ╚═╝ ██║╚██████╔╝ ╚████╔╝ ███████╗
  // ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝ ╚═════╝   ╚═══╝  ╚══════╝
  //
  const deleteResourceMutation = useMutation((resourceId: string) => request.delete(props.endpoint + "/" + resourceId), {
    onMutate: async (resourceId) => {
      await queryClient.cancelQueries(key)

      const previousResource = JSON.parse(JSON.stringify(queryClient.getQueryData(key)))

      removeLocal(resourceId)
      // queryClient.setQueryData<IPaginatedResponse<T>>(key, (oldData: any) => {
      //   return {
      //     ...oldData,
      //     // Atualize o array de páginas removendo a atividade deletada
      //     pages: oldData?.pages?.map((page) => ({
      //       ...page,
      //       data: page.data.filter((resource) => resource.id !== resourceId),
      //     })),
      //   }
      // })

      return { previousResource }
    },
    onError: (err, resourceId, context: any) => {
      queryClient.setQueryData(key, context?.previousResource)
    },
    onSettled: () => {
      queryClient.invalidateQueries(key)
    },
  })

  // ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  // ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  // ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  // ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  // ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
  //  ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
  //
  const updateResourceMutation = useMutation((resource: T) => request.patch(props.endpoint + "/" + resource.id, resource), {
    onMutate: async (updatedResource) => {
      await queryClient.cancelQueries(key)

      const previousResource = JSON.parse(JSON.stringify(queryClient.getQueryData(key)))

      // Atualiza otimisticamente a UI com a atividade atualizada
      updateLocal(updatedResource)
      // queryClient.setQueryData<IPaginatedResponse<T>>(key, (oldData: any) => {
      //   return {
      //     ...oldData,
      //     pages: oldData?.pages?.map((page) => ({
      //       ...page,
      //       data: page.data.map((resource) => (resource.id === updatedResource.id ? { ...resource, ...updatedResource } : resource)),
      //     })),
      //   }
      // })

      return { previousResource }
    },
    onError: (err, newResource, context: any) => {
      // Reverte para o estado anterior em caso de erro
      queryClient.setQueryData(key, context?.previousResource)
    },
    onSettled: () => {
      // Refetch ou invalida as queries relacionadas para atualizar os dados
      queryClient.invalidateQueries(key)
    },
  })

  return {
    ...queryResult,
    queryKey: key,
    flatData,
    total: queryResult.data?.pages?.[0]?.total || 0,
    add: addResourceMutation.mutate,
    remove: deleteResourceMutation.mutate as any,
    update: updateResourceMutation.mutate as any,
    addLocal,
    removeLocal,
    updateLocal,
    setAllLocal,
    invalidate: () => {
      setAllLocal([])
      queryClient.invalidateQueries(key, { refetchActive: false, refetchInactive: false })
      return queryResult.refetch({ refetchPage: (page, index) => index === 0 })
    },
  }
}
export default useInfiniteQueryGeneric
