import React, { useEffect } from "react"
import { Redirect, useLocation } from "react-router-dom"

function RedirectLogin() {
  const location = useLocation()

  useEffect(() => {
    const path = (location?.pathname || "") + (location?.search || "")
    if (path != "/") {
      localStorage.setItem("redirect", path)
    }
  }, [location])

  return <Redirect to={"/auth/login"} />
}

export default RedirectLogin
