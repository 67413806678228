import React, { useEffect } from "react"
import { FormInstance } from "antd/es/form"
import shortid from "shortid"
import CopyableInput from "components-old/forms/CopyableInput"
import { Button, Form } from "antd"
import { Structure04Icon } from "components-old/icons"
import { useSetRecoilState } from "recoil"
import { modalState } from "atoms/app.atom"
import DetermineStructureWebhookModal from "./DetermineStructureWebhookModal"

interface EditWebhookReceivedProps {
  formInstance: FormInstance
  actionFieldName: string[]
  syncElements: Function
}

function EditWebhookReceived(props: EditWebhookReceivedProps) {
  const setModal = useSetRecoilState(modalState)
  const urlFormName = [...props.actionFieldName, "webhookUrl"]

  useEffect(() => {
    if (!props.formInstance?.getFieldValue(urlFormName)) {
      props.formInstance.setFields([
        {
          name: urlFormName,
          value: "https://webhook.kinbox.com.br/v3/inbound/" + shortid.generate(),
        },
      ])
      props.syncElements()
    }
  }, [])

  return (
    <div>
      <CopyableInput
        inputProps={{
          disabled: true,
          className: "text-2xs",
        }}
        copyableValue={props.formInstance?.getFieldValue(urlFormName)}
        name={urlFormName}
        className="mb-1"
        label="Endereço Url"
      />
      <Form.Item name={[...props.actionFieldName, "schema"]}>
        <div className="flex justify-end w-full">
          <Button
            type="text"
            icon={<Structure04Icon className="!w-4 !h-4" />}
            size="small"
            className="text-xs mt-1"
            onClick={() => {
              setModal(
                <DetermineStructureWebhookModal
                  url={props.formInstance?.getFieldValue(urlFormName)}
                  onBack={function (): void {
                    setModal(null)
                  }}
                  onSubmit={(schema) => {
                    props.formInstance.setFields([
                      {
                        name: [...props.actionFieldName, "schema"],
                        value: schema,
                      },
                    ])
                    props.syncElements()
                  }}
                />
              )
            }}
          >
            Determinar estrutura
          </Button>
        </div>
      </Form.Item>
      {/* <div className="relative group">
        <Form.Item label="Endereço" name={urlFormName} className="mb-1">
          <Input disabled className="text-2xs" onClick={copyCode} />
        </Form.Item>
        <Tooltip title="Copiar">
          <Button
            icon={<Copy01Icon className="w-5 h-5" />}
            onClick={copyCode}
            className="my-2 text-xs absolute top-7 right-2 rounded-lg"
            size="small"
          />
        </Tooltip>
      </div> */}
    </div>
  )
}

export default EditWebhookReceived
