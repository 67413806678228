import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IWebhook {
    id: string
    webhook: string
    email: string
    createdAt: string
    WebhookDetails: any
    lastUpdateAt: string
    subscriptions: {
        "message:received": boolean,
        "session:status:changed": boolean,
        "person:changed": boolean
    }
    workspaceId?: string
}

export const webhooks: IGenericModel<IWebhook> = {
    ...generic(endpoints.WEBHOOK),
}