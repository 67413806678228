import React, { useMemo } from "react"
import { INodeData } from "models/bots"
import { FormInstance } from "antd/lib/form"
import { Elements, FlowElement } from "react-flow-renderer"
import { twMerge } from "tailwind-merge"
import { animated, useSpring } from "react-spring"
import { WorkflowActionsItem, WorkflowDefs, WorkflowNodeData } from "../workflow.defs"
import cx from "classnames"
import { colors } from "lib/colors"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"
import { ActionEnum } from "../workflow.enums"
export interface BaseEditActionChildrenProps {
  form: FormInstance
  editNode: FlowElement<WorkflowNodeData>
  syncElements: () => void
  actionFieldName: string[]
}
export interface BaseEditActionProps {
  form: FormInstance
  editNode?: FlowElement<WorkflowNodeData>
  syncElements: () => void
  elements: Elements<INodeData>
}

function BaseEditAction(props: BaseEditActionProps) {
  const [theme] = useRecoilState(themeAtom)

  const springProps = useSpring({
    opacity: !!props.editNode ? 1 : 0,
    transform: !!props.editNode ? "translateX(0%)" : "translateX(100%)",
    config: { duration: 100 },
  })

  const { actionFieldName, actionDef, EditComponent, category } = useMemo(() => {
    const nodeIndex = props.elements?.findIndex((el) => el.id === props.editNode?.id)
    const actionDef = WorkflowDefs.ActionRecord[props.editNode?.type!] as WorkflowActionsItem
    const category = actionDef?.variableCategory ? WorkflowDefs.CategoryRecord[actionDef.variableCategory] : null
    const EditComponent = !!props.editNode ? actionDef.edit : null
    return { actionFieldName: ["elements", nodeIndex, "data", "action"], actionDef, EditComponent, category }
  }, [props.editNode?.id, props.editNode?.type, props.elements])

  if (actionDef?.type === ActionEnum.MAKE_REQUEST && !!props.editNode && !!EditComponent) {
    return <EditComponent actionFieldName={actionFieldName} syncElements={props.syncElements} editNode={props.editNode} form={props.form} />
  }

  return (
    <animated.div
      className={twMerge(
        "absolute w-96 h-[calc(100%-32px)] bg-base-100 rounded-lg right-4 top-4 z-10 shadow-lg overflow-hidden flex flex-col select-none base-edit-action",
        !props.editNode && "pointer-events-none"
      )}
      style={springProps}
    >
      {/* <div className="p-4 text-lg font-medium pb-0">{actionDef?.name}</div> */}
      <div className={cx("w-full flex items-center justify-center text-lg relative g_base_edit_action overflow-hidden")}>
        <div
          className="flex justify-start items-center w-full gap-2 text-white rounded-t-lg py-3.5 px-3"
          style={
            actionDef?.type === ActionEnum.TRIGGER
              ? {
                  color: colors["sky"][600],
                }
              : {
                  backgroundColor: actionDef?.colors?.bg || colors[category?.palette!]?.[theme === "dark" ? 800 : 700],
                  color: actionDef?.colors?.text,
                }
          }
        >
          <div
            className="w-10 h-10 rounded-xl flex-center"
            // style={{
            //   backgroundColor: actionProps?.color,
            // }}
          >
            {actionDef?.icon && (
              <actionDef.icon
                className="text-white w-7 h-7"
                style={
                  actionDef?.type === ActionEnum.TRIGGER
                    ? {
                        color: actionDef?.colors?.text || colors[category?.palette!]?.[600],
                        // backgroundColor: actionDef?.color,
                        // borderRadius: "20px",
                        // padding: "5px",
                      }
                    : {
                        color: actionDef?.colors?.text,
                      }
                }
              />
            )}
          </div>
          <span
            className="font-semibold"
            style={
              actionDef?.type === ActionEnum.TRIGGER
                ? {
                    fontWeight: 700,
                    color: "var(--content-100)",
                  }
                : {}
            }
          >
            {actionDef?.name}
          </span>
          {props.editNode?.type !== ActionEnum.TRIGGER && <span className="opacity-60 text-xs font-medium">#{props.editNode?.id}</span>}
        </div>
        <div className="after"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372 15" className="absolute w-[calc(100%_+_8px)] -left-2 -bottom-2">
          <path
            d="M349.8 1.4C334.5.4 318.5 0 302 0h-2.5c-9.1 0-18.4.1-27.8.4-34.5 1-68.3 3-102.3 4.7-14 .5-28 1.2-41.5 1.6C84 7.7 41.6 5.3 0 2.2v8.4c41.6 3 84 5.3 128.2 4.1 13.5-.4 27.5-1.1 41.5-1.6 33.9-1.7 67.8-3.6 102.3-4.7 9.4-.3 18.7-.4 27.8-.4h2.5c16.5 0 32.4.4 47.8 1.4 8.4.3 15.6.7 22 1.2V2.2c-6.5-.5-13.8-.5-22.3-.8z"
            fill="var(--base-100)"
          />
        </svg>
      </div>
      <div className="p-4 overflow-auto flex-1">
        {!!props.editNode && !!EditComponent && (
          <EditComponent actionFieldName={actionFieldName} syncElements={props.syncElements} editNode={props.editNode} form={props.form} />
        )}
      </div>
    </animated.div>
  )
}

export default BaseEditAction
