import { endpoints } from "config/endpoints.config"
import { InputType } from "lib/constants"
import { IGenericModel, generic } from "models/generic"

export interface UtmCampaign {
  utm_campaign: string
  utm_source: string
  utm_medium: string
  utm_term?: string
  utm_content?: string
  utm_campaign_name?: string
  utm_ad_name?: string
  gad_source?: string
  gclid?: string
  origin?: string
  utm_ad_collection?: string
  utm_ad?: string
}

export interface IContact {
  id: string
  updatedAt?: string
  createdAt: string
  name: string
  avatar?: string
  isPrivate?: boolean

  externalId?: string
  twitterId?: string
  facebookId?: string
  instagramId?: string
  telegramId?: string
  notes?: string
  phone?: string
  email?: string

  Workspace?: { id: string; customerIdentifier: string }
  customFields?: {
    [key: string]: {
      type: InputType
      value: string | null
    }
  }
  lastUpdateAt: string
  location: string
  workspaceId: string
  newContact?: boolean
  isImported?: boolean
  hasConversation?: boolean
  origin?: string
  crmData?: {
    utmCampaigns?: UtmCampaign[]
    referrals?: any
    hubspotId?: string
    zohoId?: string
    zohoType?: string
    cloud_api: Record<string, { stoppedMarketingTemplateByUser: boolean }>
  }
  authorType?: "user" | "automation" | "bot"
  authorId?: string
}

export const contacts: IGenericModel<IContact> = {
  ...generic(endpoints.CONTACTS),
}
