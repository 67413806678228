import { Workflow, WorkflowActionLog, WorkflowLog } from "models/workflows"
import { Edge, Elements, Position } from "react-flow-renderer"
import { VariableCategory, WorkflowNodeAction, WorkflowNodeData } from "./workflow.defs"
import toast from "lib/toast"
import { FormInstance } from "antd"
import shortid from "shortid"
import { setWorkflowShouldAnimateNode } from "./EditWorkflow"
import { ActionEnum } from "./workflow.enums"
import { colors } from "lib/colors"
import Avatar from "components-old/ui/Avatar"

export const workflowInitialElements = [
  {
    id: ActionEnum.TRIGGER,
    type: ActionEnum.TRIGGER,
    data: { label: "" },
    position: { x: 300, y: 300 },
    sourcePosition: Position.Right,
  },
]

export function mountWorkflowModel(model: Partial<Workflow>) {
  const nodes = model.elements?.filter((node) => node.type != "removable_edge")
  const actions = nodes?.reduce((acc: any, node) => {
    const nodeEdges = model.elements?.filter((elem) => (elem as Edge).source == node.id)
    const targets = (nodeEdges as any)?.reduce((acc: any, edge: Edge) => {
      if (edge.sourceHandle) {
        return {
          ...acc,
          [edge.sourceHandle as string]: edge.target,
        }
      }

      return acc
    }, {})

    const action = {
      id: node.id,
      type: node.type,
      targets,
      data: node?.data?.action,
    }

    return {
      ...acc,
      // [node.id]: node?.type == ActionEnum.TRIGGER ? action : JSON.stringify(action),
      [node.id]: action,
    }
  }, {})

  const workflow = {
    ...model,
    actions,
  }
  return JSON.parse(JSON.stringify(workflow)?.replace(/\u00A0/g, " "))
}

export const updateElementAction = (
  nodeId: string,
  actionParam: WorkflowNodeAction | ((oldAction: WorkflowNodeAction) => WorkflowNodeAction),
  opts: { setElements: any; overwrite?: boolean }
) => {
  opts.setElements((oldEls: Elements<WorkflowNodeData>) => {
    const els = [...oldEls]
    const elIndex = els.findIndex((e) => e.id == nodeId)
    const action = typeof actionParam === "function" ? actionParam(els[elIndex].data?.action!) : actionParam

    if (elIndex > -1) {
      els[elIndex] = {
        ...els[elIndex],
        data: {
          ...els[elIndex].data!,
          action: opts.overwrite
            ? action
            : {
                ...els[elIndex].data?.action,
                ...action,
              },
        },
      }
    } else {
      toast.error("Nó não existente")
    }
    return els
  })
}

export const generateNewNodeId = (form: FormInstance) => {
  let id
  try {
    let idInt = form?.getFieldValue("lastNodeId") + 1
    id = idInt + ""

    form?.setFieldsValue({ lastNodeId: idInt })
  } catch (e) {
    id = shortid.generate()
  }

  return id
}

export const enableWorkflowAnimation = (nodeId) => {
  setWorkflowShouldAnimateNode(nodeId)

  setTimeout(() => {
    setWorkflowShouldAnimateNode(false)
  }, 500)
}
export const getDescriptionFormatted = (dto: {
  suffix?: string
  preffix?: string
  icon?: any
  avatar?: string
  name?: string
  palette?: string
}) => {
  return (
    <span className="inline-flex items-center flex-wrap gap-1">
      <span>{dto.preffix}</span>
      <div
        className="bg-base-300 pl-1 pr-2 py-0.5 rounded-xl inline-flex mx-1 items-center gap-1 truncate"
        style={
          {
            // backgroundColor: colors[dto.palette][200],
            // color: colors[dto.palette][800],
            // border: `1px solid var(--divider)`,
          }
        }
      >
        {dto.icon && (
          <dto.icon
            className="w-3 h-3"
            style={{
              color: colors["slate" || dto.palette][600],
            }}
          />
        )}
        {dto.avatar !== undefined && (
          <Avatar
            imgSrc={dto.avatar}
            size={18}
            iconStyle={{
              color: colors["slate" || dto.palette][800],
              // color: "white"
            }}
            placeholderStyle={{
              backgroundColor: colors["slate" || dto.palette][200],
            }}
          />
        )}
        <span className="truncate max-w-[100px]" title={dto?.name}>{dto?.name}</span>
      </div>
      <span>{dto.suffix}</span>
      {/* <TagItem
    size="sm"
    colors={{
      bg: palette[50],
      text: palette[600],
      500: palette[500],
    }}
  >
    <div className="inline-flex items-center gap-1">
      <Icon
        className="w-3 h-3"
        style={{
          color: palette[600],
        }}
      />
      {dto.actionLog?.context?.contact?.name}
    </div>
  </TagItem> */}
      {/* foi {wasCreated ? "criado" : "encontrado"}. */}
    </span>
  )
}
