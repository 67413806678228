import React from "react"
import axios from "axios"
// import toaster from "./toaster"
import { getFromMock, printRequest, writeSpell } from "./dev"
import config from "config"
import { stores } from "store"
import { toast as toaster } from "./toast"
import envs from "config/envs.config"

function getHeaders(customHeaders) {
  const workspace = stores.session.workspace
  return {
    "Content-Type": "application/json",
    Authorization: workspace ? workspace.token : "",
    ...customHeaders,
  }
}

async function handleError(error, props, url) {
  console.error(error)
  printRequest({ ...props, url, response: error.response })

  /* Server offline */
  if (error.message == "Network Error") {
    const message = "Erro de conexão. Sem contato com servidor"
    // toaster.error(message, { toastId: "conectionError" })
    toaster.error(message)
    writeSpell("response", { ...props, data: { error: message } })
    return { error: message }
    // return new Error(message)
  }

  /* Unknown Error */
  let errorDescriptions
  try {
    errorDescriptions = error.response.data.errors[0].descriptions
  } catch (e) {
    const message = (
      <div>
        Erro inesperado no servidor (Erro {error.response.status})<br />
        {error.message}{" "}
      </div>
    )
    toaster.error(message)
    writeSpell("response", {
      ...props,
      data: error.response && error.response.data,
    })
    return { error: message }
    // return new Error(error.message)
  }

  /* Token expirado */
  // if (error.response.data.errors[0].code == "UNAUTHORIZED") {
  //     console.log("Vou renovar o token")
  //     const response = await renewToken(url, props)
  //     console.log("Resposta da renovação de token", response)
  //     return response
  // }

  /* Standard Error */
  errorDescriptions && errorDescriptions.forEach((e) => toaster.error(e))
  writeSpell("response", { ...props, data: error.response.data })
  throw error.response.data
}

/*
 *  Req
 */
export default async function req(url, { method = "get", data, endpointId, customHeaders = {}, loader = method + url, toast, toastDanger } = {}) {
  const props = {
    method,
    data,
    customHeaders,
    loader,
    toast,
    url,
    toastDanger,
  }
  let domain = stores.general._domain.url
  let finalCustomHeaders = customHeaders || {}

  /* Loading */
  stores.general.addLoader(loader)

  /* DEVELOPER */
  if (!stores.general._isProduction) {
    writeSpell("sent", props)
  }

  let response

  // console.log(
  //     "https://bcb7-177-190-210-78.ngrok.io/api" + url + (endpointId ? "/" + endpointId : "")
  // )

  // NEW API DOMAIN
  try {
    const env = JSON.parse(localStorage.getItem("nanui-env"))
    const version = url.slice(0, 3)
    const workspace = stores.session.workspace

    switch (version) {
      case "/v1":
        domain = env?.v1Url || envs[1].v1Url
        finalCustomHeaders.Authorization = "bearer " + workspace.token
        break
      case "/v3":
        domain = env?.v2Url || envs[1].v2Url
        finalCustomHeaders.Authorization = "bearer " + workspace.token
        break
      default:
        domain = env?.url || envs[1].url
        break
    }
    // if (!env?.key || env?.key == "production") {
    //     domain = "https://kinbox-api-v0-bk-g6pbb.ondigitalocean.app/api"
    // }
    // if (url.slice(0, 3) == "/v1") {
    //     if (!env?.key || env?.key == "production" || env?.key == "localhost-prod") {
    //         // domain = "https://kinbox-api-sr7d7.ondigitalocean.app/api"
    //         domain = "https://kinbox-api-sr7d7.ondigitalocean.app/api"
    //     } else if (env?.key == "staging" || env?.key == "localhost") {
    //         domain =
    //             !import.meta.env?.PROD ? "https://staging-api-v1.kinbox.com.br/api" : "https://staging-api-v1.kinbox.com.br/api"
    //         // : "http://localhost:3000/api"
    //     }
    //     const workspace = stores.session.workspace
    //     finalCustomHeaders.Authorization = "bearer " + workspace.token
    // }
  } catch (e) {
    console.error(e)
  }

  try {
    if (domain == "mock") {
      response = await getFromMock(method, url)
    } else {
      response = await axios({
        method,
        url: domain + url + (endpointId ? "/" + endpointId : ""),
        // url:
        //     "https://bcb7-177-190-210-78.ngrok.io/api" +
        //     url +
        //     (endpointId ? "/" + endpointId : ""),
        data,
        headers: getHeaders(customHeaders),
        params: method == "get" ? { ...data } : {},
      })
      // console.log("response", response)
    }
  } catch (e) {
    console.log(e)
    stores.general.removeLoader(loader)
    return handleError(e, { ...props, domain }, url)
  }

  /* DEVELOPER */
  if (!stores.general._isProduction) {
    writeSpell("response", { ...props, data: response.data })
    printRequest({ ...props, url, response })
  }

  /* Toasters */
  if (toast) {
    toaster.success(toast)
  }
  if (toastDanger) {
    toaster.error(toastDanger)
  }

  stores.general.removeLoader(loader)
  return response.data
}
