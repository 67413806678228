import React, { useMemo, useState } from "react"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import { Button, Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStore, useStoreActions, useStoreState } from "hooks"
import FacebookSvg from "../svgs/FacebookSvg"
import { IChannel, IChannelFacebook } from "models/channels"
import { ChannelStatus } from ".."
import { FacebookProvider } from "react-facebook"
import { envKeys } from "config/envs.config"
import { general } from "store"
import toast from "lib/toast"
import axios from "axios"
import { endpoints } from "config/endpoints.config"
import request from "lib/request"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"
import HorizontalSwitch from "components-old/ui/HorizontalSwitch"
import GroupsSelect from "components-old/ui/GroupsSelect"

interface IEditFacebookProps extends RouteComponentProps<{ id: string }> {
  onChange: Function
}

function EditFacebook(props: IEditFacebookProps) {
  const [form] = Form.useForm()
  const { all: channels } = useStoreState((state) => state.channels)
  const { patchElementOnAll } = useStoreActions((state) => state.channelsFacebook)
  const { id } = useParams<any>()
  const { _domain } = useStore(general)
  const [reconnecting, setReconnecting] = useState(false)
  const [item, setItem] = useState<IChannelFacebook>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const channel = useMemo(() => {
    return channels.find((c) => c.id == id)
  }, [channels, id])

  return (
    <SettingsPageEdit<IChannelFacebook>
      {...props}
      pageComponent="modal"
      routeName="channels"
      width={500}
      onChangeItem={(item) => {
        setItem(item)
      }}
      title="Canal de Facebook"
      onSubmitSuccess={() => {
        props.onChange()
      }}
      okProps={{
        loading,
        // style: {
        //   display: "none",
        // },
      }}
      onSubmitBefore={(values) => {
        setLoading(true)
        return request
          .patch<IChannelFacebook>(`${endpoints.FACEBOOK}/${id}`, {
            customFields: values.customFields,
            isPrivate: values.isPrivate,
            groupIds: values.groupIds,
          })
          .then((res) => {
            toast.success("Canal atualizado.")
            patchElementOnAll(res.data)
            setLoading(false)
            history.push(`/settings/channels`)
          })
      }}
      titleOverwrite={
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <Row wrap={false}>
              <Col>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <FacebookSvg />
                </div>
              </Col>
              <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                {channel?.description}
              </Col>
            </Row>
          </Col>
        </Row>
      }
      // actions={[
      //     <FacebookProvider
      //         appId={
      //             ((envKeys as any)[_domain.key] || {}).facebook_app_id ||
      //             envKeys.staging.facebook_app_id
      //         }
      //         key="face-provider"
      //     >
      //         <Login
      //             scope="pages_show_list, pages_messaging, pages_manage_metadata"
      //             onCompleted={(data: any) => {
      //                 setReconnecting(true)
      //                 const accessToken = data?.tokenDetail?.accessToken
      //                 axios({
      //                     method: "get",
      //                     url: endpoints.FACEBOOK_GRAPH_API + accessToken,
      //                 })
      //                     .then((response) => {
      //                         console.log("response", response, channel, item)
      //                         const page = response.data?.data?.find(
      //                             (page: any) => page.id == item?.pageId
      //                         )
      //                         request
      //                             .save(endpoints.FACEBOOK, {
      //                                 avatar: page.picture.data.url,
      //                                 pageId: page.id,
      //                                 name: page.name,
      //                                 accessToken: page.access_token,
      //                             })
      //                             .then((response) => {
      //                                 toast.success(
      //                                     <span>
      //                                         Conectado ao canal de Facebook{" "}
      //                                         <strong>{response?.data.name}</strong>
      //                                     </span>
      //                                 )
      //                                 history.push("/settings/channels")
      //                             })
      //                             .finally(() => {
      //                                 setReconnecting(false)
      //                             })
      //                     })
      //                     .catch((e) => {
      //                         console.log(e)
      //                         setReconnecting(false)
      //                     })
      //             }}
      //             onError={(err: any) => toast.error(err.message)}
      //         >
      //             {({ loading, handleClick, error, data }: any) => (
      //                 <Button
      //                     icon={
      //                         <i
      //                             className="fab fa-facebook"
      //                             style={{ color: "#fff" }}
      //                         />
      //                     }
      //                     loading={reconnecting || loading}
      //                     onClick={handleClick}
      //                     type="primary"
      //                 >
      //                     Reconectar ao Facebook
      //                 </Button>
      //             )}
      //         </Login>
      //     </FacebookProvider>,
      // ]}
      storePath={(store) => store.channelsFacebook}
      form={form}
      hideFooterWhenSaved
    >
      <Form.Item label="Status">
        <ChannelStatus status={channel?.status} channel={channel} />
      </Form.Item>
      <Form.Item label="Página" name="name">
        <Input disabled />
      </Form.Item>
      <Form.Item>
        <HorizontalSwitch
          title="Restringir visibilidade"
          description="Selecione os grupos que tem acesso a visualizar esse canal"
          name="isPrivate"
        >
          <Form.Item name="groupIds">
            <GroupsSelect mode="multiple" />
          </Form.Item>
        </HorizontalSwitch>
      </Form.Item>
      <EditEntityCustomFields form={form} item={item} entity="channel" />
    </SettingsPageEdit>
  )
}

export default EditFacebook
