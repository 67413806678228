import React from "react"
import { twMerge } from "tailwind-merge"

export interface CardProps {
  children: React.ReactNode
  className?: string
  headerClassName?: string
  contentClassName?: string
  title?: string | React.ReactNode
  extra?: React.ReactNode
  style?: React.CSSProperties
}

function Card(props: CardProps) {
  const showHeader = props.title || props.extra
  return (
    <div className={twMerge("bg-base-100 shadow rounded-xl px-2", props.className)} style={props.style}>
      {showHeader && (
        <header className={twMerge("p-4 pb-3 flex items-center w-full justify-between", props.headerClassName)}>
          <h2 className="font-bold text-xl">{props.title}</h2>
          {props.extra}
        </header>
      )}
      <section className={props.contentClassName}>{props.children}</section>
    </div>
  )
}

export default Card
