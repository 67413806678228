import React, { useMemo, useState } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import { Col, Form, Input, Row } from "antd"
import SettingsPageEdit from "components-old/common/SettingsPage/SettingsPageEdit"
import { useStore, useStoreState } from "hooks"
import { IChannel } from "models/channels"
import { ChannelStatus } from ".."
import shortid from "shortid"
import ImageUploader from "components-old/forms/ImageUploader"
import CustomSvg from "../svgs/CustomSvg"
import { general } from "store"
import EditEntityCustomFields from "pages/crm/contacts/EditEntityCustomFields"

interface IEditCustomProps extends RouteComponentProps<{ id: string }> {
    onChange: Function
}

function EditCustom(props: IEditCustomProps) {
    const [form] = Form.useForm()
    const { all: channels } = useStoreState((state) => state.channels)
    const { id } = useParams<any>()
    const [loading, setLoading] = useState(false)
    const { uploadFile } = useStore(general)
    const [item, setItem] = useState<IChannel>()

    const channel = useMemo(() => {
        return channels.find((c) => c.id == id)
    }, [channels, id])

    return (
        <SettingsPageEdit<IChannel>
            {...props}
            pageComponent="modal"
            routeName="channels"
            width={550}
            onChangeItem={(item) => setItem(item)}
            title="Canal customizado"
            onSubmitSuccess={() => {
                props.onChange()
            }}
            titleOverwrite={
                <Row justify="space-between" align="middle" wrap={false}>
                    <Col>
                        <Row wrap={false}>
                            <Col>
                                <div
                                    style={{
                                        width: 24,
                                        height: 24,
                                        display: "flex",
                                        marginRight: 10,
                                    }}
                                >
                                    <CustomSvg />
                                </div>
                            </Col>
                            <Col className="truncate" title={channel?.description} style={{ display: "flex", alignItems: "center" }}>
                                {channel?.description}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            storePath={(store) => store.channelsCustom}
            form={form}
            hideFooterWhenSaved
            defaultValues={{
                identifier: shortid.generate(),
            }}
            onSubmitBefore={async (model, submit) => {
                setLoading(true)
                if ((model.avatar as any)?.[0].preview) {
                    const formData = new FormData()
                    formData.append("avatar", model.avatar![0])
                    const uploadResponse: any = await uploadFile({
                        data: formData,
                    })
                    if (!uploadResponse[0].fileName) {
                        setLoading(false)
                        throw new Error("Error uploading file")
                    }
                    model.avatar = uploadResponse[0].fileName
                }
                submit(model)
            }}
            okProps={{
                loading,
            }}
        >
            <div style={{ color: "var(--secondary)" }}>
                <p>Um canal customizado pode ser controlado usando nossa API para integrar qualquer plataforma de mensagem com o Kinbox.</p>
            </div>
            <br />
            <Form.Item label="Status">
                <ChannelStatus status={channel?.status} channel={channel} />
            </Form.Item>
            <Form.Item label="Imagem" style={{ textAlign: "center" }}>
                <ImageUploader
                    size={100}
                    removable
                    bottomDescription={
                        <>
                            <div style={{ color: "var(--secondary)" }}>Arraste e solte uma imagem ou faça Upload</div>
                            <div style={{ color: "var(--secondary)", fontSize: 12 }}>JPEG/PNG - Min. 100x100px</div>
                        </>
                    }
                    avatarPlaceholderIcon={"fal fa-wifi-slash"}
                    avatarProps={{
                        placeholderStyle: {
                            backgroundColor: "var(--primary)",
                            color: "white",
                        },
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Descrição"
                name="description"
                rules={[
                    {
                        required: true,
                        message: "Campo obrigatório",
                    },
                ]}
            >
                <Input placeholder="Meu canal" />
            </Form.Item>
            <Form.Item
                shouldUpdate={(prev, values) => {
                    return prev.identifier != values.identifier
                }}
                noStyle
            >
                {({ getFieldValue }) => (
                    <Form.Item label="Endpoint para o Kinbox receber mensagens" help="Chame essa URL para receber mensagens no Kinbox">
                        <Input disabled value={`https://api.kinbox.com.br/api/v1/channels/${getFieldValue("identifier")}/messages`} />
                    </Form.Item>
                )}
            </Form.Item>
            <Form.Item
                label="Endpoint para o Kinbox enviar mensagem e atualização de status"
                help="Kinbox vai enviar um POST para essa URL sempre que um agente interagir com uma conversa desse canal"
                name="hashIdentifier"
            >
                <Input placeholder="https://api.myserver.com/integration/kinbox" />
            </Form.Item>
            <EditEntityCustomFields form={form} item={item} entity="channel" />
        </SettingsPageEdit>
    )
}

export default EditCustom
