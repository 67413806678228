import { Editor } from "@tiptap/react"
import { VariableItem } from "pages/settings/workflows/workflow.defs"

// {contact:$json|$.customFields.idade.value|55|fallback}
// {contact:name|||}
export const splitVariableString = (variableStringRaw: string) => {
  const [type, jsonPath, reference, fallback] = variableStringRaw.split("|")
  return {
    type,
    jsonPath,
    reference,
    fallback,
  }
}

export const mountVariableString = (variableParts: { type: string; jsonPath: string; reference: string; fallback: string }) => {
  return `{${variableParts.type}|${variableParts.jsonPath || ""}|${variableParts.reference || ""}|${variableParts.fallback || ""}}`
}

export function convertVariablesToHtml(
  inputString: string,
  context: { variablesRecord: Record<string, VariableItem>; multiLine?: boolean; json?: boolean }
) {
  const regex = /{(.*?)}/g
  let match
  let htmlString = inputString

  const foundVariables = new Set<string>()

  // Encontre todas as variáveis na string de entrada
  while ((match = regex.exec(inputString)) !== null) {
    foundVariables.add(match[1])
  }

  // Substitua apenas as variáveis encontradas
  foundVariables.forEach((variableStringRaw) => {
    // let [typeWithReference, fallback] = variableAndFallback?.split("|")
    // let [type, reference] = typeWithReference?.split("#")
    let { type, fallback, reference, jsonPath } = splitVariableString(variableStringRaw)
    let variable

    if (context.variablesRecord.hasOwnProperty(type as any)) {
      variable = context.variablesRecord[type as any]
    }

    // if (!variable) {
    //   variable = WorkflowDefs.VariableRecord.deleted
    // }

    // //TODO: Verificar se variavel não existe e fazer com que mostra icone de warning
    // if (!variable && type.startsWith("context:")) {
    //   variable = {

    //   }
    // }

    if (variable) {
      const replacement = `<variable-component id="${type}" reference="${reference}" jsonPath="${jsonPath}" label="${
        variable.label
      }" fallback="${fallback || ""}">${variable.label}</variable-component>`
      htmlString = htmlString.replaceAll(`{${variableStringRaw}}`, replacement)
    }
  })

  if (context.multiLine) {
    // htmlString = htmlString.replace(/\n\n/g, "<br>") //?.replace(/\s/g, "&nbsp;")
    // return htmlString.split("\n").map((line) => ({ type: "paragraph", content: [{ type: "text", text: line }] }))
    htmlString =
      htmlString
        .split("\n\n")
        // ?.filter((line) => line.trim() !== "")
        .map((line) => `<p>${line}</p>`)
        ?.join("") || ""

    return htmlString
  }

  // if (context.json) {
  //   return `<pre>${htmlString}</pre>`
  // }

  return `<p>${htmlString}</p>`
}

export const detectVariableFromString = (inputString: string | undefined, context: { variablesRecord }) => {
  if (inputString && inputString?.startsWith?.("{") && inputString?.endsWith?.("}")) {
    try {
      const withoutBrackets = inputString.slice(1, -1)
      const variable = splitVariableString(withoutBrackets)

      const variableRecord = context.variablesRecord?.[variable?.type] as VariableItem | undefined

      if (variable?.type) {
        return { variableRecord, variable }
      }
    } catch (error) {}
    return {}
  }
}

// export function convertVariablesToJson(inputString, context: { variablesRecord: Record<string, VariableItem> }) {
//   const regex = /{(.*?)}/g
//   let match
//   const contentArray: any = []
//   let lastIndex = 0

//   // Encontra todas as variáveis na string de entrada
//   while ((match = regex.exec(inputString)) !== null) {
//     // Adiciona o texto antes da variável
//     if (match.index > lastIndex) {
//       contentArray.push({
//         type: "text",
//         text: inputString.slice(lastIndex, match.index),
//       })
//     }

//     // Adiciona a variável como um mention
//     const variableId = match[1]?.split("|")?.[0]
//     if (context.variablesRecord.hasOwnProperty(variableId)) {
//       const variable = context.variablesRecord[variableId]
//       contentArray.push({
//         type: "mention",
//         attrs: {
//           // id: variable.type,
//           id: match[1],
//           label: variable.label,
//         },
//       })
//     }

//     lastIndex = regex.lastIndex
//   }

//   // Adiciona o texto restante após a última variável
//   if (lastIndex < inputString.length) {
//     contentArray.push({
//       type: "text",
//       text: inputString.slice(lastIndex),
//     })
//   }

//   // Constrói o objeto JSON final
//   const jsonOutput = {
//     type: "doc",
//     content: [
//       {
//         type: "paragraph",
//         content: contentArray,
//       },
//     ],
//   }

//   return jsonOutput
// }

export function updateLineNumbers(editor: Editor, ref: any) {
  // const content = editor.getText() // Obtenha o texto do editor
  // const lines = content.split("\n") // Divida o texto em linhas
  // const lineNumbersContainer = document.querySelector(".line-numbers") as any // Obtenha o contêiner de números de linha
  const lineNumbersContainer = ref.current

  if (!lineNumbersContainer) return

  const lines = editor.getJSON().content || []

  // console.log("text", editor.getText())
  // console.log("html", editor.getHTML())
  // console.log("json", editor.getJSON().content?.length)

  lineNumbersContainer.innerHTML = "" // Limpa os números de linha existentes

  // Adiciona novos números de linha
  // lines?.forEach((_, index) => {
  //   const lineNumber = document.createElement("div")
  //   lineNumber.textContent = index + 1 + ""
  //   lineNumbersContainer.appendChild(lineNumber)
  // })
  let totalLines = 0
  for (let index = 0; index < lines?.length; index++) {
    const line = lines?.[index]
    const lineNumber = document.createElement("div")
    lineNumber.textContent = totalLines++ + 1 + ""
    lineNumbersContainer.appendChild(lineNumber)

    for (let i = 0; i < (line?.content || [])?.length; i++) {
      const content = line?.content?.[i]
      if (content?.type === "hardBreak") {
        const lineNumber = document.createElement("div")
        lineNumber.textContent = totalLines++ + 1 + ""
        lineNumbersContainer.appendChild(lineNumber)
      }
    }
  }
}

export function parseVariableString(inputString: string, context: { variablesRecord: Record<string, VariableItem> }) {
  const regex = /{(.*?)}/g
  let match
  let parsed = inputString

  const foundVariables = new Set<string>()

  // Encontre todas as variáveis na string de entrada
  while ((match = regex.exec(inputString)) !== null) {
    foundVariables.add(match[1])
  }

  // Substitua apenas as variáveis encontradas
  foundVariables.forEach((variableStringRaw) => {
    let { type, fallback, reference, jsonPath } = splitVariableString(variableStringRaw)
    let variable: VariableItem | undefined

    if (context.variablesRecord.hasOwnProperty(type as any)) {
      variable = context.variablesRecord[type as any] as VariableItem
    }

    if (variable) {
      const example = getExampleValueFromPrimitiveType(variable.primitiveType)
      // if (variable.primitiveType === "string") {
      //   parsed = parsed.replaceAll(`"{${variableStringRaw}}"`, `${getExampleValueFromPrimitiveType(variable.primitiveType)}`)
      // }

      parsed = parsed.replaceAll(`{${variableStringRaw}}`, `${example}`)

      // if (example === '"string|boolean|number|null|undefined"') {
      //   parsed = parsed.replaceAll(`""string|boolean|number|null|undefined""`, `"string|boolean|number|null|undefined"`)
      // }
    }
  })

  return parsed
}

const getExampleValueFromPrimitiveType = (primitiveType: string) => {
  switch (primitiveType) {
    case "string":
      return "string"
    case "number":
      return 0
    case "boolean":
      return true
    case "date":
      return new Date().toISOString()
    case "json":
      return null
    default:
      return ""
  }
}
