import { endpoints } from "config/endpoints.config"
import { action, thunk } from "easy-peasy"
import { InputType } from "lib/constants"
import request from "lib/request"
import { GenericResource, IGenericModel, generic } from "models/generic"

export interface IGroup extends GenericResource {
  name: string
  description: string
  icon: string
  members: string[]
  ddd?: string
  cep?: string
  customFields?: {
    [key: string]: {
      type: InputType
      value: string | null
    }
  }
  isActive?: boolean
  // members: {
  //     id: string
  // }[]
}

export const groups: IGenericModel<IGroup> = {
  ...generic(endpoints.GROUP),
  // setAll: action((state, payload) => {
  //     state.all = payload.map(group => ({ ...group, members: group.members.map((m: any) => (m?.id || m)) }))
  // }),
  // setElementOnAll: action((state, item) => {
  //     const newItem = { ...item, members: item.members.map((m: any) => (m?.id || m)) }
  //     const index = state.all.findIndex(x => x.id == newItem.id)
  //     if (index > -1) {
  //         state.all[index] = newItem
  //     } else {
  //         state.all = [newItem, ...state.all]
  //         // if (state.totalCount) {
  //         state.totalCount = (state.totalCount || 0) + 1
  //         // }
  //     }
  // }),
  // save: thunk(async (actions, model) => {
  //     actions.patchLoading({
  //         saving: true
  //     })
  //     const saveEndpoint = model.id ? endpoints.GROUP + '/' + model.id : endpoints.GROUP
  //     return request.save(saveEndpoint, { ...model, members: model.members?.map(memberId => ({ id: memberId })) }).then((response) => {
  //         actions.setElementOnAll(response.data)
  //         return response.data
  //     }).finally(() => {
  //         actions.patchLoading({
  //             saving: false
  //         })
  //     })
  // })
}
