import React, { useEffect } from "react"
import FormModal from "components-old/forms/FormModal"
import { Form } from "antd"
import { InputType } from "lib/constants"
import { getValueInput, validateValue } from "lib/helper"
import { useStoreState } from "hooks"
import { useRecoilState } from "recoil"
import { themeAtom } from "atoms/app.atom"
import moment from "moment"
import toast from "lib/toast"

interface IEditInputModalProps {
  type: InputType
  label?: string | React.ReactNode
  labelOverwrite?: string | React.ReactNode
  successOverwrite?: string
  value: string | string[] | number
  placeholder: string
  onSubmit?: any
  htmlInputProps?: any
  hideSuccessMessage?: boolean
  options?: {
    value: string
    label: string | React.ReactNode
  }[]
  okText?: string
}

function EditInputModal(props: IEditInputModalProps) {
  const [form] = Form.useForm()
  const { all: customFields } = useStoreState((state) => state.customFields)
  const [theme] = useRecoilState(themeAtom)

  const customField = customFields.find((field: any) => field.placeholder == props.placeholder)

  return (
    <FormModal
      form={form}
      onSubmit={(values: any) => {
        let isValid: any = true
        if (customField && customField?.validate) {
          try {
            let value = values[String(props.placeholder as any)]
            if (value?.length > 0)
              isValid = validateValue(values[String(props.placeholder as any)], customField?.validation_type!, customField?.validation_mask)
          } catch (e) {}
        }

        if (!isValid) {
          toast.error("Valor inválido para a máscara " + customField?.validation_mask)
          throw new Error("Valor inválido")
        }

        return props.onSubmit(values)
      }}
      type="modal"
      titleOverwrite={props.labelOverwrite || `Editar ${props.label}`}
      successMessage={props.successOverwrite || `${props.label} editado.`}
      item={{
        [props.placeholder]: props.type == InputType.Date ? moment(props.value as string) : (props.value as string),
      }}
      formProps={{
        initialValues: {
          [props.placeholder]: props.type == InputType.Date ? moment(props.value as string) : (props.value as string),
        },
      }}
      saved={false}
      hideSuccessMessage={props.hideSuccessMessage}
      isEditing
      okText={props.okText}
      okStyle={!props.onSubmit ? { display: "none" } : {}}
    >
      <Form.Item
        name={props.placeholder}
        // valuePropName={props.type == InputType?.Boolean ? "checked" : undefined}
      >
        {getValueInput(
          {
            type: props.type,
            options: props.options || customField?.options,
            htmlInputProps: props.htmlInputProps,
          },
          {
            json: {
              form: form,
              theme: theme,
              name: props.placeholder,
              value: props.value,
              onChange: (value) => {
                form?.setFields([
                  {
                    name: props.placeholder,
                    value,
                  },
                ])
              },
            },
          }
        )}
      </Form.Item>
    </FormModal>
  )
}

export default EditInputModal
