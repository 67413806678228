import React from "react"

function EmailSvg() {
    return (
        <svg
            id="Capa_1"
            enableBackground="new 0 0 497 497"
            viewBox="0 0 497 497"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    clipRule="evenodd"
                    d="m0 197.515 213.11-164.52c21.71-16.76 52.01-16.67 73.61.21l210.28 164.31v1l-248.5 174.075z"
                    fill="#f8c201"
                    fillRule="evenodd"
                />
                <path
                    d="m282.791 291.181c-10.229-5.008-21.554-7.696-33.011-7.696-11.836 0-23.559 2.867-34.069 8.214l-215.711-94.184 70 52.1 139.68 78.03 3.43-2.65c21.71-16.76 52.01-16.67 73.61.21l3.55 2.78 136.73-77.03 70-52.44z"
                    fill="#faa515"
                />
                <path
                    d="m497 198.515v268c0 .78-.09 1.54-.26 2.27-.03.13-.06.26-.1.39-.05.18-.1.36-.16.54-.06.15-.11.3-.17.45-.03.09-.07.18-.11.27-.1.23-.2.45-.32.67-.02.05-.04.09-.07.13-.25.47-.54.92-.87 1.34-.05.08-.11.16-.18.24-.02.03-.05.06-.08.09-.16.19-.32.38-.5.56-.15.16-.31.31-.47.46l-.01.01-125.76-80.45-73.95-75.59-3.72-2.91 25.45-14.34 111.28-62.69z"
                    fill="#fad207"
                />
                <path
                    d="m209.68 314.645-3.76 2.9-75.41 75.94-127.19 80.47c-.17-.16-.34-.32-.5-.49-1.75-1.8-2.82-4.25-2.82-6.95v-269l70 39.1 100.14 55.94 12.41 6.93z"
                    fill="#fad207"
                />
                <path
                    d="m209.68 314.645-3.76 2.9-75.41 75.94-127.19 80.47c-.17-.16-.34-.32-.5-.49-1.75-1.8-2.82-4.25-2.82-6.95v-.38l172.55-172.65z"
                    fill="#faa515"
                />
                <path
                    d="m497 466.135v.38c0 .78-.09 1.54-.26 2.27-.03.13-.06.26-.1.39-.05.18-.1.36-.16.54-.06.15-.11.3-.17.45-.03.09-.07.18-.11.27-.1.23-.2.45-.32.67-.02.05-.04.09-.07.13-.25.47-.54.92-.87 1.34-.05.08-.11.16-.18.24-.02.03-.05.06-.08.09-.16.19-.32.38-.5.56-.15.16-.31.31-.47.46l-.01.01c-.01.01-.01.01-.02.02l-127.19-80.47-75.41-75.94-3.76-2.9 1.47-.82 1.48 1.16 35.044-19.745z"
                    fill="#faa515"
                />
                <path
                    d="m493.7 473.935c-.01.01-.01.01-.02.02-1.77 1.59-4.11 2.56-6.68 2.56h-477c-2.42 0-4.63-.86-6.36-2.29-.11-.08-.22-.17-.32-.27l117.19-90.47 85.41-65.94 3.76-2.9 3.43-2.65c10.45-8.06 22.89-12.23 35.39-12.5 13.47-.28 27.02 3.95 38.22 12.71l2.07 1.62 1.48 1.16 3.72 2.91 83.95 65.59z"
                    fill="#fad207"
                />
                <path
                    d="m345.968 163.743c-1.29-50.895-42.115-92.566-92.941-94.869-27.27-1.234-53.035 8.544-72.542 27.539-19.512 19-29.978 44.472-29.468 71.723.475 25.361 10.737 49.25 28.897 67.265 18.159 18.014 42.129 28.087 67.495 28.365.365.004.729.006 1.093.006 11.473 0 22.701-1.974 33.392-5.872 3.892-1.418 5.896-5.723 4.478-9.614s-5.723-5.898-9.614-4.478c-9.327 3.398-19.176 5.088-29.184 4.958-44.146-.483-80.733-36.78-81.559-80.911-.432-23.063 8.424-44.617 24.935-60.695 16.506-16.071 38.337-24.338 61.397-23.301 42.998 1.948 77.534 37.205 78.625 80.266.255 10.038-1.268 19.879-4.524 29.247-2.163 6.222-8.097 10.4-14.768 10.4-.001 0-.002 0-.002 0-8.645 0-15.678-7.033-15.678-15.679v-61.823c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.406c-8.504-7.994-19.936-12.906-32.5-12.906-26.191 0-47.5 21.309-47.5 47.5s21.309 47.5 47.5 47.5c13.563 0 25.807-5.724 34.471-14.871 4.381 11.599 15.593 19.872 28.706 19.872h.003c13.05 0 24.679-8.229 28.936-20.475 3.852-11.079 5.652-22.704 5.352-34.553zm-97.467 35.027c-17.921 0-32.5-14.579-32.5-32.5s14.579-32.5 32.5-32.5 32.5 14.579 32.5 32.5-14.579 32.5-32.5 32.5z"
                    fill="#f5f5f5"
                />
            </g>
        </svg>
    )
}

export default EmailSvg
