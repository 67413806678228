import { endpoints } from "config/endpoints.config"
import { InputType, ValidationType } from "lib/constants"
import { IGenericModel, PrivateGenericResource, generic } from "models/generic"

export type ICustomFieldEntity = "contact" | "group" | "channel" | "session" | "user" | "product" | "task" | "deal"

export interface ICustomField extends PrivateGenericResource {
  updatedAt: string
  name: string
  description: string
  placeholder: string
  invisible: boolean
  isIntegration: boolean
  showAlways: boolean
  requiredToResolve: boolean
  type: InputType
  entity: ICustomFieldEntity
  position: number
  options?: {
    value: string
    label: string
  }[]
  validate: boolean
  validation_type: ValidationType
  validation_mask: ValidationType
  isDependent?: boolean
  dependency?: string
}

export const customFields: IGenericModel<ICustomField> = {
  ...generic(endpoints.CUSTOM_FIELDS),
}
