import React, { useEffect } from "react"
import Form, { FormInstance } from "antd/es/form"
import ChannelsSelect from "components-old/ui/ChannelsSelect"

interface EditCampaignMessageReceivedProps {
  formInstance: FormInstance
  actionFieldName: string[]
  syncElements: Function
}

function EditCampaignMessageReceived(props: EditCampaignMessageReceivedProps) {
  const channelFormName = [...props.actionFieldName, "channelId"]
  const channelId = props.formInstance.getFieldValue(channelFormName)

  useEffect(() => {
    props.formInstance.setFields([
      {
        name: [...props.actionFieldName, "triggerFilterKey"],
        value: "channelId:" + (channelId || ""),
      },
    ])
    setTimeout(() => {
      props.formInstance.setFields([
        {
          name: [...props.actionFieldName, "triggerFilterKey"],
          value: "channelId:" + (channelId || ""),
        },
      ])
      props.syncElements()
    }, 500)
  }, [channelId])

  return (
    <div>
      <ChannelsSelect name={channelFormName} required placeholder="Selecione um canal" />
    </div>
  )
}

export default EditCampaignMessageReceived
