import React from "react"

interface MenuFragmentProps {
  children: React.ReactElement
}

// Usado para evitar erro no console log
function MenuFragment(props: MenuFragmentProps) {
  return props.children
}

export default MenuFragment
