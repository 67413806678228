import { Button, Dropdown, Menu } from "antd"
import Tooltip from "components-old/Tooltip"
import MenuItemInnerSelectable from "components-old/ui/SearchDrop/MenuItemInnerSelectable"

export interface SegmentDropdownProps<T> {
  selected: string
  setSelected: (value: string) => void
  title?: string
  segments: {
    value: string
    label: string
    icon?: React.ReactNode
    filterFn?: (data: T[]) => T[]
    filtered?: T[]
    filteredTotal?: number
  }[]
}

function SegmentDropdown<T>(props: SegmentDropdownProps<T>) {
  const option = props.segments.find((option) => option.value === props.selected)

  return (
    <Dropdown
      overlay={
        <Menu>
          {props.segments.map((option, i) => {
            const hasCount = !!option?.filteredTotal || !!option?.filtered?.length
            return (
              <Menu.Item
                key={option.value}
                onClick={(e) => {
                  props.setSelected(option.value)
                }}
              >
                <MenuItemInnerSelectable selected={props.selected == option.value} title={option.label}>
                  {/* <div className="flex items-center">
                  {(option?.filteredTotal ?? option?.filtered?.length) && (
                    <div className="w-9 mr-0.5">
                      <span className="text-xs bg-slate-100 dark:bg-transparent dark:font-bold border border-solid border-slate-200 dark:border-divider-100 rounded-full px-1 font-medium truncate">
                        {option?.filteredTotal ?? option.filtered?.length}
                      </span>
                    </div>
                  )} */}
                  {option.label}
                  {hasCount && (
                    <span className="text-xs bg-slate-100 dark:bg-transparent dark:font-bold border border-solid border-slate-200 dark:border-divider-100 rounded-full px-1 font-medium truncate ml-2">
                      {option?.filteredTotal ?? option.filtered?.length}
                    </span>
                  )}
                  {/* </div> */}
                  {/* {option?.filtered?.length ? ` (${option.filtered.length})` : ""} */}
                </MenuItemInnerSelectable>
              </Menu.Item>
            )
          })}
        </Menu>
      }
      trigger={["click"]}
    >
      <Tooltip title={props.title || "Segmento"} placement="bottom">
        <Button className="rounded-xl">
          {option?.icon}
          {option?.label || "Tudo"}
          <i className="fas fa-caret-down text-xs ml-2" />
        </Button>
      </Tooltip>
    </Dropdown>
  )
}

export default SegmentDropdown
