import React, { useEffect } from "react"
import { Drawer, DrawerProps } from "antd"
import useLocalStorage from "react-use/lib/useLocalStorage"

let isResizing: boolean | null = null

export interface ResizableDrawerProps extends DrawerProps {
  widthKey: string
  children: React.ReactNode
  minWidth?: number
}

const ResizableDrawer = ({ children, ...props }: ResizableDrawerProps) => {
  const [drawerWidth, setDrawerWidth] = useLocalStorage<any>("drawer-resize-" + props.widthKey, props.width || 500)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
      document.body.style.overflowX = "hidden"
    }
  }, [])

  useEffect(() => {
    if (drawerWidth > window.innerWidth - 40) {
      setDrawerWidth(window.innerWidth - 40)
    }
  }, [drawerWidth, setDrawerWidth])

  const cbHandleMouseMove = React.useCallback(handleMousemove, [])
  const cbHandleMouseUp = React.useCallback(handleMouseup, [])

  // useEffect(() => {
  //     setDrawerWidth(props.width)
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.visible])

  function handleMouseup(e) {
    if (!isResizing) {
      return
    }
    isResizing = false
    document.removeEventListener("mousemove", cbHandleMouseMove)
    document.removeEventListener("mouseup", cbHandleMouseUp)
  }

  function handleMousedown(e) {
    e.stopPropagation()
    e.preventDefault()
    // we will only add listeners when needed, and remove them afterward
    document.addEventListener("mousemove", cbHandleMouseMove)
    document.addEventListener("mouseup", cbHandleMouseUp)
    isResizing = true
  }

  function handleMousemove(e) {
    let offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft)
    let minWidth = props.minWidth || (props.width ? +props.width : 0) || 500
    let maxWidth = window.innerWidth - 40
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setDrawerWidth(offsetRight)
    }
  }

  const { widthKey, ...rest } = props

  return (
    <Drawer
      {...rest}
      width={drawerWidth}
      onClose={(e) => {
        document.body.style.overflow = "unset"
        props.onClose?.(e)
      }}
    >
      <div className="sidebar-dragger" onMouseDown={handleMousedown} />
      {children}
    </Drawer>
  )
}

export default ResizableDrawer
