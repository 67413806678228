import { Form } from "antd"
import ChannelsSelect from "components-old/ui/ChannelsSelect"
import { CHANNEL_TYPES } from "lib/constants"
import { BaseEditActionChildrenProps } from "../../../actions/BaseEditAction"
import SelectReference from "../../../actions/SelectReference"
import ConversationFormCore from "../ConversationFormCore"
import { WorkflowDefs } from "pages/settings/workflows/workflow.defs"

interface CreateConversationActionProps extends BaseEditActionChildrenProps {}

const CreateConversationAction = (props: CreateConversationActionProps) => {
  const fieldName = [...props.actionFieldName, "conversation"]

  return (
    <div>
      <Form.Item
        label="Contato"
        name={[...fieldName, "contactReference"]}
        rules={[
          {
            required: true,
            message: "Obrigatório",
          },
        ]}
      >
        <SelectReference showSearch category={WorkflowDefs.CategoryRecord.contact} />
      </Form.Item>
      <ChannelsSelect label="Canal" placeholder="Selecione um canal" required name={[...fieldName, "channelId"]} filter={(c) => c.channelId == CHANNEL_TYPES.WHATSAPP} />
      <ConversationFormCore fieldName={fieldName} />
    </div>
  )
}

export default CreateConversationAction
