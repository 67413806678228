import { endpoints } from "config/endpoints.config"
import { IGenericModel, generic } from "models/generic"

export interface IBuySMSTransaction {
    id: string
    _id: string
    workspaceId: number
    operatorId: number
    operatorName: string
    status: "Aguardando pagamento" | "Pagamento recebido" | "Aguardando aprovação" | "Aprovada"
    cents: number
    type: string
    events: {
        _id: string
        event: string
        date: string
    }[]
    extraInfo: {
        quantity: number
        pix_qr_code: string
        orderId: string
    }
    updatedAt: string
    createdAt: string
    __v: number
}

export const adminTransactions: IGenericModel<IBuySMSTransaction> = {
    ...generic(endpoints.ADMIN_TRANSACTIONS),
}
