import { verifyPermission } from "lib/helper"
import toast from "lib/toast"
import { IRolePermissions } from "models/roles"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

function usePermission(permission: keyof IRolePermissions | undefined) {
    const history = useHistory()

    useEffect(() => {
        if (permission && !verifyPermission(permission)) {
            toast.error("Você não tem permissão para acessar essa página")
            history.replace("/")
        }
    }, [history, permission])
}

export default usePermission
